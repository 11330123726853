import React, { useState, useEffect } from "react";
import "./Interactive.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import InfoIcon from "@mui/icons-material/Info";
import EraseIcon from "../../assets/icons/EraseIcon.svg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MuiGrid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportIcon from "../../assets/icons/ReportIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import "intro.js/introjs.css";
import { Steps, Hints } from "intro.js-react";
import {
  setInteractiveDetails,
  getInteractiveNames,
  getAllMasterData,
  getAllDiagnosis,
  getAllProcedures,
  setSelectedFileToDownload,
  getZipFile,
  clearValues,
  errorMessageClose,
  setDiagnosis,
  setSelectionButton,
  setDiagnosisAdditionalCode,
  updateSelectedDiagnosis,
  diagnosisMoveUp,
  diagnosisMoveDown,
  deleteDiagnosis,
  deleteAdditionalDiagnosis,
  setProcedure,
  setProcedureAdditionalCode,
  updateSelectedProcedure,
  procedureMoveUp,
  procedureMoveDown,
  deleteProcedure,
  deleteAdditionalProcedure,
  getDiagnosisSearch,
  getProcedureSearch,
  setSearchStatus,
  setGender,
  setBirthValue,
  setValues,
  dateErrorClose,
  setDateToDefault,
  setPanel,
  clearPatientInformation,
  addToSpell,
  clearSpellInformation,
  clearEpisodesInformation,
  setInteractiveList,
  interactiveProcessResult,
  showPanels,
  setRequestType,
  setReportDialog,
  clearDiseaseInformation,
  clearResultInformation,
  clearAllInformation,
  setInteractiveLoading,
  handleErrorClose,
  clearOnContact,
  resetInteractive,
} from "./interactiveSlice";
import InteractiveSelectionTree from "./InteractiveSelectionTree";
import DiagnosisTreeView from "./DiagnosisTreeView";
import ProcedureTreeView from "./ProcedureTreeView";
import Spells from "./Spells";
import Episodes from "./Episodes";
import Results from "./Results";
import Report from "./Report";
import { fabClasses } from "@mui/material";
import VisualDiagram from "./VisualDiagram";
import { redirectToLogin } from ".././authentication/authenticationSlice";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "5px 0",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

export default function Interactive() {
  const [open, setOpen] = useState(false);
  const [diagnosisSearchValue, setDiagnosisSearchValue] = useState("");
  const [procedureSearchValue, setProcedureSearchValue] = useState("");
  const [incrementDiagnosis, setIncrementDiagnosis] = useState(1);
  const [incrementAdditionalDiagnosis, setIncrementAdditionalDiagnosis] =
    useState(1);
  const [incrementProcedure, setIncrementProcedure] = useState(1);
  const [incrementAdditionalProcedure, setIncrementAdditionalProcedure] =
    useState(1);
  const [incrementInteractiveList, setIncrementInteractiveList] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [panelName, setPanelName] = useState("");
  const [showSpells, setShowSpells] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const [hintEnabled, setHintEnabled] = useState(false);
  const [stepCounter, setStepCounter] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const interactive = useSelector((state) => state.interactive);
  const {
    selectedInteractiveObj,
    selectedInteractiveName,
    selectedInteractiveYear,
    blobToDownload,
    selectedFileType,
    fileNotExists,
    patientInfoPanel,
    diseasesPanel,
    episodesPanel,
    spellsPanel,
    resultsPanel,
    isChooseDiagnosis,
    isChooseDiagAdditional,
    diagnosis,
    diagnosisData,
    diagnosisSet,
    procedure,
    procedureData,
    isChooseProcedure,
    isChooseProcAdditional,
    procedureSet,
    diagnosisSearch,
    procedureSearch,
    patientInputs,
    gender,
    disableBirth,
    birthValue,
    enableBirthInfo,
    isMale,
    age,
    ageUnit,
    gestationAge,
    discharge,
    contactType,
    birthType,
    height,
    weight,
    hospitalisationMethod,
    reasonForContact,
    contactDays,
    contactDaysStartDate,
    contactDaysEndDate,
    disableContactReason,
    varighed,
    isYearGreaterThan2021,
    disableContactDays,
    dateError,
    interactiveList,
    updateSuccess,
    contactDaysNoProgress,
    resultError,
    errorMessage,
    reportDialog,
    visualDialog,
    isResultLoading,
    noVisualIDError,
    loadDiagAndProc,
  } = interactive;
  const languageState = useSelector((state) => state.language);
  const { language } = languageState;

  useEffect(() => {
    dispatch(getInteractiveNames({ method: "GET", login: true })).then(() => {
      dispatch(getAllDiagnosis({ method: "GET" })).then(() => {
        dispatch(getAllProcedures({ method: "GET" })).then(() => { });
      });
    });
    dispatch(getAllMasterData({ method: "GET" }));
    return dispatch(resetInteractive());
  }, []);

  const handleDownload = (e) => {
    e.preventDefault();
    //console.log("selectedInteractiveName:", selectedInteractiveName);
    if (selectedInteractiveName != "") {
      var eventValue = e.currentTarget.value;
      dispatch(setSelectedFileToDownload(eventValue));
      switch (eventValue) {
        case "News":
          var url =
            "api/DownloadPdfFiles?Id=" +
            selectedInteractiveObj.id +
            "&selectionType=" +
            "Interactive" +
            "&selectedOption=" +
            "InteractiveNews";
          break;
        case "Guidance":
          var url =
            "api/DownloadPdfFiles?Id=" +
            selectedInteractiveObj.id +
            "&selectionType=" +
            "Interactive" +
            "&selectedOption=" +
            "InteractiveGuide";
          break;
      }
      dispatch(getZipFile({ url: url, method: "GET" }));
    }
  };
  const handleQuickStartGuide = (e) => {
    setEnabled(true);
  };
  const onExit = () => {
    setEnabled(false);
  };
  const steps = [
    {
      element: "#step1",
      intro: t("interactive.intro1"),
      position: "bottom",
    },
    {
      element: "#step2",
      intro: t("interactive.intro2"),
      position: "bottom",
    },
    {
      element: "#step3",
      intro: t("interactive.intro3"),
      position: "bottom",
    },
    {
      element: "#step4",
      intro: t("interactive.intro4"),
      position: "bottom",
    },
    {
      element: "#step5",
      intro: t("interactive.intro5"),
      position: "bottom",
    },
    {
      element: "#step6",
      intro: t("interactive.intro6"),
      position: "bottom",
    },
  ];
  const stepOptions = {
    showBullets: true,
    showButtons: true,
    exitOnOverlayClick: true,
    keyboardNavigation: true,
    nextLabel: t("interactive.next") + "→",
    prevLabel: "←" + t("interactive.back"),
    skipLabel: t("interactive.skip"),
    doneLabel: t("interactive.done"),
  };
  const infoTip = [
    {
      element: "#info1",
      title: t("interactive.AboutInteractive"),
      intro: t("interactive.info1"),
      position: "bottom",
      tooltipClass: "info-stepper",
    },
    {
      element: "#info2",
      title: t("interactive.AboutPatientInfo"),
      intro: t("interactive.info2"),
      position: "bottom",
      tooltipClass: "info-stepper",
    },
    {
      element: "#info3",
      title: t("interactive.AboutDiseasesTreatment"),
      intro: t("interactive.info3"),
      position: "bottom",
      tooltipClass: "info-stepper",
    },
    {
      element: "#info4",
      title: t("interactive.AboutEpisodes"),
      intro: t("interactive.info4"),
      position: "bottom",
      tooltipClass: "info-stepper",
    },
    {
      element: "#info5",
      title: t("interactive.AboutSpells"),
      intro: t("interactive.info5"),
      position: "bottom",
      tooltipClass: "info-stepper",
    },
    {
      element: "#info6",
      title: t("interactive.AboutResults"),
      intro: t("interactive.info6"),
      position: "bottom",
      tooltipClass: "info-stepper",
    },
  ];
  const handleHintClick = (count) => (e) => {
    setHintEnabled(true);
    setStepCounter(count);
  };
  const handleInfoClose = () => {
    setHintEnabled(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    if (selectedInteractiveObj) {
      dispatch(clearAllInformation());
      dispatch(
        setInteractiveDetails({
          id: selectedInteractiveObj.id,
          name: selectedInteractiveObj.interactiveName,
          year: selectedInteractiveObj.year,
        })
      );
      setDiagnosisSearchValue("");
      setProcedureSearchValue("");
      dispatch(setSearchStatus({ name: "diagnosis", value: false }));
      dispatch(setSearchStatus({ name: "procedure", value: false }));
      dispatch(getAllDiagnosis({ method: "GET" })).then(() => {
        dispatch(getAllProcedures({ method: "GET" }));
      });
      dispatch(getAllMasterData({ method: "GET" }));
    }
    setOpen(false);
  };

  const handlePanelChange = (panel) => (event, newExpanded) => {
    dispatch(setPanel(panel));
  };

  const handleClear = (panel) => (event, newExpanded) => {
    event.preventDefault();
    setPanelName(panel);
    if (panel === "patientInfoPanel") {
      setDialogContent(t("interactive.patientClear"));
    } else if (panel === "diseasesPanel") {
      setDialogContent(t("interactive.diseaseClear"));
    } else if (panel === "spellsPanel") {
      setDialogContent(t("interactive.spellClear"));
    } else if (panel === "episodesPanel") {
      setDialogContent(t("interactive.episodesClear"));
    } else if (panel === "resultsPanel") {
      setDialogContent(t("interactive.resultClear"));
    } else if (panel === "clearAll") {
      setDialogContent(t("interactive.clearAllInfo"));
    }
    setOpenDialog(true);
  };

  const handleOk = () => {
    setOpenDialog(false);
    if (panelName === "patientInfoPanel") {
      dispatch(clearPatientInformation());
      dispatch(getAllMasterData({ method: "GET" }));
    } else if (panelName === "diseasesPanel") {
      dispatch(clearDiseaseInformation());
      setDiagnosisSearchValue("");
      setProcedureSearchValue("");
      dispatch(getAllDiagnosis({ method: "GET" }));
      dispatch(getAllProcedures({ method: "GET" }));
    } else if (panelName === "spellsPanel") {
      dispatch(clearSpellInformation());
    } else if (panelName === "episodesPanel") {
      dispatch(clearEpisodesInformation());
    } else if (panelName === "resultsPanel") {
      dispatch(clearResultInformation());
    } else if (panelName === "clearAll") {
      dispatch(clearAllInformation());
      setDiagnosisSearchValue("");
      setProcedureSearchValue("");
      dispatch(getAllDiagnosis({ method: "GET" })).then(() => {
        dispatch(getAllProcedures({ method: "GET" }));
      });
      dispatch(getAllMasterData({ method: "GET" }));
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  //Patient Information Section
  const handleGenderChange = (e) => {
    dispatch(setGender(e.target.value));
  };

  const handleBirthChange = (e) => {
    dispatch(setBirthValue(e.target.value));
  };

  const handleAgeChange = (e) => {
    dispatch(setValues({ name: "age", value: e.target.value }));
  };

  const handleAgeUnitChange = (e) => {
    dispatch(setValues({ name: "ageUnit", value: e.target.value }));
  };

  const handleGestationAgeChange = (e) => {
    dispatch(setValues({ name: "gestationAge", value: e.target.value }));
  };

  const handleDischargeChange = (e) => {
    dispatch(setValues({ name: "discharge", value: e.target.value }));
  };

  const handleContactTypeChange = (e) => {
    dispatch(setValues({ name: "contactType", value: e.target.value }));
    if(e.target.value == "6"){
      dispatch(setSelectionButton({ name: "diagnosis", value: true }));
      dispatch(setSelectionButton({ name: "diagAdditional", value: true }));
      dispatch(clearOnContact());
    }
    if(diagnosis.length > 0 && e.target.value!= "6"){
      dispatch(setSelectionButton({ name: "diagnosis", value: false }));
      dispatch(setSelectionButton({ name: "diagAdditional", value: false }));
    }
  };

  const handleBirthTypeChange = (e) => {
    dispatch(setValues({ name: "birthType", value: e.target.value }));
  };

  const handleHeightChange = (e) => {
    dispatch(setValues({ name: "height", value: e.target.value }));
  };

  const handleWeightChange = (e) => {
    dispatch(setValues({ name: "weight", value: e.target.value }));
  };

  const handlePriorityChange = (e) => {
    dispatch(
      setValues({ name: "hospitalisationMethod", value: e.target.value })
    );
  };

  const handleContactReasonChange = (e) => {
    dispatch(setValues({ name: "reasonForContact", value: e.target.value }));
  };

  const handleContactDaysChange = (e) => {
    dispatch(setValues({ name: "contactDays", value: e.target.value }));
  };

  const handleContactStartDateChange = (val) => {
    dispatch(setValues({ name: "contactDaysStartDate", value: val }));
    if (contactDaysStartDate != null && contactDaysEndDate != null) {
      calculateDate();
    }
  };

  const handleContactEndDateChange = (val) => {
    dispatch(setValues({ name: "contactDaysEndDate", value: val }));
    if (contactDaysStartDate != null && contactDaysEndDate != null) {
      calculateDate();
    }
  };
  if (contactDaysStartDate != null && contactDaysEndDate != null) {
    calculateDate();
  }
  function calculateDate() {
    var date1 = contactDaysStartDate;
    var date2 = contactDaysEndDate;
    if (date1 != null && date2 != null) {
      var oneDay = 24 * 60 * 60 * 1000;
      var duration = Math.round(
        Math.abs((date1.getTime() - date2.getTime()) / oneDay)
      );
      if (date1 > date2) {
        dispatch(setDateToDefault());
      } else {
        var contactDays = duration + 1;
        dispatch(setValues({ name: "contactDays", value: String(contactDays) }));
      }
    }
  }
  const handleDurationChange = (e) => {
    dispatch(setValues({ name: "varighed", value: e.target.value }));
  };

  function displayGestation() {
    if (patientInputs.length > 0 && isMale) {
      return Object.values(patientInputs[0].maleGestation).map((a) => {
        return <MenuItem key={a.value} value={a.value}>{a.name[language]}</MenuItem>;
      });
    } else if (patientInputs.length > 0) {
      return Object.values(patientInputs[0].femaleGestation).map((a) => {
        return <MenuItem key={a.value} value={a.value}>{a.name[language]}</MenuItem>;
      });
    } else {
      return null;
    }
  }

  //Diagnosis
  const selectDiagnosis = (e) => {
    setIncrementDiagnosis(incrementDiagnosis + 1);
    var tempData = [];
    if (diagnosis.length <= 0) {
      dispatch(
        setDiagnosis({
          uniqueID: incrementDiagnosis,
          type: "A",
          code: diagnosisData.code,
          Id: diagnosisData.id,
          name: diagnosisData.name,
          selected: true,
        })
      );
    } else {
      dispatch(
        setDiagnosis({
          uniqueID: incrementDiagnosis,
          type: "B",
          code: diagnosisData.code,
          Id: diagnosisData.id,
          name: diagnosisData.name,
          selected: false,
        })
      );
    }
  };

  if (diagnosisSet) {
    checkAdditionalDiagButton();
  }

  function checkAdditionalDiagButton() {
    if ((diagnosis.length > 0) && contactType!= "6" && isChooseDiagnosis == false)  {
      dispatch(setSelectionButton({ name: "diagAdditional", value: false }));
    } else {
      dispatch(setSelectionButton({ name: "diagAdditional", value: true }));
    }
  }

  const selectDiagnosisAdditionalCode = (e) => {
    setIncrementAdditionalDiagnosis(incrementAdditionalDiagnosis + 1);
    var index = diagnosis.findIndex((item) => item.selected === true);
    dispatch(
      setDiagnosisAdditionalCode({
        index: index,
        uniqueID: incrementAdditionalDiagnosis,
        type: "T",
        code: diagnosisData.code,
        Id: diagnosisData.Id,
        name: diagnosisData.name,
      })
    );
  };

  const handleDiagnosisSelect = (item) => () => {
    var index = diagnosis.findIndex((a) => a.Id === item.Id);
    dispatch(updateSelectedDiagnosis({ index: index, value: true }));
  };
  const handleDiagnosisMoveUp = (index) => () => {
    dispatch(diagnosisMoveUp(index));
  };
  const handleDiagnosisMoveDown = (index) => () => {
    dispatch(diagnosisMoveDown(index));
  };
  const handleDeleteDiagnosis = (val) => () => {
    var index = diagnosis.findIndex((item) => item.uniqueID === val);
    dispatch(deleteDiagnosis({ index: index }));
  };

  const handleDeleteAdditionalDiagnosis = (diagID, codeID) => () => {
    var diagIndex = diagnosis.findIndex((item) => item.uniqueID === diagID);
    var addDiagIndex = diagnosis[diagIndex].additionalCode.findIndex(
      (a) => a.uniqueID === codeID
    );
    dispatch(
      deleteAdditionalDiagnosis({
        diagIndex: diagIndex,
        addDiagIndex: addDiagIndex,
      })
    );
  };

  const handleDiagnosisSearch = (event) => {
    setDiagnosisSearchValue(event.target.value);
    if (event.target.value.length > 2) {
      dispatch(
        getDiagnosisSearch({
          method: "GET",
          search: event.target.value ?.toLowerCase(),
        })
      ).then(() => {
        dispatch(setSearchStatus({ name: "diagnosis", value: true }));
      });
    }
    else if (event.target.value == "") {
      dispatch(setSearchStatus({ name: "diagnosis", value: false }));
    }
  };
  const closeDiagnosisSearch = () => {
    setDiagnosisSearchValue("");
    dispatch(setSearchStatus({ name: "diagnosis", value: false }));
  };

  //Procedure
  const selectProcedure = (e) => {
    setIncrementProcedure(incrementProcedure + 1);
    dispatch(
      setProcedure({
        uniqueID: incrementProcedure,
        type: "P",
        code: procedureData.code,
        Id: procedureData.id,
        name: procedureData.name,
        selected: procedure.length <= 0 ? true : false,
      })
    );
  };

  if (procedureSet) {
    checkAdditionalProcButton();
  }
  function checkAdditionalProcButton() {
    if (procedure.length > 0) {
      dispatch(setSelectionButton({ name: "procAdditional", value: false }));
    } else {
      dispatch(setSelectionButton({ name: "procAdditional", value: true }));
    }
  }

  const selectProcedureAdditionalCode = (e) => {
    setIncrementAdditionalProcedure(incrementAdditionalProcedure + 1);
    var index = procedure.findIndex((item) => item.selected === true);
    dispatch(
      setProcedureAdditionalCode({
        index: index,
        uniqueID: incrementAdditionalProcedure,
        type: "T",
        code: procedureData.code,
        Id: procedureData.Id,
        name: procedureData.name,
      })
    );
  };

  const handleProcedureSelect = (item) => () => {
    var index = procedure.findIndex((a) => a.Id === item.Id);
    dispatch(updateSelectedProcedure({ index: index, value: true }));
  };

  const handleProcedureMoveUp = (index) => () => {
    dispatch(procedureMoveUp(index));
  };
  const handleProcedureMoveDown = (index) => () => {
    dispatch(procedureMoveDown(index));
  };
  const handleDeleteProcedure = (val) => () => {
    var index = procedure.findIndex((item) => item.uniqueID === val);
    dispatch(deleteProcedure({ index: index }));
  };

  const handleDeleteAdditionalProcedure = (procID, codeID) => () => {
    var procIndex = procedure.findIndex((item) => item.uniqueID === procID);
    var addProcIndex = procedure[procIndex].additionalCode.findIndex(
      (a) => a.uniqueID === codeID
    );
    dispatch(
      deleteAdditionalProcedure({
        procIndex: procIndex,
        addProcIndex: addProcIndex,
      })
    );
  };

  const handleProcedureSearch = (event) => {
    setProcedureSearchValue(event.target.value);
    if (event.target.value.length > 2) {
      dispatch(
        getProcedureSearch({
          method: "GET",
          search: event.target.value ?.toLowerCase(),
        })
      ).then(() => {
        dispatch(setSearchStatus({ name: "procedure", value: true }));
      });
    }
    else if (event.target.value == "") {
      dispatch(setSearchStatus({ name: "procedure", value: false }));
    }
  };
  const closeProcedureSearch = () => {
    setProcedureSearchValue("");
    dispatch(setSearchStatus({ name: "procedure", value: false }));
  };

  const handleAddToSpell = (e) => {
    if (diagnosis.length > 0 || (procedure.length> 0 && contactType == '6')) {
      setShowSpells(true);

      getPatientInputs(e.target.value);
    } else {
      setShowSpells(false);
    }
  };

  const handleRunQuery = (e) => {
    if (diagnosis.length > 0 || (procedure.length> 0 && contactType == '6')) {
      //setShowEpisodes(true);
      dispatch(setInteractiveLoading(true));
      getPatientInputs(e.target.value);
    } else {
      //setShowEpisodes(false);
    }
  };

  //Used to generate the interactive array from selected values
  function getPatientInputs(handler) {
    setIncrementInteractiveList(incrementInteractiveList + 1);
    var id = incrementInteractiveList;
    var genderText = patientInputs[0].gender.filter((a) => a.value == gender)[0]
      ?.name;
    var ageTypeText = patientInputs[0].unit.filter((a) => a.value == ageUnit)[0]
      ?.name;
    var contactTypeText = patientInputs[0].contactType.filter(
      (a) => a.value == contactType
    )[0].name;
    var hospWayText = patientInputs[0].hospitalisationMethod.filter(
      (a) => a.value == hospitalisationMethod
    )[0].name;
    var dischargeText = patientInputs[0].releaseMethod.filter(
      (a) => a.value == discharge
    )[0].name;
    var birthTypeText = patientInputs[0].birthType.filter(
      (a) => a.value == birthType
    )[0].name;
    var reasonForContactText = patientInputs[0].reasonForContact.filter(
      (a) => a.value == reasonForContact
    )[0].name;
    if (isMale) {
      var gestationText = patientInputs[0].maleGestation.filter(
        (a) => a.value == gestationAge
      )[0].name;
    } else {
      var gestationText = patientInputs[0].femaleGestation.filter(
        (a) => a.value == gestationAge
      )[0].name;
    }
    if (isYearGreaterThan2021)
      var varighedDescription = patientInputs[0].varighed.filter(
        (a) => a.value == varighed
      )[0].name;
    var intData = [];
    intData.push({
      id: incrementInteractiveList,
      age: age,
      ageTypeValue: ageUnit,
      ageTypeText: ageTypeText,
      contactTypeValue: contactType,
      contactTypeText: contactTypeText,
      genderValue: gender,
      genderText: genderText,
      isMale: isMale,
      isBirth: birthValue === "Yes" ? 1 : 0,
      birthTypeText: birthTypeText,
      birthTypeValue: birthType,
      height: height,
      weight: weight,
      hospWayText: hospWayText,
      hospWayValue: hospitalisationMethod,
      reasonForContactText: reasonForContactText,
      reasonForContactValue: reasonForContact,
      dischargeText: dischargeText,
      dischargeValue: discharge,
      maleGestationAgeValue: gestationAge,
      femaleGestationAgeValue: gestationAge,
      gestationText: gestationText,
      varighed: varighed,
      varighedDescription: varighedDescription,
      contactDays: parseInt(contactDays),
      contactDaysStartDate: contactDaysStartDate,
      contactDaysEndDate: contactDaysEndDate,
      diagnosis: diagnosis,
      procedure: procedure,
      expandCollapseValue: "Collapse",
      registrations: [],
      progress: [],
      results: [],
    });

    if (handler === "AddToSpell") {
      dispatch(addToSpell({ data: intData, handler: handler }));
    } else if (handler === "RunQuery") {
      dispatch(addToSpell({ data: intData, handler: handler }));
      var requestList = createRequestList(intData[0]);
      postInteractiveRequest(requestList, "contact", intData, handler);
    }
  }
  /* if (updateSuccess && showEpisodes) {
    createRequestList();
  } */
  function createRequestList(obj) {
    var reqData = [];

    let gestationAge = isMale
      ? {
        Code: obj.maleGestationAgeValue,
        Description: obj.gestationText[language],
        Significance: "",
      }
      : {
        Code: obj.femaleGestationAgeValue,
        Description: obj.gestationText[language],
        Significance: "",
      };
    let reasonForContact =
      obj.hospWayValue === "1"
        ? generateListItems(obj, "ReasonForContact")
        : null;
    let contactType = generateListItems(obj, "ContactType");
    var liggeDage = "";
    if (isYearGreaterThan2021) {
      if (obj.varighed == "0") {
        liggeDage = "";
      } else if (obj.varighed == "1") {
        liggeDage = "1";
      } else {
        liggeDage = "12";
      }
    }
    var diagnosis = [];
    obj.diagnosis.forEach((ele) => {
      let addlCodes = [];
      if (ele.additionalCode != undefined) {
        for (var i = 0; i < ele.additionalCode.length; i++) {
          addlCodes.push({
            Code: ele.additionalCode[i].code,
            Description: ele.additionalCode[i].name,
          });
        }
      }
      diagnosis.push({
        Type: ele.type,
        Code: ele.code,
        Description: ele.name,
        AdditionalCodes: addlCodes,
      });
    });
    var procedure = [];
    obj.procedure.forEach((ele) => {
      let addlCodes = [];
      if (ele.additionalCode != undefined) {
        for (var i = 0; i < ele.additionalCode.length; i++) {
          addlCodes.push({
            Code: ele.additionalCode[i].code,
            Description: ele.additionalCode[i].name,
          });
        }
      }
      procedure.push({
        Type: ele.type,
        Code: ele.code,
        Description: ele.name,
        AdditionalCodes: addlCodes,
      });
    });
    if (isYearGreaterThan2021) {
      reqData.push({
        RId: 1,
        Age: obj.age,
        AgeTypeText: obj.ageTypeText[language],
        AgeTypeValue: obj.ageTypeValue,
        Gender: {
          Code: obj.genderValue,
          Description: obj.genderText[language],
          Significance: "",
        },
        GestationAge: gestationAge,
        AdmissionType: {
          Code: obj.hospWayValue,
          Description: obj.hospWayText[language],
          Significance: "",
        },
        ContactReason: reasonForContact,
        DischargeType: {
          Code: obj.dischargeValue,
          Description: obj.dischargeText[language],
          Significance: "",
        },
        ContactType: contactType,
        BedinDays: obj.contactDays == "" ? null : obj.contactDays,
        SurvivalDays: null,
        LiggidageBedInDays: liggeDage,
        Varighed: {
          Code: obj.varighed,
          Description: obj.varighedDescription[language],
          Significance: "",
        },
        isYearGreaterthan2021: isYearGreaterThan2021,
        IsBirth: obj.isBirth === 1 ? true : false,
        Height: obj.isBirth === 1 ? Number(obj.height) : null,
        Weight: obj.isBirth === 1 ? Number(obj.weight) : null,
        BirthType: obj.isBirth === 1 ? Number(obj.birthTypeValue) : 0,
        BirthTypeText: obj.isBirth === 1 ? obj.birthTypeText[language]: null,
        Diagnosis: diagnosis,
        Procedure: procedure,
      });
    } else {
      reqData.push({
        RId: 1,
        Age: obj.age,
        AgeTypeText: obj.ageTypeText[language],
        AgeTypeValue: obj.ageTypeValue,
        Gender: {
          Code: obj.genderValue,
          Description: obj.genderText[language],
          Significance: "",
        },
        GestationAge: gestationAge,
        AdmissionType: {
          Code: obj.hospWayValue,
          Description: obj.hospWayText[language],
          Significance: "",
        },
        ContactReason: reasonForContact,
        DischargeType: {
          Code: obj.dischargeValue,
          Description: obj.dischargeText[language],
          Significance: "",
        },
        ContactType: contactType,
        BedinDays: obj.contactDays == "" ? null : obj.contactDays,
        SurvivalDays: null,
        isYearGreaterthan2021: isYearGreaterThan2021,
        IsBirth: obj.isBirth === 1 ? true : false,
        Height: obj.isBirth === 1 ? Number(obj.height) : null,
        Weight: obj.isBirth === 1 ? Number(obj.weight) : null,
        BirthType: obj.isBirth === 1 ? Number(obj.birthTypeValue) : 0,
        BirthTypeText: obj.isBirth === 1 ? obj.birthTypeText[language]: null,
        Diagnosis: diagnosis,
        Procedure: procedure,
      });
    }
    return reqData;
  }

  function generateListItems(obj, col) {
    let retObj = {};
    if (col == "ContactType") {
      if (isYearGreaterThan2021) {
        if (obj.contactTypeValue == "6") {
          retObj.Code = "2";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else if (obj.contactTypeValue == "5") {
          retObj.Code = "1";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else {
          retObj.Code = obj.contactTypeValue;
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        }
      } else {
        if (obj.contactTypeValue == "3") {
          retObj.Code = "5";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else if (obj.contactTypeValue == "6") {
          retObj.Code = "5";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else {
          retObj.Code = obj.contactTypeValue;
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        }
      }
    } else if (col == "ReasonForContact") {
      if (obj.reasonForContactValue == "0") {
        retObj.Code = " ";
        retObj.Description = obj.reasonForContactText[language];
        retObj.Significance = "";
      } else {
        retObj.Code = obj.reasonForContactValue;
        retObj.Description = obj.reasonForContactText[language];
        retObj.Significance = "";
      }
    }
    return retObj;
  }

  function postInteractiveRequest(requestList, type, data, handler) {
    var viewModel = {};
    let overWriteBedDays, overWriteVarighed;
    dispatch(setRequestType(type));
    if (type == "episode") {
      overWriteBedDays = contactDaysNoProgress;
      if (isYearGreaterThan2021) {
        //varighed
        overWriteVarighed = requestList.Varighed;
      }
    } else {
      overWriteBedDays = null;
      overWriteVarighed = null;
    }

    viewModel = {
      Name: selectedInteractiveObj.interactiveName,
      Version: selectedInteractiveObj.interactiveVersion,
      Year: selectedInteractiveObj.year,
      OverWriteBedDays: overWriteBedDays,
      OverWriteVarighed: overWriteVarighed,
      interactive: requestList,
    };

    dispatch(
      interactiveProcessResult({ method: "POST", body: viewModel })
    ).then(() => {
      //console.log("Res Run Query");
    });
  }
  const handleReport = (e) => {
    if (resultsPanel) {
      dispatch(setReportDialog(true));
    }
  };

  if (blobToDownload && selectedFileType) {
    switch (selectedFileType) {
      case "News":
        saveAs(blobToDownload, "Interactive News.pdf");
        break;
      case "Guidance":
        saveAs(blobToDownload, "Interactive Guidance.pdf");
        break;
    }
    dispatch(clearValues());
  }

  if (patientInputs.length > 0) {
    //console.log(Object.values(patientInputs[0].contactType)[0].value);
  }

  if (!interactive.isSessionValid) {
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  return (
    <div className="interactive-container">
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        options={stepOptions}
      />
      <div className="info-stepper">
        <Steps
          enabled={hintEnabled}
          steps={infoTip}
          initialStep={stepCounter}
          onExit={handleInfoClose}
          options={stepOptions}
        />
      </div>
      <Paper className="interactive-section-container">
        <div className="interactive-section-header" id="step1">
          <div className="interactive-header-left">
            <Typography
              id="headerLabel"
              variant="h6"
              className="interactive-title"
              gutterBottom
            >
              {t("interactive.Interactive")}
            </Typography>
            <InfoIcon
              fontSize="small"
              id="info1"
              sx={{ paddingLeft: "10px", fill: "#959CA0", cursor: "pointer" }}
              onClick={handleHintClick(0)}
            />
          </div>
          <div className="interactive-header-right">
            <Button
              variant="contained"
              size="small"
              className="interactive-button-news"
              onClick={handleDownload}
              value="News"
            >
              <DownloadIcon sx={{ paddingRight: "10px" }} />
              {t("interactive.news")} (PDF)
            </Button>
            <Button
              variant="contained"
              size="small"
              className="interactive-button-guidance"
              onClick={handleDownload}
              value="Guidance"
            >
              <DownloadIcon sx={{ paddingRight: "10px" }} />
              {t("interactive.guidance")} (PDF)
            </Button>
            <Button
              variant="contained"
              size="small"
              className="interactive-button-guide"
              onClick={handleQuickStartGuide}
              value="QuickStart"
            >
              <HelpOutlineIcon sx={{ paddingRight: "10px" }} />
              {t("interactive.quickStartGuide")}
            </Button>
            <Button
              variant="contained"
              size="small"
              className="interactive-button-clear"
              onClick={handleClear("clearAll")}
              value="Clear"
            >
              <CloseIcon sx={{ paddingRight: "10px" }} />
              {t("interactive.clearAll")}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="interactive-section">
          <Paper className="interactive-content">
            <div className="interactive-content-header">
              <Typography
                id="headerLabel"
                className="interactive-text"
                gutterBottom
              >
                {selectedInteractiveName == ""
                  ? "Select the interactive file"
                  : selectedInteractiveName + " - " + selectedInteractiveYear}
              </Typography>
              <Link
                component="button"
                variant="body2"
                className="interactive-link"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("Global.open_file")}
              </Link>
            </div>
            <Divider />
            <Box sx={{ width: "100%" }}>
              <Accordion expanded={patientInfoPanel} id="step2">
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <ArrowRightIcon
                      fontSize="large"
                      onClick={handlePanelChange("patientInfoPanel")}
                      sx={{ cursor: "pointer" }}
                    />
                  }
                  sx={{
                    padding: "0px !important",
                    cursor: "default !important",
                  }}
                >
                  <div className="interactive-accordion-text">
                    <Typography className="interactive-text">
                      {t("interactive.patientInformation")}
                    </Typography>
                    <InfoIcon
                      fontSize="small"
                      id="info2"
                      sx={{
                        paddingLeft: "10px",
                        fill: "#959CA0",
                        cursor: "pointer",
                      }}
                      onClick={handleHintClick(1)}
                    />
                  </div>
                  <div>
                    <Button
                      sx={{ paddingRight: "20px", cursor: "pointer" }}
                      onClick={handleClear("patientInfoPanel")}
                    >
                      <img
                        src={EraseIcon}
                        alt="Clear"
                        style={{ paddingRight: "5px" }}
                      />
                      {t("interactive.clear")}
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="patient-information-1">
                    <TextField
                      label={t("interactive.sex")}
                      variant="outlined"
                      select
                      value={
                        patientInputs.length > 0 && gender == ""
                          ? Object.values(patientInputs[0].gender)[0].value
                          : gender
                      }
                      onChange={handleGenderChange}
                      sx={{ width: "10%" }}
                    >
                      {patientInputs.length > 0
                        ? Object.values(patientInputs[0].gender).map((a) => {
                          return (
                            <MenuItem key={a.value} value={a.value}>
                              {a.name[language]}
                            </MenuItem>
                          );
                        })
                        : null}
                    </TextField>
                    <TextField
                      label={t("interactive.birth")}
                      variant="outlined"
                      select
                      value={birthValue}
                      disabled={disableBirth}
                      onChange={handleBirthChange}
                      sx={{ width: "7%" }}
                    >
                      <MenuItem key="Yes" value="Yes">{t("Global.yes")}</MenuItem>
                      <MenuItem key="No" value="No">{t("Global.no")}</MenuItem>
                    </TextField>
                    <TextField
                      label={t("interactive.age")}
                      variant="outlined"
                      value={age
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1")}
                      onChange={handleAgeChange}
                      sx={{ width: "7%" }}
                    />
                    <TextField
                      label={t("interactive.unitAge")}
                      variant="outlined"
                      select
                      value={
                        patientInputs.length > 0 && ageUnit == ""
                          ? Object.values(patientInputs[0].unit)[0].value
                          : ageUnit
                      }
                      onChange={handleAgeUnitChange}
                      sx={{ width: "9%" }}
                    >
                      {patientInputs.length > 0
                        ? Object.values(patientInputs[0].unit).map((a) => {
                          return (
                            <MenuItem key={a.value} value={a.value}>
                              {a.name[language]}
                            </MenuItem>
                          );
                        })
                        : null}
                    </TextField>
                    <TextField
                      label={t("interactive.gestationAge")}
                      variant="outlined"
                      value={gestationAge}
                      onChange={handleGestationAgeChange}
                      select
                      sx={{ width: "20%" }}
                    >
                      {patientInputs.length > 0 ? displayGestation() : null}
                    </TextField>
                    <TextField
                      label={t("interactive.dischargeType")}
                      variant="outlined"
                      select
                      value={
                        patientInputs.length > 0 && discharge == ""
                          ? Object.values(patientInputs[0].releaseMethod)[0]
                            .value
                          : discharge
                      }
                      onChange={handleDischargeChange}
                      sx={{ width: "20%" }}
                    >
                      {patientInputs.length > 0
                        ? Object.values(patientInputs[0].releaseMethod).map(
                          (a) => {
                            return (
                              <MenuItem key={a.value} value={a.value}>
                                {a.name[language]}
                              </MenuItem>
                            );
                          }
                        )
                        : null}
                    </TextField>
                    <TextField
                      label={t("interactive.contactType")}
                      variant="outlined"
                      select
                      value={
                        patientInputs.length > 0 && contactType == ""
                          ? Object.values(patientInputs[0].contactType)[0].value
                          : contactType
                      }
                      onChange={handleContactTypeChange}
                      sx={{ width: "20%" }}
                    >
                      {patientInputs.length > 0
                        ? Object.values(patientInputs[0].contactType).map(
                          (a) => {
                            return (
                              <MenuItem key={a.value} value={a.value}>
                                {a.name[language]}
                              </MenuItem>
                            );
                          }
                        )
                        : null}
                    </TextField>
                  </div>
                  {enableBirthInfo ? (
                    <div className="patient-information-2">
                      <Typography className="interactive-text">
                        {t("interactive.motherInformation")}
                      </Typography>
                      <div className="patient-information-female">
                        <TextField
                          label={t("interactive.birthType")}
                          variant="outlined"
                          select
                          value={
                            patientInputs.length > 0 && birthType == ""
                              ? Object.values(patientInputs[0].birthType)[0]
                                .value
                              : birthType
                          }
                          onChange={handleBirthTypeChange}
                          sx={{ width: "50%" }}
                        >
                          {patientInputs.length > 0
                            ? Object.values(patientInputs[0].birthType).map(
                              (a) => {
                                return (
                                  <MenuItem key={a.value} value={a.value}>
                                    {a.name[language]}
                                  </MenuItem>
                                );
                              }
                            )
                            : null}
                        </TextField>
                        <TextField
                          label={t("interactive.height")}
                          variant="outlined"
                          value={height}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">cm</InputAdornment>
                            ),
                          }}
                          onChange={handleHeightChange}
                          sx={{ width: "20%" }}
                        />
                        <TextField
                          label={t("interactive.weight")}
                          variant="outlined"
                          value={weight}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">kg</InputAdornment>
                            ),
                          }}
                          onChange={handleWeightChange}
                          sx={{ width: "20%" }}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="patient-information-3">
                    <TextField
                      label={t("interactive.priority")}
                      variant="outlined"
                      select
                      value={
                        patientInputs.length > 0 && hospitalisationMethod == ""
                          ? Object.values(
                            patientInputs[0].hospitalisationMethod
                          )[1].value
                          : hospitalisationMethod
                      }
                      onChange={handlePriorityChange}
                      sx={
                        isYearGreaterThan2021
                          ? { width: "15%" }
                          : { width: "20%" }
                      }
                      helperText={t("interactive.priority_helper")}
                    >
                      {patientInputs.length > 0
                        ? Object.values(
                          patientInputs[0].hospitalisationMethod
                        ).map((a) => {
                          return (
                            <MenuItem key={a.value} value={a.value}>
                              {a.name[language]}
                            </MenuItem>
                          );
                        })
                        : null}
                    </TextField>
                    <TextField
                      label={t("interactive.contactReason")}
                      variant="outlined"
                      select
                      value={
                        patientInputs.length > 0 && reasonForContact == ""
                          ? Object.values(patientInputs[0].reasonForContact)[0]
                            .value
                          : reasonForContact
                      }
                      onChange={handleContactReasonChange}
                      sx={
                        isYearGreaterThan2021
                          ? { width: "15%" }
                          : { width: "20%" }
                      }
                      disabled={disableContactReason}
                    >
                      {patientInputs.length > 0
                        ? Object.values(patientInputs[0].reasonForContact).map(
                          (a) => {
                            return (
                              <MenuItem key={a.value} value={a.value}>
                                {a.name[language]}
                              </MenuItem>
                            );
                          }
                        )
                        : null}
                    </TextField>
                    {isYearGreaterThan2021 ? (
                      <TextField
                        label={t("interactive.duration")}
                        variant="outlined"
                        select
                        value={
                          patientInputs.length > 0 && varighed == ""
                            ? Object.values(patientInputs[0].varighed)[0].value
                            : varighed
                        }
                        onChange={handleDurationChange}
                        sx={{ width: "15%" }}
                      >
                        {patientInputs.length > 0
                          ? Object.values(patientInputs[0].varighed).map(
                            (a) => {
                              return (
                                <MenuItem key={a.value} value={a.value}>
                                  {a.name[language]}
                                </MenuItem>
                              );
                            }
                          )
                          : null}
                      </TextField>
                    ) : null}
                    <TextField
                      label={
                        isYearGreaterThan2021
                          ? t("interactive.lengthOfStay")
                          : t("interactive.contactDays")
                      }
                      variant="outlined"
                      value={disableContactDays ? "" : String(contactDays)}
                      onChange={handleContactDaysChange}
                      disabled={isYearGreaterThan2021 && disableContactDays}
                      sx={
                        isYearGreaterThan2021
                          ? { width: "10%" }
                          : { width: "15%" }
                      }
                      helperText={t("interactive.helperText")}
                    />
                    <div className="patient-information-4">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label={t("interactive.admissiondate")}
                          value={contactDaysStartDate}
                          onChange={(newValue) =>
                            handleContactStartDateChange(newValue)
                          }
                          disabled={isYearGreaterThan2021 && disableContactDays}
                          className="interactive-date-picker"
                          sx={{ width: "20%" }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label={t("interactive.dischargedate")}
                          className="interactive-date-picker"
                          value={contactDaysEndDate}
                          onChange={(newValue) =>
                            handleContactEndDateChange(newValue)
                          }
                          sx={{ width: "20%" }}
                          disabled={isYearGreaterThan2021 && disableContactDays}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={diseasesPanel} id="step3">
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <ArrowRightIcon
                      fontSize="large"
                      onClick={handlePanelChange("diseasesPanel")}
                      sx={{ cursor: "pointer" }}
                    />
                  }
                  sx={{
                    padding: "0px !important",
                    cursor: "default !important",
                  }}
                >
                  <div className="interactive-accordion-text">
                    <Typography className="interactive-text">
                      {t("interactive.diseasesTreatments")}
                    </Typography>
                    <InfoIcon
                      fontSize="small"
                      id="info3"
                      sx={{
                        paddingLeft: "10px",
                        fill: "#959CA0",
                        cursor: "pointer",
                      }}
                      onClick={handleHintClick(2)}
                    />
                  </div>
                  <div>
                    <Button
                      sx={{ paddingRight: "20px", cursor: "pointer" }}
                      onClick={handleClear("diseasesPanel")}
                    >
                      <img
                        src={EraseIcon}
                        alt="Clear"
                        style={{ paddingRight: "5px" }}
                      />
                      {t("interactive.clear")}
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Grid container>
                      <Grid item xs className="interactive-grid">
                        <div className="selection-container">
                          <Typography
                            id="headerLabel"
                            className="interactive-text"
                            gutterBottom
                            sx={{ 
                              paddingBottom: "10px",
                              fontSize: "12px !important", 
                            }}
                          >
                            {t("interactive.diagnosis")}
                          </Typography>
                          <div className="selection-button-container">
                            <Button
                              sx={{ paddingRight: "10px" }}
                              disabled={isChooseDiagnosis}
                              onClick={selectDiagnosis}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                sx={{ paddingRight: "5px" }}
                              />
                              {t("interactive.diagnosis")}
                            </Button>
                            <Button
                              sx={{ paddingRight: "10px" }}
                              disabled={isChooseDiagAdditional}
                              onClick={selectDiagnosisAdditionalCode}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                sx={{ paddingRight: "5px" }}
                              />
                              {t("interactive.additionalCode")}
                            </Button>
                          </div>
                        </div>
                        <TextField
                          variant="outlined"
                          placeholder={t("interactive.search")}
                          value={diagnosisSearchValue}
                          onChange={handleDiagnosisSearch}
                          sx={{ width: "100%", paddingBottom: "10px" }}
                          InputProps={{
                            startAdornment: (
                              <SearchIcon sx={{ color: "#00000040" }} />
                            ),
                            endAdornment: (
                              <CancelRoundedIcon
                                fontSize="small"
                                sx={{ color: "#00000040", cursor: "pointer" }}
                                onClick={closeDiagnosisSearch}
                              />
                            ),
                          }}
                        />
                        {loadDiagAndProc ? (
                          <DiagnosisTreeView
                            search={diagnosisSearch ? true : false}
                          />
                        ) : null}
                        <div className="interactive-space"></div>
                        <Paper
                          elevation={0}
                          className="interactive-paper-selected"
                        >
                          <Typography
                            id="headerLabel"
                            className="interactive-text"
                            gutterBottom
                            sx={{ padding: "10px" }}
                          >
                            {t("interactive.selectedDiagnosis")}
                          </Typography>
                          <div className="selected-diagnosis">
                            {diagnosis ?.map((item, index) => {
                              return (
                                <div className="selected-diagnosis-item">
                                  <Paper
                                    elevation={0}
                                    className="selected-primary"
                                  >
                                    <Link
                                      underline="none"
                                      onClick={handleDiagnosisSelect(item)}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography
                                        gutterBottom
                                        className="selected-text"
                                      >
                                        <span>{item.type}</span>&nbsp;&nbsp;
                                        {item.name}
                                      </Typography>
                                    </Link>
                                    <div className="selected-diagnosis-button">
                                      {diagnosis.length > 1 && (
                                        <div>
                                          {index === 0 ? (
                                            <ArrowDownwardIcon
                                              fontSize="small"
                                              sx={{
                                                paddingRight: "2px",
                                                fill: "#959CA0",
                                                cursor: "pointer",
                                              }}
                                              onClick={handleDiagnosisMoveDown(
                                                index
                                              )}
                                            />
                                          ) : (
                                              <ArrowUpwardIcon
                                                fontSize="small"
                                                sx={{
                                                  paddingRight: "2px",
                                                  fill: "#959CA0",
                                                  cursor: "pointer",
                                                }}
                                                onClick={handleDiagnosisMoveUp(index)}
                                              />
                                            )}
                                        </div>
                                      )}
                                      <DeleteIcon
                                        fontSize="small"
                                        sx={{
                                          paddingRight: "2px",
                                          fill: "#959CA0",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleDeleteDiagnosis(
                                          item.uniqueID
                                        )}
                                      />
                                    </div>
                                  </Paper>
                                  {item.additionalCode.map((b) => {
                                    return (
                                      <Paper
                                        elevation={0}
                                        className="selected-subcode"
                                      >
                                        <Typography
                                          gutterBottom
                                          className="selected-text"
                                        >
                                          <span>{b.type}</span>&nbsp;&nbsp;
                                          {b.name}
                                        </Typography>
                                        <DeleteIcon
                                          fontSize="small"
                                          sx={{
                                            paddingRight: "2px",
                                            fill: "#959CA0",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleDeleteAdditionalDiagnosis(
                                            item.uniqueID,
                                            b.uniqueID
                                          )}
                                        />
                                      </Paper>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </Paper>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs className="interactive-grid">
                        <div className="selection-container">
                          <Typography
                            id="headerLabel"
                            className="interactive-text"
                            gutterBottom
                            sx={{
                              paddingBottom: "10px",
                              fontSize: "12px !important",
                            }}
                          >
                            {t("interactive.procedure_and")}
                          </Typography>
                          <div className="selection-button-container">
                            <Button
                              sx={{ paddingRight: "10px" }}
                              disabled={isChooseProcedure}
                              onClick={selectProcedure}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                sx={{ paddingRight: "5px" }}
                              />
                              {t("interactive.procedure")}
                            </Button>
                            <Button
                              sx={{ paddingRight: "10px" }}
                              disabled={isChooseProcAdditional}
                              onClick={selectProcedureAdditionalCode}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                sx={{ paddingRight: "5px" }}
                              />
                              {t("interactive.additionalCode")}
                            </Button>
                          </div>
                        </div>
                        <TextField
                          variant="outlined"
                          placeholder={t("interactive.search")}
                          value={procedureSearchValue}
                          onChange={handleProcedureSearch}
                          sx={{ width: "100%", paddingBottom: "10px" }}
                          InputProps={{
                            startAdornment: (
                              <SearchIcon sx={{ color: "#00000040" }} />
                            ),
                            endAdornment: (
                              <CancelRoundedIcon
                                fontSize="small"
                                sx={{ color: "#00000040", cursor: "pointer" }}
                                onClick={closeProcedureSearch}
                              />
                            ),
                          }}
                        />
                        {loadDiagAndProc ? (
                          <ProcedureTreeView
                            search={procedureSearch ? true : false}
                          />
                        ) : null}
                        <div className="interactive-space"></div>
                        <Paper
                          elevation={0}
                          className="interactive-paper-selected"
                        >
                          <Typography
                            id="headerLabel"
                            className="interactive-text"
                            gutterBottom
                            sx={{ padding: "10px" }}
                          >
                            {t("interactive.selectedProcedure")}
                          </Typography>
                          <div className="selected-diagnosis">
                            {procedure ?.map((item, index) => {
                              return (
                                <div className="selected-diagnosis-item">
                                  <Paper
                                    elevation={0}
                                    className="selected-primary"
                                  >
                                    <Link
                                      underline="none"
                                      onClick={handleProcedureSelect(item)}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Typography
                                        gutterBottom
                                        className="selected-text"
                                      >
                                        <span>{item.type}</span>&nbsp;&nbsp;
                                        {item.name}
                                      </Typography>
                                    </Link>
                                    <div className="selected-diagnosis-button">
                                      {procedure.length > 1 && (
                                        <div>
                                          {index === 0 ? (
                                            <ArrowDownwardIcon
                                              fontSize="small"
                                              sx={{
                                                paddingRight: "2px",
                                                fill: "#959CA0",
                                                cursor: "pointer",
                                              }}
                                              onClick={handleProcedureMoveDown(
                                                index
                                              )}
                                            />
                                          ) : (
                                              <ArrowUpwardIcon
                                                fontSize="small"
                                                sx={{
                                                  paddingRight: "2px",
                                                  fill: "#959CA0",
                                                  cursor: "pointer",
                                                }}
                                                onClick={handleProcedureMoveUp(index)}
                                              />
                                            )}
                                        </div>
                                      )}
                                      <DeleteIcon
                                        fontSize="small"
                                        sx={{
                                          paddingRight: "2px",
                                          fill: "#959CA0",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleDeleteProcedure(
                                          item.uniqueID
                                        )}
                                      />
                                    </div>
                                  </Paper>
                                  {item.additionalCode.map((b) => {
                                    return (
                                      <Paper
                                        elevation={0}
                                        className="selected-subcode"
                                      >
                                        <Typography
                                          gutterBottom
                                          className="selected-text"
                                        >
                                          <span>{b.type}</span>&nbsp;&nbsp;
                                          {b.name}
                                        </Typography>
                                        <DeleteIcon
                                          fontSize="small"
                                          sx={{
                                            paddingRight: "2px",
                                            fill: "#959CA0",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleDeleteAdditionalProcedure(
                                            item.uniqueID,
                                            b.uniqueID
                                          )}
                                        />
                                      </Paper>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    <div className="interactive-query-container">
                      <div className="run-query-container">
                        <Button
                          variant="contained"
                          size="small"
                          className="interactive-button-clear"
                          onClick={handleAddToSpell}
                          value="AddToSpell"
                          disabled={diagnosis.length > 0 || 
                            (procedure.length> 0 && contactType == '6') 
                            ? false : true}
                        >
                          {t("interactive.addToSpell")}
                        </Button>
                        <span className="interactive-empty"></span>
                        <Button
                          variant="contained"
                          size="small"
                          className="interactive-button-clear"
                          onClick={handleRunQuery}
                          value="RunQuery"
                          disabled={diagnosis.length > 0 || 
                            (procedure.length> 0 && contactType == '6') 
                            ? false : true}
                        >
                          {t("interactive.runQuery")}
                        </Button>
                        <span className="interactive-empty"></span>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={episodesPanel} id="step4">
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <ArrowRightIcon
                      fontSize="large"
                      onClick={handlePanelChange("episodesPanel")}
                      sx={{ cursor: "pointer" }}
                    />
                  }
                  sx={{
                    padding: "0px !important",
                    cursor: "default !important",
                  }}
                >
                  <div className="interactive-accordion-text">
                    <Typography className="interactive-text">
                      {t("interactive.episodes")}
                    </Typography>
                    <InfoIcon
                      fontSize="small"
                      id="info4"
                      sx={{
                        paddingLeft: "10px",
                        fill: "#959CA0",
                        cursor: "pointer",
                      }}
                      onClick={handleHintClick(3)}
                    />
                  </div>
                  <div>
                    <Button
                      sx={{ paddingRight: "20px", cursor: "pointer" }}
                      onClick={handleClear("episodesPanel")}
                    >
                      <img
                        src={EraseIcon}
                        alt="Clear"
                        style={{ paddingRight: "5px" }}
                      />
                      {t("interactive.clear")}
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {episodesPanel ? <Episodes /> : null}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={spellsPanel} id="step5">
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <ArrowRightIcon
                      fontSize="large"
                      onClick={handlePanelChange("spellsPanel")}
                      sx={{ cursor: "pointer" }}
                    />
                  }
                  sx={{
                    padding: "0px !important",
                    cursor: "default !important",
                  }}
                >
                  <div className="interactive-accordion-text">
                    <Typography className="interactive-text">
                      {t("interactive.spells")}
                    </Typography>
                    <InfoIcon
                      fontSize="small"
                      id="info5"
                      sx={{
                        paddingLeft: "10px",
                        fill: "#959CA0",
                        cursor: "pointer",
                      }}
                      onClick={handleHintClick(4)}
                    />
                  </div>
                  <div>
                    <Button
                      sx={{ paddingRight: "20px", cursor: "pointer" }}
                      onClick={handleClear("spellsPanel")}
                    >
                      <img
                        src={EraseIcon}
                        alt="Clear"
                        style={{ paddingRight: "5px" }}
                      />
                      {t("interactive.clear")}
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {spellsPanel ? <Spells /> : null}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={resultsPanel} id="step6">
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <ArrowRightIcon
                      fontSize="large"
                      onClick={handlePanelChange("resultsPanel")}
                      sx={{ cursor: "pointer" }}
                    />
                  }
                  sx={{
                    padding: "0px !important",
                    cursor: "default !important",
                  }}
                >
                  <div className="interactive-accordion-text">
                    <Typography className="interactive-text">
                      {t("interactive.results")}
                    </Typography>
                    <InfoIcon
                      fontSize="small"
                      id="info6"
                      sx={{
                        paddingLeft: "10px",
                        fill: "#959CA0",
                        cursor: "pointer",
                      }}
                      onClick={handleHintClick(5)}
                    />
                  </div>
                  <div>
                    <Button
                      sx={{ paddingRight: "20px", cursor: "pointer" }}
                      onClick={handleReport}
                    >
                      <img
                        src={ReportIcon}
                        alt="Clear"
                        style={{ paddingRight: "5px" }}
                      />
                      {t("interactive.reportView")}
                    </Button>
                    <Button
                      sx={{ paddingRight: "20px", cursor: "pointer" }}
                      onClick={handleClear("resultsPanel")}
                    >
                      <img
                        src={EraseIcon}
                        alt="Clear"
                        style={{ paddingRight: "5px" }}
                      />
                      {t("interactive.clear")}
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {resultsPanel ? <Results /> : null}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Paper>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ m: 0, p: 2, width: "420px" }}>
            {t("interactive.Open_Interactive_File")}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <InteractiveSelectionTree />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" onClick={handleOpen}>
              {t("interactive.Open")}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={fileNotExists}
        autoHideDuration={5000}
        onClose={() => dispatch(errorMessageClose())}
      >
        <Alert
          onClose={() => dispatch(errorMessageClose())}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("viewer.fileNotExists")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={dateError}
        autoHideDuration={5000}
        onClose={() => dispatch(dateErrorClose())}
      >
        <Alert
          onClose={() => dispatch(dateErrorClose())}
          severity="info"
          sx={{ width: "100%" }}
        >
          {t("interactive.dateError")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={resultError}
        autoHideDuration={5000}
        onClose={() => dispatch(dateErrorClose())}
      >
        <Alert
          onClose={() => dispatch(dateErrorClose())}
          severity="info"
          sx={{ width: "100%" }}
        >
          {t(errorMessage)}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("interactive.clearAll")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            {t("interactive.noButton")}
          </Button>
          <Button onClick={handleOk} variant="contained" autoFocus>
            {t("interactive.yesButton")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="interactive-report-dialog"
        open={reportDialog}
        onClose={handleClose}
      >
        <DialogContent>
          <Report />
        </DialogContent>
      </Dialog>
      <Dialog
        className="interactive-visual-dialog"
        open={visualDialog}
        onClose={handleClose}
      >
        <DialogContent>
          <VisualDiagram />
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={resultError}
        autoHideDuration={5000}
        onClose={() => dispatch(handleErrorClose())}
      >
        <Alert
          onClose={() => dispatch(handleErrorClose())}
          severity="info"
          sx={{ width: "100%" }}
        >
          {!noVisualIDError && t(errorMessage)}
          {noVisualIDError && t("interactive.noVisualError")}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isResultLoading}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
