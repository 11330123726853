import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

export const loadlogic = createAsyncThunk(
  "publicPageSlice/loadlogic",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      return r.json();
    });
  }
);

export const downloadLogic = createAsyncThunk(
  "publicPageSlice/downloadLogic",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      return r.blob();
    });
  }
);

export const getAllGrouperData = createAsyncThunk(
  "publicPageSlice/getAllGrouperData",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("Public/GetGrouperUsedDetails", reqOptions).then((r) => {
      return r.json();
    });
  }
);

export const downloadGrouper = createAsyncThunk(
  "publicPageSlice/downloadGrouper",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      return r.blob();
    });
  }
);

export const getCountries = createAsyncThunk(
  "publicPageSlice/getCountries",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("home/GetCountry", reqOptions).then((r) => {
      return r.json();
    });
  }
);

export const GetDRGFrontPageContent = createAsyncThunk(
  "publicPageSlice/GetDRGFrontPageContent",
  async (obj, { dispatch, getState }) => {
    const { selectedYear, isEnvironment } = getState().publicPage;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let langval = localStorage.getItem("language");
    let url = "Public/GetDRGFrontPageContent?language=" + langval + "&year=" + selectedYear + "&destEnvironment=" + isEnvironment;
    return await fetch(url, reqOptions).then((res) => {
      return res.json();
    });
  }
);

export const GetGrouperFrontPageContent = createAsyncThunk(
  "publicPageSlice/GetGrouperFrontPageContent",
  async (obj, { dispatch, getState }) => {
    const { selectedGrouperYear, isEnvironment } = getState().publicPage;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let langval = localStorage.getItem("language");
    let url = "Public/GetGrouperFrontPageContent?language=" + langval + "&year=" + selectedGrouperYear + "&destEnvironment=" + isEnvironment;
    return await fetch(url, reqOptions).then((res) => {
      return res.json();
    });
  }
);

const publicPageSlice = createSlice({
  name: "publicPageSlice",
  initialState: {
    isLoading: false,
    data: [],
    selectedYear: "",
    distinctUploadYear: [],
    filterdata: [],
    masterfilterdata: [],
    selectedDRGLogic: null,
    selectedDRGLogicID: "",
    page: "",
    prodData: [],
    prodYear: [],
    distinctGrouperYear: [],
    masterGrouperData: [],
    selectedGrouperYear: "",
    selectedGrouper: null,
    selectedGrouperID: "",
    selectedGrouperName: "",
    grouperPage: "",
    prodGrouperData: [],
    prodGrouperYear: [],
    countries: [],
    drgFrontPageContent: "",
    isEnvironment: "",
    grouperFrontPageContent: "",
    isValidData: false,
    isInvalidData: false,
  },
  reducers: {
    setSelectedYear(state, action) {
      state.selectedYear = action.payload;
    },
    setMasterFilterData(state, action) {
      state.masterfilterdata = action.payload;
      state.selectedDRGLogic = state.masterfilterdata;
      state.selectedDRGLogicID = state.masterfilterdata[0]._id;
    },
    setFilterData(state, action) {
      state.filterdata = action.payload;
    },
    setSelectedDRGLogic(state, action) {
      state.selectedDRGLogic = state.masterfilterdata.filter(
        (data) => data._id == action.payload
      );
      state.selectedDRGLogicID = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setGrouperPage(state, action) {
      state.grouperPage = action.payload;
    },
    setSelectedGrouperYear(state, action) {
      state.selectedGrouperYear = action.payload;
    },
    setSelectedGrouper(state, action) {
      state.selectedGrouperID = action.payload;
    },
    setMasterGrouperData(state, action) {
      state.masterGrouperData = action.payload;
      state.selectedGrouperName = state.masterGrouperData[0] ?.grouperName;
      state.selectedGrouperID = state.masterGrouperData[0] ?.id;
    },
    setEnvironment(state, { payload }) {
      state.isEnvironment = payload;
    },
  },
  extraReducers: {
    [loadlogic.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loadlogic.fulfilled]: (state, { payload }) => {
      //console.log("payload:", payload);
      state.isLoading = false;
      state.isRender = false;
      if (payload != null && payload.length > 0) {
        // state.isNoData = false;
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.data = payload;
        state.distinctUploadYear = payload
          .map((item) => item.Year)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((one, two) => (one > two ? -1 : 1));
        if (state.page === "test") {
          if (state.selectedYear != "" && state.selectedDRGLogicID != "") {
            state.filterdata = payload.filter(
              (item) =>
                item.Year == state.selectedYear &&
                item._id == state.selectedDRGLogicID &&
                item.isTest == true
            );
            if (state.filterdata.length > 0) {
              state.isValidData = true;
              state.masterfilterdata = state.filterdata;
              state.selectedDRGLogic = state.masterfilterdata;
            } else {
              state.isInvalidData = true;
            }
          }
        } else if (state.page === "hearing") {
          state.filterdata = payload.filter(item => item.isPreProd == true);
          if (state.filterdata.length > 0) {
            state.isValidData = true;
            state.selectedYear = state.filterdata[0] ?.Year;
            state.masterfilterdata = state.filterdata;
            state.selectedDRGLogicID = state.masterfilterdata[0]._id;
            state.selectedDRGLogic = state.masterfilterdata;
          } else {
            state.isInvalidData = true;
          }
        } else if (state.page === "prod") {
          state.prodData = payload.filter((item) => item.isPublished == true);
          if (state.prodData.length > 0) {
            state.isValidData = true;
            state.prodYear = state.prodData
              .map((item) => item.Year)
              .filter((value, index, self) => self.indexOf(value) === index)
              .sort((one, two) => (one > two ? -1 : 1));
            let currentYear = new Date().getFullYear();
            let upcomingYear = currentYear + 1;
            let previousYear = currentYear - 1;
            if (state.prodYear.includes(JSON.stringify(upcomingYear))) {
              state.selectedYear = JSON.stringify(upcomingYear);
            } else if (
              state.prodYear.includes(JSON.stringify(currentYear))
            ) {
              state.selectedYear = JSON.stringify(currentYear);
            } else if (
              state.prodYear.includes(JSON.stringify(previousYear))
            ) {
              state.selectedYear = JSON.stringify(previousYear);
            } else {
              state.selectedYear = state.prodYear[0];
            }
            state.filterdata = payload.filter(
              (item) =>
                item.Year == state.selectedYear && item.isPublished == true
            );
            state.masterfilterdata = state.filterdata;
            state.selectedDRGLogic = state.masterfilterdata;
            state.selectedDRGLogicID = state.masterfilterdata[0]._id;
          }
        } else {
          state.isInvalidData = true;
        }
      }
    },
    [loadlogic.rejected]: (state, action) => {
      console.log("Error from loadLogic", action.error.message);
    },
    [downloadLogic.pending]: (state, action) => {
      state.isLoading = true;
    },
    [downloadLogic.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.type == "application/json") {
        console.log("Error from downloadLogic");
      } else if (payload.type == "application/octet-stream") {
        saveAs(payload, state.selectedDRGLogic[0] ?.Name + ".zip");
      }
    },
    [downloadLogic.rejected]: (state, action) => {
      state.isLoading = false;
      console.log("Error from downloadLogic", action.error.message);
    },
    [getAllGrouperData.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllGrouperData.fulfilled]: (state, { payload }) => {
      state.masterGrouperData = payload;
      state.distinctGrouperYear = payload
        .map((item) => item.year)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((one, two) => (one > two ? -1 : 1));
      if (state.grouperPage === "test") {
        if (state.selectedGrouperYear != "" && state.selectedGrouperID != "") {
          var filterdata = payload.filter(
            (item) =>
              item.year == state.selectedGrouperYear &&
              item.id == state.selectedGrouperID &&
              item.status == 3
          );
          if (filterdata.length > 0) {
            state.isValidData = true;
            state.masterGrouperData = filterdata;
            state.selectedGrouperName = state.masterGrouperData[0].grouperName;
          } else {
            state.isInvalidData = true;
          }
        }
      } else if (state.grouperPage === "hearing") {
        var filterdata = payload.filter(item => item.status == 2);
        if (filterdata.length > 0) {
          state.isValidData = true;
          state.selectedGrouperYear = filterdata[0] ?.year;
          state.masterGrouperData = filterdata.length > 0 ? filterdata : payload;
          state.selectedGrouperName = state.masterGrouperData[0].grouperName;
          state.selectedGrouperID = state.masterGrouperData[0] ?.id;
        } else {
          state.isInvalidData = true;
        }
      } else if (state.grouperPage === "prod") {
        state.prodGrouperData = payload.filter((item) => item.status == 1);
        state.isValidData = true;
        if (state.prodGrouperData.length > 0) {
          state.prodGrouperYear = state.prodGrouperData
            .map((item) => item.year)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((one, two) => (one > two ? -1 : 1));
          let currentYear = new Date().getFullYear();
          let upcomingYear = currentYear + 1;
          let previousYear = currentYear - 1;
          if (state.prodGrouperYear.includes(JSON.stringify(upcomingYear))) {
            state.selectedGrouperYear = JSON.stringify(upcomingYear);
          } else if (
            state.prodGrouperYear.includes(JSON.stringify(currentYear))
          ) {
            state.selectedGrouperYear = JSON.stringify(currentYear);
          } else if (
            state.prodGrouperYear.includes(JSON.stringify(previousYear))
          ) {
            state.selectedGrouperYear = JSON.stringify(previousYear);
          } else {
            state.selectedGrouperYear = state.prodGrouperYear[0];
          }
          var filterdata = payload.filter(
            (item) => item.year == state.selectedGrouperYear && item.status == 1
          );
          state.masterGrouperData = filterdata;
          state.selectedGrouperName = state.masterGrouperData[0].grouperName;
          state.selectedGrouperID = state.masterGrouperData[0] ?.id;
        }
      } else {
        state.isInvalidData = true;
      }
    },
    [getAllGrouperData.rejected]: (state, action) => {
      state.isFailed = true;
      console.log("Error from getAllGrouperData", action.error.message);
    },
    [downloadGrouper.pending]: (state, action) => {
      state.isLoading = true;
    },
    [downloadGrouper.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload.type == "application/json") {
        console.log("Error from downloadGrouper");
      } else if (payload.type == "application/octet-stream") {
        saveAs(payload, state.selectedGrouperName + ".zip");
      }
    },
    [downloadGrouper.rejected]: (state, action) => {
      state.isLoading = false;
      console.log("Error from downloadGrouper", action.error.message);
    },
    [getCountries.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      // console.log("Countries", payload);
      state.countries = payload;
      state.status = "success";
    },
    [getCountries.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getCountries", action.error.message);
    },
    [GetDRGFrontPageContent.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetDRGFrontPageContent.fulfilled]: (state, { payload }) => {
      if (payload.length > 0 && payload[0].languageValue != "") {
        state.drgFrontPageContent = payload[0].languageValue;
      }
      else {
        state.drgFrontPageContent = "";
      }
    },
    [GetDRGFrontPageContent.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from GetDRGFrontPageContent", action.error.message);
    },
    [GetGrouperFrontPageContent.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetGrouperFrontPageContent.fulfilled]: (state, { payload }) => {
      if (payload.length > 0 && payload[0].languageValue != "") {
        state.grouperFrontPageContent = payload[0].languageValue;
      }
      else {
        state.grouperFrontPageContent = "";
      }
    },
    [GetGrouperFrontPageContent.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from GetGrouperFrontPageContent", action.error.message);
    },
  },
});
export const {
  setSelectedYear,
  setMasterFilterData,
  setFilterData,
  setSelectedDRGLogic,
  setPage,
  setGrouperPage,
  setSelectedGrouperYear,
  setSelectedGrouper,
  setMasterGrouperData,
  setEnvironment,
} = publicPageSlice.actions;
export default publicPageSlice.reducer;
