import * as React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import ImageBanner from "../../assets/logos/ImageBanner.svg";
import IQVIALogo from "../../assets/logos/Logo-IQVIA-white.svg";
import { Link, useHistory } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  appbarStyle: {
    background:
      "transparent linear-gradient(272deg, #0076AE 0%, #250056 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    top: 0,
    left: 0,
    //width: 1440,
    height: 56,
    alignContent: "space-between",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  toolbarStyle: {
    paddingLeft: 5,
    height: 56,
  },
  logoStyle: {
    paddingRight: 20,
    height: 15,
  },
  title: {
    flexGrow: 1,
  },
  casemixLogo: {
    alignSelf: "center",
    height: "19px",
    fontStyle: "normal",
    fontSize: "14px",
    fontStretch: "normal",
    paddingLeft: 20,
    paddingRight: 12,
    //fontFamily: "'Montserrat', sans-serif",
  },
  navItems: {
    letterSpacing: 0,
    color: "white",
    opacity: 1,
    textDecoration: "none",
    paddingLeft: 16,
    paddingRight: 16,
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: "normal",
    fontSize: "14px",
    fontStretch: "normal",
  },
  adminMenuStyle: {
    backgroundColor: "#0D3062",
  },
  menuItemStyle: {
    textDecoration: "none",
    color: "white",
    fontStyle: "normal",
    fontSize: "14px",
  },
  customHeight: {
    minHeight: 200,
  },
  iconStyle: {
    padding: "10px",
    width: "56px",
  },
  buttonStyle: {
    textTransform: "capitalize",
  },
}));

const StyledAdminMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 145,
    backgroundColor: "#0D3062",
    color: "#FFFFFFDE",
    fontStyle: "normal",
    fontSize: "14px",
    fontStretch: "normal",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontStyle: "normal",
      fontSize: "14px",
      fontStretch: "normal",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: "#0D3062",
    color: "#FFFFFFDE",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontStyle: "normal",
      fontSize: "14px",
      fontStretch: "normal",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "#0D3062",
      },
    },
  },
}));

export default function NavBar() {
  const classes = useStyles();
  const { user, isViewerRole } = useSelector((state) => state.authentication);
  const history = useHistory();
  const { t, i18n } = useTranslation("language");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);
  const openBoolean = Boolean(anchorEl);
  const openBooleanAdmin = Boolean(anchorElAdmin);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAdminClick = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };
  const handleEnd = () => {
    setAnchorEl(null);
    setAnchorElAdmin(null);
  };
  const handleLogout = () => {
    history.push("/logout");
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <AppBar className={classes.appbarStyle}>
          <Toolbar
            variant="dense"
            sx={{
              backgroundImage: `url(${ImageBanner})`,
              backgroundPosition: "left top",
              backgroundRepeat: "no-repeat",
            }}
            className={classes.toolbarStyle}
          >
            {/* <Link to="/" className={classes.logoStyle}>
              <img src={IQVIALogo} alt="IQVIA-Logo" />
            </Link> */}
            <img src={IQVIALogo} className={classes.logoStyle} alt="IQVIA-Logo" />
            <Divider
              orientation="vertical"
              sx={{
                height: 28,
                alignSelf: "center",
                backgroundColor: "lightslategrey",
              }}
              flexItem
            />
            {/* <Link className={classes.casemixLogo} to="/">
              <img src={CasemixLogo} alt="Logo" />
              Casemix360
            </Link> */}
            <Typography className={classes.navItems}>Casemix360</Typography>
            <Link className={classes.navItems} to="/dashboard">
              {t("header.dashboard")}
            </Link>
            {!isViewerRole && (
              <Link className={classes.navItems} to="/manager">
                {t("header.manager")}
              </Link>
            )}
            <Link className={classes.navItems} to="/editor">
              {t("header.editor")}
            </Link>
            <Link className={classes.navItems} to="/interactive">
              {t("header.interactive")}
            </Link>
            <Link className={classes.navItems} to="/viewer">
              {t("header.viewer")}
            </Link>
            {user[0].roleName == "admin" ||
              user[0].roleName == "super admin" ? (
                <div>
                  <Button
                    color="inherit"
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                    id="admin-button"
                    aria-controls="admin-menu"
                    aria-haspopup="true"
                    aria-expanded={openBooleanAdmin ? "true" : undefined}
                    disableElevation
                    onClick={handleAdminClick}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    {t("header.admin")}
                  </Button>
                  <StyledAdminMenu
                    id="admin-menu"
                    MenuListProps={{
                      "aria-labelledby": "admin-button",
                    }}
                    anchorEl={anchorElAdmin}
                    open={openBooleanAdmin}
                    onClose={handleEnd}
                  >
                    <Link to="/manage" className={classes.menuItemStyle}>
                      <MenuItem
                        className={classes.menuItemStyle}
                        onClick={handleEnd}
                        disableRipple
                      >
                        {t("header.manage_user")}
                      </MenuItem>
                    </Link>
                    <Link to="/publisher" className={classes.menuItemStyle}>
                      <MenuItem
                        className={classes.menuItemStyle}
                        onClick={handleEnd}
                        disableRipple
                      >
                        {t("header.publisher")}
                      </MenuItem>
                    </Link>
                    <Link to="/content" className={classes.menuItemStyle}>
                      <MenuItem
                        className={classes.menuItemStyle}
                        onClick={handleEnd}
                        disableRipple
                      >
                        {t("header.upload_content")}
                      </MenuItem>
                    </Link>
                  </StyledAdminMenu>
                </div>
              ) : null}
          </Toolbar>
          <Toolbar variant="dense" className={classes.toolbarStyle}>
            {/* <IconButton
              size="large"
              className={classes.iconStyle}
              aria-label="search"
              color="inherit"
            >
              <SearchIcon />
            </IconButton>
            <div className={classes.iconStyle}>
              <Badge badgeContent={4} color="error">
                <NotificationsIcon color="white" />
              </Badge>
            </div> */}
            <div>
              <Button
                color="inherit"
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={openBoolean ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
              >
                {`${user[0].firstName} ${user[0].lastName}`}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={openBoolean}
                onClose={handleEnd}
              >
                <MenuItem
                  className={classes.menuItemStyle}
                  onClick={handleEnd}
                  disableRipple
                  style={{ textTransform: "capitalize" }}
                  sx = {{cursor:"default"}}
                >
                  {user[0].roleName}
                </MenuItem>
                <MenuItem
                  className={classes.menuItemStyle}
                  onClick={handleEnd}
                  disableRipple
                  sx = {{cursor:"default"}}
                >
                  {user[0].email}
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <Link to="/myprofile" className={classes.menuItemStyle}>
                  <MenuItem
                    className={classes.menuItemStyle}
                    onClick={handleEnd}
                    disableRipple
                  >
                    {t("header.my_profile")}
                  </MenuItem>
                </Link>
                <MenuItem
                  className={classes.menuItemStyle}
                  onClick={handleEnd}
                  disableRipple
                >
                  <Button
                    sx={{
                      width: "-webkit-fill-available",
                      textTransform: "uppercase",
                    }}
                    variant="contained"
                    size="small"
                    onClick={handleLogout}
                  >
                    {t("header.log_out")}
                  </Button>
                </MenuItem>
              </StyledMenu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
