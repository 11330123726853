import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getRoles = createAsyncThunk(
  "manageUser/getRoles",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/Registration/GetRoles", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);
export const getUsers = createAsyncThunk(
  "manageUser/getUsers",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("home/api/GetUsers", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const saveChanges = createAsyncThunk(
  "manageUser/saveChanges",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const UserActivation = createAsyncThunk(
  "manageUser/UserActivation",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    let url = "home/api/UserActivation";
    return await fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

const manageUserSlice = createSlice({
  name: "manageUser",
  initialState: {
    roles: [],
    users: [],
    rows: [],
    status: null,
    loading: true,
    userStatus: null,
    isSuccess: false,
    isFailed: false,
    isSessionValid: true,
  },
  reducers: {
    endUserSession(state,action){
      state.isSessionValid = false;
   },
    setRows(state, action) {
      state.rows = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    clearStatus(state, action) {
      state.isSuccess = false;
      state.isFailed = false;
    },
  },
  extraReducers: {
    [getRoles.pending]: (state, action) => {
      state.status = "loading";
    },
    [getRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload;
      state.status = "success";
    },
    [getRoles.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getRoles", action.error.message);
    },
    [getUsers.pending]: (state, action) => {
      state.userStatus = "loading";
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
      state.users = payload
        .map((item) => item)
        .sort()
        .reverse();
      state.rows = payload
        .map((item) => item)
        .sort()
        .reverse();
      state.userStatus = "success";
      state.loading = false;
      }
    },
    [getUsers.rejected]: (state, action) => {
      state.userStatus = "failed";
      console.log("Error from getUsers", action.error.message);
    },
    [saveChanges.pending]: (state, action) => {
      state.loading = true;
    },
    [saveChanges.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = payload;
    },
    [saveChanges.rejected]: (state, action) => {
      state.isFailed = true;
      console.log("Error from getRoles", action.error.message);
    },
  },
});
export const { clearStatus, setRows, setLoading, endUserSession } = manageUserSlice.actions;
export default manageUserSlice.reducer;
