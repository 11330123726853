import React, { useState, useEffect } from 'react'
import "./Manager.css";
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FileLibrary from "./FileLibrary";
import DRG from "./DRG";
import GenerateGrouper from "./GenerateGrouper";
import GenerateInteractive from "./GenerateInteractive";
import GenerateViewer from "./GenerateViewer";
import { useSelector, useDispatch } from "react-redux";
import {
    setIsClinicalCode,
    setIsCasemixFile,
    setIsSupportFile,
    setIsDRG,
    setIsDRGLogic,
    setIsGGrouper,
    setIsGGrouperLogic,
    setIsGInteractive,
    setIsGInteractiveLogic,
    setIsGViewer,
    setIsGViewerLogic,
    getRoles,
    clearMangerStatus,
} from "./managerSlice";
import MenuItem from '@mui/material/MenuItem';
import { left } from '@popperjs/core';
import {redirectToLogin} from ".././authentication/authenticationSlice";

const useStyles = makeStyles((theme) => ({
    accordionContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        //paddingLeft: "20px",
    }
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
    borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    padding: "10px 0px",
}));

const NavButton = styled(Button)(({ theme }) => ({
    color: "#000000DE",
    textAlign: "left",
    paddingLeft: "30px",
    justifyContent: "left",
    width: "100%",
    borderRadius: 0,
    "&:hover": {
        backgroundColor: "#FFFFFF"
    },
    "&:active": {
        backgroundColor: "#F4F4F4"
    },
    "&:focus": {
        backgroundColor: "#F4F4F4"
    },
}));

export default function Manager() {
    const { t } = useTranslation("language");
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState();
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        if (panel == "panel2" && isDRG == false) {
            dispatch(setIsDRG());
        }
        else if (panel == "panel3" && isGGrouper == false) {
            dispatch(setIsGGrouper());
        }
        else if (panel == "panel4" && isGInteractive == false) {
            dispatch(setIsGInteractive());
        }
        else if (panel == "panel5" && isGViewer == false) {
            dispatch(setIsGViewer());
        }
    };
    const { user } = useSelector((state) => state.authentication);
    const manager = useSelector((state) => state.manager);
    const {
        isLoading,
        isClinicalCode,
        isCasemixFile,
        isSupportFile,
        isDRG,
        isGGrouper,
        isGInteractive,
        isGViewer,
        masterfilterdata,
        isNoData,
        isDRGLogic,
        isGGrouperLogic,
        isGInteractiveLogic,
        isGViewerLogic,
    } = manager;

    useEffect(() => {
        console.log("after useeffect run");
        // dispatch(clearMangerStatus());
        dispatch(getRoles({ method: "GET" }));
    }, []);

    if(!manager.isSessionValid){
        dispatch(redirectToLogin({ isAuthorised: false }));
      }

    return (
        <div className="manager">
            <div className="leftContainer">
                <Paper className="paperContainer">
                    <div className="section-header">
                        <Typography variant="h5" className="typography-title" color="#000000">
                        {t("Manager.Manager")}
                </Typography>
                    </div>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography variant="subtitle2">{t("Manager.File_lib")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionContent}>
                                <NavButton variant="text" sx={isClinicalCode == true && {backgroundColor:"#F4F4F4"}} onClick={() => dispatch(setIsClinicalCode())}> {t("Manager_Common.SKS_Data")}</NavButton>
                                <NavButton variant="text" sx={isCasemixFile == true && {backgroundColor:"#F4F4F4"}} onClick={() => dispatch(setIsCasemixFile())}> {t("Manager_Common.Casemix_File")}</NavButton>
                                <NavButton variant="text" sx={isSupportFile == true && {backgroundColor:"#F4F4F4"}} onClick={() => dispatch(setIsSupportFile())}> {t("Manager.support_file")} </NavButton>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {isDRG == true && isNoData == true && (
                        <Accordion expanded={false} sx={{backgroundColor:"#F4F4F4"}}>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography variant="subtitle2">{t("Manager.Diagnose_Related_Logics")}</Typography>
                        </AccordionSummary>
                        </Accordion>
                    )}
                    {isNoData == false && (
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography variant="subtitle2">{t("Manager.Diagnose_Related_Logics")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionContent}>
                                {isDRG == true &&  masterfilterdata.length > 0 && (masterfilterdata.map((data) => {
                                    return (
                                        <NavButton variant="text" sx={isDRGLogic[0] ?._id == data._id && {backgroundColor:"#F4F4F4"}} onClick={() => dispatch(setIsDRGLogic(data._id))} >{data.Name} {data.version} </NavButton>
                                    );
                                })
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                     )}
                    {isGGrouper == true && isNoData == true && (
                        <Accordion expanded={false} sx={{backgroundColor:"#F4F4F4"}}>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography variant="subtitle2">{t("Manager.Generate_Grouper")}</Typography>
                        </AccordionSummary>
                        </Accordion>
                    )}
                    {isNoData == false && (
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography variant="subtitle2">{t("Manager.Generate_Grouper")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionContent}>
                                {isGGrouper == true &&  masterfilterdata.length > 0 && (masterfilterdata.map((data) => {
                                    return (
                                        <NavButton variant="text" sx={isGGrouperLogic[0] ?.id == data.id && {backgroundColor:"#F4F4F4"}} onClick={() => dispatch(setIsGGrouperLogic(data.id))}>{data.grouperName} {data.grouperVersion} </NavButton>
                                    );
                                })
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    )}
                    {isGInteractive == true && isNoData == true && (
                        <Accordion expanded={false} sx={{backgroundColor:"#F4F4F4"}}>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography variant="subtitle2">{t("Manager.Generate_Interactive")}</Typography>
                        </AccordionSummary>
                        </Accordion>
                    )}
                    {isNoData == false && (
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography variant="subtitle2">{t("Manager.Generate_Interactive")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionContent}>
                                {isGInteractive == true &&  masterfilterdata.length > 0 && (masterfilterdata.map((data) => {
                                    return (
                                        <NavButton variant="text" sx={isGInteractiveLogic[0] ?.id == data.id && {backgroundColor:"#F4F4F4"}} onClick={() => dispatch(setIsGInteractiveLogic(data.id))}>{data.interactiveName} {data.interactiveVersion} </NavButton>
                                    );
                                })
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    )}
                    {isGViewer == true && isNoData == true && (
                        <Accordion expanded={false} sx={{backgroundColor:"#F4F4F4"}}>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography variant="subtitle2">{t("Manager.Generate_Visual")}</Typography>
                        </AccordionSummary>
                        </Accordion>
                    )}
                    {isNoData == false && (
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography variant="subtitle2">{t("Manager.Generate_Visual")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.accordionContent}>
                                {isGViewer == true &&  masterfilterdata.length > 0 && (masterfilterdata.map((data) => {
                                    return (
                                        <NavButton variant="text" sx={isGViewerLogic[0] ?.id == data.id && {backgroundColor:"#F4F4F4"}} onClick={() => dispatch(setIsGViewerLogic(data.id))}>{data.visualName} {data.visualVersion} </NavButton>
                                    );
                                })
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    )}
                </Paper>
            </div>

            <div className="rightContainer">
                {(isClinicalCode || isCasemixFile || isSupportFile) && (<FileLibrary></FileLibrary>)}
                {isDRG && (<DRG></DRG>)}
                {isGGrouper && (<GenerateGrouper></GenerateGrouper>)}
                {isGInteractive && (<GenerateInteractive></GenerateInteractive>)}
                {isGViewer && (<GenerateViewer></GenerateViewer>)}
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress />
            </Backdrop>
        </div>
    )
}

