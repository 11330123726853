import React, { useState, useEffect } from "react";
import "./DRGTest.css";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IQVIALogo from "../../assets/logos/logo-iqvia.svg";
import Backdrop from "@mui/material/Backdrop";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllGrouperData,
  setSelectedGrouperYear,
  setSelectedGrouper,
  downloadGrouper,
  setGrouperPage,
  GetGrouperFrontPageContent,
  setEnvironment,
  getCountries,
} from "./publicPageSlice";
import parse from 'html-react-parser';

const useStyles = makeStyles({
  input: {
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      "-webkit-text-fill-color": "white",
    },
  },
  cssLabel: {
    color: "white",
    "&.Mui-focused": {
      color: "#23A5EB",
    },
    "&.Mui-disabled": {
      color: "white",
      "-webkit-text-fill-color": "white",
    },
  },
  root: {
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
});

export default function GrouperTest() {
  const { t, i18n } = useTranslation("language");
  const dispatch = useDispatch();
  const publicPage = useSelector((state) => state.publicPage);
  const {
    selectedGrouperYear,
    selectedGrouperID,
    selectedGrouperName,
    isLoading,
    countries,
    grouperFrontPageContent,
    isValidData,
    isInvalidData
  } = publicPage;
  const queryParams = new URLSearchParams(window.location.search);
  const year = queryParams.get("year");
  const grouperId = queryParams.get("id");
  const [languageVal, setLanguageVal] = useState("1");

  useEffect(() => {
    dispatch(setEnvironment("Test"));
    dispatch(setSelectedGrouperYear(year));
    dispatch(setSelectedGrouper(grouperId));
    dispatch(setGrouperPage("test"));
    dispatch(getCountries({ method: "GET" }));
    dispatch(getAllGrouperData({ method: "GET" })).then(() => {
      dispatch(GetGrouperFrontPageContent({ method: "GET" }));
    });
  }, []);

  const exportSelectedGrouper = (id) => (e) => {
    let selectedID = id;
    let url = "Public/GetGrouper?id=" + selectedID;
    dispatch(downloadGrouper({ method: "GET", url: url }));
  };
  const classes = useStyles();
  function handleLanguageChange(val) {
    if (countries != null && countries.length > 0) {
      var language = countries.find((x) => x.id == val);
      setLanguageVal(val);
      i18n.changeLanguage(language.name);
      localStorage.setItem("language", language.name);
      dispatch(GetGrouperFrontPageContent({ method: "GET" }));
    }
  }

  return (
    <div className="drg-test">
      {isValidData && (
        <div className="validContent">
          <div className="drg-container-header">
            <div className="drg-logo">
              <img src={IQVIALogo} className="logo-style" alt="Logo" />
            </div>
            <div className="drg-lang">
              <TextField
                label="Language"
                variant="outlined"
                size="small"
                select
                onChange={(event) => handleLanguageChange(event.target.value)}
                value={languageVal}
              >
                {countries ? (
                  countries.map((country) => (
                    <MenuItem value={country.id}>{country.language}</MenuItem>
                  ))
                ) : (
                    <div></div>
                  )}
              </TextField>
            </div>
          </div>
          <Paper className="header-section-container">
            <div className="header-section">
              <Typography className="header-text" gutterBottom>
                Grouper
          </Typography>
              <Typography className="header-text" gutterBottom>
                {t("Manager.Year")}: {selectedGrouperYear}
              </Typography>
              <Typography className="header-text" gutterBottom>
                {t("Manager_Common.Name")}: {selectedGrouperName}
              </Typography>

              <Button
                variant="contained"
                size="small"
                className="download-button"
                onClick={exportSelectedGrouper(selectedGrouperID)}
                value="Download"
              >
                <DownloadIcon sx={{ paddingRight: "10px" }} />
                Download
          </Button>
            </div>

            <Divider />
            {grouperFrontPageContent != "" &&
              <Box sx={{ width: "100%" }}>
                <div className="drgFrontPageContent">
                  {parse(grouperFrontPageContent)}
                </div>
              </Box>
            }
          </Paper>
        </div>
      )}
      {/* <div className="copyright-placeholder">
        <span className="copyrightText">
          © 2022 IQVIA. All Rights Reserved.
        </span>
      </div> */}
      {isInvalidData && (
        <div> <Typography variant="h4" align="center"> The link you accessed has no data or expired </Typography></div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
