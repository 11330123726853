import React, { useState, useEffect } from "react";
import "./ContentUpload.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RichTextEditor from "./RichTextEditor";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearValues, SaveContent, handleClose, resetContentUpload } from "./contentUploadSlice";
import {redirectToLogin} from ".././authentication/authenticationSlice";

export default function ContentUpload() {
  const [value, setValue] = React.useState("interactive");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const contentUpload = useSelector((state) => state.contentUpload);
  const {
    year,
    language,
    destEnvironment,
    updatedInteractiveContent,
    updatedVisualContent,
    updatedDRGContent,
    updatedGrouperContent,
    uploadSuccess,
    uploadFailed,
  } = contentUpload;

  useEffect(() => {
    return (() => {
      dispatch(resetContentUpload());
    })
  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(clearValues());
  };

  const handleSave = (e) => {
    e.preventDefault();
    var lang;
    if (language == "2") {
      lang = "dk";
    } else {
      lang = "en";
    }
    let data = [];
    let url = "";
    if (value == "interactive") {
      data = [
        {
          Year: year,
          LanguageType: lang,
          Action: destEnvironment,
          LanguageValue: updatedInteractiveContent,
        },
      ];
      url = "api/SaveInteractiveFrontPageContent";
      dispatch(SaveContent({ url: url, body: data[0], method: "POST" }));
    } else if (value == "viewer") {
      data = [
        {
          Year: year,
          LanguageType: lang,
          Action: destEnvironment,
          LanguageValue: updatedVisualContent,
        },
      ];
      url = "/api/SaveVisualFrontPageContent";
      dispatch(SaveContent({ url: url, body: data[0], method: "POST" }));
    } else if (value == "DRG") {
      data = [
        {
          Year: year,
          LanguageType: lang,
          Action: destEnvironment,
          LanguageValue: updatedDRGContent,
        },
      ];
      url = "/api/SaveDrgFrontPageContent";
      dispatch(SaveContent({ url: url, body: data[0], method: "POST" }));
    } else if (value == "grouper") {
      data = [
        {
          Year: year,
          LanguageType: lang,
          Action: destEnvironment,
          LanguageValue: updatedGrouperContent,
        },
      ];
      url = "/api/SaveGrouperFrontPageContent";
      dispatch(SaveContent({ url: url, body: data[0], method: "POST" }));
    }
  };

  if(!contentUpload.isSessionValid){
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  return (
    <div className="content-upload-container">
      <Paper className="content-section-container">
        <div className="content-section-header">
          <Typography
            id="headerLabel"
            variant="h6"
            className="content-upload-title"
            gutterBottom
          >
            {t("contentUpload.ContentUpload")}
          </Typography>
          <Button
            variant="contained"
            size="small"
            className="form-button-primary"
            onClick={handleSave}
          >
            {t("contentUpload.saveLabel")}
          </Button>
        </div>
        <Divider />
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "0px 24px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="content tabs"
              >
                <Tab
                  id="tabInteractiveLabel"
                  value="interactive"
                  label={t("contentUpload.interactiveContentLabel")}
                />
                <Tab
                  value="viewer"
                  label={t("contentUpload.viewerContentLabel")}
                />
                <Tab
                  value="DRG"
                  label={t("contentUpload.DRGContentLabel")}
                />
                <Tab
                  value="grouper"
                  label={t("contentUpload.grouperContentLabel")}
                />
              </Tabs>
            </Box>
            <TabPanel value="interactive">
              <RichTextEditor tab="interactive" />
            </TabPanel>
            <TabPanel value="viewer">
              <RichTextEditor tab="viewer" />
            </TabPanel>
            <TabPanel value="DRG">
              <RichTextEditor tab="DRG" />
            </TabPanel>
            <TabPanel value="grouper">
              <RichTextEditor tab="grouper" />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={uploadSuccess}
        autoHideDuration={5000}
        onClose={() => dispatch(handleClose())}
      >
        <Alert
          onClose={() => dispatch(handleClose())}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("contentUpload.successMessage")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={uploadFailed}
        autoHideDuration={5000}
        onClose={() => dispatch(handleClose())}
      >
        <Alert
          onClose={() => dispatch(handleClose())}
          severity="info"
          sx={{ width: "100%" }}
        >
          {t("contentUpload.errorMessage")}
        </Alert>
      </Snackbar>
    </div>
  );
}
