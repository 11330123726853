import React, { useState, useEffect } from "react";
import "./Manager.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SharedComponent from "./SharedComponent";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FolderIcon from "@mui/icons-material/Folder";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import {
  setMasterFilterData,
  setFilterData,
  setSelectedYear,
  GetAllGrouperDetails,
  setSelectedUploadYear,
  saveLogic,
  setIsGGrouperLogic,
  remove,
  setIsGGrouperCheck,
  GGrouper,
  setIsCasemixDRG,
  setSelFileType,
  setSelFileDetails,
  UpdateGrouper,
  DeleteGrouperSublist,
  setIsGGrouperRender,
  setIsDrg,
  loadDrg,
  loadcasemixDRG,
  setIsGGrouperLogicTariff,
  setExpanded,
  setIsDisabled,
  setDefaultIsGrouperLogic,
  setIsGGrouperDataUpdated,
  setIsGGrouperDataRemoved,
  GetUpdatedGrouper,
  setGGrouperTariffFlag,
} from "./managerSlice";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    padding: "0px",
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
      padding: "0px",
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      margin: "0px",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      padding: "0px",
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function GenerateGrouper() {
  const { t } = useTranslation("language");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { user, isAdminPrivileges } = useSelector(
    (state) => state.authentication
  );
  const manager = useSelector((state) => state.manager);
  const {
    isInitialLoadAPI,
    data,
    isNoData,
    distinctUploadYear,
    isRender,
    isGGrouperRender,
    selectedYear,
    isGGrouperLogic,
    isDrgMaster,
    isDrg,
    isCasemixDRGMaster,
    isCasemixDRG,
    selFileType,
    selFileDetails,
    isExpanded,
    isDisabled,
    isGGrouperDataAdded,
    isGGrouperDataRemoved,
    isGrouperLogicRender,
    grouperTariffFlag,
  } = manager;
  const [addLogic, setAddLogic] = useState(false);
  const [removeLogic, setRemoveLogic] = useState(false);
  const [addSubset, setAddSubset] = useState(false);
  const [ropen, setRopen] = useState(false);
  const [deleteItem, setDeleteItem] = useState();

  useEffect(() => {
    let url =
      "api/GetDRGLogicList?userId=" +
      user[0]["email"] +
      "&isAdmin=" +
      user[0].isAdmin;
    dispatch(loadDrg({ method: "GET", url: url }));
  }, []);

  useEffect(() => {
    if (isInitialLoadAPI && isRender) {
      let url = "api/GetGrouperUsedDetails";
      dispatch(GetAllGrouperDetails({ method: "GET", url: url }));
    } else if (isGGrouperRender) {
      dispatch(setIsGGrouperLogic(isGGrouperLogic[0]?.id));
    }
  }, [isInitialLoadAPI, isRender, isGGrouperRender]);

  useEffect(() => {
    if (isGGrouperLogic?.length > 0) {
      let url =
        "api/GetGrouperUsedDetailsById?grouperId=" + isGGrouperLogic[0]?.id;
      dispatch(GetUpdatedGrouper({ method: "GET", url: url }));
    }
  }, [isGrouperLogicRender]);

  useEffect(() => {
    if (isGGrouperDataAdded) {
      dispatch(setDefaultIsGrouperLogic());
      dispatch(setIsGGrouperDataUpdated(false));
    }
  }, [isGGrouperDataAdded]);

  useEffect(() => {
    if (isGGrouperDataRemoved) {
      dispatch(setIsGrouperLogic(0));
      dispatch(setIsGGrouperDataRemoved(false));
    }
  }, [isGGrouperDataRemoved]);

  const handleYearSelect = (e) => {
    const value = e.target.value;
    dispatch(setSelectedYear(value));
    if (value) {
      const result = data.filter((item) => {
        return item.year == value;
      });
      dispatch(setMasterFilterData(result));
      dispatch(setFilterData(result));
      dispatch(setIsGGrouperLogic(0));
    }
    if (value) {
      const result = isDrgMaster.filter((item) => {
        return item.year == value;
      });
      dispatch(setIsDrg(result));
    }
    if (value) {
      const result = isCasemixDRGMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsCasemixDRG(result));
    }
  };

  const handleClose = () => {
    // reset();
    setAddLogic(false);
    setRemoveLogic(false);
    setAddSubset(false);
    dispatch(setExpanded([]));
    dispatch(setIsDisabled(true));
    setRopen(false);
  };

  const onSubmit = (values) => {
    if (
      values.name !== null &&
      values.name !== "" &&
      values.name !== undefined
    ) {
      addGGrouperLogic(values);
      // reset();
      setAddLogic(false);
    }
  };

  async function addGGrouperLogic(values) {
    let logicName = values.name;
    let logicVersion = values.version;
    let logicYear = values.year;
    dispatch(setSelectedUploadYear(logicYear));
    let url =
      "api/AddGrouper?grouperName=" +
      logicName +
      "&grouperVersion=" +
      logicVersion +
      "&Year=" +
      logicYear;
    dispatch(saveLogic({ method: "POST", url: url, body: null }));
    dispatch(setIsGGrouperRender());
  }

  const onOk = (values) => (e) => {
    setRemoveLogic(false);
    removeSelectedLogic(values);
  };

  async function removeSelectedLogic(values) {
    let logicName = values.grouperName;
    let logicVersion = values.grouperVersion;
    let logicYear = values.year;
    let url =
      "api/DeleteGrouper?grouperName=" +
      logicName +
      "&grouperVersion=" +
      logicVersion +
      "&grouperYear=" +
      logicYear;
    dispatch(remove({ method: "POST", url: url, body: null }));
  }

  const GenerateGrouper = () => {
    if (
      isGGrouperLogic[0]?.drgLogicName == null ||
      isGGrouperLogic[0]?.drgLogicName == "" ||
      isGGrouperLogic[0]?.drgLogicVersion == null ||
      isGGrouperLogic[0]?.drgLogicVersion == ""
    ) {
      dispatch(setIsGGrouperCheck());
    } else {
      let url = "api/GetGrouper?id=" + isGGrouperLogic[0]?.id;
      dispatch(GGrouper({ method: "GET", url: url }));
      dispatch(setIsGGrouperRender());
    }
  };

  const handleTariff = (attribute) => (e) => {
    let checkedValue = e.target.checked;
    let result = isGGrouperLogic.map((row) => {
      return { ...row, [attribute]: checkedValue };
    });
    dispatch(setIsGGrouperLogicTariff(result));
  };

  const onSelect = (nodeID) => () => {
    dispatch(setIsDisabled(true));
    if (isExpanded[0] == nodeID) {
      dispatch(setExpanded([]));
    } else {
      dispatch(setExpanded([nodeID]));
    }
  };

  const onSubsetSelect = (details, fileType) => () => {
    dispatch(setSelFileDetails(details));
    dispatch(setSelFileType(fileType));
    dispatch(setIsDisabled(false));
  };

  const onAddSubset = () => {
    let grplogicName = "";
    let grplogicVersion = "";
    let grplogicYear = "";
    let grplogicId = "";
    let grpPriceName = "";
    let grpPriceVersion = "";
    let grpTariff = isGGrouperLogic[0]?.showTariff;

    if (selFileType == "DRGLogic") {
      grplogicName = selFileDetails.name;
      grplogicVersion = selFileDetails.version;
      grplogicYear = selFileDetails.year;
      grplogicId = selFileDetails.id;
    } else if (selFileType == "DRGPrice") {
      grpPriceName = selFileDetails.Name;
      grpPriceVersion = selFileDetails.version;
    }

    if (
      grplogicName == undefined ||
      grplogicVersion == undefined ||
      grplogicName == "" ||
      grplogicVersion == ""
    ) {
      grplogicName = isGGrouperLogic[0]?.drgLogicName;
      grplogicVersion = isGGrouperLogic[0]?.drgLogicVersion;
      grplogicYear = isGGrouperLogic[0]?.drgLogicYear;
      grplogicId = isGGrouperLogic[0]?.drgLogicId;
    }
    if (
      grpPriceName == undefined ||
      grpPriceVersion == undefined ||
      grpPriceName == "" ||
      grpPriceVersion == ""
    ) {
      grpPriceName = isGGrouperLogic[0]?.drgPriceName;
      grpPriceVersion = isGGrouperLogic[0]?.drgPriceVersion;
    }
    dispatch(setGGrouperTariffFlag());
    let url =
      "api/UpdateGrouper?grouperName=" +
      isGGrouperLogic[0]?.grouperName +
      "&grouperVersion=" +
      isGGrouperLogic[0]?.grouperVersion +
      "&logicName=" +
      grplogicName +
      "&logicVersion=" +
      grplogicVersion +
      "&logicYear=" +
      grplogicYear +
      "&logicId=" +
      grplogicId +
      "&priceName=" +
      grpPriceName +
      "&priceVersion=" +
      grpPriceVersion +
      "&showTariff=" +
      grpTariff +
      "&Type=" +
      undefined +
      "&Year=" +
      isGGrouperLogic[0]?.year +
      "&grouperId=" +
      isGGrouperLogic[0]?.id;
    dispatch(UpdateGrouper({ method: "POST", url: url, body: null }));
    setAddSubset(false);
    dispatch(setIsDisabled(true));
    dispatch(setExpanded([]));
    dispatch(setSelFileDetails([]));
  };

  const onDelete = (values) => (e) => {
    e.preventDefault();
    setDeleteItem(values);
    setRopen(true);
  };

  const onDeleteOk = (value) => (e) => {
    setRopen(false);
    let url =
      "api/DeleteGrouperSublist?grouperName=" +
      isGGrouperLogic[0]?.grouperName +
      "&grouperVersion=" +
      isGGrouperLogic[0]?.grouperVersion +
      "&year=" +
      isGGrouperLogic[0]?.year +
      "&selectedType=" +
      value;
    dispatch(DeleteGrouperSublist({ method: "POST", url: url }));
  };
  if (grouperTariffFlag) {
    onAddSubset();
  }
  return (
    <div className="GenerateGrouper">
      {isNoData == true && (
        <Paper className="paperContainer">
          <div className="right-section-container">
            <div className="right-section-header left">
              <Typography variant="h5">
                {t("Manager.Generate_Grouper")}
              </Typography>
            </div>
            <div className="right-section-header right">
              <div className="right-section-header-container">
                <Button
                  variant="contained"
                  size="small"
                  className="manager-button"
                  onClick={() => setAddLogic(true)}
                >
                  {t("GGrouper.add_grouper")}
                </Button>
              </div>
            </div>
          </div>
          <Divider />
          <div className="right-section-container-one">
            <div className="container-block two">
              <Typography
                align="center"
                color="#00000099"
                sx={{ marginLeft: "2px" }}
              >
                {t("GGrouper.GGrouper_Validation")}
              </Typography>
            </div>
          </div>
        </Paper>
      )}

      <Paper className="paperContainer">
        <div className="right-section-container">
          <div className="right-section-header left">
            <Typography variant="h5">
              {t("Manager.Generate_Grouper")}
            </Typography>
          </div>
          <div className="right-section-header right">
            <div className="right-section-header-container">
              <TextField
                label={t("Manager.Year")}
                variant="outlined"
                size="small"
                select
                onChange={handleYearSelect}
                sx={{ marginRight: 1 }}
                value={selectedYear}
              >
                {distinctUploadYear.map((year) => {
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button
                variant="contained"
                size="small"
                className="manager-button"
                onClick={() => {
                  setAddLogic(true), reset({ year: selectedYear });
                }}
              >
                {t("GGrouper.add_grouper")}
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        {isGGrouperLogic.length > 0 && (
          <div className="right-section-container">
            <div className="right-section-header left">
              <Typography variant="h6">
                {isGGrouperLogic[0]?.grouperName}-
                {isGGrouperLogic[0]?.grouperVersion}
              </Typography>
            </div>
            <div className="right-section-header right">
              <div className="right-section-header-container">
                {isAdminPrivileges && isGGrouperLogic[0]?.status == 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    className="manager-button"
                    onClick={() => setRemoveLogic(true)}
                  >
                    {t("Manager.Remove")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  className="manager-button"
                  onClick={GenerateGrouper}
                >
                  {t("Manager.Generate_Grouper")}
                </Button>
              </div>
            </div>
          </div>
        )}
        {isGGrouperLogic.length > 0 && (
          <div className="right-section-container-one">
            <div className="container-block one">
              <Typography
                className="typography-title"
                color="#00000099"
                gutterBottom
              >
                {t("Manager.publisher_status")}:
                <Typography
                  variant="string"
                  color="#000000DE"
                  sx={{ marginLeft: "2px" }}
                >
                  {isGGrouperLogic[0]?.status == 1 && t("Global.production")}
                  {isGGrouperLogic[0]?.status == 2 && t("Global.hearing")}
                  {isGGrouperLogic[0]?.status == 3 && t("Global.test")}
                  {isGGrouperLogic[0]?.status == 0 && t("Global.notpublished")}
                </Typography>
              </Typography>
              <Typography color="#00000099">
                Status:
                <Typography
                  variant="string"
                  color="#000000DE"
                  sx={{ marginLeft: "2px" }}
                >
                  {isGGrouperLogic[0]?.isGenerated == true
                    ? t("Manager_Common.generated")
                    : t("Manager_Common.not_generated")}
                </Typography>
                <Checkbox
                  checked={
                    isGGrouperLogic[0]?.showTariff == true ? true : false
                  }
                  disabled={isGGrouperLogic[0]?.status == 0 ? false : true}
                  onChange={handleTariff("showTariff")}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="string" color="#000000DE">
                  {t("Manager.show_Tarrifs")}
                </Typography>
              </Typography>
              {(isGGrouperLogic[0]?.usedInteractive.length > 0 ||
                isGGrouperLogic[0]?.usedViwer.length > 0) && (
                <Typography className="typography-title">
                  {t("Manager_Common.used_in")}:
                  {isGGrouperLogic[0]?.usedInteractive.length > 0 && (
                    <Tooltip
                      arrow
                      title={
                        <div>
                          {isGGrouperLogic[0]?.usedInteractive.map((row) => {
                            return (
                              <Typography color="#99DAF2">
                                {row.interactiveName} {row.interactiveVersion}{" "}
                                {row.year}
                              </Typography>
                            );
                          })}
                        </div>
                      }
                    >
                      <Link
                        component="button"
                        color="#00A3E0"
                        sx={{ padding: "0 1%" }}
                      >
                        {isGGrouperLogic[0]?.usedInteractive.length}{" "}
                        {t("Manager_Common.Interactives")}
                      </Link>
                    </Tooltip>
                  )}
                  {isGGrouperLogic[0]?.usedViwer.length > 0 && (
                    <Tooltip
                      arrow
                      title={
                        <div>
                          {isGGrouperLogic[0]?.usedViwer.map((row) => {
                            return (
                              <Typography color="#99DAF2">
                                {row.visualName} {row.visualVersion} {row.year}
                              </Typography>
                            );
                          })}
                        </div>
                      }
                    >
                      <Link
                        component="button"
                        color="#00A3E0"
                        sx={{ padding: "0 1%" }}
                      >
                        {isGGrouperLogic[0]?.usedViwer.length} Viewer
                      </Link>
                    </Tooltip>
                  )}
                </Typography>
              )}
            </div>
            <Divider />
            <div className="container-block two">
              <div className="section">
                <Typography color="#00000099">{t("Manager.Subset")}</Typography>
                {isGGrouperLogic[0]?.status == 0 && (
                  <Link
                    component="button"
                    color="#00A3E0"
                    onClick={() => setAddSubset(true)}
                  >
                    {t("Manager.Add_Subset")}
                  </Link>
                )}
              </div>
              {isGGrouperLogic[0]?.drgLogicName != null &&
                isGGrouperLogic[0]?.drgLogicName != "" && (
                  <div className="manage_subset">
                    <Typography color="#00000099">
                      {t("Manager_Common.drg_logic_file")}:
                      <Typography variant="string" color="#000000DE">
                        {" "}
                        {isGGrouperLogic[0]?.drgLogicName}{" "}
                        {isGGrouperLogic[0]?.drgLogicVersion}{" "}
                        {isGGrouperLogic[0]?.drgLogicYear}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGGrouperLogic[0]?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete("DRGLogic")}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              {isGGrouperLogic[0]?.drgPriceName != null &&
                isGGrouperLogic[0]?.drgPriceName != "" && (
                  <div className="manage_subset">
                    <Typography color="#00000099">
                      {t("Manager_Common.casemix_drg_file")}:
                      <Typography variant="string" color="#000000DE">
                        {" "}
                        {isGGrouperLogic[0]?.drgPriceName}{" "}
                        {isGGrouperLogic[0]?.drgPriceVersion}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGGrouperLogic[0]?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete("DRGCasemix")}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        )}
      </Paper>

      <Dialog open={addLogic} onClose={handleClose}>
        <DialogTitle>{t("GGrouper.add_grouper")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              paddingTop: "5px !important",
              paddingBottom: "12px !important",
            }}
          >
            <div className="form-section">
              <TextField
                id="name"
                label={t("Manager_Common.Name")}
                fullWidth
                variant="outlined"
                className="form-section"
                {...register("name", {
                  required: "Name is required.",
                  // pattern: {
                  //     value: /^[a-zA-Z0-9_.-]*$/,
                  //     message: t("Manager_Common.Validataion"),
                  // },
                })}
                error={Boolean(errors.name)}
                helperText={errors?.name?.message}
                inputProps={{ maxLength: 20 }}
              />
            </div>
            <div className="form-section-double">
              <div className="form-sub-section">
                <TextField
                  label="Version"
                  variant="outlined"
                  className="form-sub-section"
                  {...register("version", {
                    required: t("Manager.Version_Required"),
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/,
                      message: t("Manager.Invalid_Version"),
                    },
                  })}
                  error={Boolean(errors.version)}
                  helperText={errors?.version?.message}
                  inputProps={{ maxLength: 5 }}
                />
              </div>
              <div className="form-sub-section">
                <TextField
                  label={t("Manager.yearLabel")}
                  variant="outlined"
                  className="form-sub-section"
                  {...register("year", {
                    required: t("Manager.Year_Required"),
                    pattern: {
                      value: /^[0-9]{4,4}$/,
                      message: t("Manager.entryDigits"),
                    },
                  })}
                  type="Number"
                  error={Boolean(errors.year)}
                  helperText={errors?.year?.message}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" onClick={onSubmit} type="submit">
              {t("Manager.Save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={removeLogic} onClose={handleClose} maxWidth="xs">
        <DialogTitle>{t("GGrouper.remove_grouper")}</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            {t("GGrouper.remove_validation")}
          </Typography>
          <Typography gutterBottom>
            Grouper {t("Manager_Common.Name")}:{" "}
            {isGGrouperLogic[0]?.grouperName}-
            {isGGrouperLogic[0]?.grouperVersion}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          <Button
            variant="contained"
            onClick={onOk(isGGrouperLogic[0])}
            type="submit"
          >
            {t("Manager.Remove")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addSubset} onClose={handleClose}>
        <DialogTitle>{t("Manager.Add_Subset")}</DialogTitle>
        <DialogContent>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            sx={{ minWidth: 300 }}
            expanded={isExpanded}
          >
            <StyledTreeItem
              nodeId="DRGLogic"
              labelText="DRG Logic"
              labelIcon={FolderIcon}
              labelInfo={isDrg.length}
              onClick={onSelect("DRGLogic")}
            >
              {isDrg.map((item) => {
                return (
                  <StyledTreeItem
                    nodeId={item.name + "-" + item.version}
                    labelText={item.name + "-" + item.version}
                    onClick={onSubsetSelect(item, "DRGLogic")}
                  />
                );
              })}
            </StyledTreeItem>
            <StyledTreeItem
              nodeId="DRGPrice"
              labelText="Casemix DRG"
              labelIcon={FolderIcon}
              labelInfo={isCasemixDRG.length}
              onClick={onSelect("DRGPrice")}
            >
              {isCasemixDRG.map((item) => {
                return (
                  <StyledTreeItem
                    nodeId={item.Name + "-" + item.version}
                    labelText={item.Name + "-" + item.version}
                    onClick={onSubsetSelect(item, "DRGPrice")}
                  />
                );
              })}
            </StyledTreeItem>
          </TreeView>
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          <Button
            variant="contained"
            onClick={onAddSubset}
            disabled={isDisabled}
          >
            {t("Manager.Add")}{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={ropen} onClose={handleClose}>
        <DialogContent>{t("Manager_Common.deleteItem")}</DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          <Button
            variant="contained"
            onClick={onDeleteOk(deleteItem)}
            type="submit"
          >
            {t("Manager.Ok")}
          </Button>
        </DialogActions>
      </Dialog>
      {isGGrouperLogic.length == 0 && (
        <div className="right-section-container-one">
          <Typography
            align="center"
            color="#00000099"
            sx={{ marginLeft: "2px" }}
          >
            {t("Manager.No_Grouper_Selected")}
          </Typography>
        </div>
      )}
      <SharedComponent />
    </div>
  );
}
