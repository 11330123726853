
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import * as React from 'react';
import { DiagramProps } from './viewerModel'
import './Diagram.css';
import "./Viewer.css";
import Modal from 'react-modal';
import { store } from "../../app/store";
import { testCall, saveCodeValues, getDRGPriceJSON, getMetadataForLogicName, getDRGPopUpJSON, getMDCGroupCodeJSON, getGroupCodeJSON, setModalActive } from "./viewerSlice";
import NodeModal from './NodeModal';


const customStyles  = {
    content: {
        top: '13%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        position: 'absolute',
        background: 'lightYellow',
        border: '1px solid'
    },
};

export class DRGDiagramWrapper extends React.Component {
    /**
     * Ref to keep a reference to the Diagram component, which provides access to the GoJS diagram via getDiagram().
     */
    diagramRef;
    searchNodesArray = [];
    myDiagram;
    myWholeModel;
    state;
    popupText = "";

    /** @internal */
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            modalIsOpen: false,
            setIsOpen: false,
            metadataCollection: [],
            groupCode: '',
            groupCodeValue: '',
            tableData: [],
            page: 0,
            rowsPerPage: 5,
            expanded: "Show",
            regelValue: this.props.regelValue,
            searchVal: this.props.searchValue
        };
        this.showModalDialog = this.showModalDialog.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.diagramRef = React.createRef();
    }

    componentDidMount() {

        let value;
        store.subscribe(function () {
            let state = store.getState();
            value = state.viewer;
            if (!value.modalActive) {
                var btn = document.getElementById("mdCloseBtn");
                if (btn != null) {
                    btn.click();
                }
            }
        });

    }

    /**
     * Diagram initialization method, which is passed to the ReactDiagram component.
     * This method is responsible for making the diagram and initializing the model, any templates,
     * and maybe doing other initialization tasks like customizing tools.
     * The model's data should not be set here, as the ReactDiagram component handles that.
     */
    initDiagram() {
        const $ = go.GraphObject.make;

        function showMessage(str, groupcode, yellowbox, groupCodeVal) {

            if (!yellowbox) {
                document.getElementById("inputEventsMsg").textContent = groupcode;
                document.getElementById("infoBoxHolder").textContent = groupCodeVal;
                // console.log(groupCodeVal);
                var btn = document.getElementById("mdBtn");
                if (btn != null) {
                    btn.click();
                }
            }
        }

        function OffsetLink() {
            go.Link.call(this);
          }
          go.Diagram.inherit(OffsetLink, go.Link);
          OffsetLink.prototype.computeMidOrthoPosition = function (
            fromX,
            fromY,
            toX,
            toY,
            vertical
          ) {
            if (vertical) return toY - 15;
            return go.Link.prototype.computeMidOrthoPosition.call(
              this,
              fromX,
              fromY,
              toX,
              toY,
              vertical
            );
          };

        go.Shape.defineFigureGenerator("TipOutline", function (sh, w, h) {
            var alen = 10;  // horizontal length of pointer
            var t = h / 8;  // breadth (height) of pointer
            var geo = new go.Geometry()
                .add(new go.PathFigure(0, 0, true, true)  // filled and shadowed
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, 0))
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, h / 2 - t))
                    .add(new go.PathSegment(go.PathSegment.Line, w, h / 2))
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, h / 2 + t))
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, h))
                    .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
            geo.spot2 = new go.Spot(1, 1, -alen, 0);  // content should not overlap right side
            return geo;
        });
        // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
        //go.Diagram.licenseKey = "73f14fe5b00537c702d90776423d6af919a475609b8417a30b0446f6ef083f1c249cee7101d6d8c6d1fc1efb4f7fc7cbcdc3617ac24c556be76583de47e284a9b73275bb15084788f4032ec5c9f82ef2ab7c79a596b77ea58a2d8ca7ecadc2cc0efae3d21d9a08bb7b280723467dae49b7e4d964fa03";
        // go.Diagram.licenseKey =
         //"2bf843e4b76658c511d35a25403e7efb0fab2d3bcf804df7590417a3ec0a3d12229dbd2b51d38cc1d2ab49f81974908dd9c53e7c944f033bb231868e47b6d5ffb63372b315581789f70122c68bea2da9af2d71f791e620a2d87e8cf6e2acc6cb55ebf7d44e9a0aeb2e2d04630676ab4be2f8da7ffe50994f3e3f9da4a9eaab4cfb697280ceec0781eb5977d8baeaa60c247405cf3ca82ca61b6411d9cc43e92be460eca88839";
        go.Diagram.licenseKey = "2bf843e4b76658c511d35a25403f7efb0fab2d3bcf804df7590417a3ec0a3d12229dbd2b51d38dc1d2ab49f9086dc6d18e906979c74c516be535d3de13e783ffbb3673b212584786a25324c7caad2ea9ab2c73fbc5e522a28f7f89f3bbfad18c5abda3d248985eba3b6805305676b04ea8f8dc";

        const diagram =
            $(go.Diagram,
                {
                    // 'undoManager.isEnabled': true,  // must be set to allow for model change listening        
                    // 'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },        
                    layout: $(go.Layout,
                        { isInitial: false, isOngoing: false }),
                    model: $(go.GraphLinksModel,
                        {
                            linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                            // positive keys for nodes
                            makeUniqueKeyFunction: (m, data) => {
                                let k = data.key || 1;
                                while (m.findNodeDataForKey(k)) k++;
                                data.key = k;
                                return k;
                            },
                            // negative keys for links
                            makeUniqueLinkKeyFunction: (m, data) => {
                                let k = data.key || -1;
                                while (m.findLinkDataForKey(k)) k--;
                                data.key = k;
                                return k;
                            }
                        })
                });

        // define a simple Node template
        diagram.nodeTemplate =

            $(go.Node, "Auto",
                { visible: true, movable: false, deletable: false, locationSpot: go.Spot.Center, alignment: go.Spot.Center, },
                { resizable: false },
                { isShadowed: true },
                {
                    selectionAdornmentTemplate:
                        $(go.Adornment, "Spot",
                            $(go.Panel, "Auto",
                                // this Adornment has a rectangular blue Shape around the selected node
                                $(go.Shape, { fill: null, stroke: "dodgerblue", strokeWidth: 3, strokeDashArray: [4, 2] })
                                , $(go.Placeholder)
                            )

                        )  // end Adornment
                },

                $(go.Panel, "Horizontal",
                    {
                        visible: true, alignment: go.Spot.Center, defaultAlignment: go.Spot.Center
                    },
                    $(go.Shape,
                        { name: "SHAPE", visible: true, alignment: go.Spot.Center },
                        new go.Binding("cursor", "cursor"),
                        new go.Binding("geometryString", "fig"),
                        new go.Binding("figure", "fig"),
                        new go.Binding("width", "width"),
                        new go.Binding("height", "height"),
                        new go.Binding("fill", "color"),
                        new go.Binding("stroke", "isHighlighted", function (h) { return h ? "#0d0033" : "black"; }).ofObject(),
                        //new go.Binding("strokeWidth", "isHighlighted", function(h) { return h ? 2 : 0 }).ofObject(),
                        new go.Binding("strokeDashArray", "isHighlighted", function (h) { return h ? [4, 2] : [0, 0]; }).ofObject())
                ),
                new go.Binding("position", "bounds", function (b) { return b.position; })
                    .makeTwoWay(function (p, d) { return new go.Rect(p.x, p.y, d.bounds.width, d.bounds.height); }),

                {
                    click: function (e, obj) {


                        var data = obj?.part?.data;
                        //console.log("Node Data:", data);
                        showMessage(data.text, data.groupCode, data.yellowbox, data.groupCodeValue);
                        // var node = myDiagram.findNodeForKey(obj.part.data.key);
                    }
                },

                $(go.TextBlock,
                    { margin: 1, font: "normal 9px Montserrat",stretch: go.GraphObject.Horizontal, maxSize: new go.Size(120, NaN), textAlign: "center", alignment: go.Spot.Center, overflow: go.TextBlock.OverflowEllipsis, maxLines: 5},
                    new go.Binding("cursor", "cursor"),
                    new go.Binding("text", "text")),
                {
                    toolTip:
                        // myToolTip
                        $(go.Adornment, "Spot",
                            new go.Binding("visible", "tooltip", function (t, obj) { return (t.length >= 0 && obj.data.leafnode) }),
                            { isShadowed: true },
                            $(go.Placeholder),
                            $(go.Panel, "Auto",
                                { alignment: go.Spot.TopLeft, alignmentFocus: go.Spot.TopRight, minSize: new go.Size(140, NaN) },
                                $(go.Shape, "TipOutline",
                                    { fill: "white", stroke: "gray", strokeWidth: 0.5, shadowVisible: true }),
                                $(go.Panel, "Table",
                                    $(go.RowColumnDefinition, { row: 0, background: "#F2F1EC" }),
                                    $(go.TextBlock,
                                        { row: 0, margin: 10, stretch: go.GraphObject.Horizontal, font: "normal 12px Montserrat", textAlign: "left", minSize: new go.Size(140, NaN) },
                                        new go.Binding("text", "rownumber")),
                                    $(go.TextBlock,
                                        { row: 1, margin: 10, alignment: go.Spot.Left, font: "normal 12px Montserrat", textAlign: "left", minSize: new go.Size(140, NaN), visible: false },
                                        new go.Binding("text", "tooltip"),
                                        new go.Binding("visible", "tooltip", function (t) { return !!t; })
                                    )
                                )
                            )
                        )
                }
            );

        // relinking depends on modelData
        diagram.linkTemplate =

            $(OffsetLink,
                { routing: go.Link.Orthogonal, corner: 10 },
                // { fromSpot: go.Spot.Bottom, toSpot: go.Spot.Top },
                new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
                new go.Binding("toSpot", "toSpot", go.Spot.parse),
                $(go.Shape),
                $(go.Shape, { toArrow: "OpenTriangle" })
            );

        return diagram;
    }

    render() {
        return (
            <div>

                <div className="diagram-container">
                    <ReactDiagram
                        ref={this.diagramRef}
                        divClassName='drg-diagram-component'
                        initDiagram={this.initDiagram}
                        nodeDataArray={this.props.nodeDataArray}
                        linkDataArray={this.props.linkDataArray}
                        modelData={this.props.modelData}
                        skipsDiagramUpdate={this.props.skipsDiagramUpdate}
                    />
                    <div id="inputEventsMsg" style={{ display: "none" }}></div>
                    <div id="infoBoxHolder" style={{ display: "none" }}></div>
                    <button id="mdBtn" onClick={this.showModalDialog} style={{ display: "none" }}>Show dialog</button>
                    <button id="mdCloseBtn" onClick={this.closeModal} style={{ display: "none" }}>Close dialog</button>

                    {this.state.modalIsOpen ? <NodeModal /> : null}
                </div>

            </div >

        );
    }

    showModalDialog() {
        this.popupText = "";
        var t = document.getElementById("infoBoxHolder");
        if (t != undefined && t != null) {
            this.popupText = t.textContent;
        }
        var groupCode = document.getElementById("inputEventsMsg").textContent;
        var groupCodeValue = document.getElementById("infoBoxHolder").textContent;
        var fields = groupCodeValue?.trim().split(" ");
        var subCode = fields[0].replace("-", "");

        store.dispatch(saveCodeValues({ groupCode: groupCode, groupCodeValue: groupCodeValue, subCode: subCode }));
        if (
            subCode.includes("AK") ||
            subCode.includes("HJ") ||
            subCode.includes("OP") ||
            subCode.includes("PR") ||
            subCode.includes("SP") ||
            subCode.includes("TE") ||
            subCode.includes("MP") ||
            subCode.includes("MA") ||
            subCode.includes("UA")
        ) {
            store.dispatch(getDRGPriceJSON()).then(() => {
                store.dispatch(getMetadataForLogicName()).then(() => {
                    store.dispatch(getDRGPopUpJSON()).then(() => {
                        store.dispatch(setModalActive(true));
                        this.setState({ modalIsOpen: true });
                    });
                })
            });
        }
        else {
            store.dispatch(getMetadataForLogicName()).then(() => {
                if (
                    groupCode == "MDC" ||
                    groupCode == "mdc"
                ) {
                    store.dispatch(getMDCGroupCodeJSON()).then(() => {
                        store.dispatch(setModalActive(true));
                        this.setState({ modalIsOpen: true });
                    });
                } else {
                    store.dispatch(getGroupCodeJSON()).then(() => {
                        store.dispatch(setModalActive(true));
                        this.setState({ modalIsOpen: true });
                    });
                }
            })
        }
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }
}


