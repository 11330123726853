import React, { useState, useEffect } from 'react'
import "./Manager.css";
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import Paper, { PaperProps } from '@mui/material/Paper';
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Dropzone from "react-dropzone";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setFilterData, loadlogic, upload, clearStatus, setSelectedGrpCodeValue, setSelectedYear, setSelectedUploadYear, setMasterFilterData, remove, download, setIsRender } from "./managerSlice";
import { FileNames, FileNamesUpload, SupportFilesUpload } from "./AdminFiles";
import { Link, useHistory } from "react-router-dom";
import SharedComponent from "./SharedComponent";
import { resetWarningCache } from 'prop-types';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Draggable from 'react-draggable';
import CloseIcon from "@mui/icons-material/Close";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function FileLibrary() {
    const { t } = useTranslation("language");
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [fileName, setFileName] = useState(undefined);
    const [fileId, setFileId] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [ropen, setRopen] = useState(false);
    const [removeFile, setRemoveFile] = useState(false);
    const manager = useSelector((state) => state.manager);
    const {
        data,
        distinctUploadYear,
        filterdata,
        isRender,
        selectedYear,
        selectedUploadYear,
        masterfilterdata,
        isClinicalCode,
        isCasemixFile,
        isSupportFile,
    } = manager;
    const [selectedGrpCodeVal, setSelectedGrpCodeVal] = useState(FileNamesUpload[0]);
    const [selectedSupportVal, setSelectedSupportVal] = useState(SupportFilesUpload[0]);
    const { user, isAdminPrivileges } = useSelector((state) => state.authentication);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        setSearchValue("");
        if (isRender) {
            if ((selectedUploadYear == null || selectedUploadYear == '' || selectedUploadYear == undefined) && removeFile == false) {
                dispatch(setSelectedYear());
            }
            let selectedGrpCodeValue = "";
            if (isClinicalCode) {
                selectedGrpCodeValue = "sks.csv";
            }
            if (isCasemixFile) {
                selectedGrpCodeValue = selectedGrpCodeVal;
            }
            if (isSupportFile) {
                selectedGrpCodeValue = selectedSupportVal;
            }
            let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=" + selectedGrpCodeValue;
            dispatch(loadlogic({ method: "GET", url: url }));
        }
    }, [isRender, isClinicalCode, isCasemixFile, isSupportFile, selectedGrpCodeVal, selectedSupportVal]);

    const handleClose = () => {
        setSelectedFiles("");
        // reset();
        setOpen(false);
        setRopen(false);
    }

    const handleYearSelect = (e) => {
        const value = e.target.value;
        dispatch(setSelectedYear(value));
        if (value) {
            const result = data.filter((item) => {
                return item.Year == value;
            });
            dispatch(setMasterFilterData(result));
            dispatch(setFilterData(result));
        }
    }

    const handleSelectedGrpCodeVal = (e) => {
        dispatch(setIsRender());
        setSelectedGrpCodeVal(e.target.value);
    }

    const handleSelectedSupportVal = (e) => {
        dispatch(setIsRender());
        setSelectedSupportVal(e.target.value);
    }

    const requestSearch = (event) => {
        setSearchValue(event.target.value);
        const result = masterfilterdata.filter((item) => {
            //         return (
            //             item.Name
            //                 ?.toLowerCase()
            //                     .includes(event.target.value ?.toLowerCase()) ||
            //                     item.version ?.toLowerCase().includes(event.target.value ?.toLowerCase())
            //   );
            if (item.Name.toLowerCase().includes(event.target.value.toLowerCase())) {
                return item.Name.toLowerCase().includes(event.target.value.toLowerCase());
            }
            else if (item.version.toLowerCase().includes(event.target.value.toLowerCase())) {
                return item.version.toLowerCase().includes(event.target.value.toLowerCase());
            }
            else if (event.target.value.toLowerCase().includes(item.Name.toLowerCase()) || event.target.value.toLowerCase().includes(item.version.toLowerCase())) {
                return item.Name + " " + item.version.toLowerCase().includes(event.target.value.toLowerCase());
            }
        });
        dispatch(setFilterData(result));
    };

    const onDrop = (files) => {
        if (files.length > 0) {
            setSelectedFiles(files);
        }
    };

    const uploadFile = () => {
        setOpen(true);
        reset({ year: selectedYear });
    };

    async function uploadLogic(values) {
        let currentFile = selectedFiles[0];
        setCurrentFile(currentFile);
        setSelectedFiles("");
        let logicName = values.name;
        let logicVersion = values.version;
        let logicYear = values.year;
        dispatch(setSelectedUploadYear(logicYear));
        let selectedGrpCodeValue = "";
        if (isClinicalCode) {
            selectedGrpCodeValue = "sks.csv";
        }
        if (isCasemixFile) {
            selectedGrpCodeValue = selectedGrpCodeVal;
        }
        if (isSupportFile) {
            selectedGrpCodeValue = selectedSupportVal;
        }
        let url = "api/uploadCSV?logicName=" + logicName + "&logicVersion=" + logicVersion + "&logicYear=" + logicYear +
            "&selectedGrpCodeVal=" + selectedGrpCodeValue + "&userEmail=" + user[0]['email'];
        console.log(url);
        let formData = new FormData();
        formData.append('file', currentFile, currentFile.name);
        dispatch(upload({ method: "POST", url: url, body: formData }));
    }

    const onSubmit = (values) => {
        if (values.name !== null && values.name !== '' && values.name !== undefined) {
            uploadLogic(values);
            // reset();
            setOpen(false);
        }
    }

    const onDownload = (values) => (e) => {
        downloadSelectedFile(values._id);
    }

    async function downloadSelectedFile(id) {
        let url = "";
        let selectedID = id;
        if (isClinicalCode) {
            dispatch(setSelectedGrpCodeValue("sks.csv"));
            url = "api/ExportSksMaster?&id=" + selectedID;
        }
        if (isCasemixFile) {
            if (selectedGrpCodeVal == "till.csv") {
                url = "api/ExportTillMaster?&id=" + selectedID;
            }
            else if (selectedGrpCodeVal == "dg1.csv") {
                url = "api/ExportDG1Master?&id=" + selectedID;
            }
            else if (selectedGrpCodeVal == "drglogik.csv") {
                url = "api/ExportDRGLogikMaster?&id=" + selectedID;
            }
            else if (selectedGrpCodeVal == "drgtpt.csv") {
                url = "api/ExportDRGTPTMaster?&id=" + selectedID;
            }
            else if (selectedGrpCodeVal == "CountingCode.yaml") {
                url = "api/ExportCountingCodeMaster?&id=" + selectedID;
            }
            else if (selectedGrpCodeVal == "casemixDRG.csv") {
                url = "api/ExportCasemixDrg?&id=" + selectedID;
            }
            else if (selectedGrpCodeVal == "interne koder.yaml") {
                url = "api/ExportInterneKoder?&id=" + selectedID;
            }
            dispatch(setSelectedGrpCodeValue(selectedGrpCodeVal));
        }
        if (isSupportFile) {
            if (selectedSupportVal == "egenskaber.csv") {
                url = "api/ExportEGENSKABERMaster?&id=" + selectedID;
            }
            else if (selectedSupportVal == "MDCSpecification.yaml") {
                url = "api/ExportMDCSPECIFICATIONMaster?&id=" + selectedID;
            }
            dispatch(setSelectedGrpCodeValue(selectedSupportVal));
        }
        dispatch(download({ method: "GET", url: url }));
    }

    const onRemove = (values) => (e) => {
        e.preventDefault();
        setFileName(values.Name + " " + values.version);
        setFileId(values._id);
        setRopen(true);
    }

    const onOk = (id) => (e) => {
        setRopen(false);
        removeSelectedLogic(id);
        setRemoveFile(true);
    }

    async function removeSelectedLogic(id) {
        let selectedID = id;
        let selectedGrpCodeValue = "";
        if (isClinicalCode) {
            selectedGrpCodeValue = "sks.csv";
        }
        if (isCasemixFile) {
            selectedGrpCodeValue = selectedGrpCodeVal;
        }
        if (isSupportFile) {
            selectedGrpCodeValue = selectedSupportVal;
        }
        let url = "api/removeLogicNameAndVersion?&selectedGrpCodeVal=" + selectedGrpCodeValue +
            "&logicNameAndVersion=" + selectedID;
        dispatch(remove({ method: "GET", url: url }));
    }

    return (
        <div className="FileLibrary">
            <Paper className="paperContainer">
                <div className="right-section-container">
                    <div className="right-section-header left">
                        <Typography variant="h5">
                            {isClinicalCode && t("Manager.SKS_Data_Files")}
                            {isCasemixFile && t("Manager.Casemix_File")}
                            {isSupportFile && t("Manager.support_file")}
                        </Typography>
                        <Typography variant="string" color="#00000099">
                            {t("Global.Total")} {filterdata.length}
                        </Typography>
                    </div>
                    <div className="right-section-header right">
                        <div className="right-section-header-container">
                            <TextField
                                label={t("Manager.yearLabel")}
                                variant="outlined"
                                size="small"
                                select
                                onChange={handleYearSelect}
                                sx={{ marginRight: 1 }}
                                value={selectedYear}
                            >
                                {distinctUploadYear.map((year) => {
                                    return (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            {isCasemixFile && (
                                <TextField
                                    label={t("Manager_Common.File_Type")}
                                    variant="outlined"
                                    size="small"
                                    select
                                    onChange={handleSelectedGrpCodeVal}
                                    sx={{ marginRight: 1 }}
                                    value={selectedGrpCodeVal}
                                >
                                    {FileNamesUpload.map((name) => {
                                        return (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            )}
                            {isSupportFile && (
                                <TextField
                                    label={t("Manager_Common.File_Type")}
                                    variant="outlined"
                                    size="small"
                                    select
                                    onChange={handleSelectedSupportVal}
                                    sx={{ marginRight: 1 }}
                                    value={selectedSupportVal}
                                >
                                    {SupportFilesUpload.map((name) => {
                                        return (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            )}
                            <Button
                                variant="contained"
                                size="small"
                                className="manager-button"
                                // disabled={!selectedFiles}
                                onClick={uploadFile}
                            >
                                {t("Manager_Common.Upload_File")}
                            </Button>
                        </div>
                    </div>
                </div>
                <Divider
                />
                <div className="right-section-container">
                    <TextField
                        variant="outlined"
                        placeholder={t("Manager_Common.search")}
                        value={searchValue}
                        onChange={requestSearch}
                        sx={{ width: "100%" }}
                        InputProps={{
                            startAdornment: (
                                <SearchIcon
                                    sx={{ color: "#00000040" }}
                                />
                            ),
                        }}
                    />
                </div>
                <div className="right-section-container">
                    <Table>
                        <TableBody>
                            {filterdata
                                .map((row) => {
                                    return (
                                        <TableRow className="tableRow" key={row ?._id}>
                                            <TableCell className="tableCell" sx={{ flexGrow: 1 }} >{row.Name} {row.version}</TableCell>
                                            {/* <TableCell className="tableCell" >{<CheckCircleIcon sx={{ color: "green" }} />}</TableCell>
                                                <TableCell className="tableCell" >{<DownloadIcon sx={{ color: "blue" }} />}</TableCell> */}
                                            <TableCell className="tableCell" ><Typography variant="string" color="#00000080"> {row.UploadedDateFormat}</Typography></TableCell>
                                            <TableCell className="tableCell" >{<div><CheckCircleIcon sx={{ color: "#A1D794" }} /><DownloadIcon onClick={onDownload(row)} sx={{ color: "#959CA0", cursor: "pointer", "&:hover": { color: "#40BAE8" } }} />{isAdminPrivileges && <DeleteIcon className="test" onClick={onRemove(row)} sx={{ color: "#959CA0", cursor: "pointer", "&:hover": { color: "#EC948D" } }} />}</div>}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth="lg"
                >
                    <DialogTitle style={{ cursor: "move" }} >
                        {isClinicalCode && (t("Manager.Upload") + " " + t("Manager_Common.SKS_Data") + " " + t("Global.File"))}
                        {isCasemixFile && (t("Manager.Upload") + " " + selectedGrpCodeVal + " " + t("Global.File"))}
                        {isSupportFile && (t("Manager.Upload") + " "  + selectedSupportVal + " " + t("Global.File"))}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent sx={{ paddingTop: "5px !important", paddingBottom: "12px !important" }}>
                            <div className="dropzone-container">
                                <Dropzone
                                    onDrop={onDrop}
                                    multiple={false}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section className="dropzone">
                                            <div {...getRootProps({})}>
                                                <input {...getInputProps()} />
                                                {selectedFiles && selectedFiles[0].name ? (
                                                    <div className="selected-file">
                                                        {selectedFiles && selectedFiles[0].name}
                                                    </div>
                                                ) : (
                                                        <div>
                                                            <UploadFileIcon fontSize="medium" />
                                                            <div>
                                                                {t("Manager_Common.Drag")}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                            <div className="form-section">
                                <TextField
                                    id="name"
                                    label={t("Manager_Common.Name")}
                                    fullWidth
                                    variant="outlined"
                                    className="form-section"
                                    {...register("name", {
                                        required: t("Manager_Common.File_Name_Required"),
                                        // pattern: {
                                        //     value: /^[a-zA-Z0-9_.-]*$/,
                                        //     message: t("Manager_Common.Validataion"),
                                        // },
                                    })}
                                    error={Boolean(errors.name)}
                                    helperText={errors ?.name ?.message}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </div>
                            <div className="form-section-double">
                                <div className="form-sub-section">
                                    <TextField
                                        label="Version"
                                        variant="outlined"
                                        className="form-sub-section"
                                        {...register("version", {
                                            required: t("Manager.Version_Required"),
                                            // pattern: {
                                            //     value: /^[a-zA-Z0-9]*$/,
                                            //     message: t("Manager.Invalid_Version"),
                                            // },
                                        })}
                                        error={Boolean(errors.version)}
                                        helperText={errors ?.version ?.message}
                                        inputProps={{ maxLength: 20 }}

                                    />
                                </div>
                                <div className="form-sub-section">
                                    <TextField
                                        label={t("Manager.yearLabel")}
                                        variant="outlined"
                                        className="form-sub-section"
                                        {...register("year", {
                                            required: t("Manager.Year_Required"),
                                            pattern: {
                                                value: /^[0-9]{4,4}$/,
                                                message: t("Manager.entryDigits"),
                                            },
                                        })}
                                        type="Number"
                                        error={Boolean(errors.year)}
                                        helperText={errors ?.year ?.message}
                                        inputProps={{ maxLength: 4 }}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions sx={{ padding: "12px 24px !important" }}>
                            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
                            <Button variant="contained" onClick={onSubmit} type="submit">{t("Manager.Save")}</Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Dialog open={ropen} onClose={handleClose}>
                    <DialogContent>
                        {t("Manager_Remove.Dialog_msg")} {fileName}
                    </DialogContent>
                    <DialogActions sx={{ padding: "12px 24px !important" }}>
                        <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
                        <Button variant="contained" onClick={onOk(fileId)} type="submit">{t("Manager.Ok")}</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
            <SharedComponent />
        </div>
    )
}

