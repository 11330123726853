import React, { useState, useEffect } from "react";
import * as services from "../../services/apiServices";
import "./Publisher.css";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  getDrgLogics,
  getAllGrouperData,
  getAllInteractiveData,
  getAllVisualData,
  setLogicValue,
  setActionFlag,
  handleYearValueChange,
  handleFileNameUpdates,
  handleActionChange,
  publishDRG,
  publishOtherCollection,
  handleClose,
  setActionAlert,
  resetPublishSuccess,
  setShowTariff,
  getDRGContent,
  getGrouperContent,
  getInteractiveContent,
  getVisualContent,
  closeUploadContent,
  setAlreadyPublished,
  validatePublishAction,
  resetPublisher,
} from "./publisherSlice";
import { redirectToLogin } from ".././authentication/authenticationSlice";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

// import {getDRGContent} from "../contentUpload/contentUploadSlice";

function Publisher() {
  const { user } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const history = useHistory();
  const publisher = useSelector((state) => state.publisher);
  const {
    actionFlag,
    logicValue,
    yearValue,
    masterDrgData,
    masterGrouperData,
    masterInteractiveData,
    masterVisualData,
    fileNames,
    publishValue,
    fileNameValue,
    disablePublish,
    disableUnPublish,
    uniqueYears,
    publishSuccess,
    publishFailed,
    actionAlert,
    showTariff,
    isLoading,
    destEnvironment,
    noUploadContent,
    noDRGContent,
    noGrouperContent,
    noInteractiveContent,
    noViewerContent,
    alreadyPublished,
    selectedFile,
  } = publisher;
  const { t } = useTranslation("language");
  const [openDialog, setOpenDialog] = useState(false);
  const [publishOrUnpublish, setPublishOrUnpublish] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [finalData, setFinalData] = useState([]);
  const [finalUrl, setFinalUrl] = useState("");
  const [showTariffWarning, setShowTariffWarning] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showAlreadyPublished, setShowAlreadyPublished] = useState(false);
  //const [showTharif, setShowTharif] = useState(false);
  const siteUrl = localStorage.getItem("siteUrl");
  const languageState = useSelector((state) => state.language);
  const { language } = languageState;

  useEffect(() => {
    setShowAlreadyPublished(alreadyPublished?.isPublished == true);

    if (alreadyPublished?.isPublished == false) {
      PublishOrUnpublish("Publish");
    }
  }, [alreadyPublished]);

  const LoadTypeDetails = (e) => {
    e.preventDefault();
    dispatch(setLogicValue(e.target.value));
    dispatch(setActionFlag(false));
    if (e.target.value == "1") {
      let url =
        "api/GetDRGLogicListUserMap?userId=" +
        user[0].email +
        "&isAdmin=" +
        user[0].isAdmin;
      dispatch(getDrgLogics({ url: url, method: "GET" }));
    } else if (e.target.value == "2") {
      dispatch(getAllGrouperData({ method: "GET" }));
    } else if (e.target.value == "3") {
      dispatch(getAllInteractiveData({ method: "GET" }));
    } else if (e.target.value == "4") {
      dispatch(getAllVisualData({ method: "GET" }));
    }
  };
  const handleYearChange = (e) => {
    dispatch(setActionFlag(false));
    if (logicValue == "1") {
      dispatch(
        handleYearValueChange({
          logicValue: logicValue,
          masterData: masterDrgData,
          value: e.target.value,
        })
      );
    } else if (logicValue == "2") {
      dispatch(
        handleYearValueChange({
          logicValue: logicValue,
          masterData: masterGrouperData,
          value: e.target.value,
        })
      );
    } else if (logicValue == "3") {
      dispatch(
        handleYearValueChange({
          logicValue: logicValue,
          masterData: masterInteractiveData,
          value: e.target.value,
        })
      );
    } else if (logicValue == "4") {
      dispatch(
        handleYearValueChange({
          logicValue: logicValue,
          masterData: masterVisualData,
          value: e.target.value,
        })
      );
    }
  };
  const handleFileNameChange = (e) => {
    dispatch(setActionFlag(false));
    dispatch(
      handleFileNameUpdates({
        logicValue: logicValue,
        fileNames: fileNames,
        value: e.target.value,
      })
    );
  };

  const handleOk = () => {
    setOpenDialog(false);
    var eventValue = publishOrUnpublish;
    setPublishOrUnpublish("");
    if (logicValue == "1") {
      console.log("data for post:", finalData[0]);
      if (eventValue == "Publish") {
        let contentURL =
          "/api/GetDRGFrontPageContent?language=" +
          language +
          "&year=" +
          yearValue +
          "&destEnvironment=" +
          destEnvironment +
          "";
        dispatch(getDRGContent({ url: contentURL, method: "GET" }));
      }

      let url =
        "api/GetDRGLogicListUserMap?userId=" +
        user[0].email +
        "&isAdmin=" +
        user[0].isAdmin;
      dispatch(publishDRG({ method: "POST", body: finalData[0], apiUrl: url }));
    } else {
      console.log("url for post:", finalUrl);
      if (eventValue == "Publish") {
        if (logicValue == 2) {
          let contentURL =
            "api/GetGrouperFrontPageContent?language=" +
            language +
            "&year=" +
            yearValue +
            "&destEnvironment=" +
            destEnvironment +
            "";
          dispatch(getGrouperContent({ url: contentURL, method: "GET" }));
        } else if (logicValue == 3) {
          let contentURL =
            "api/GetInteractiveFrontPageContent?language=" +
            language +
            "&year=" +
            yearValue +
            "&destEnvironment=" +
            destEnvironment +
            "";
          dispatch(getInteractiveContent({ url: contentURL, method: "GET" }));
        } else {
          let contentURL =
            "api/GetVisualFrontPageContent?language=" +
            language +
            "&year=" +
            yearValue +
            "&destEnvironment=" +
            destEnvironment +
            "";
          dispatch(getVisualContent({ url: contentURL, method: "GET" }));
        }
      }
      dispatch(
        publishOtherCollection({
          url: finalUrl,
          method: "POST",
          logicType: logicValue,
        })
      );
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setPublishOrUnpublish("");
  };
  const validatePublish = (e) => {
    e.preventDefault();
    var eventValue = e.currentTarget.value;
    if (
      eventValue == "Publish" &&
      (publishValue == "1" || publishValue == "2")
    ) {
      let validateUrl =
        "api/ValidatePublishAction?publishValue=" +
        publishValue +
        "&year=" +
        yearValue +
        "&logicValue=" +
        logicValue;
      dispatch(validatePublishAction({ apiUrl: validateUrl, method: "GET" }));
    } else {
      PublishOrUnpublish(eventValue);
    }
  };
  const handlePublish = (e) => {
    e.preventDefault();
    var eventValue = e.currentTarget.value;
    PublishOrUnpublish(eventValue);
  };

  function PublishOrUnpublish(eventValue) {
    dispatch(setAlreadyPublished({}));
    var data = [];
    if (eventValue == "Publish" && publishValue == "") {
      dispatch(setActionAlert(true));
    } else if (
      publishValue == "1" &&
      logicValue != "1" &&
      logicValue != "" &&
      eventValue == "Publish" &&
      !showTariff
    ) {
      setShowTariffWarning(true);
    } else {
      var option = "0";
      if (eventValue == "Publish") {
        option = publishValue;
      } else {
        option = "0";
      }
      if (logicValue == "1") {
        var drgLogicData = masterDrgData.filter((a) => a.id == fileNameValue);
        var publish = false;
        var preProd = false;
        var test = false;
        if (eventValue == "Publish") {
          if (publishValue == "1") {
            publish = true;
          } else {
            publish = false;
          }
          if (publishValue == "2") {
            preProd = true;
          } else {
            preProd = false;
          }
          if (publishValue == "3") {
            test = true;
          } else {
            test = false;
          }
        } else if (eventValue == "UnPublish") {
          publish = false;
          preProd = false;
          test = false;
        }
        // var publishCheck = masterDrgData.filter(
        //   (a) => a.year == drgLogicData[0].year
        // );
        var published = [];

        published = drgLogicData.filter(
          (a) =>
            a.isPublished == true || a.isPreProd == true || a.IsTest == true
        );

        data.push({
          Id: drgLogicData[0].id,
          DrgLogicName: drgLogicData[0].name,
          DrgLogicVersion: drgLogicData[0].version,
          Year: drgLogicData[0].year,
          IsPublished: publish,
          IsPreProd: preProd,
          IsTest: test,
        });
        setFinalData(data);
        if (
          published.length > 0 &&
          (publishValue == "1" || publishValue == "2" || publishValue == "3") &&
          eventValue == "Publish"
        ) {
          var dialogMessage =
            t("publisher.headerMsg1") +
            published[0].name +
            " - " +
            published[0].version +
            t("publisher.headerMsg2") +
            published[0].year +
            t("publisher.headerMsg3");
          console.log("final:", finalData);

          setDialogContent(dialogMessage);
          setOpenDialog(true);
          setPublishOrUnpublish(eventValue);
        } else {
          console.log("data for post:", data[0]);
          if (eventValue == "Publish") {
            let contentURL =
              "/api/GetDRGFrontPageContent?language=" +
              language +
              "&year=" +
              yearValue +
              "&destEnvironment=" +
              destEnvironment +
              "";
            dispatch(getDRGContent({ url: contentURL, method: "GET" }));
          }

          let url =
            "api/GetDRGLogicListUserMap?userId=" +
            user[0].email +
            "&isAdmin=" +
            user[0].isAdmin;

          dispatch(publishDRG({ apiUrl: url, method: "POST", body: data[0] }));
        }

        // updateCollections(
        //   eventValue,
        //   masterDrgData,
        //   dialogMessage,
        //   option,
        //   "DRGUserMap"
        // );
      } else if (logicValue == "2") {
        var dataToValidate = masterGrouperData.filter(
          (a) => a.id == selectedFile.id
        );
        console.log("grouper data:", dataToValidate);
        var dialogMessage =
          "Grouper " +
          dataToValidate[0].grouperName +
          " - " +
          dataToValidate[0].grouperVersion +
          t("publisher.headerMsg2") +
          dataToValidate[0].year +
          t("publisher.headerMsg3");
        updateCollections(
          eventValue,
          masterGrouperData,
          dialogMessage,
          option,
          "DRGGrouper"
        );
      } else if (logicValue == "3") {
        var dataToValidate = masterInteractiveData.filter(
          (a) => a.id == selectedFile.id
        );
        var dialogMessage =
          "Interactive " +
          dataToValidate[0].interactiveName +
          " - " +
          dataToValidate[0].interactiveVersion +
          t("publisher.headerMsg2") +
          dataToValidate[0].year +
          t("publisher.headerMsg3");
        updateCollections(
          eventValue,
          masterInteractiveData,
          dialogMessage,
          option,
          "DRGInteractive"
        );
      } else if (logicValue == "4") {
        var dataToValidate = masterVisualData.filter(
          (a) => a.id == selectedFile.id
        );
        var dialogMessage =
          "Viewer " +
          dataToValidate[0].visualName +
          " - " +
          dataToValidate[0].visualVersion +
          t("publisher.headerMsg2") +
          dataToValidate[0].year +
          t("publisher.headerMsg3");
        updateCollections(
          eventValue,
          masterVisualData,
          dialogMessage,
          option,
          "DRGVisual"
        );
      }
    }
  }

  function updateCollections(
    eventValue,
    data,
    dialogMessage,
    option,
    collectionName
  ) {
    var dataToValidate = data.filter(
      (a) => a.year == yearValue && a.id == fileNameValue
    );
    var published = [];
    var showTarriffValue = showTariff;
    // if (publishValue == "1") {
    //   showTarriffValue = true;
    // }

    published = dataToValidate.filter((a) => a.status > 0);

    let url =
      "api/PublishUnpublishOtherCollection?ID=" +
      fileNameValue +
      "&Status=" +
      option +
      "&collectionName=" +
      collectionName +
      "&Year=" +
      yearValue +
      "&showTharif=" +
      showTarriffValue +
      "";
    setFinalUrl(url);
    if (
      published.length > 0 &&
      (publishValue == "1" || publishValue == "2" || publishValue == "3") &&
      eventValue == "Publish"
    ) {
      setDialogContent(dialogMessage);
      setOpenDialog(true);
      setPublishOrUnpublish(eventValue);
    } else {
      if (logicValue == 2 && eventValue == "Publish") {
        let contentURL =
          "api/GetGrouperFrontPageContent?language=" +
          language +
          "&year=" +
          yearValue +
          "&destEnvironment=" +
          destEnvironment +
          "";
        dispatch(getGrouperContent({ url: contentURL, method: "GET" }));
      } else if (logicValue == 3 && eventValue == "Publish") {
        let contentURL =
          "api/GetInteractiveFrontPageContent?language=" +
          language +
          "&year=" +
          yearValue +
          "&destEnvironment=" +
          destEnvironment +
          "";
        dispatch(getInteractiveContent({ url: contentURL, method: "GET" }));
      } else if (logicValue == 4 && eventValue == "Publish") {
        let contentURL =
          "api/GetVisualFrontPageContent?language=" +
          language +
          "&year=" +
          yearValue +
          "&destEnvironment=" +
          destEnvironment +
          "";
        dispatch(getVisualContent({ url: contentURL, method: "GET" }));
      }
      dispatch(
        publishOtherCollection({
          url: url,
          method: "POST",
          logicType: logicValue,
        })
      );
    }
  }
  useEffect(() => {
    let url =
      "api/GetDRGLogicListUserMap?userId=" +
      user[0].email +
      "&isAdmin=" +
      user[0].isAdmin;
    dispatch(getDrgLogics({ url: url, method: "GET" }));
    return () => {
      dispatch(resetPublisher());
    };
  }, []);

  function urlGenerator() {
    var url = "";
    if (logicValue == "1") {
      if (publishValue == "1") {
        url = `${window.location.origin}/DrgProd`;
      } else if (publishValue == "2") {
        url = `${window.location.origin}/DrgHearing`;
      } else if (publishValue == "3") {
        url = `${window.location.origin}/DrgTest?year=${yearValue}&id=${fileNameValue}`;
      }
    } else if (logicValue == "2") {
      if (publishValue == "1") {
        url = `${window.location.origin}/GrouperProd`;
      } else if (publishValue == "2") {
        url = `${window.location.origin}/GrouperHearing`;
      } else if (publishValue == "3") {
        url = `${window.location.origin}/GrouperTest?year=${yearValue}&id=${fileNameValue}`;
      }
    } else if (logicValue == "3") {
      if (publishValue == "1") {
        url = `${window.location.origin}/InteractiveProd`;
      } else if (publishValue == "2") {
        url = `${window.location.origin}/InteractiveHearing`;
      } else if (publishValue == "3") {
        url = `${window.location.origin}/InteractiveTest?id=${fileNameValue}`;
      }
    } else if (logicValue == "4") {
      if (publishValue == "1") {
        url = `${window.location.origin}/ViewerProd`;
      } else if (publishValue == "2") {
        url = `${window.location.origin}/ViewerHearing`;
      } else if (publishValue == "3") {
        url = `${window.location.origin}/ViewerTest?id=${fileNameValue}`;
      }
    }
    return url;
  }

  function actionText() {
    var actionText = "";
    var logicName = "";
    if (!actionFlag) {
      logicName = GetLogicType();
      if (publishValue == 1) {
        {
          actionText = `${t("publisher.actionMsg1")} ${logicName} ${t(
            "publisher.actionMsgProd"
          )}`;
        }
      } else if (publishValue == 2) {
        {
          actionText = `${t("publisher.actionMsg1")} ${logicName} ${t(
            "publisher.actionMsgHearing"
          )}`;
        }
      } else if (publishValue == 3) {
        {
          actionText = `${t("publisher.actionMsg1")} ${logicName} ${t(
            "publisher.actionMsgTest"
          )}`;
        }
      }
    }
    return actionText;
  }

  if (!publisher.isSessionValid) {
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  const handleSwitch = (key, attribute) => (e) => {
    dispatch(setShowTariff(e.target.checked));
  };

  const handleCopyClick = (url) => () => {
    navigator.clipboard.writeText(url);
    setOpenAlert(true);
  };

  const handleCloseWarning = () => {
    // reset();
    dispatch(setAlreadyPublished("not set"));
    //setShowAlreadyPublished(false);
  };

  function GetLogicType() {
    var logicName;
    switch (logicValue) {
      case "1":
        logicName = t("publisher.DRG_Logic");
        break;
      case "2":
        logicName = "Grouper";
        break;
      case "3":
        logicName = "Interactive";
        break;
      case "4":
        logicName = "Visual";
        break;
      default:
        break;
    }
    return logicName;
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  function contentPageLink() {
    return (
      <span>
        {noDRGContent && t("publisher.noDRGContent")}
        {noGrouperContent && t("publisher.noGrouperContent")}
        {noInteractiveContent && t("publisher.noInteractiveCotent")}
        {noViewerContent && t("publisher.noViewerContent")}
        <br />
        <a href="/content" target="_blank" rel="noopener noreferrer">
          {t("publisher.uploadContentLink")}
        </a>{" "}
        {t("publisher.uploadContentLinkHere")}
      </span>
    );
  }

  return (
    <div>
      {user[0] && user[0]?.token ? (
        <div className="cardContainer">
          <Card className="publisher-card">
            <div className="publisher-section-header">
              <Typography
                id="labelTitle"
                variant="h6"
                className="publisher-title"
                gutterBottom
              >
                {t("header.publisher")}
              </Typography>
            </div>

            <form>
              <div className="publisher-section-double">
                <div className="publisher-sub-section">
                  <TextField
                    label={t("publisher.Logic_Type")}
                    variant="outlined"
                    select
                    className="publisher-section"
                    value={logicValue}
                    onChange={LoadTypeDetails}
                    style={{ width: "180px" }}
                  >
                    <MenuItem key="1" value="1">
                      {t("publisher.DRG_Logic")}
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      {"Grouper"}
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      {t("interactive.Interactive")}
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      {"Viewer"}
                    </MenuItem>
                  </TextField>
                </div>
                <div style={{ padding: "10px" }}></div>
                <div className="publisher-sub-section">
                  <TextField
                    label={t("Manager.Year")}
                    variant="outlined"
                    select
                    className="publisher-section"
                    value={yearValue}
                    onChange={handleYearChange}
                    style={{ width: "180px" }}
                  >
                    {uniqueYears.map((x) => {
                      return (
                        <MenuItem key={x} value={x}>
                          {x}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="publisher-section">
                <TextField
                  label="File Name"
                  variant="outlined"
                  select
                  className="publisher-section"
                  value={fileNameValue}
                  onChange={handleFileNameChange}
                >
                  {fileNames.map((x) => {
                    return (
                      <MenuItem key={x.id} value={x.id}>
                        {`${x.name}_${x.version}`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
              <div className="publisher-section">
                <TextField
                  label={t("publisher.to")}
                  variant="outlined"
                  select
                  className="publisher-section"
                  value={publishValue}
                  onChange={(e) => {
                    dispatch(handleActionChange(e.target.value));
                  }}
                >
                  <MenuItem key="1" value="1">
                    {t("publisher.Production")}
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    {t("publisher.Hearing")}
                  </MenuItem>
                  <MenuItem key="3" value="3">
                    Test
                  </MenuItem>
                </TextField>
              </div>

              <div className="publisher-tharif-section">
                {logicValue != "1" && publishValue != "" && (
                  <FormControlLabel
                    label={t("Manager.show_Tarrifs")}
                    control={
                      <Switch checked={showTariff} onChange={handleSwitch()} />
                    }
                  />
                )}
              </div>
              {/* <div className="publisher-section"> */}
              <Divider />
              {disablePublish && (
                <div className="publisher-url-section">
                  <Typography className="publisher-text">URL</Typography>
                  <div className="link-template">
                    <Link
                      component="div"
                      className="url-text"
                      href={urlGenerator()}
                      target="_blank"
                      size="small"
                    >
                      {urlGenerator()}
                    </Link>
                    <div>
                      <ContentCopyIcon
                        sx={{ cursor: "pointer" }}
                        fontSize="medium"
                        onClick={handleCopyClick(urlGenerator())}
                      />
                    </div>
                  </div>
                </div>
              )}
              <Divider />
              <div className="publisher-text-section">
                <Typography
                  className="publisher-text"
                  // sx={{ backgroundColor: "#e7f3fe" }}
                >
                  {actionText()}
                </Typography>
              </div>

              <div className="publisher-button-section">
                <div className="publisher-button-container">
                  <Button
                    size="small"
                    className="publisher-button-secondary"
                    onClick={() => history.push("/")}
                  >
                    {t("registerUser.cancel")}
                  </Button>
                </div>
                <div className="publisher-button-container">
                  <Button
                    variant="contained"
                    size="small"
                    className="publisher-button-primary"
                    type="submit"
                    disabled={disableUnPublish}
                    value="UnPublish"
                    onClick={validatePublish}
                  >
                    {t("publisher.Unpublish")}
                  </Button>
                </div>
                <div className="publisher-button-container">
                  <Button
                    id="publishButton"
                    variant="contained"
                    size="small"
                    className="publisher-button-primary"
                    type="submit"
                    disabled={disablePublish}
                    onClick={validatePublish}
                    value="Publish"
                  >
                    {t("publisher.Publish")}
                  </Button>
                </div>
              </div>
            </form>
          </Card>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={actionAlert}
            autoHideDuration={5000}
            onClose={() => dispatch(handleClose())}
          >
            <Alert
              onClose={() => dispatch(handleClose())}
              severity="error"
              sx={{ width: "100%" }}
            >
              {t("publisher.actionAlert")}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showTariffWarning}
            autoHideDuration={10000}
            onClose={() => setShowTariffWarning(false)}
            sx={{ marginTop: "50px" }}
          >
            <Alert
              onClose={() => setShowTariffWarning(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {t("publisher.ShowTariff_Notification_Prod")}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={publishSuccess}
            autoHideDuration={5000}
            onClose={() => dispatch(handleClose())}
          >
            <Alert
              onClose={() => dispatch(handleClose())}
              severity="success"
              sx={{ width: "100%" }}
            >
              {t("publisher.successMessage")}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={publishFailed}
            autoHideDuration={5000}
            onClose={() => dispatch(handleClose())}
          >
            <Alert
              onClose={() => dispatch(handleClose())}
              severity="info"
              sx={{ width: "100%" }}
            >
              {t("publisher.pub_unpub_failed")}
            </Alert>
          </Snackbar>
          <Dialog
            open={openDialog}
            onClose={() => dispatch(handleClose())}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogContent}
              </DialogContentText>
              <Typography color="red" variant="body2">
                {t("publisher.replace_warning")}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>{t("Global.cancel")}</Button>
              <Button onClick={handleOk} autoFocus>
                {t("Manager.Ok")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showAlreadyPublished} onClose={handleCloseWarning}>
            <DialogContent>
              <Typography variant="body1">
                {GetLogicType() +
                  " " +
                  alreadyPublished.logicName +
                  "_" +
                  alreadyPublished.version +
                  " " +
                  t("publisher.published_warning").replace(
                    "yyyy",
                    alreadyPublished.year
                  )}
              </Typography>
              <Typography color="red" variant="body2">
                {t("publisher.replace_warning")}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: "12px 24px !important" }}>
              <Button onClick={handleCloseWarning}>
                {t("Manager.cancel")}
              </Button>
              <Button
                variant="contained"
                value="Publish"
                onClick={handlePublish}
                type="submit"
              >
                {t("Manager.Ok")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={noUploadContent}
        autoHideDuration={10000}
        onClose={() => dispatch(closeUploadContent())}
      >
        <Alert
          onClose={() => dispatch(closeUploadContent())}
          severity="info"
          sx={{ width: "50%" }}
        >
          {noDRGContent && contentPageLink()}
          {noGrouperContent && contentPageLink()}
          {noInteractiveContent && contentPageLink()}
          {noViewerContent && contentPageLink()}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          URL copied
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}

export default Publisher;
