import React, { useState, useRef } from "react";
import "./Interactive.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import PatientInformation from "./PatientInformation";
import { setVisualDialog, noVisualError, setExpand } from "./interactiveSlice";
import Currency from 'react-currency-formatter';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "5px 0",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function Results() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  const interactive = useSelector((state) => state.interactive);
  const { selectedInteractiveObj, requestList, resultList } = interactive;
  const languageState = useSelector((state) => state.language);
  const { language } = languageState;
  //const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    //setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const handleOpenInViewer = (e) => {
    if (resultList ?.length > 0) {
      let visualLink = resultList[0].val[0].visualLink;
      var tempArray = visualLink.split("&");
      let visualID = tempArray[0].replace("visualid=", "");
      let searchVal = tempArray[2].replace("search=", "");
      if (visualID != "" && visualID != null) {
        dispatch(
          setVisualDialog({ status: true, id: visualID, search: searchVal })
        );
      } else {
        dispatch(noVisualError());
      }
    }
  };

  return (
    <div>
      {/* {resultList?.length > 0 ? ( */}
      {resultList.map((row, index) => {
        return (
          <div>
            {row.val ?.map((a) => {
              return a.isCourse ? (
                <Paper className="result-container">
                  <Accordion expanded={row.expand} className="result-accordion">
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={
                        <ArrowRightIcon
                          fontSize="large"
                          onClick={() => dispatch(setExpand(index))}
                          sx={{ cursor: "pointer" }}
                        />
                      }
                      sx={{
                        padding: "0px !important",
                        cursor: "default !important",
                      }}
                      className="result-accordion"
                    >
                      <div className="left-accordion-summary">
                        <div className="interactive-accordion-text">
                          <Typography component="div" className="episode-content-bold">
                            {t("interactive.drgGroup")}:
                          </Typography>
                          <Typography component="div" className="episode-content">
                            {a.results.drg + " " + (a.results.drgDescription == null ? "": a.results.drgDescription)}
                          </Typography>
                        </div>
                        {row.expand != true && (
                          <>
                            <div className="interactive-accordion-text">
                              <Typography component="div" className="episode-content-bold">
                                {t("interactive.primaryDiagnosis")}:
                            </Typography>
                              <Typography component="div" className="episode-content">
                                {a.results.aDiagDescription}
                              </Typography>
                            </div>
                            <div className="result-patient-info">
                              <Typography
                                className="episode-content-bold"
                              >
                                {t("interactive.sex")}:
                          <Typography
                                  className="episode-content"
                                >
                                  {a.genderDescription}
                                </Typography>
                                <Typography
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.age")}:
                              </Typography>
                                <Typography
                                  className="episode-content"
                                >
                                  {a.age} {a.ageTypeText}
                                </Typography>
                                <Typography
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.rate")}:
                            </Typography>
                                <Typography
                                  className="episode-content"
                                >
                                  {a.results.standardRate == null ? "0.00" : (
                                    <Currency quantity={a.results.standardRate ?.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                                  )}
                                </Typography>
                                <Typography
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.longStayRate")}:
                              </Typography>
                                <Typography
                                  className="episode-content"
                                >
                                  {a.results.langliggertakst == null ? "0.00" : (
                                    <Currency quantity={a.results.langliggertakst ?.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                                  )}
                                </Typography>
                              </Typography>
                            </div>
                          </>
                        )}
                      </div>
                      {/* <div className="right-accordion-summary">
                        <div className="result-icon-container">
                          <Button sx={{ paddingRight: "20px", cursor: "pointer" }}>
                            <DeleteIcon fontSize="small" />
                            {t("interactive.delete")}
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={handleOpenInViewer}
                            value="OpenInViewer"
                          >
                            {t("interactive.openInViewer")}
                          </Button>
                        </div>
                      </div> */}
                    </AccordionSummary>
                    <AccordionDetails className="result-accordion">
                      <Grid container sx={{ padding: "" }}>
                        <Grid item xs={4}>
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="grid-header-text"
                          >
                            {t("interactive.spellResults")}
                          </Typography>
                          <Divider
                            sx={{
                              width: "360px",
                              ml: "10px",
                              borderTopWidth: "thin",
                            }}
                          />
                          <div className="grid-patientInfo">
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="episode-content-bold"
                            >
                              {t("interactive.primaryDiagnosis")}:&nbsp;
                              {a.aDiagDescription && (
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  A {a.aDiagDescription}
                                </Typography>
                              )}
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="episode-content-bold"
                            >
                              {t("interactive.drgGroup")}:&nbsp;
                            <Typography
                                gutterBottom
                                className="episode-content"
                              >
                                {a.results.drg}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="episode-content-bold"
                            >
                              {t("interactive.rate")}:&nbsp;
                            <Typography
                                gutterBottom
                                className="episode-content"
                              >
                                {a.results.standardRate == null ? "0.00" : (
                                  <Currency quantity={a.results.standardRate ?.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                                )}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="episode-content-bold"
                            >
                              {t("interactive.longStayRate")}:&nbsp;
                            <Typography
                                gutterBottom
                                className="episode-content"
                              >
                                {a.results.langliggertakst == null ? "0.00" : (
                                  <Currency quantity={a.results.langliggertakst ?.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                                )}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="episode-content-bold"
                            >
                              {t("interactive.groupingKey")}:&nbsp;
                            <Typography
                                gutterBottom
                                className="episode-content"
                              >
                                DRG {row.names.year}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="episode-content-bold"
                            >
                              {t("interactive.noOfEpisodes")}:&nbsp;
                            <Typography
                                gutterBottom
                                className="episode-content"
                              >
                                {row.val.length - 1}
                              </Typography>
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={8}>
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="grid-header-text"
                          >
                            {t("interactive.patientInformation")}
                          </Typography>
                          <Divider
                            sx={{
                              width: "725px",
                              ml: "10px",
                              borderTopWidth: "thin",
                            }}
                          />
                          <div className="result-patient-info">
                            <div className="grid-patientInfo">
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.sex")}:&nbsp;
                              <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a.genderDescription}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.age")}:&nbsp;
                              </Typography>
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a.age} {a.ageTypeText}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.gestationAge")}:&nbsp;
                              <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a.gestationAgeDescription}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.dischargeType")}:&nbsp;
                              <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a.dischargeTypeDescription}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.contactType")}:&nbsp;
                              <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a.contactTypeDescription}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.priority")}:&nbsp;
                              <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a.admissionTypeDescription}
                                </Typography>
                              </Typography>
                            </div>
                            <div className="grid-patientInfo">
                              {a.contactReasonDescription && (
                                <Typography
                                  gutterBottom
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.contactReason")}:&nbsp;
                              <Typography
                                    gutterBottom
                                    className="episode-content"
                                  >
                                    {a.contactReasonDescription}
                                  </Typography>
                                </Typography>
                              )}
                              {a.durationBedDays && (
                                <Typography
                                  gutterBottom
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.contactDays")}:&nbsp;
                              <Typography
                                    gutterBottom
                                    className="episode-content"
                                  >
                                    {a.durationBedDays}
                                  </Typography>
                                </Typography>
                              )}
                              {a.birthType && (
                                <Typography
                                  gutterBottom
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.birthType")}:&nbsp;
                              <Typography
                                    gutterBottom
                                    className="episode-content"
                                  >
                                    {a.birthType}
                                  </Typography>
                                </Typography>
                              )}
                              {a.birthHeight && (
                                <Typography
                                  gutterBottom
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.height")}:&nbsp;
                              <Typography
                                    gutterBottom
                                    className="episode-content"
                                  >
                                    {a.birthHeight}
                                  </Typography>
                                </Typography>
                              )}
                              {a.birthWeight && (
                                <Typography
                                  gutterBottom
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.weight")}:&nbsp;
                              <Typography
                                    gutterBottom
                                    className="episode-content"
                                  >
                                    {a.birthWeight}
                                  </Typography>
                                </Typography>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{
                          width: "100%",
                        }}
                      />
                      <div className="results">
                        <div className="spell-navigation">
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="spell-header-bold"
                          >
                            Episodes&nbsp;&nbsp;
                        </Typography>
                          <div>
                            {scrollX !== 0 && (
                              <KeyboardArrowLeftIcon
                                sx={{
                                  fill: "#959CA0",
                                  cursor: "pointer",
                                }}
                                className="prev"
                                onClick={() => slide(-50)}
                                onMouseEnter={(e) => anim(e)}
                                onMouseLeave={(e) => anim2(e)}
                              />
                            )}
                            {!scrolEnd && (
                              <KeyboardArrowRightIcon
                                sx={{
                                  fill: "#959CA0",
                                  cursor: "pointer",
                                }}
                                className="next"
                                onClick={() => slide(+50)}
                                onMouseEnter={(e) => anim(e)}
                                onMouseLeave={(e) => anim2(e)}
                              />
                            )}
                          </div>
                        </div>

                        <ul ref={scrl} onScroll={scrollCheck}>
                          {row.val ?.map((a, index) => {
                            return !a.isCourse ? (
                              <li>
                                <Paper elevation={0} className="result-paper">
                                  <div className="spell-header">
                                    <Typography
                                      gutterBottom
                                      sx={{ padding: "10px", width: "75%" }}
                                      className="spell-header-bold"
                                    >
                                      {t("interactive.Episode")}&nbsp;&nbsp; {a.rId}
                                    </Typography>
                                  </div>
                                  <div className="result-episode-placeholder-1">
                                    <div className="result-episode-content">
                                      <Typography
                                        gutterBottom
                                        sx={{ padding: "10px" }}
                                        className="episode-content-bold"
                                      >
                                        {t("interactive.drgGroup")}:&nbsp;
                                    </Typography>
                                      <Typography
                                        gutterBottom
                                        className="episode-content"
                                      >
                                        {a.results.drg}
                                      </Typography>
                                    </div>
                                    <div className="result-episode-content">
                                      <Typography
                                        gutterBottom
                                        sx={{ padding: "10px" }}
                                        className="episode-content-bold"
                                      >
                                        {t("interactive.rate")}:&nbsp;
                                    </Typography>
                                      <Typography
                                        gutterBottom
                                        className="episode-content"
                                      >
                                        {a.results.standardRate == null ? "0.00" : (
                                          <Currency quantity={a.results.standardRate ?.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                                        )}
                                      </Typography>
                                    </div>
                                    {/* <div className="result-episode-content">
                                    <Typography
                                      gutterBottom
                                      sx={{ padding: "10px" }}
                                      className="episode-content-bold"
                                    >
                                      {t("interactive.longStayRate")}:&nbsp;
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      className="episode-content"
                                    >
                                      {a.results.langliggertakst}
                                    </Typography>
                                  </div> */}
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ padding: "10px" }}
                                      className="grid-header-text"
                                    >
                                      {t("interactive.selectedDiagnosis")}
                                    </Typography>

                                    {a.diags ?.map((item, index) => {
                                      return (
                                        <div className="result-selected-diagnosis-item">
                                          {item.type != "T" ? (
                                            <Paper
                                              elevation={0}
                                              className="episode-selected-primary"
                                            >
                                              <Typography
                                                gutterBottom
                                                className="episode-diagnosis-content"
                                              >
                                                <span className="episode-diagnosis-bold">
                                                  {item.type}
                                                </span>
                                                &nbsp;&nbsp;
                                              {item.description}
                                              </Typography>
                                            </Paper>
                                          ) : null}
                                          {item.type == "T" ? (
                                            <Paper
                                              elevation={0}
                                              className="episode-selected-subcode"
                                            >
                                              <Typography
                                                gutterBottom
                                                className="episode-diagnosis-content"
                                              >
                                                <span className="episode-diagnosis-bold">
                                                  {item.type}
                                                </span>
                                                &nbsp;&nbsp;
                                              {item.description}
                                              </Typography>
                                            </Paper>
                                          ) : null}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ padding: "10px" }}
                                      className="grid-header-text"
                                    >
                                      {t("interactive.selectedProcedure")}
                                    </Typography>

                                    {a ?.procs ?.map((item, index) => {
                                      return (
                                        <div className="result-selected-diagnosis-item">
                                          {item.type != "T" ? (
                                            <Paper
                                              elevation={0}
                                              className="episode-selected-primary"
                                            >
                                              <Typography
                                                gutterBottom
                                                className="episode-diagnosis-content"
                                              >
                                                <span className="episode-diagnosis-bold">
                                                  {item.type}
                                                </span>
                                                &nbsp;&nbsp;
                                              {item.description}
                                              </Typography>
                                            </Paper>
                                          ) : null}
                                          {item.type == "T" ? (
                                            <Paper
                                              elevation={0}
                                              className="episode-selected-subcode"
                                            >
                                              <Typography
                                                gutterBottom
                                                className="episode-diagnosis-content"
                                              >
                                                <span className="episode-diagnosis-bold">
                                                  {item.type}
                                                </span>
                                                &nbsp;&nbsp;
                                              {item.description}
                                              </Typography>
                                            </Paper>
                                          ) : null}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="patient-information-link">
                                    <CustomTooltip
                                      arrow
                                      title={<PatientInformation a={a} />}
                                      placement="bottom"
                                      className="result-tooltip"
                                    >
                                      <Link
                                        component="button"
                                        variant="body2"
                                        onClick={handleClick}
                                        className="episode-diagnosis-content"
                                      >
                                        {t("interactive.Show_Patient_Information")}
                                      </Link>
                                    </CustomTooltip>
                                  </div>
                                  {/* <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                >
                                  <PatientInformation a={a} />
                                </Popover> */}
                                </Paper>
                              </li>
                            ) : null;
                          })}
                        </ul>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              ) : null;
            })}
          </div>
        );
      })}
      {/* ) : null} */}
    </div>
  );
}
