import "dotenv/config";
//import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./translations/i18n";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./themes/default";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

LicenseInfo.setLicenseKey(
  "28d31a3b61b93e70d9101ff0df9777afTz01NTAxNyxFPTE3MDEwMDU5NzQzOTMsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </I18nextProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  rootElement
);

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import { store } from './app/store';
// import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
