import React, { useState, useEffect } from "react";
import iqLogo from "../../assets/logos/logo-iqvia.svg";
import ftLogo from "../../assets/logos/logo-footer.svg";
import "./login.css";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import RegisterUser from "../registerUser/RegisterUser";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCountries, onLogin } from "./loginSlice";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {clearStatus} from "../authentication/authenticationSlice";

export function Login() {
  const { t, i18n } = useTranslation("language");
  const history = useHistory();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const { status, countries, loginStatus, redirectUrl, isLoggedIn } = login;
  const [showRegUser, setShowRegUser] = useState(false);
  const [languageVal, setLanguageVal] = useState("1");

  const {isFailed, isInactive, isUnauthorized } = useSelector((state)=>state.authentication);

  function handleCancel() {
    setShowRegUser(false);
  }

  useEffect(() => {
    //getLanguages();
    dispatch(getCountries({ method: "GET" }));
    let language = localStorage.getItem("language");
    if (language != undefined && language != null) {
      i18n.changeLanguage(language);
      if (language == "en") {
        setLanguageVal("1");
      } else if (language == "dk") {
        setLanguageVal("2");
      }
    } else {
      localStorage.setItem("language", "en");
      // setLanguageVal("1");
    }
  }, []);

  if (loginStatus == "" || loginStatus == "pending") {
    console.log("Loading...");
  }
  if (loginStatus == "success" && isLoggedIn) {
    console.log("Logged In", redirectUrl);
    window.location.href = redirectUrl;
  }
  function handleLanguageChange(val) {
    if (countries != null && countries.length > 0) {
      var language = countries.find((x) => x.id == val);
      setLanguageVal(val);
      i18n.changeLanguage(language.name);
      localStorage.setItem("language", language.name);
    }
  }

  const handleCloseSnackbar = () => {
    dispatch(clearStatus());
  };

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={0}
        sm={0}
        md={0}
        lg={6}
        xl={7}
        style={{ backgroundColor: "#F8F9FB" }}
      >
        <div className="loginImage">
          <div className="casemix360-text">
            <Typography variant="h3" color="white" gutterBottom>
              {t("login.application_name")}
            </Typography>
            <Typography
              sx={{ textAlign: "justify" }}
              variant="subtitle1"
              gutterBottom
              color="white"
            >
              {t("login.casemix_info")}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={5}
        style={{ backgroundColor: "#F8F9FB" }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-end",

            bgcolor: "F8F9FB",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "center",
              bgcolor: "#F8F9FB",
              width: "100%",
              height: "100%",
            }}
          >
            {showRegUser && (
              <RegisterUser
                IsAdmin={false}
                OnCancel={handleCancel}
              ></RegisterUser>
            )}

            {!showRegUser && (
              <Container maxWidth="sm">
                <Card interactive="true"  className="login-card">
                  <img className="logo-iqvia" src={iqLogo}></img>

                  <Typography
                    style={{
                      marginTop: 24,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    variant="h6"
                    gutterBottom
                  >
                    {t("login.welcome_label")}
                  </Typography>
                  <div className="login-button">
                    <Button
                      id="loginButton"
                      className="login-button"
                      onClick={() => {dispatch(onLogin({ method: "GET" })); localStorage.setItem("isUnauthorized", "true");}}
                      variant="contained"
                      fullWidth
                    >
                      {t("login.login_button")}
                    </Button>
                  </div>
                  <div className="req-access">
                    <Typography
                      sx={{ display: "unset", fontSize: 14 }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      {t("login.new_user")}
                    </Typography>
                    <span className="text-link">
                      <span
                        className="cstLink"
                        onClick={() => {
                          setShowRegUser(true);
                        }}
                        id="lnkReqAccess"
                      >
                        {t("login.req_access")}
                      </span>
                    </span>
                  </div>
                </Card>
              </Container>
            )}

            <div></div>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={2} md={2} lg={2} xl={2}>
              <div className="logo-container">
                <img className="logo-footer" src={ftLogo}></img>
              </div>
            </Grid>
            <Grid item xs={8} md={8} lg={8} xl={8}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  bgcolor: "#F8F9FB",
                }}
              >
                <ButtonGroup
                  variant="text"
                  aria-label="outlined primary button group"
                >                  
                  <Button
                    style={{
                      borderRight: "none",
                      fontSize: 12,
                      color: "#0768FD",
                    }}
                    onClick={() =>
                      window.open(
                        "https://www.customerservice.iqvia.com/support",
                        "_blank"
                      )
                    }
                  >
                    {t("footer.contact")}
                  </Button>
                </ButtonGroup>
                <Select
                  style={{
                    color: "#0768FD",
                    fontSize: 12,
                    fontWeight: 500,
                    width: 80,
                    paddingLeft: 10,
                  }}
                  variant="standard"
                  labelId="languageSelect-label"
                  id="languageSelect"
                  value={languageVal}
                  label="Age"
                  onChange={(event) => handleLanguageChange(event.target.value)}
                >
                  {countries ? (
                    countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>{country.language}</MenuItem>
                    ))
                  ) : (
                      <div></div>
                    )}
                </Select>
              </Box>
            </Grid>

            <Grid item xs={2} md={2} lg={2} xl={2}>
              <div className="copy-container">
                <span className="copy-right">© IQVIA</span>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isFailed}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: "100%" }}>
          {isInactive && t("login.msg_inactive")}
          {isUnauthorized && t("login.msg_unauthorized")}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
