import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import "./Interactive.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PdfIcon from "../../../assets/icons/PdfIcon.svg";
import PrintIcon from "@mui/icons-material/Print";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Results from "./Results";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { setReportDialog } from "./publicInteractiveSlice";
import PatientInformation from "./PatientInformation";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import Currency from 'react-currency-formatter';

export default function Report() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const interactive = useSelector((state) => state.publicInteractive);
  const { selectedInteractiveObj, requestList, resultList } = interactive;
  const languageState = useSelector((state) => state.language);
  const { language } = languageState;
  const [printOverview, setPrintOverview] = useState(false);
  let componentRef = useRef();

  // const saveAsPDF = () => {
  //   // initialize jsPDF
  //   /* const doc = new jsPDF();
  //   doc.html(ReactDOMServer.renderToStaticMarkup(<Report />), {
  //     callback: () => {
  //       doc.save("myDocument.pdf");
  //     },
  //   }); */
  //   const report = new jsPDF("portrait", "pt", "a3");
  //   report.setFontSize(8);
  //   report.html(document.querySelector("#report")).then(() => {
  //     report.save("report.pdf");
  //   });
  // };

  const handlePrint = async () => {
    await setPrintOverview(true);
    await triggerPrint();
    setPrintOverview(false);
  }

  const triggerPrint = useReactToPrint({
    documentTitle: "Report",
    content: () => componentRef.current,
    bodyClass: "printOverviewLayout",
  });

  // const handlePrint = () => {
  //   let popupWin;
  //   let printContentBody = document.getElementById("report").innerHTML;
  //   popupWin = window.open("", "_blank");
  //   popupWin.document.open();
  //   popupWin.document.write(`
  //         <html>
  //           <head>
  //             <title>Interactive Results - Report</title>
  //           </head>
  //           <body onload="window.print();window.close()"> 
  //             <div style="border: 1px solid black;padding-left:10px">
  //               ${printContentBody}
  //             </div>
  //           </body>
  //         </html>`);
  //   popupWin.document.close();
  // };
  const handleClose = () => {
    dispatch(setReportDialog(false));
  };

  return (
    <div ref={componentRef} className="interactive-report-container" id="report">
      <div className="report-header-section">
        <div className="modal-header-left">
          <Typography id="headerLabel" className="viewer-text" gutterBottom>
            {t("interactive.Report_Results")}
          </Typography>
        </div>
        {!printOverview && (
          <div className="modal-header-right">
            {/* <IconButton onClick={saveAsPDF}>
              {" "}
              <PictureAsPdfIcon sx={{ paddingRight: "2px" }} />
              <img src={PdfIcon} alt="PDF-Icon" sx={{ paddingRight: "2px" }} />
            </IconButton> */}

            <IconButton onClick={handlePrint}>
              {" "}
              <PrintIcon sx={{ paddingRight: "2px" }} />
            </IconButton>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        {/* {resultList?.length > 0 ? ( */}
        {resultList.map((row) => {
          return (
            <div className="report-wrapper">
              {row.val ?.map((a, index) => {
                // return a.rId > 0 ? (
                return a.isCourse ? (
                  <Paper className="result-container page-break">
                    <div className="interactive-accordion-text">
                      <Typography className="report-interactive-text">
                        {a.results.drgDescription}
                      </Typography>
                    </div>

                    <Grid container sx={{ padding: "", width: "90%" }}>
                      <Grid item xs={5}>
                        <Typography
                          gutterBottom
                          sx={{ padding: "10px" }}
                          className="grid-header-text"
                        >
                          {t("interactive.spellResults")}
                        </Typography>
                        <Divider
                          sx={{
                            width: "95%",
                            ml: "10px",
                            borderTopWidth: "thin",
                          }}
                        />
                        <div className="grid-patientInfo">
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="report-episode-content-bold"
                          >
                            {t("interactive.primaryDiagnosis")}:&nbsp;
                            {a.aDiagDescription && (
                              <Typography
                                gutterBottom
                                className="report-episode-content"
                              >
                                A {a.aDiagDescription}
                              </Typography>
                            )}
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="report-episode-content-bold"
                          >
                            {t("interactive.drgGroup")}:&nbsp;
                        <Typography
                              gutterBottom
                              className="report-episode-content"
                            >
                              {a.results.drg}
                            </Typography>
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="report-episode-content-bold"
                          >
                            {t("interactive.rate")}:&nbsp;
                        <Typography
                              gutterBottom
                              className="report-episode-content"
                            >
                              {a.results.standardRate == null ? "0.00" : (
                                <Currency quantity={a.results.standardRate.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                              )}
                            </Typography>
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="report-episode-content-bold"
                          >
                            {t("interactive.longStayRate")}:&nbsp;
                        <Typography
                              gutterBottom
                              className="report-episode-content"
                            >
                              {a.results.langliggertakst == null ? "0.00" : (
                                <Currency quantity={a.results.langliggertakst.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                              )}
                            </Typography>
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="report-episode-content-bold"
                          >
                            {t("interactive.groupingKey")}:&nbsp;
                        <Typography
                              gutterBottom
                              className="report-episode-content"
                            >
                              DRG {row.names.year}
                            </Typography>
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="report-episode-content-bold"
                          >
                            {t("interactive.noOfEpisodes")}:&nbsp;
                        <Typography
                              gutterBottom
                              className="report-episode-content"
                            >
                              {row.val.length - 1}
                            </Typography>
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={7}>
                        <Typography
                          gutterBottom
                          sx={{ padding: "10px" }}
                          className="grid-header-text"
                        >
                          {t("interactive.patientInformation")}
                        </Typography>
                        <Divider
                          sx={{
                            width: "95%",
                            ml: "10px",
                            borderTopWidth: "thin",
                          }}
                        />
                        <div className="result-patient-info">
                          <div className="grid-patientInfo">
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="report-episode-content-bold"
                            >
                              {t("interactive.sex")}:&nbsp;
                          <Typography
                                gutterBottom
                                className="report-episode-content"
                              >
                                {a.genderDescription}
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="report-episode-content-bold"
                              >
                                {t("interactive.age")}:&nbsp;
                          </Typography>
                              <Typography
                                gutterBottom
                                className="report-episode-content"
                              >
                                {a.age} {a.ageTypeText}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="report-episode-content-bold"
                            >
                              {t("interactive.gestationAge")}:&nbsp;
                          <Typography
                                gutterBottom
                                className="report-episode-content"
                              >
                                {a.gestationAgeDescription}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="report-episode-content-bold"
                            >
                              {t("interactive.dischargeType")}:&nbsp;
                          <Typography
                                gutterBottom
                                className="report-episode-content"
                              >
                                {a.dischargeTypeDescription}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="report-episode-content-bold"
                            >
                              {t("interactive.contactType")}:&nbsp;
                          <Typography
                                gutterBottom
                                className="report-episode-content"
                              >
                                {a.contactTypeDescription}
                              </Typography>
                            </Typography>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="report-episode-content-bold"
                            >
                              {t("interactive.priority")}:&nbsp;
                          <Typography
                                gutterBottom
                                className="report-episode-content"
                              >
                                {a.admissionTypeDescription}
                              </Typography>
                            </Typography>
                          </div>
                          <div className="grid-patientInfo">
                            {a.contactReasonDescription && (
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="report-episode-content-bold"
                              >
                                {t("interactive.contactReason")}:&nbsp;
                          <Typography
                                  gutterBottom
                                  className="report-episode-content"
                                >
                                  {a.contactReasonDescription}
                                </Typography>
                              </Typography>
                            )}
                            {a.durationBedDays && (
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="report-episode-content-bold"
                              >
                                {t("interactive.contactDays")}:&nbsp;
                          <Typography
                                  gutterBottom
                                  className="report-episode-content"
                                >
                                  {a.durationBedDays}
                                </Typography>
                              </Typography>
                            )}
                            {a.birthType && (
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="report-episode-content-bold"
                              >
                                {t("interactive.birthType")}:&nbsp;
                          <Typography
                                  gutterBottom
                                  className="report-episode-content"
                                >
                                  {a.birthType}
                                </Typography>
                              </Typography>
                            )}
                            {a.birthHeight && (
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="report-episode-content-bold"
                              >
                                {t("interactive.height")}:&nbsp;
                          <Typography
                                  gutterBottom
                                  className="report-episode-content"
                                >
                                  {a.birthHeight}
                                </Typography>
                              </Typography>
                            )}
                            {a.birthWeight && (
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="report-episode-content-bold"
                              >
                                {t("interactive.weight")}:&nbsp;
                          <Typography
                                  gutterBottom
                                  className="report-episode-content"
                                >
                                  {a.birthWeight}
                                </Typography>
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        width: "100%",
                      }}
                    />
                    <div className="report-list">
                      <ul>
                        {row.val ?.map((a, index) => {
                          // return a.rId > 0 ? (
                          return !a.isCourse ? (
                            <li>
                              <Paper
                                elevation={0}
                                className="result-report-paper"
                              >
                                <div className="spell-header">
                                  <Typography
                                    gutterBottom
                                    sx={{ padding: "10px", width: "75%" }}
                                    className="spell-header-bold"
                                  >
                                    {t("interactive.Episode")}&nbsp;&nbsp; {a.rId}
                                  </Typography>
                                </div>
                                <div className="result-episode-placeholder-1">
                                  <div className="result-episode-content">
                                    <Typography
                                      gutterBottom
                                      sx={{ padding: "10px" }}
                                      className="report-episode-content-bold"
                                    >
                                      {t("interactive.drgGroup")}:&nbsp;
                                </Typography>
                                    <Typography
                                      gutterBottom
                                      className="report-episode-content"
                                    >
                                      {a.results.drg}
                                    </Typography>
                                  </div>
                                  <div className="result-episode-content">
                                    <Typography
                                      gutterBottom
                                      sx={{ padding: "10px" }}
                                      className="report-episode-content-bold"
                                    >
                                      {t("interactive.rate")}:&nbsp;
                                </Typography>
                                    <Typography
                                      gutterBottom
                                      className="report-episode-content"
                                    >
                                      {a.results.standardRate == null ? "0.00" : (
                                        <Currency quantity={a.results.standardRate.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />
                                      )}
                                    </Typography>
                                  </div>
                                  {/* <div className="result-episode-content">
                                    <Typography
                                      gutterBottom
                                      sx={{ padding: "10px" }}
                                      className="report-episode-content-bold"
                                    >
                                      {t("interactive.longStayRate")}:&nbsp;
                                </Typography>
                                    <Typography
                                      gutterBottom
                                      className="report-episode-content"
                                    >
                                      {a.results.langliggertakst}
                                    </Typography>
                                  </div> */}
                                </div>
                                <div>
                                  <Typography
                                    gutterBottom
                                    sx={{ padding: "10px" }}
                                    className="grid-header-text"
                                  >
                                    {t("interactive.selectedDiagnosis")}
                                  </Typography>

                                  {a.diags ?.map((item, index) => {
                                    return (
                                      <div className="result-selected-diagnosis-item">
                                        {item.type != "T" ? (
                                          <Paper
                                            elevation={0}
                                            className="episode-selected-primary"
                                          >
                                            <Typography
                                              gutterBottom
                                              className="report-episode-diagnosis-content"
                                            >
                                              <span className="report-episode-diagnosis-bold">
                                                {item.type}
                                              </span>
                                              &nbsp;&nbsp;
                                          {item.description}
                                            </Typography>
                                          </Paper>
                                        ) : null}
                                        {item.type == "T" ? (
                                          <Paper
                                            elevation={0}
                                            className="episode-selected-subcode"
                                          >
                                            <Typography
                                              gutterBottom
                                              className="report-episode-diagnosis-content"
                                            >
                                              <span className="report-episode-diagnosis-bold">
                                                {item.type}
                                              </span>
                                              &nbsp;&nbsp;
                                          {item.description}
                                            </Typography>
                                          </Paper>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div>
                                  <Typography
                                    gutterBottom
                                    sx={{ padding: "10px" }}
                                    className="grid-header-text"
                                  >
                                    {t("interactive.selectedProcedure")}
                                  </Typography>

                                  {a ?.procs ?.map((item, index) => {
                                    return (
                                      <div className="result-selected-diagnosis-item">
                                        {item.type != "T" ? (
                                          <Paper
                                            elevation={0}
                                            className="episode-selected-primary"
                                          >
                                            <Typography
                                              gutterBottom
                                              className="report-episode-diagnosis-content"
                                            >
                                              <span className="report-episode-diagnosis-bold">
                                                {item.type}
                                              </span>
                                              &nbsp;&nbsp;
                                          {item.description}
                                            </Typography>
                                          </Paper>
                                        ) : null}
                                        {item.type == "T" ? (
                                          <Paper
                                            elevation={0}
                                            className="episode-selected-subcode"
                                          >
                                            <Typography
                                              gutterBottom
                                              className="report-episode-diagnosis-content"
                                            >
                                              <span className="report-episode-diagnosis-bold">
                                                {item.type}
                                              </span>
                                              &nbsp;&nbsp;
                                          {item.description}
                                            </Typography>
                                          </Paper>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                                <PatientInformation a={a} />
                              </Paper>
                            </li>
                          ) : null;
                        })}
                      </ul>
                    </div>
                  </Paper>
                ) : null;
              })}
            </div>
            // ) : null}
          );
        })}
      </div>
    </div>
  );
}
