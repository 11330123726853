import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getVisualDatabyID,
  getVisualMetadata,
  getVisualNodesJSON,
  setVisualDialog,
} from "./publicInteractiveSlice";
import { DiagramBase } from "../../viewer/DiagramBase";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export default function VisualDiagram(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const interactive = useSelector((state) => state.publicInteractive);
  const { isVisualLoading, visualID, visualSearchValue, dataForGoJs } =
    interactive;

  useEffect(() => {
    dispatch(getVisualDatabyID({ method: "GET", id: visualID })).then((res) => {
      var data = JSON.parse(res.payload);

      dispatch(
        getVisualMetadata({
          method: "GET",
          name: data.VisualName,
          version: data.VisualVersion,
          year: data.Year,
        })
      ).then((r) => {
        dispatch(
          getVisualNodesJSON({
            method: "GET",
            logicName: r.payload.DRGLogicName,
            logicVersion: r.payload.DRGLogicVersion,
            year: r.payload.DRGLogicYear,
            priceName: r.payload.DRGPriceName,
            priceVersion: r.payload.DRGPriceVersion,
          })
        );
      });
    });
  }, []);

  useEffect(() => {
    //console.log("Current Value of isVisualLoading:", isVisualLoading);
  }, [isVisualLoading]);

  const handleClose = () => {
    dispatch(setVisualDialog({ status: false, id: "", search: "" }));
  };

  return (
    <div>
      {isVisualLoading ? (
        <div className="drg-diagram-container">
          <div className="viewermap-header">
            <div className="viewermap-header-left">
              <Typography id="headerLabel" className="viewer-text" gutterBottom>
                {t("interactive.fullLogic")}
              </Typography>
            </div>
            <div className="viewermap-header-right">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <Divider sx={{ paddingTop: "10px" }} />
          <DiagramBase
            dataForGoJs={dataForGoJs}
            searchValue={visualSearchValue}
            regelValue={visualSearchValue}
          />
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
