export const FileNames = (
    [
    "till.csv",
    "dg1.csv",
    "drglogik.csv",
    "drgtpt.csv",
    "CountingCode.yaml",
    "interne koder.yaml",
    "casemixDRG.csv",
    "egenskaber.csv",
    "MDCSpecification.yaml",
    "sks.csv",
]
);

export const FileNamesUpload = (
    [
        "till.csv",
        "dg1.csv",
        "drglogik.csv",
        "drgtpt.csv",
        "CountingCode.yaml",
        "interne koder.yaml",
        "casemixDRG.csv"
    ]  
);

export const SupportFilesUpload = (
    [
        "egenskaber.csv",
        "MDCSpecification.yaml",
    ]  
);