import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FolderIcon from "@mui/icons-material/Folder";
import {
  setSelectedEditor,
} from "./editorSlice";
import { useSelector, useDispatch } from "react-redux";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: "var(--tree-view-bg-color)",
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function InteractiveSelectionTree() {
  const dispatch = useDispatch();
  const editor = useSelector((state) => state.editor);
  const {
    editorCollection,
    yearsCollection,
    selectedEditorID,
    selectedEditorName,
    selectedEditorYear,
  } = editor;

  const [selectedValue, setSelectedValue] = useState(selectedEditorID);

  const handleSelection = (obj) => () => {
    setSelectedValue(obj.id);
    dispatch(setSelectedEditor(obj));
  };

  return (
    <TreeView
      aria-label="VisualTree"
      defaultExpanded={[selectedEditorYear]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: "100%", flexGrow: 1, maxWidth: 600, width: 360 }}
    >
      {yearsCollection.map((year) => {
        return (
          <StyledTreeItem
            nodeId={year}
            labelText={year}
            labelIcon={FolderIcon}
            labelInfo={
              editorCollection ?.filter((a) => a.year == year).length
            }
            color="#1a73e8"
          >
            {editorCollection
              .filter((a) => a.year == year)
              .map((x) => {
                let labelDate = x.createdDate != null ? x.createdDate : "";
                return (
                  <StyledTreeItem
                    key={x.id}
                    nodeId={x.id}
                    labelText={x.name + " - " + x.version}
                    labelInfo={labelDate}
                    color="#1a73e8"
                    bgColor={x.id == selectedValue ? "#e8f0fe" : "#fff"}
                    onClick={handleSelection(x)}
                  />
                );
              })}
          </StyledTreeItem>
        );
      })}
    </TreeView>
  );
}
