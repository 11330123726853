import React, { useState, useEffect } from "react";
import "./IdleTimeOut.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { onLogout } from "../authentication/authenticationSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import IdleJs from "idle-js";

export default function IdleTimeOut() {
  const { t } = useTranslation("language");
  const dispatch = useDispatch();
  const [logoutTimerValue, setLogoutTimerValue] = useState(3600000);
  const [open, setOpen] = useState(false);
  const oneHour = 1000 * 60 * 60;
  const tenMinutes = 1000 * 60 * 10;
  const thirtySeconds = 1000 * 30;

  const handleOnIdle = () => {
    setOpen(true);
    setLogoutTimerValue(thirtySeconds); // Set 30 second logout timer
  };

  const handleStayLoggedIn = () => {
    if (logoutTimerValue) {
      setLogoutTimerValue(oneHour);
    }
    idleInstance.reset(); // Reset idle timer
    setOpen(false);
  };

  // Initialize idle-js instance
  const idleInstance = new IdleJs({
    idle: tenMinutes, // allowed idle time is 10 minutes
    onIdle: handleOnIdle,
    keepTracking: true,
    startAtIdle: false,
  });

  useEffect(() => {
    idleInstance.start(); // Start the idle tracker
    return () => {
      idleInstance.stop(); // Cleanup on component unmount
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLogoutTimerValue((prevValue) => Math.max(0, prevValue - 1000)); // Decrease timer by 1 second
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // If the logoutTimerValue reaches 0, the user will get logged out due to inactivity
  useEffect(() => {
    if (logoutTimerValue === 0) {
      dispatch(onLogout({ method: "GET" })); // Trigger logout when timer runs out
    }
  }, [logoutTimerValue]);

  return (
    <div className="IdleTimeOut">
      <Dialog open={open}>
        <DialogTitle>
          {t("Global.Session", {
            seconds: `${Math.round(logoutTimerValue / 1000)}`,
          })}
        </DialogTitle>
        <DialogContent>{t("Global.Timeout_Session")}</DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button
            variant="contained"
            onClick={handleStayLoggedIn}
            type="submit"
          >
            {t("Global.Stay_Logged_In")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
