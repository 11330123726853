import React from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import { clearStatus } from "./editorSlice";

function SharedComponent() {
  const { t } = useTranslation("language");
  const dispatch = useDispatch();
  const editor = useSelector((state) => state.editor);
  const {
    isSuccess,
    isFailed,
    isError,
    isServerError,
    isRowLocked,
    isRowUnlocked,
    isRowUnlockFailed,
    isPasted,
    isDuplicated,
    isDeleted,
    isSaveSuccess,
    isSaveFailed,
    isLogicLocked,
    isLogicUnlocked,
    isCountingCodeUpdated,
    isCountingCodeFileError,
    countingCodeUpdatedFailed,
    isRowLockedByStatus,
    isRowLockedBy,
    groupCodeExists,
    groupCodeEditWarning,
    subCodeExists,
    groupCodesUpdated,
    groupCodesWarningMessage,
    groupCodesUpdateWarning,
    saveGroupCodeError,
    isInfoBulkActions,
    existingSubCodes
  } = editor;

  const handleCloseSnackbar = () => {
    dispatch(clearStatus());
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSuccess}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {isSaveSuccess && t("Editor.SaveLogic_Success")}
          {isRowLocked && t("Editor.isRowLocked")}
          {isRowUnlocked && t("Editor.isRowUnLocked")}
          {isPasted && t("Editor.isPasted")}
          {isDuplicated && t("Editor.isDuplicated")}
          {isDeleted && t("Editor.isDeleted")}
          {isLogicLocked && t("Editor.isLogicLocked")}
          {isLogicUnlocked && t("Editor.isLogicUnlocked")}
          {isCountingCodeUpdated && t("ClassificationCode.countindCodeUpdated")}
          {groupCodesUpdated && t("ClassificationCode.groupCodesUpdated")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isFailed}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{ width: "100%" }}
        >
          {isSaveFailed && t("Editor.SaveLogic_Failed")}
          {isRowUnlockFailed && t("Editor.isRowUnLockFailed")}
          {isCountingCodeFileError &&
            t("ClassificationCode.countingCodeFileError")}
          {countingCodeUpdatedFailed &&
            t("ClassificationCode.countingCodeUpdateFailed")}
          {groupCodeExists && t("ClassificationCode.groupCodeExists")}
          {groupCodeEditWarning && t("ClassificationCode.groupCodeEditWarning")}
          {subCodeExists && (t("ClassificationCode.subCodeExist") + ' (' + existingSubCodes + ')')}
          {groupCodesUpdateWarning && groupCodesWarningMessage}
          {isInfoBulkActions && t("Editor.isInfoBulkActions")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isError}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {isServerError && t("Global.server_error")}
          {isRowLockedByStatus &&
            t("Editor.isRowLockedBy") + " " + isRowLockedBy}
          {saveGroupCodeError && t("ClassificationCode.groupCodesError")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SharedComponent;
