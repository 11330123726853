import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import "./Editor.css";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from "@mui/material/Typography";
import FormHelperText from '@mui/material/FormHelperText';
import Divider from "@mui/material/Divider";
import CancelIcon from '@mui/icons-material/Cancel';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useForm, Controller } from "react-hook-form";
import { List } from "react-virtualized";

import {
    clearStatus,
    setMasterSelectedEditorData,
    setSelectedGroupCode,
    GetGroupCodes,
    GetGroupCodesSubList,
    setDrgLogicComments,
    setGroupCodesFilter,
    setDiagonisFilter,
    setOpenCellEditContains,
    setSelectedEditorData,
} from "./editorSlice";

export default function CellEditContains(props) {
    const { t } = useTranslation("language");
    const dispatch = useDispatch();
    const editor = useSelector((state) => state.editor);
    const {
        selectedEditorID,
        selectedCellDetails,
        selectedRows,
        masterGroupCodesList,
        groupCodesList,
        masterGroupCodesSubList,
        groupCodesSubList,
        openCellEditContains,
        masterSelectedEditorData,
        selectedEditorData,
        selectedGroupCode,
        DrgLogicComments,
        isLoading,
        SKSList,
    } = editor;
    const apiRef = props.apiRef;
    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const ruleId = selectedCellDetails.id
    let fieldName = selectedCellDetails.field.toUpperCase();
    let fieldValue = selectedCellDetails.value;
    const [value, setValue] = useState("allow");
    const [remove, setRemove] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchMsg, setSearchMsg] = useState("");

    useEffect(() => {
        if (fieldValue != " " && fieldValue != null) {
            if (fieldValue.includes("-")) {
                setValue("disAllow");
                dispatch(setSelectedGroupCode(fieldValue));
                fieldValue = fieldValue.slice(1, fieldValue.length);
            }
            else {
                dispatch(setSelectedGroupCode(fieldValue));
            }
        }
        if (fieldName != "") {
            if (fieldName.includes("PROCPRO") || fieldName.includes("SECPROC")) {
                fieldName = "PROCPROP";
            }
            if (fieldName.includes("DGPROP") && fieldName != "PDGPROP") {
                fieldName = "DGPROP";
            }
        }
        let url = "api/GetGroupCodes?groupCode=" + fieldName + "&drgLogicName=" + selectedEditorID;
        console.log(url);
        dispatch(GetGroupCodes({ method: "GET", url: url }));

        if (fieldValue != "" && fieldValue != null) {
            let url = "api/GetGroupCodesSubList?groupCode=" + fieldName +
                "&groupCodeSubList=" + fieldValue + "&drgLogicName=" + selectedEditorID;
            dispatch(GetGroupCodesSubList({ method: "GET", url: url }));
        }
        reset({ sel_groupCode: fieldValue });
    }, []);

    const handleClose = () => {
        dispatch(clearStatus());
    }

    const handleAllowDisAllowChange = (event) => {
        if (event.target.value == "allow") {
            setValue('allow');
            if (selectedGroupCode.includes("-")) {
                dispatch(setSelectedGroupCode(selectedGroupCode.slice(1, selectedGroupCode.length)));
            }
        }
        else if (event.target.value == "disAllow") {
            setValue("disAllow");
            if (selectedGroupCode != null) {
                dispatch(setSelectedGroupCode("-" + selectedGroupCode));
            }
            else if (selectedGroupCode == null) {
                dispatch(setSelectedGroupCode());
            }
        }
    }

    const handleGroupCodeChange = (event) => {
        reset({ sel_groupCode: event.target.value });
        if (value == "allow") {
            dispatch(setSelectedGroupCode(event.target.value));
        }
        else if (value == "disAllow") {
            dispatch(setSelectedGroupCode("-" + event.target.value));
        }
        if (event.target.value != "" && event.target.value != null) {
            if (fieldName != "") {
                if (fieldName.includes("PROCPRO") || fieldName.includes("SECPROC")) {
                    fieldName = "PROCPROP";
                }
                if (fieldName.includes("DGPROP") && fieldName != "PDGPROP") {
                    fieldName = "DGPROP";
                }
            }
            let url = "api/GetGroupCodesSubList?groupCode=" + fieldName +
                "&groupCodeSubList=" + event.target.value + "&drgLogicName=" + selectedEditorID;
            dispatch(GetGroupCodesSubList({ method: "GET", url: url }));
        }
    }

    const handleGroupCodesSearch = (event) => {
        const result = masterGroupCodesList.filter((item) => {
            return (
                item ?.toLowerCase().includes(event.target.value ?.toLowerCase())
                );
        });
        if (result.length == 0) {
            setSearchMsg("Searched value does not match with any of the field values");
        }
        dispatch(setGroupCodesFilter(result));
    }

    const handleDiagnosisSearch = (event) => {
        const result = masterGroupCodesSubList.filter((item) => {
            return (
                item ?.name.includes(event.target.value) ||
                    item ?.Description.toLowerCase().includes(event.target.value ?.toLowerCase())
                );
        });
        if (result.length == 0) {
            setSearchMsg("Searched value does not match with any of the field values");
        }
        dispatch(setDiagonisFilter(result));
    }

    const onSubmit = async (values) => {
        const cellName = selectedCellDetails.field;
        const cellValue = selectedGroupCode;
        const comment = values.comment_msg;
        let commentArray = [];

        if (selectedRows.length > 0) {
            for (let i = 0; i < selectedRows.length; i++) {
                apiRef.current.startCellEditMode({ id: selectedRows[i], field: cellName });
                const isValid = await apiRef.current.setEditCellValue({
                    id: selectedRows[i],
                    field: cellName,
                    value: cellValue,
                    debounceMs: 1
                });
                if (isValid) {
                    apiRef.current.stopCellEditMode({ id: selectedRows[i], field: cellName });
                }
                var commentData = {
                    RuleNo: selectedRows[i],
                    FieldName: fieldName,
                    Comments: comment,
                    debounceMs: 1
                }
                commentArray.push(commentData);
            }
            dispatch(setDrgLogicComments(commentArray));
        }
        else {
            apiRef.current.startCellEditMode({ id: ruleId, field: cellName });
            const isValid = await apiRef.current.setEditCellValue({
                id: ruleId,
                field: cellName,
                value: cellValue,
                debounceMs: 1
            });
            if (isValid) {
                apiRef.current.stopCellEditMode({ id: ruleId, field: cellName });
            }
            var commentData = {
                RuleNo: ruleId,
                FieldName: fieldName,
                Comments: comment,
                debounceMs: 1
            }
            dispatch(setDrgLogicComments(commentData));
        }
        const rows = apiRef.current.getRowModels();
        let updatedRows = [];
        for (let value of rows.values()) {
            updatedRows.push(value);
        }
        let updatedArray = [];
        updatedArray = masterSelectedEditorData.map(obj => updatedRows.find(o => o.ruleNo === obj.ruleNo) || obj);
        dispatch(setSelectedEditorData(updatedRows));
        dispatch(setMasterSelectedEditorData(updatedArray));
        dispatch(setOpenCellEditContains(false));
        dispatch(clearStatus());
    }

    const onRemove = async (values) => {
        const cellName = selectedCellDetails.field;
        const cellValue = null;
        const comment = values.comment_msg;
        let commentArray = [];

        if (selectedRows.length > 0) {
            for (let i = 0; i < selectedRows.length; i++) {
                apiRef.current.startCellEditMode({ id: selectedRows[i], field: cellName });
                const isValid = await apiRef.current.setEditCellValue({
                    id: selectedRows[i],
                    field: cellName,
                    value: cellValue,
                    debounceMs: 1
                });
                if (isValid) {
                    apiRef.current.stopCellEditMode({ id: selectedRows[i], field: cellName });
                }
                var commentData = {
                    RuleNo: selectedRows[i],
                    FieldName: fieldName,
                    Comments: comment,
                }
                commentArray.push(commentData);
            }
            dispatch(setDrgLogicComments(commentData));
        }
        else {
            apiRef.current.startCellEditMode({ id: ruleId, field: cellName });
            const isValid = await apiRef.current.setEditCellValue({
                id: ruleId,
                field: cellName,
                value: cellValue,
                debounceMs: 1
            });
            if (isValid) {
                apiRef.current.stopCellEditMode({ id: ruleId, field: cellName });
            }
            var commentData = {
                RuleNo: ruleId,
                FieldName: fieldName,
                Comments: comment,
            }
            dispatch(setDrgLogicComments(commentData));
        }
        const rows = apiRef.current.getRowModels();
        let updatedRows = [];
        for (let value of rows.values()) {
            updatedRows.push(value);
        }
        let updatedArray = [];
        updatedArray = masterSelectedEditorData.map(obj => updatedRows.find(o => o.ruleNo === obj.ruleNo) || obj);
        dispatch(setSelectedEditorData(updatedRows));
        dispatch(setMasterSelectedEditorData(updatedArray));
        dispatch(setSelectedGroupCode());
        dispatch(setOpenCellEditContains(false));
        dispatch(clearStatus());
    }

    const renderRow = ({ index, key, style }) => {
        let sksData = SKSList.length > 0 ? SKSList.find(x => x.code == groupCodesSubList[index].name) : null;
        return (<div key={key} style={style}>
            {groupCodesSubList[index].isDisabled ? (
                <ListItem>
                    <ListItemText
                        primary={
                            (sksData != null && sksData != undefined) ?
                                (groupCodesSubList[index].name + " " + sksData.text) :
                                groupCodesSubList[index].name}
                    />
                </ListItem>
            ) : null}
        </div>
        )
    }

    return (
        <div>
            <Dialog open={openCellEditContains} onClose={handleClose} maxWidth='md' fullWidth >
                <DialogTitle>
                    {selectedRows.length > 1 && (<>
                        Edit {fieldName} for selected rows</>
                    )}
                    {selectedRows.length <= 1 && (<>
                        {t("Global.edit")} {fieldName} for {t("Editor.rule_no")}: {ruleId}</>
                    )}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={remove ? handleSubmit(onRemove) : handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div className="customDiv" >
                            {selectedGroupCode != "" && (
                                <Typography variant="h6" component="div" className="typographySubHeading">{fieldName} : {selectedGroupCode} </Typography>
                            )}
                            {selectedGroupCode == "" && (
                                <Typography variant="h6" component="div" className="typographySubHeading">{fieldName} : {t("Manager.Add")} {fieldName} </Typography>
                            )}
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={handleAllowDisAllowChange}
                                    value={value}
                                >
                                    <FormControlLabel value="allow" control={<Radio />} label="Allow" />
                                    <FormControlLabel value="disAllow" control={<Radio />} label="Disallow" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="editor-groupCodes">
                            <div className="editor-groupCodes-sectionLeft">
                                <Typography variant="h6" component="div" gutterBottom className="typographySubHeading">{t("Global.Group_Codes")} {fieldName} </Typography>
                                <TextField
                                    variant="outlined"
                                    placeholder={t("Global.search")}
                                    onChange={handleGroupCodesSearch}
                                    sx={{ width: "100%" }}
                                    //value = {searchValue}
                                    InputProps={{
                                        startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
                                        // endAdornment: <CancelIcon onClick = {()=>console.log("Yes it works")}/>,
                                    }}
                                />
                                <div className="align-groupCodes">
                                    {groupCodesList.length > 0 && (
                                        <FormControl>
                                            <Controller
                                                rules={{ required: true }}
                                                control={control}
                                                name="sel_groupCode"
                                                render={({ field }) => {
                                                    return (
                                                        <RadioGroup {...field} onChange={handleGroupCodeChange}>
                                                            {groupCodesList.map((option) => (
                                                                <FormControlLabel
                                                                    value={option}
                                                                    control={<Radio />}
                                                                    label={option}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                    {groupCodesList.length == 0 && (
                                        <Typography variant="h6" className="typographySubHeading">{searchMsg}</Typography>
                                    )}
                                </div>
                                <FormHelperText sx={{ marginTop: "-3%" }} error={errors.sel_groupCode ?.type === 'required'} >{errors.sel_groupCode ?.type === 'required' && (fieldName + " " + "is required")} </FormHelperText>
                            </div>
                            <div className="editor-groupCodes-sectionRight">
                                <Typography variant="h6" component="div" gutterBottom className="typographySubHeading">Diagnosis</Typography>
                                <TextField
                                    variant="outlined"
                                    placeholder={t("Global.search")}
                                    onChange={handleDiagnosisSearch}
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
                                    }}
                                />
                                <div className="align-groupCodes-left">
                                    {/* {groupCodesSubList.map((item) => {
                                        return (
                                            <Typography variant="body1" align="justify" gutterBottom>{item ?.name} {item ?.Description}</Typography>
                                        )
                                    })} */}
                                    {groupCodesSubList.length > 0 ? (
                                        <List
                                            width={400}
                                            height={220}
                                            rowRenderer={renderRow}
                                            rowCount={groupCodesSubList.length}
                                            rowHeight={35}
                                        />
                                    ) : (
                                            <>
                                                {groupCodesSubList.length == 0 && (
                                                    <Typography variant="h6" className="typographySubHeading">{searchMsg}</Typography>
                                                )}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            label={t("Editor.comments")}
                            multiline
                            rows={3}
                            margin="normal"
                            fullWidth
                            {...register("comment_msg", {
                                required: t("Editor.comment_msg"),
                            })}
                            error={Boolean(errors.comment_msg)}
                            helperText={errors ?.comment_msg ?.message}
                        />
                    </DialogContent>
                    <DialogActions sx={{ padding: "12px 24px !important" }}>
                        <Button onClick={handleClose}>{t("Global.cancel")}</Button>
                        <Button variant="contained" type="submit">{t("Global.save")}</Button>
                        <Button variant="contained" type="submit" onClick={() => setRemove(true)}>{t("Global.remove")}</Button>
                    </DialogActions>
                </form>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress />
                </Backdrop>
            </Dialog>
        </div>
    )
}


