import React, { useState, useEffect } from "react";
import "./Viewer.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import VisualSelectionTree from "./VisualSelectionTree";
import { useTranslation } from "react-i18next";
import {
  setVisualDetails,
  getVisualMenus,
  setSelectedSpecification,
  getZipFile,
  setSelectedFileToDownload,
  clearValues,
  errorMessageClose,
  setView,
  getMetadata,
  setTabValue,
  setResetValues,
  resetViewer,
} from "./viewerSlice";
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import ViewerDiagram from "./ViewerDiagram";
import DRG from "./DRG";
import SKSCodes from "./SKSCodes";
import { redirectToLogin } from ".././authentication/authenticationSlice";

export default function Viewer() {
  const [open, setOpen] = useState(false);
  const [displayDiagram, setDisplayDiagram] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const { language } = useSelector((state) => state.language);
  const viewer = useSelector((state) => state.viewer);
  const {
    selectedVisualObj,
    selectedVisualID,
    selectedVisualName,
    selectedVisualYear,
    enableTabs,
    visualMenuCollection,
    selectedSpecification,
    blobToDownload,
    selectedFileType,
    fileNotExists,
    view,
    tabValue,
    metadataCollection,
    isHourGlass
  } = viewer;

  useEffect(() => {
    if (Object.entries(selectedVisualObj).length != 0) {
      dispatch(getVisualMenus({ method: "GET", id: selectedVisualObj.id }));
    }
    if (selectedSpecification ?.name == "Full Logic" && language == "en") {
      dispatch(setSelectedSpecification({
        displayName: "Full Logic",
        groupName: "Full Logic",
        name: "Full Logic",
        value: "Full Logic",
        language: "en",
      }
      ));
    }
    else if (selectedSpecification ?.name == "Full Logic" && language == "dk") {
      dispatch(setSelectedSpecification({
        displayName: "Komplet logik",
        groupName: "Komplet logik",
        name: "Full Logic",
        value: "Komplet logik",
        language: "dk",
      }
      ));
    }
  }, [language])

  useEffect(() => {
    return dispatch(resetViewer());
  }, [])

  const handleChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
    //setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    if (selectedVisualObj) {
      dispatch(
        setVisualDetails({
          id: selectedVisualObj.id,
          name: selectedVisualObj.visualName,
          year: selectedVisualObj.year,
        })
      );
    }
    dispatch(setTabValue("viewerMap"));
    setOpen(false);
    dispatch(getVisualMenus({ method: "GET", id: selectedVisualObj.id }));

  };

  const handleDownload = (e) => {
    e.preventDefault();
    var eventValue = e.currentTarget.value;
    dispatch(setSelectedFileToDownload(eventValue));
    switch (eventValue) {
      case "News":
        var url =
          "api/DownloadPdfFiles?Id=" +
          selectedVisualObj.id +
          "&selectionType=" +
          "Visual" +
          "&selectedOption=" +
          "VisualNews";
        break;
      case "Guidance":
        var url =
          "api/DownloadPdfFiles?Id=" +
          selectedVisualObj.id +
          "&selectionType=" +
          "Visual" +
          "&selectedOption=" +
          "VisualGuide";
        break;
      case "Codes":
        var url =
          "api/DownloadPdfFiles?Id=" +
          selectedVisualObj.id +
          "&selectionType=" +
          "Visual" +
          "&selectedOption=" +
          "InterneKoderPdfFile";
        break;
    }
    dispatch(getZipFile({ url: url, method: "GET" }));
  };
  const handleViewChange = (e) => {
    setDisplayDiagram(false);
    dispatch(setResetValues());
    dispatch(setView(e.target.value));
    if (metadataCollection != null) {
      setDisplayDiagram(true);
    }
  };

  const handleSpecification = (event, value) => {
    // if (value ?.groupName == "Full Logic" || value ?.groupName == "Komplet logik" || value ?.groupName == "Other Specification" || value ?.groupName == "Øvrige Specifikationer" || value ?.groupName == "Type" ) {
    //   dispatch(setView("SimpleView"));
    // }
    dispatch(setView("SimpleView"));
    dispatch(setResetValues());
    dispatch(setSelectedSpecification(value));
    if (value != null) {
      dispatch(
        getMetadata({
          method: "GET",
          name: selectedVisualObj.visualName,
          version: selectedVisualObj.visualVersion,
          year: selectedVisualObj.year,
        })
      );
      setDisplayDiagram(true);
    }
  };
  function loadDiagram() {
    console.log("Display Diagram", displayDiagram);
    if (displayDiagram) {
      return <ViewerDiagram />;
    } else {
      return <div></div>;
    }
  }
  function loadTabContent() {
    if (enableTabs) {
      return (
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              padding: "0px 24px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="content tabs"
            >
              <Tab
                id="tabInteractiveLabel"
                value="viewerMap"
                label={t("viewer.VIEWER_MAP")}
              />
              <Tab value="skscodes" label={t("Editor.SKS_CODES")} />
              <Tab value="drg" label="DRG" />
            </Tabs>
          </Box>
          <TabPanel value="viewerMap" className="viewermap-panel">
            <div className="viewermap-header">
              <div className="viewermap-header-left">
                <Typography
                  id="headerLabel"
                  className="viewer-text"
                  gutterBottom
                >
                  {selectedSpecification ?.value}
                </Typography>
              </div>
              <div className="viewermap-header-right">
                <TextField
                  variant="outlined"
                  select = {(selectedSpecification ?.groupName == "MDC Specification" ||
                  selectedSpecification ?.groupName == "MDC Specifikationer") ? true : false}
                  disabled = {(selectedSpecification ?.groupName == "MDC Specification" ||
                  selectedSpecification ?.groupName == "MDC Specifikationer") ? false : true}
                  value={(selectedSpecification ?.groupName == "MDC Specification" ||
                  selectedSpecification ?.groupName == "MDC Specifikationer") ? view : t("viewer.simpleView")} 
                  onChange={handleViewChange}
                  style={{ width: "180px" }}
                >
                  <MenuItem key="SimpleView" value="SimpleView">
                    {t("viewer.simpleView")}
                  </MenuItem>
                  {(selectedSpecification ?.groupName == "MDC Specification" ||
                    selectedSpecification ?.groupName == "MDC Specifikationer") && (
                      <MenuItem key="AdvancedView" value="AdvancedView">
                        {t("viewer.advancedView")}
                      </MenuItem>
                    )}
                </TextField>
                <Autocomplete
                  id="grouped-demo"
                  value={selectedSpecification}
                  options={visualMenuCollection}
                  groupBy={(option) => option.groupName}
                  getOptionLabel={(option) => option.displayName}
                  sx={{ width: 300, paddingLeft: "20px" }}
                  onChange={handleSpecification}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("viewer.selectSpecification")}
                    />
                  )}
                />
              </div>
            </div>
            <Divider sx={{ paddingTop: "10px" }} />
            {loadDiagram()}
          </TabPanel>
          <TabPanel value="skscodes" className="drg-panel">
            <SKSCodes />
          </TabPanel>
          <TabPanel value="drg" className="drg-panel">
            <DRG />
          </TabPanel>
        </TabContext>
      );
    }
  }

  if (blobToDownload && selectedFileType) {
    switch (selectedFileType) {
      case "News":
        saveAs(blobToDownload, "Visual News.pdf");
        break;
      case "Guidance":
        saveAs(blobToDownload, "Visual Guidance.pdf");
        break;
      case "Codes":
        saveAs(blobToDownload, "InterneKoder.xlsx");
        break;
    }
    dispatch(clearValues());
  }

  if (!viewer.isSessionValid) {
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  return (
    <div className="viewer-container">
      <Paper className="viewer-section-container">
        <div className="viewer-section-header">
          <div className="viewer-header-left">
            <Typography
              id="headerLabel"
              variant="h6"
              className="viewer-title"
              gutterBottom
            >
              {t("viewer.Viwer")}
            </Typography>
          </div>
          <div className="viewer-header-right">
            <Button
              variant="contained"
              size="small"
              className="viewer-button-news"
              onClick={handleDownload}
              value="News"
              disabled={!enableTabs}
            >
              <DownloadIcon sx={{ paddingRight: "10px" }} />
              {t("viewer.news")} (PDF)
            </Button>
            <Button
              variant="contained"
              size="small"
              className="viewer-button-guidance"
              onClick={handleDownload}
              value="Guidance"
              disabled={!enableTabs}
            >
              <DownloadIcon sx={{ paddingRight: "10px" }} />
              {t("viewer.guidance")} (PDF)
            </Button>
            <Button
              variant="contained"
              size="small"
              className="viewer-button-code"
              onClick={handleDownload}
              value="Codes"
              disabled={!enableTabs}
            >
              <DownloadIcon sx={{ paddingRight: "10px" }} />
              {t("viewer.internalCodes")} (XLS)
            </Button>
          </div>
        </div>
        <Divider />
        <div className="viewer-section">
          <Paper className="viewer-content">
            <div className="viewer-content-header">
              <Typography id="headerLabel" className="viewer-text" gutterBottom>
                {selectedVisualName == ""
                  ? "Select the visual file"
                  : selectedVisualName + " - " + selectedVisualYear}
              </Typography>
              <Link
                component="button"
                variant="body2"
                className="viewer-link"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("Global.open_file")}
              </Link>
            </div>
            <Divider />
            <Box sx={{ width: "100%" }}>{loadTabContent()}</Box>
          </Paper>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ m: 0, p: 2, width: "420px" }}>
            {t("publisher.Open_Viewer_File")}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <VisualSelectionTree />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" onClick={handleOpen}>
              {t("interactive.Open")}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={fileNotExists}
        autoHideDuration={5000}
        onClose={() => dispatch(errorMessageClose())}
      >
        <Alert
          onClose={() => dispatch(errorMessageClose())}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("viewer.fileNotExists")}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isHourGlass}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
