import React, { useState, useEffect } from "react";
import "./Viewer.css";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import MuiGrid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ViewerDiagram from "./ViewerDiagram";
import DRGDiagram from "./DRGDiagram";
import Currency from 'react-currency-formatter';
import {
  getMetadata,
  getMetadataForLogicName,
  getDRGTypeSpecifications,
  setDRGSelected,
  getDRGPopUpJSON,
  getshowTariff,
  getDRGPriceJSON,
  setDRGLoading,
  getDRGDiagramData,
  setSelectedSpecification,
  setView,
  setDRGDiagramValues,
  getDRGTypeSpecificationsSearch,
  setResetValues,
} from "./viewerSlice";

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          {labelInfo === "Selected" ? (
            <DoneIcon fontSize="small" color="inherit" sx={{ pr: "5px" }} />
          ) : null}
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function DRG() {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const viewer = useSelector((state) => state.viewer);
  const {
    selectedVisualObj,
    selectedVisualID,
    selectedVisualName,
    selectedVisualYear,
    drgTypeTree,
    drgSpecificationsTree,
    menuItems,
    drgSelectedName,
    subCode,
    drgGroupCodeCollection,
    drgPriceInfoCollection,
    drgLoading,
    drgDataLoading,
    visualMenuCollection,
    selectedSpecification,
    view,
    showDRGDiagram,
    showRegelDiagram,
    isDRGSearch,
    drgSearchTree,
    isShowDRG,
    isShowTariff
  } = viewer;
  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    if (selectedVisualObj) {
      dispatch(setDRGLoading({ name: "drgLoading", value: true }));
      dispatch(
        getMetadata({
          method: "GET",
          name: selectedVisualObj.visualName,
          version: selectedVisualObj.visualVersion,
          year: selectedVisualObj.year,
        })
      ).then(() => {
        dispatch(
          getMetadataForLogicName({
            method: "GET",
            year: selectedVisualObj.year,
          })
        ).then(() => {
          dispatch(getDRGTypeSpecifications({ method: "GET" })).then(() => {
            dispatch(setDRGLoading({ name: "drgLoading", value: false }));
          });
        });
      });
    }
  }, []);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value.length > 2) {
      dispatch(
        getDRGTypeSpecificationsSearch({
          method: "GET",
          search: event.target.value ?.toLowerCase(),
        })
      ).then(() => {
        dispatch(setDRGLoading({ name: "drgSearch", value: true }));
      });
    }
    else if (event.target.value == "") {
      dispatch(setDRGLoading({ name: "sksSearch", value: false }));
      dispatch(setDRGLoading({ name: "showSKSTable", value: false }));
      dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
    }
  };
  const closeSearch = () => {
    setSearchValue("");
    dispatch(setDRGLoading({ name: "drgSearch", value: false }));
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
  };

  const handleTreeSelection = (obj) => () => {
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
    setSelectedValue(obj.id);
    dispatch(setDRGSelected({ code: obj.id, name: obj.name }));
    dispatch(setDRGLoading({ name: "drgDataLoading", value: true }));
    dispatch(getDRGPopUpJSON()).then(() => {
      dispatch(
        getshowTariff({ visualID: selectedVisualID, method: "GET" })
      ).then(() => {
        dispatch(getDRGPriceJSON()).then(() => {
          dispatch(setDRGLoading({ name: "drgDataLoading", value: false }));
        });
      });
    });
  };
  const handleRegelClick = (drg) => {
    //console.log("showRegelDiagram clicked:", drg);
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setDRGLoading({ name: "showRegelDiagram", value: true }));
    var regel = drg.code.replace("REGEL", "").trim();
    var filter1 = drg.desc.split(" / ")[0];
    var filter = filter1.substring(0, 2).trim() + "C00";
    var column = "DIAGRAM1";
    dispatch(
      getDRGDiagramData({
        method: "GET",
        filter: filter,
        regel: regel,
        column: column,
      })
    );
  };

  function displayRegelDiagram() {
    if (showRegelDiagram) {
      return <DRGDiagram />;
    } else {
      return null;
    }
  }

  const drgDescClick = (drg, desc) => {
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
    var link = 0;
    var mdcCode = desc.substring(0, 2);
    var regel = drg.code.replace("REGEL", "").trim();
    if ((desc.includes("EX")) && (mdcCode >= 1 && mdcCode <= 27)) link = 2;
    else if (mdcCode > 27) link = 1;
    else if (mdcCode >= 1 && mdcCode <= 27) link = 1;
    else link = 3;
    var selectedSpecification = visualMenuCollection.filter(
      (a) => a.name == mdcCode
    );

    dispatch(setSelectedSpecification(selectedSpecification[0]));
    let view = "";
    if (link == 2) {
      view = "AdvancedView";
    } else {
      view = "SimpleView";
    }
    dispatch(
      setDRGDiagramValues({ view: view, search: selectedValue, regel: regel })
    );
  };
  const handleViewChange = (e) => {
    dispatch(setResetValues());
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setView(e.target.value));
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: true }));
  };

  const handleSpecification = (event, value) => {
    // if (value ?.groupName == "Full Logic" || value ?.groupName == "Komplet logik" || value ?.groupName == "Other Specification" || value ?.groupName == "Øvrige Specifikationer" || value ?.groupName == "Type" ) {
    //   dispatch(setView("SimpleView"));
    // }
    dispatch(setView("SimpleView"));
    dispatch(setResetValues());
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setSelectedSpecification(value));
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: true }));
  };
  function displayDRGDiagram() {
    if (selectedSpecification != null && showDRGDiagram) {
      return (
        <div className="drg-diagram-container">
          <div className="viewermap-header">
            <div className="viewermap-header-left">
              <Typography id="headerLabel" className="viewer-text" gutterBottom>
                {selectedSpecification ?.value}
              </Typography>
            </div>
            <div className="viewermap-header-right">
              <TextField
                variant="outlined"
                select={(selectedSpecification ?.groupName == "MDC Specification" ||
                  selectedSpecification ?.groupName == "MDC Specifikationer") ? true : false}
                disabled={(selectedSpecification ?.groupName == "MDC Specification" ||
                  selectedSpecification ?.groupName == "MDC Specifikationer") ? false : true}
                value={(selectedSpecification ?.groupName == "MDC Specification" ||
                  selectedSpecification ?.groupName == "MDC Specifikationer") ? view : t("viewer.simpleView")}
                onChange={handleViewChange}
                style={{ width: "180px" }}
              >
                <MenuItem key="SimpleView" value="SimpleView">{t("viewer.simpleView")}</MenuItem>
                {(selectedSpecification ?.groupName == "MDC Specification" ||
                  selectedSpecification ?.groupName == "MDC Specifikationer") && (
                    <MenuItem key="AdvancedView" value="AdvancedView">
                      {t("viewer.advancedView")}
                    </MenuItem>
                  )}
              </TextField>
              <Autocomplete
                id="grouped-demo"
                options={visualMenuCollection}
                groupBy={(option) => option.groupName}
                getOptionLabel={(option) => option.displayName}
                sx={{ width: 300, paddingLeft: "20px" }}
                onChange={handleSpecification}
                value={selectedSpecification}
                renderInput={(params) => (
                  <TextField {...params} label={t("viewer.selectSpecification")} />
                )}
              />
            </div>
          </div>
          <Divider sx={{ paddingTop: "10px" }} />
          <ViewerDiagram />
        </div>
      );
    } else {
      return null;
    }
  }
  function generateGroupCodeTable() {
    if (drgPriceInfoCollection ?.length > 0) {
      var mdcDescription = "";
      var linkGroupCode = [];
      var mdcCode = subCode.substring(0, 2);
      if (menuItems.length > 0) {
        if (mdcCode > 27) {
          var tempArray = menuItems[1];
          var temp = tempArray ?.value ?.filter((a) => a.name == mdcCode)[0];
          mdcDescription = temp ?.value;
        } else {
          var tempArray = menuItems[0];
          var temp = tempArray ?.value ?.filter((a) => a.name == mdcCode)[0];
          mdcDescription = temp ?.value;
        }
      }

      var codeInfo = drgGroupCodeCollection;
      for (let i = 0; i < codeInfo ?.length; i++) {
        var descCollection = [];
        descCollection.push({
          id: 1,
          formattedData:
            codeInfo[i].desc.split(" / ")[0] + " - " + mdcDescription,
          item: codeInfo[i],
          linkValue: codeInfo[i].desc.split(" / ")[0],
        });
        let count = codeInfo[i].desc.split(" / ").length;
        for (let j = 1; j < count; j++) {
          if (codeInfo[i].desc.split(" / ")[j] != "") {
            descCollection.push({
              id: j + 1,
              formattedData: codeInfo[i].desc.split(" / ")[j],
              item: codeInfo[i],
              linkValue: codeInfo[i].desc.split(" / ")[j],
            });
          }
        }
        linkGroupCode.push({
          id: i,
          item: codeInfo[i],
          code: codeInfo[i].code,
          desc: descCollection,
        });
      }
      console.log("linkGroupCode in DRG.jsx", linkGroupCode);
      return (
        <div>
          <Typography className="drg-rules-count" gutterBottom>
            {t("Global.Total_Rules")} : {drgGroupCodeCollection ?.length}
          </Typography>
          <table>
            {linkGroupCode.map((row) => {
              return (
                <tr key={row.id}>
                  <td width="300px">
                    <Link
                      component="button"
                      variant="body2"
                      className="drg-groupCode-tableContent"
                      onClick={() => {
                        handleRegelClick(row.item);
                      }}
                    >
                      {row.code}
                    </Link>
                  </td>
                  <td width="900px">
                    {row.desc.map((a) => {
                      return (
                        <>
                          <Link
                            component="button"
                            variant="body2"
                            className="drg-groupCode-linkContent"
                            onClick={() => {
                              drgDescClick(a.item, a.linkValue);
                            }}
                          >
                            {a.formattedData}
                          </Link>
                          {/* &nbsp;&nbsp;/&nbsp;&nbsp; */}
                          { row.desc.indexOf(a) < row.desc.length - 1 && ( <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>)}
                        </>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      );
    }
  }

  return (
    <div>
      {drgLoading ? (
        <div>
          <Backdrop
            sx={{
              color: "#ffffff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={drgLoading}
          >
            <CircularProgress />
          </Backdrop>
        </div>
      ) : (
          <div>
            <Grid container>
              <Grid item xs className="drg-treeGrid">
                <TextField
                  variant="outlined"
                  placeholder={t("Global.search")}
                  value={searchValue}
                  onChange={handleSearch}
                  sx={{ width: "100%", paddingBottom: "10px" }}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
                    endAdornment: (
                      <CancelRoundedIcon
                        fontSize="small"
                        sx={{ color: "#00000040", cursor: "pointer" }}
                        onClick={closeSearch}
                      />
                    ),
                  }}
                />
                {isDRGSearch ? (
                  <div className="drg-treeview-container">
                    <TreeView
                      aria-label="VisualTree"
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                      defaultEndIcon={<div style={{ width: 24 }} />}
                      sx={{
                        height: "100%",
                        flexGrow: 1,
                        maxWidth: "95%",
                        width: "95%",
                        paddingTop: "10px",
                      }}
                    >
                      {drgSearchTree ?.map((b) => {
                        return (
                          <StyledTreeItem
                            key={b.id}
                            nodeId={b.id}
                            labelText={b.name}
                            labelInfo={
                              b.id == selectedValue ? t("viewer.selected") : t("viewer.select")
                            }
                            color="#1a73e8"
                            bgColor="#e8f0fe"
                            onClick={handleTreeSelection(b)}
                          />
                        );
                      })}
                    </TreeView>
                  </div>
                ) : (
                    <div className="drg-treeview-container">
                      <TreeView
                        aria-label="VisualTree"
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        sx={{
                          height: "100%",
                          flexGrow: 1,
                          maxWidth: "95%",
                          width: "95%",
                          paddingTop: "10px",
                        }}
                      >
                        <StyledTreeItem
                          nodeId="type"
                          labelText="Type"
                          color="#1a73e8"
                          bgColor="#e8f0fe"
                        >
                          {drgTypeTree ?.map((x) => {
                            return (
                              <StyledTreeItem
                                key={x.id}
                                nodeId={x.id}
                                labelText={x.name}
                                color="#1a73e8"
                                bgColor="#e8f0fe"
                              >
                                {x ?.children ?.map((b) => {
                                  return (
                                    <StyledTreeItem
                                      key={b.id}
                                      nodeId={b.id}
                                      labelText={b.name}
                                      labelInfo={
                                        b.id == selectedValue
                                          ? t("viewer.selected")
                                          : t("viewer.select")
                                      }
                                      color="#1a73e8"
                                      bgColor="#e8f0fe"
                                      onClick={handleTreeSelection(b)}
                                    />
                                  );
                                })}
                              </StyledTreeItem>
                            );
                          })}
                        </StyledTreeItem>
                        <StyledTreeItem
                          nodeId="specification"
                          labelText={t("viewer.specification")}
                          color="#1a73e8"
                          bgColor="#e8f0fe"
                        >
                          {drgSpecificationsTree ?.map((x) => {
                            return (
                              <StyledTreeItem
                                key={x.id}
                                nodeId={x.id}
                                labelText={x.name}
                                color="#1a73e8"
                                bgColor="#e8f0fe"
                              >
                                {x ?.children ?.map((b) => {
                                  return (
                                    <StyledTreeItem
                                      key={b.id}
                                      nodeId={b.id}
                                      labelText={b.name}
                                      labelInfo={
                                        b.id == selectedValue
                                          ? t("viewer.selected")
                                          : t("viewer.select")
                                      }
                                      color="#1a73e8"
                                      bgColor="#e8f0fe"
                                      onClick={handleTreeSelection(b)}
                                    />
                                  );
                                })}
                              </StyledTreeItem>
                            );
                          })}
                        </StyledTreeItem>
                      </TreeView>
                    </div>
                  )}
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs className="drg-treeGrid">
                <Typography id="headerLabel" className="viewer-text" gutterBottom>
                  {t("viewer.drg_details")}
                </Typography>
                {drgDataLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                    <div>
                      {isShowDRG && (
                        <div>
                          <Typography
                            id="headerLabel"
                            className="viewer-text"
                            sx={{ paddingTop: "10px" }}
                            gutterBottom
                          >
                            {drgSelectedName}
                          </Typography>
                          <List
                            sx={{
                              width: "100%",
                              bgcolor: "background.paper",
                              textAlign: "left",
                            }}
                          >
                            {drgPriceInfoCollection ?.map((x) => {
                              let infoText;
                              if (x.code == "Takst") {
                                infoText = t("viewer.tariff");
                              } else if (x.code == "Trimpunkt") {
                                infoText = t("viewer.averageStay");
                              } else if (x.code == "Langliggertakst") {
                                infoText = t("viewer.thresholdRate");
                              }
                              return (
                                <div>
                                  <ListItem key={x.code}>
                                    <ListItemText
                                      edge="start"
                                      className="modal-infoText"
                                      sx={{ width: "40%" }}
                                      primary={infoText}
                                    />

                                    <ListItemText
                                      edge="start"
                                      className="modal-infoText"
                                      sx={{ width: "60%" }}
                                      primary={isShowTariff ? x.code == "Trimpunkt" ? x.desc.trim() : <Currency quantity={x.desc.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />: ""}
                                    />
                                  </ListItem>
                                </div>
                              );
                            })}
                          </List>

                          {generateGroupCodeTable()}
                        </div>
                      )}
                    </div>
                  )}
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: "10px" }} />
            {displayDRGDiagram()}
            {showRegelDiagram ? displayRegelDiagram() : null}
          </div>
        )}
    </div>
  );
}
