import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import "./Editor.css";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { useForm } from "react-hook-form";
import { editorEditValues } from "./editorEditValues";
import { debounce } from "@mui/material";
import {
    clearStatus,
    setMasterSelectedEditorData,
    setSelectedEditorData,
    setDrgLogicComments,
    setOpenCellEdit,
} from "./editorSlice";

export default function CellEdit(props) {
    const { t } = useTranslation("language");
    const dispatch = useDispatch();
    const editor = useSelector((state) => state.editor);
    const { register, handleSubmit, reset, formState: { errors }, } = useForm();
    const [masterData, setMasterData] = useState();
    const {
        selectedCellDetails,
        openCellEdit,
        selectedRows,
        masterSelectedEditorData,
        selectedEditorData,
        DrgLogicComments,
    } = editor;
    const apiRef = props.apiRef;
    const ruleId = selectedCellDetails.id
    const fieldName = selectedCellDetails.field.toUpperCase();
    let fieldValue = selectedCellDetails.value;

    useEffect(() => {
        setMasterData(editorEditValues[selectedCellDetails.field.toUpperCase()]);
        reset({ columnValue: selectedCellDetails.value });
    }, []);

    const handleClose = () => {
        dispatch(clearStatus());
    }

    const handleValidate = (fieldName) => (fieldValue) => {
        if (fieldName == "MDC") {
            if (fieldValue == "" || fieldValue == null) {
                return true;
            }
            else {
                var myRe = /^(1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|98)$/g;
                var myArray = myRe.exec(fieldValue);
                if (myArray == null) {
                    return t("Editor.invalid_mdc");
                }
                else { return true; }
            }
        }
        if (fieldName == "AGELIM") {
            if (fieldValue == "" || fieldValue == null) {
                return true;
            }
            else {
                var myRe = /^[-]?[0-9]{1,5}$/g;
                var myArray = myRe.exec(fieldValue);
                if (myArray == null) {
                    return t("Editor.invalid_agelim");
                }
                else { return true; }
            }
        }
        if (fieldName == "DRG") {
            if (fieldValue == null || fieldValue == "") {
                return t("Editor.empty_drg");
            }
            else {
                var myRe = /^[a-zA-Z0-9]{2,10}$/;
                var myArray = myRe.exec(fieldValue);
                if (myArray == null) {
                    return t("Editor.invalid_drg");
                }
                else { return true; }
            }

        }
        if (fieldName == "RTC") {
            if (fieldValue == null || fieldValue == "") {
                return t("Editor.empty_rtc");
            }
            else {
                var myRe = /^[0-9]{1}$/g;
                var myArray = myRe.exec(fieldValue);
                if (myArray == null) {
                    return t("Editor.invalid_rtc");
                }
                else { return true; }
            }
        }
        if (fieldName == "DOEDLIM") {
            if (fieldValue == "" || fieldValue == null) {
                return true;
            }
            else {
                var myRe = /^[0-9]{1,10}$/g;
                var myArray = myRe.exec(fieldValue);
                if (myArray == null) {
                    return t("Editor.invalid_doedlim");
                }
                else { return true; }
            }
        }
        else { return true; }
    }

    const onSubmit = async (values) => {
        const cellName = selectedCellDetails.field;
        const cellValue = values?.columnValue;
        const comment = values?.comment_msg;
        let commentArray = [];
        if (selectedRows.length > 0) {
            for (let i = 0; i < selectedRows.length; i++) {
                apiRef.current.startCellEditMode({ id: selectedRows[i], field: cellName });
                const isValid = await apiRef.current.setEditCellValue({
                    id: selectedRows[i],
                    field: cellName,
                    value: cellValue,
                    debounceMs: 1 
                });
                if (isValid) {
                    apiRef.current.stopCellEditMode({ id: selectedRows[i], field: cellName });
                }
                var commentData = {
                    RuleNo: selectedRows[i],
                    FieldName: fieldName,
                    Comments: comment,
                }
                commentArray.push(commentData);
            }
            dispatch(setDrgLogicComments(commentArray));
        }
        else {
            apiRef.current.startCellEditMode({ id: ruleId, field: cellName });
            const isValid = await apiRef.current.setEditCellValue({
                id: ruleId,
                field: cellName,
                value: cellValue,
                debounceMs: 1
            });

            if (isValid) {
                apiRef.current.stopCellEditMode({ id: ruleId, field: cellName });
            }

            var commentData = {
                RuleNo: ruleId,
                FieldName: fieldName,
                Comments: comment,
            }
            dispatch(setDrgLogicComments(commentData));
        }
        const rows = apiRef.current.getRowModels();
        let updatedRows = [];
        for (let value of rows.values()) {
            updatedRows.push(value);
        }
        let updatedArray = [];
        updatedArray = masterSelectedEditorData.map(obj => updatedRows.find(o => o.ruleNo === obj.ruleNo) || obj);
        dispatch(setSelectedEditorData(updatedRows));
        dispatch(setMasterSelectedEditorData(updatedArray));
        dispatch(clearStatus());
    };

    return (
        <div>
            <Dialog open={openCellEdit} onClose={handleClose} maxWidth='xs' fullWidth>
                <DialogTitle>
                    {selectedRows.length > 1 && (<>
                        Edit {fieldName} for selected rows</>
                    )}
                    {selectedRows.length <= 1 && (<>
                        {t("Global.edit")} {fieldName} for {t("Editor.rule_no")}: {selectedCellDetails.id}</>
                    )}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        {masterData == undefined && (
                            <TextField
                                id="outlined-basic"
                                label={fieldName}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                autoFocus={true}
                                {...register('columnValue', {
                                    validate: handleValidate(fieldName),
                                })}
                                error={Boolean(errors.columnValue)}
                                helperText={errors ?.columnValue ?.message}
                            />
                        )}
                        {masterData != undefined && masterData.length > 0 && (
                            <TextField
                                id="outlined-basic"
                                label={fieldName}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                autoFocus="true"
                                select
                                defaultValue={selectedCellDetails.value}
                                {...register("columnValue", {
                                    validate: handleValidate(fieldName),
                                })}
                                error={Boolean(errors.columnValue)}
                                helperText={errors ?.columnValue ?.message}
                            >
                                {masterData.map((item) => {
                                    return (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                        <TextField
                            id="outlined-multiline-static"
                            label={t("Editor.comments")}
                            multiline
                            rows={3}
                            margin="normal"
                            fullWidth
                            {...register("comment_msg", {
                                required: t("Editor.comment_msg"),
                            })}
                            error={Boolean(errors.comment_msg)}
                            helperText={errors ?.comment_msg ?.message}
                        />
                    </DialogContent>
                    <DialogActions sx={{ padding: "12px 24px !important" }}>
                        <Button onClick={handleClose}>{t("Global.cancel")}</Button>
                        <Button variant="contained" type="submit">{t("Global.save")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
