import React, { useState, useEffect } from "react";
import "./Viewer.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import VisualSelectionTree from "./VisualSelectionTree";
import { useTranslation } from "react-i18next";
import {
  setVisualDetails,
  getVisualMenus,
  setSelectedSpecification,
  getZipFile,
  setSelectedFileToDownload,
  clearValues,
  errorMessageClose,
  setView,
  getMetadata,
  setTabValue,
  setEnvironment,
  getCountries,
  GetAllProductionVisual,
  GetVisualFrontPageContent,
  setSelectedVisual,
  setPanel,
  setResetValues,
  resetViewer,
} from "./publicViewerSlice";
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import ViewerDiagram from "./ViewerDiagram";
import DRG from "./DRG";
import SKSCodes from "./SKSCodes";
import { redirectToLogin } from "../.././authentication/authenticationSlice";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import MuiGrid from "@mui/material/Grid";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import parse from "html-react-parser";
import Logo from "../../../assets/logos/drg-logo.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { languageChanged } from "../../../translations/languageSlice";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "5px 0",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

export default function ViewerProd() {
  const [open, setOpen] = useState(false);
  const [displayDiagram, setDisplayDiagram] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const { language } = useSelector((state) => state.language);
  const [languageVal, setLanguageVal] = useState("2");
  const viewer = useSelector((state) => state.publicViewer);
  const {
    selectedVisualObj,
    selectedVisualID,
    selectedVisualName,
    selectedVisualYear,
    enableTabs,
    visualMenuCollection,
    selectedSpecification,
    blobToDownload,
    selectedFileType,
    fileNotExists,
    view,
    tabValue,
    metadataCollection,
    countries,
    visualCollection,
    yearsCollection,
    viewerFrontPageContent,
    introInfoPanel,
    isHourGlass,
    isValidData,
    isInvalidData,
  } = viewer;

  useEffect(() => {
    dispatch(setEnvironment("Production"));
    dispatch(getCountries({ method: "GET" }));
    let url = "Public/GetAllProductionVisual?isHearing=false";
    dispatch(GetAllProductionVisual({ method: "GET", url: url })).then(() => {
      dispatch(GetVisualFrontPageContent({ method: "GET" }));
      dispatch(getVisualMenus({ method: "GET" }));
    });
    return () => {
      dispatch(resetViewer());
    };
  }, []);

  useEffect(() => {
    if (Object.entries(selectedVisualObj).length != 0) {
      dispatch(getVisualMenus({ method: "GET", id: selectedVisualObj.id }));
    }
    if (selectedSpecification?.name == "Full Logic" && language == "en") {
      dispatch(
        setSelectedSpecification({
          displayName: "Full Logic",
          groupName: "Full Logic",
          name: "Full Logic",
          value: "Full Logic",
          language: "en",
        })
      );
    } else if (
      selectedSpecification?.name == "Full Logic" &&
      language == "dk"
    ) {
      dispatch(
        setSelectedSpecification({
          displayName: "Komplet logik",
          groupName: "Komplet logik",
          name: "Full Logic",
          value: "Komplet logik",
          language: "dk",
        })
      );
    }
  }, [language]);

  const handlePanelChange = (panel) => (event, newExpanded) => {
    dispatch(setPanel(panel));
  };

  const handleChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
    //setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    if (selectedVisualObj) {
      dispatch(
        setVisualDetails({
          id: selectedVisualObj.id,
          name: selectedVisualObj.visualName,
          year: selectedVisualObj.year,
        })
      );
    }
    dispatch(setTabValue("viewerMap"));
    setOpen(false);
    dispatch(getVisualMenus({ method: "GET", id: selectedVisualObj.id }));
  };

  const handleDownload = (e) => {
    e.preventDefault();
    var eventValue = e.currentTarget.value;
    dispatch(setSelectedFileToDownload(eventValue));
    switch (eventValue) {
      case "News":
        var url =
          "Public/DownloadPdfFiles?Id=" +
          selectedVisualObj.id +
          "&selectionType=" +
          "Visual" +
          "&selectedOption=" +
          "VisualNews";
        break;
      case "Guidance":
        var url =
          "Public/DownloadPdfFiles?Id=" +
          selectedVisualObj.id +
          "&selectionType=" +
          "Visual" +
          "&selectedOption=" +
          "VisualGuide";
        break;
      case "Codes":
        var url =
          "Public/DownloadPdfFiles?Id=" +
          selectedVisualObj.id +
          "&selectionType=" +
          "Visual" +
          "&selectedOption=" +
          "InterneKoderPdfFile";
        break;
    }
    dispatch(getZipFile({ url: url, method: "GET" }));
  };
  const handleViewChange = (e) => {
    setDisplayDiagram(false);
    dispatch(setResetValues());
    dispatch(setView(e.target.value));
    if (metadataCollection != null) {
      setDisplayDiagram(true);
    }
  };

  const handleSpecification = (event, value) => {
    // if (value ?.groupName == "Full Logic" || value ?.groupName == "Komplet logik" || value ?.groupName == "Other Specification" || value ?.groupName == "Øvrige Specifikationer" || value ?.groupName == "Type" ) {
    //   dispatch(setView("SimpleView"));
    // }
    dispatch(setView("SimpleView"));
    dispatch(setResetValues());
    dispatch(setSelectedSpecification(value));
    if (value != null) {
      dispatch(
        getMetadata({
          method: "GET",
          name: selectedVisualObj.visualName,
          version: selectedVisualObj.visualVersion,
          year: selectedVisualObj.year,
        })
      );
      setDisplayDiagram(true);
    }
  };
  function loadDiagram() {
    if (displayDiagram) {
      return <ViewerDiagram />;
    } else {
      return <div></div>;
    }
  }
  function loadTabContent() {
    if (enableTabs) {
      return (
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              padding: "0px 24px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="content tabs"
            >
              <Tab
                id="tabInteractiveLabel"
                value="viewerMap"
                label={t("viewer.VIEWER_MAP")}
              />
              <Tab value="skscodes" label={t("Editor.SKS_CODES")} />
              <Tab value="drg" label="DRG" />
            </Tabs>
          </Box>
          <TabPanel value="viewerMap" className="viewermap-panel">
            <div className="viewermap-header">
              <div className="viewermap-header-left">
                <Typography
                  id="headerLabel"
                  className="viewer-text"
                  gutterBottom
                >
                  {selectedSpecification?.value}
                </Typography>
              </div>
              <div className="viewermap-header-right">
                <TextField
                  variant="outlined"
                  select={
                    selectedSpecification?.groupName == "MDC Specification" ||
                    selectedSpecification?.groupName == "MDC Specifikationer"
                      ? true
                      : false
                  }
                  disabled={
                    selectedSpecification?.groupName == "MDC Specification" ||
                    selectedSpecification?.groupName == "MDC Specifikationer"
                      ? false
                      : true
                  }
                  value={
                    selectedSpecification?.groupName == "MDC Specification" ||
                    selectedSpecification?.groupName == "MDC Specifikationer"
                      ? view
                      : t("viewer.simpleView")
                  }
                  onChange={handleViewChange}
                  style={{ width: "180px" }}
                >
                  <MenuItem value="SimpleView">
                    {t("viewer.simpleView")}
                  </MenuItem>
                  {(selectedSpecification?.groupName == "MDC Specification" ||
                    selectedSpecification?.groupName ==
                      "MDC Specifikationer") && (
                    <MenuItem value="AdvancedView">
                      {t("viewer.advancedView")}
                    </MenuItem>
                  )}
                </TextField>
                <Autocomplete
                  id="grouped-demo"
                  value={selectedSpecification}
                  options={visualMenuCollection}
                  groupBy={(option) => option.groupName}
                  getOptionLabel={(option) => option.displayName}
                  sx={{ width: 300, paddingLeft: "20px" }}
                  onChange={handleSpecification}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("viewer.selectSpecification")}
                    />
                  )}
                />
              </div>
            </div>
            <Divider sx={{ paddingTop: "10px" }} />
            {loadDiagram()}
          </TabPanel>
          <TabPanel value="skscodes" className="drg-panel">
            <SKSCodes />
          </TabPanel>
          <TabPanel value="drg" className="drg-panel">
            <DRG />
          </TabPanel>
        </TabContext>
      );
    }
  }

  if (blobToDownload && selectedFileType) {
    switch (selectedFileType) {
      case "News":
        saveAs(blobToDownload, "Visual News.pdf");
        break;
      case "Guidance":
        saveAs(blobToDownload, "Visual Guidance.pdf");
        break;
      case "Codes":
        saveAs(blobToDownload, "InterneKoder.xlsx");
        break;
    }
    dispatch(clearValues());
  }

  if (!viewer.isSessionValid) {
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  const handleYearSelect = (e) => {
    const value = e.target.value;
    if (value) {
      const result = visualCollection.filter((item) => {
        return item.year == value;
      })[0];
      dispatch(setTabValue("viewerMap"));
      dispatch(setSelectedVisual(result));
      dispatch(
        setVisualDetails({
          id: result.id,
          name: result.visualName,
          year: result.year,
        })
      );
      dispatch(GetVisualFrontPageContent({ method: "GET" }));
      dispatch(getVisualMenus({ method: "GET" }));
    }
  };

  function handleLanguageChange(val) {
    if (countries != null && countries.length > 0) {
      var language = countries.find((x) => x.id == val);
      setLanguageVal(val);
      i18n.changeLanguage(language.name);
      // localStorage.setItem("language", language.name);
      dispatch(
        languageChanged({
          language: language.name,
        })
      );
      dispatch(GetVisualFrontPageContent({ method: "GET" }));
    }
  }

  return (
    <div className="viewer-container">
      {isValidData && (
        <div className="validContent">
          <div className="viewer-container-header">
            <div className="viewer-logo">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="viewer-lang">
              <TextField
                label={t("contentUpload.languageLabel")}
                variant="outlined"
                size="small"
                select
                onChange={(event) => handleLanguageChange(event.target.value)}
                value={languageVal}
              >
                {countries ? (
                  countries.map((country) => (
                    <MenuItem value={country.id}>
                      {country.name === "en"
                        ? t("contentUpload.english")
                        : t("contentUpload.danish")}
                    </MenuItem>
                  ))
                ) : (
                  <div></div>
                )}
              </TextField>
            </div>
          </div>
          <Paper className="viewerPublic-section-container">
            <div className="viewerProd-section-header">
              <div className="viewer-header-left">
                <Typography
                  id="headerLabel"
                  variant="h6"
                  className="viewer-title"
                  color="white"
                >
                  {t("viewer.Viwer")}
                </Typography>
              </div>
              <div className="viewer-header-right">
                <Button
                  variant="contained"
                  size="small"
                  className="viewerProd-button-news"
                  onClick={handleDownload}
                  value="News"
                  disabled={!enableTabs}
                >
                  <DownloadIcon sx={{ paddingRight: "10px" }} />
                  {t("viewer.news")} (PDF)
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className="viewerProd-button-guidance"
                  onClick={handleDownload}
                  value="Guidance"
                  disabled={!enableTabs}
                >
                  <DownloadIcon sx={{ paddingRight: "10px" }} />
                  {t("viewer.guidance")} (PDF)
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className="viewerProd-button-code"
                  onClick={handleDownload}
                  value="Codes"
                  disabled={!enableTabs}
                >
                  <DownloadIcon sx={{ paddingRight: "10px" }} />
                  {t("viewer.internalCodes")} (XLS)
                </Button>
              </div>
            </div>
            <Divider />
            <div className="viewer-section">
              <Paper className="viewer-content">
                <div className="viewer-content-header">
                  <TextField
                    label={t("Manager.Year")}
                    variant="outlined"
                    size="small"
                    select
                    onChange={handleYearSelect}
                    sx={{ marginRight: 1, padding: "0px 5px" }}
                    value={selectedVisualYear}
                    // inputProps={{ className: classes.input }}
                    // InputLabelProps={{ className: classes.cssLabel }}
                    // className={classes.root}
                  >
                    {yearsCollection.map((year) => {
                      return (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {/* <Typography id="headerLabel" className="viewer-text" gutterBottom>
                {selectedVisualName == ""
                  ? "Select the visual file"
                  : selectedVisualName + " - " + selectedVisualYear}
              </Typography>
              <Link
                component="button"
                variant="body2"
                className="viewer-link"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("Global.open_file")}
              </Link> */}
                </div>
                <Divider />
                {viewerFrontPageContent != "" && (
                  <Accordion expanded={introInfoPanel}>
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className="viewerProd-accordion-summary"
                      expandIcon={
                        <ArrowRightIcon
                          fontSize="large"
                          onClick={handlePanelChange("introInfoPanel")}
                          sx={{ cursor: "pointer", color: "white" }}
                        />
                      }
                      sx={{
                        padding: "0px !important",
                        cursor: "default !important",
                      }}
                    >
                      <div className="viewer-accordion-text">
                        <Typography className="viewerPublic-text">
                          {t("Global.introduction")}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="viewerFrontPageContent">
                        {parse(viewerFrontPageContent)}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Divider />
                <Box sx={{ width: "100%" }}>{loadTabContent()}</Box>
              </Paper>
            </div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle sx={{ m: 0, p: 2, width: "420px" }}>
                {t("publisher.Open_Viewer_File")}
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <VisualSelectionTree />
              </DialogContent>
              <DialogActions sx={{ padding: "12px 24px !important" }}>
                <Button onClick={handleClose}>{t("Global.cancel")}</Button>
                <Button variant="contained" onClick={handleOpen}>
                  {t("interactive.Open")}
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={fileNotExists}
            autoHideDuration={5000}
            onClose={() => dispatch(errorMessageClose())}
          >
            <Alert
              onClose={() => dispatch(errorMessageClose())}
              severity="error"
              sx={{ width: "100%" }}
            >
              {t("viewer.fileNotExists")}
            </Alert>
          </Snackbar>
        </div>
      )}
      {isInvalidData && (
        <div>
          {" "}
          <Typography variant="h4" align="center">
            {" "}
            The link you accessed has no data or expired{" "}
          </Typography>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isHourGlass}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
