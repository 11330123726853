import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../../../app/store";

export const getAllVisual = createAsyncThunk(
  "publicViewer/getAllVisual",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("Public/GetAllVisual", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getVisualMenus = createAsyncThunk(
  "publicViewer/getVisualMenus",
  async (obj, { dispatch, getState }) => {
    const { selectedVisualID } = getState().publicViewer;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "Public/GetVisualMenus?visualId=" + selectedVisualID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getZipFile = createAsyncThunk(
  "publicViewer/getZipFile",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch(obj.url, reqOptions).then((response) => {
      if (response.status === 401) {
        dispatch(endUserSession());
      } else {
        return response.blob();
      }
    });
  }
);

export const GetVisualDatabyId = createAsyncThunk(
  "publicViewer/GetVisualDatabyId",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 500) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getMetadata = createAsyncThunk(
  "publicViewer/getMetadata",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "Public/GetMetadata?visualname=" +
      obj.name +
      "&visualversion=" +
      obj.version +
      "&year=" +
      obj.year;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getNodesJSON = createAsyncThunk(
  "publicViewer/getNodesJSON",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    if (
      obj.specification === "" ||
      obj.specification === "Full Logic" ||
      obj.specification === "Komplet logik" ||
      obj.specification === undefined
    ) {
      let url =
        "Public/GetViewerData/?_logicName=" +
        obj.logicName +
        "&_logicVersion=" +
        obj.logicVersion +
        "&_year=" +
        obj.year +
        "&pricename=" +
        obj.priceName +
        "&priceversion=" +
        obj.priceVersion;
      return await fetch(url, reqOptions).then((res) => {
        if (res.status === 401) {
          dispatch(endUserSession());
        } else {
          return res.json();
        }
      });
    } else {
      return await fetch(
        "Public/GetViewerData/?_logicName=" +
        obj.logicName +
        "&_logicVersion=" +
        obj.logicVersion +
        "&_year=" +
        obj.year +
        "&_folder=" +
        obj.specification +
        "&_mdc=" +
        obj.mdc +
        "&_viewtype=" +
        obj.viewType +
        "&pricename=" +
        obj.priceName +
        "&priceversion=" +
        obj.priceVersion
      ).then((res) => {
        if (res.status === 401) {
          dispatch(endUserSession());
        } else {
          return res.json();
        }
      });
    }
  }
);

export const getDRGPriceJSON = createAsyncThunk(
  "publicViewer/getDRGPriceJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "Public/GetDRGPriceData/?_drg=" +
      state.publicViewer.subCode +
      "&_price=" +
      metadataColl.DRGPriceName +
      "&_priceversion=" +
      metadataColl.DRGPriceVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getMetadataForLogicName = createAsyncThunk(
  "publicViewer/getMetadataForLogicName",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "Public/GetMetadataforLogicName?logicname=" +
      metadataColl.DRGLogicName +
      "&logicversion=" +
      metadataColl.DRGLogicVersion +
      "&year=" +
      state.publicViewer.selectedVisualYear;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGPopUpJSON = createAsyncThunk(
  "publicViewer/getDRGPopUpJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "Public/GetDRGPopupData/?_drg=" +
      state.publicViewer.subCode +
      "&_drgLogicId=" +
      state.publicViewer.drgLogicID +
      "&egenskabname=" +
      state.publicViewer.egenskaberName +
      "&egenskabversion=" +
      state.publicViewer.egenskaberVersion +
      "&mdcname=" +
      state.publicViewer.mdcname +
      "&mdcversion=" +
      state.publicViewer.mdcversion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getMDCGroupCodeJSON = createAsyncThunk(
  "publicViewer/getMDCGroupCodeJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "Public/GetMDCCodesWithDescription?groupCode=" +
      state.publicViewer.groupCode +
      " &mdc=" +
      state.publicViewer.subCode +
      "&drgLogicId=" +
      state.publicViewer.drgLogicID +
      "&sksname=" +
      state.publicViewer.sksName +
      "&sksversion=" +
      state.publicViewer.sksVersion;

    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getGroupCodeJSON = createAsyncThunk(
  "publicViewer/getGroupCodeJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = "GET";
    const subList = state.publicViewer.subCode;
    
    let url =
      "Public/GetCodesWithDescription?groupCode=" +
      encodeURIComponent(state.publicViewer.groupCode) +
      " &groupCodeSubList=" +
      encodeURIComponent(subList) +
      "&drgLogicId=" +
      state.publicViewer.drgLogicID +
      "&sksname=" +
      state.publicViewer.sksName +
      "&sksversion=" +
      state.publicViewer.sksVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getshowTariff = createAsyncThunk(
  "publicViewer/getshowTariff",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "Public/GetShowTariffByVisualId/?visualId=" + obj.visualID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGTypeSpecifications = createAsyncThunk(
  "publicViewer/getDRGTypeSpecifications",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "Public/GetDRGTypeSpecifications?logicid=" +
      state.publicViewer.drgLogicID +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGTypeSpecificationsSearch = createAsyncThunk(
  "publicViewer/getDRGTypeSpecificationsSearch",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "Public/GetDRGTypeSpecificationsSearch?logicid=" +
      state.publicViewer.drgLogicID +
      "&tabType=" +
      "specification" +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&search=" +
      obj.search;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGDiagramData = createAsyncThunk(
  "publicViewer/getDRGDiagramData",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "Public/GetDRGDiagramData?filtervalue=" +
      obj.filter +
      "&rulenumber=" +
      obj.regel +
      "&column=" +
      obj.column +
      "&_logicName=" +
      metadataColl.DRGLogicName +
      "&_logicVersion=" +
      metadataColl.DRGLogicVersion +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&strYear=" +
      state.publicViewer.selectedVisualYear;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getAllParentSKS = createAsyncThunk(
  "publicViewer/getAllParentSKS",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let parentId = obj.parentId ? obj.parentId : "";
    let url =
      "Public/GetAllParentSKS?parentid=" +
      parentId +
      "&sksname=" +
      state.publicViewer.sksName +
      "&sksversion=" +
      state.publicViewer.sksVersion;

    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getSKSSearch = createAsyncThunk(
  "publicViewer/getSKSSearch",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "Public/GetSKSSearch?sksName=" +
      state.publicViewer.sksName +
      "&sksVer=" +
      state.publicViewer.sksVersion +
      "&search=" +
      obj.search;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getSKSTable = createAsyncThunk(
  "publicViewer/getSKSTable",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;

    let url =
      "Public/Getsksmap?skscode=" +
      obj.code +
      "&logicid=" +
      state.publicViewer.drgLogicID +
      "&logicname=" +
      metadataColl.DRGLogicName +
      "&logicversion=" +
      metadataColl.DRGLogicVersion +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&year=" +
      state.publicViewer.selectedVisualYear +
      "&interactivename=" +
      metadataColl.InteractiveName +
      "&interactiveversion=" +
      metadataColl.InteractiveVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getSKSCodeDiagramData = createAsyncThunk(
  "publicViewer/getSKSCodeDiagramData",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.publicViewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "/Public/GetSKSCodeDiagramData?filtervalue=" +
      obj.drgGroup +
      "&rulenumber=" +
      obj.regel +
      "&_logicName=" +
      metadataColl.DRGLogicName +
      "&_logicVersion=" +
      metadataColl.DRGLogicVersion +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&strYear=" +
      state.publicViewer.selectedVisualYear;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getCountries = createAsyncThunk(
  "publicViewer/getCountries",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("home/GetCountry", reqOptions).then((r) => {
      return r.json();
    });
  }
);

export const GetAllProductionVisual = createAsyncThunk(
  "publicViewer/GetAllProductionVisual",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch(obj.url, reqOptions).then((r) => {
      return r.json();
    });
  }
);

export const GetVisualFrontPageContent = createAsyncThunk(
  "publicViewer/GetVisualFrontPageContent",
  async (obj, { dispatch, getState }) => {
    const { selectedVisualYear, isEnvironment } = getState().publicViewer;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let langval = localStorage.getItem("language");
    let url = "Public/GetVisualFrontPageContent?language=" + langval + "&year=" + selectedVisualYear + "&destEnvironment=" + isEnvironment;
    return await fetch(url, reqOptions).then((res) => {
      return res.json();
    });
  }
);

const initialState = {
  visualCollection: [],
  yearsCollection: [],
  status: null,
  enableTabs: false,
  tabValue: "viewerMap",
  selectedVisualObj: {},
  selectedVisualID: "",
  selectedVisualName: "",
  selectedVisualYear: "",
  visualMenuCollection: [],
  selectedSpecification: null,
  blobToDownload: null,
  selectedFileType: "",
  fileNotExists: false,
  view: "SimpleView",
  metadataCollection: null,
  isLoading: false,
  dataForGoJs: null,
  isNodeData: false,
  groupCode: "",
  groupCodeValue: "",
  subCode: "",
  groupcodeArray: [],
  priceArray: [],
  drgLogicID: "",
  egenskaberName: "",
  egenskaberVersion: "",
  sksName: "",
  sksVersion: "",
  sCode: "",
  dataForModalDialog: [],
  modalActive: true,
  isShowTariff: false,
  menuItems: [],
  drgTypeTree: [],
  drgSpecificationsTree: [],
  drgSearchTree: [],
  drgSelectedName: "",
  drgLoading: true,
  drgDataLoading: false,
  drgGroupCodeCollection: [],
  drgPriceInfoCollection: [],
  searchValue: "",
  regelValue: "",
  showDRGDiagram: false,
  showRegelDiagram: false,
  isDrgData: false,
  drgDataForGoJs: null,
  sksLoading: true,
  sksParentCollection: null,
  hasChildren: false,
  childrenCount: -1,
  sksChildrenCollection: null,
  isDRGSearch: false,
  sksSelectedId: "",
  sksSelectedName: "",
  sksMapData: null,
  sksSearchTree: [],
  isSKSSearch: false,
  showSKSTable: false,
  isSessionValid: true,
  isShowDRG: false,
  countries: [],
  isEnvironment: "",
  viewerFrontPageContent: "",
  introInfoPanel: true,
  isHourGlass: false,
  isExpandedValue: "",
  page: 0,
  rowsPerPage: 5,
  mdcname: "",
  mdcversion: ""
};

const publicViewerSlice = createSlice({
  name: "publicViewer",
  initialState,
  reducers: {
    endUserSession(state, action) {
      state.isSessionValid = false;
    },
    setSelectedVisual(state, action) {
      state.selectedVisualObj = action.payload;
      //state.enableTabs = false;
    },
    setTabValue(state, action) {
      state.tabValue = action.payload;
      state.searchValue = "";
      state.regelValue = "";
      state.selectedSpecification = null;
      state.view = "SimpleView";
      state.metadataCollection = null;
      state.isLoading = true;
      state.showSKSTable = false;
      state.isShowDRG = false;
      state.isDRGSearch = false;
    },
    setVisualDetails(state, action) {
      state.selectedVisualID = action.payload.id;
      state.selectedVisualName = action.payload.name;
      state.selectedVisualYear = action.payload.year;
      state.enableTabs = true;
    },
    setSelectedSpecification(state, action) {
      if (state.tabValue == "drg" || state.tabValue == "skscodes") {
        state.selectedSpecification = action.payload;
      } else {
        state.selectedSpecification = action.payload;
        if (!("language" in state.selectedSpecification)) {
          state.isLoading = true;
        }
      }
    },
    setSelectedFileToDownload(state, action) {
      state.selectedFileType = action.payload;
    },
    clearValues(state, action) {
      state.selectedFileType = null;
      state.blobToDownload = null;
    },
    errorMessageClose(state, action) {
      state.fileNotExists = false;
    },
    setView(state, action) {
      state.view = action.payload;
    },
    testCall(state, action) {
      state.status = "";
    },
    saveCodeValues(state, { payload }) {
      state.groupCode = payload.groupCode.trim();
      state.groupCodeValue = payload.groupCodeValue;
      state.subCode = payload.subCode.trim();
    },
    setModalActive(state, { payload }) {
      state.modalActive = payload;
    },
    setDRGSelected(state, { payload }) {
      state.subCode = payload.code;
      state.drgSelectedName = payload.name;
      state.selectedSpecification = null;
      state.showDRGDiagram = false;
      state.isShowDRG = true;
    },
    setDRGLoading(state, { payload }) {
      if (payload.name == "drgLoading") {
        state.drgLoading = payload.value;
      } else if (payload.name == "drgDataLoading") {
        state.drgDataLoading = payload.value;
      } else if (payload.name == "showDRGDiagram") {
        state.showDRGDiagram = payload.value;
      } else if (payload.name == "sksLoading") {
        state.sksLoading = payload.value;
      } else if (payload.name == "showRegelDiagram") {
        state.showRegelDiagram = payload.value;
        state.isExpandedValue = payload.expandedValue;
      } else if (payload.name == "drgSearch") {
        state.isDRGSearch = payload.value;
      } else if (payload.name == "sksSearch") {
        state.isSKSSearch = payload.value;
      } else if (payload.name == "showSKSTable") {
        state.showSKSTable = payload.value;
      }
    },
    setDRGDiagramValues(state, { payload }) {
      state.view = payload.view;
      state.searchValue = payload.search;
      state.regelValue = payload.regel;
      state.showDRGDiagram = true;
    },
    setChildrenNode(state, { payload }) {
      state.hasChildren = payload;
    },
    setSKSSelected(state, { payload }) {
      state.sksSelectedId = payload.id
      state.sksSelectedName = payload.name;
    },
    setEnvironment(state, { payload }) {
      state.isEnvironment = payload;
    },
    setPanel(state, { payload }) {
      if (payload) {
        state[`${payload}`] = !state[`${payload}`];
      }
    },
    setResetValues(state, action) {
      state.searchValue = "";
      state.regelValue = "";
    },
    resetSKS(state, action) {
      state.isSKSSearch = false;
      state.showSKSTable = false;
      state.showRegelDiagram = false;
    },
    setPagination(state, action) {
      state.page = parseInt(action.payload ?.page);
      state.rowsPerPage = parseInt(action.payload ?.rowsPerPage);
    },
    resetViewer: () => initialState,
    
    setDataSatus(state, action){
      state.isInvalidData = true;
    }
  },
  extraReducers: {
    [getAllVisual.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllVisual.fulfilled]: (state, { payload }) => {
      console.log("publicViewer", payload);
      state.visualCollection = payload;
      state.yearsCollection = payload
        .map((item) => item.year)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((one, two) => (one > two ? -1 : 1));

      state.status = "success";
    },
    [getAllVisual.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getAllVisual", action.error.message);
    },
    [getVisualMenus.pending]: (state, action) => {
      state.status = "loading";
    },
    [getVisualMenus.fulfilled]: (state, { payload }) => {
      if (payload!= null && Array.isArray(payload) && payload.length > 0) {
        const language = localStorage.getItem("language");
        state.menuItems = payload;
        var tempArray = [
          {
            displayName: language == "en" ? "Full Logic" : "Komplet logik",
            groupName: language == "en" ? "Full Logic" : "Komplet logik",
            name: "Full Logic",
            value: language == "en" ? "Full Logic" : "Komplet logik",
          },
        ];
        for (let i = 0; i < payload.length; i++) {
          for (let j = 0; j < payload[i].value.length; j++) {
            tempArray.push({
              displayName: payload[i].value[j].name + " " + payload[i].value[j].value,
              groupName: language == "en" ? payload[i].name : i == 0 ? "MDC Specifikationer" : i == 1 ? "Øvrige Specifikationer" : "Type",
              name: payload[i].value[j].name,
              value: payload[i].value[j].value,
            });
          }
        }
        state.visualMenuCollection = tempArray;
      }else{
        state.visualMenuCollection = [];
      }
    },
    [getVisualMenus.rejected]: (state, action) => {
      state.visualMenuCollection = [];
      state.status = "failed";
      console.log("Error from getVisualMenus", action.error.message);
    },
    [getZipFile.pending]: (state, action) => {
      state.status = "loading";
    },
    [getZipFile.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getZipFile:", payload);
      if (payload.type == "application/json") {
        state.fileNotExists = true;
      } else {
        state.blobToDownload = payload;
      }
    },
    [getZipFile.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getZipFile", action.error.message);
    },
    [getMetadata.pending]: (state, action) => {
      state.status = "loading";
      state.isLoading = true;
    },
    [getMetadata.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getMetadata:", payload);
      state.metadataCollection = payload;
      state.isLoading = false;
    },
    [getMetadata.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getMetadata", action.error.message);
    },
    [getNodesJSON.pending]: (state, action) => {
      state.status = "loading";
      state.isNodeData = false;
    },
    [getNodesJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getNodesJSON:", payload);
      state.dataForGoJs = payload;
      state.isNodeData = true;
    },
    [getNodesJSON.rejected]: (state, action) => {
      state.status = "failed";
      state.isNodeData = false;
      console.log("Error from getNodesJSON", action.error.message);
    },
    [getDRGPriceJSON.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGPriceJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGPriceJSON:", payload);
      var grpCodes = JSON.parse(payload);
      var count = 0;
      var priceArray = [];
      grpCodes.forEach((grp) => {
        let g = { code: grp.Code, desc: grp.Text };
        priceArray.push(g);
      });
      //console.log("priceArray:", priceArray);
      state.priceArray = priceArray;
      if (state.tabValue == "drg") {
        state.drgPriceInfoCollection = priceArray;
      }
      //store.dispatch(getMetadataForLogicName());
    },
    [getDRGPriceJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getDRGPriceJSON", action.error.message);
    },
    [getMetadataForLogicName.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMetadataForLogicName.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getMetadataForLogicName:", payload);
      state.drgLogicID = payload.id;
      state.egenskaberName = payload.egenskaberMaster.name;
      state.egenskaberVersion = payload.egenskaberMaster.version;
      state.sksName = payload.sksMaster.name;
      state.sksVersion = payload.sksMaster.version;
      state.mdcname = payload.mdcspecificationMaster.name;
      state.mdcversion = payload.mdcspecificationMaster.version;
      if (state.subCode.startsWith("12")) {
        state.sCode = state.subCode.replace("12", "98");
      } else if (state.subCode.startsWith("13")) {
        state.sCode = state.subCode.replace("13", "98");
      }
    },
    [getMetadataForLogicName.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getMetadataForLogicName", action.error.message);
    },
    [getDRGPopUpJSON.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGPopUpJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGPopUpJSON:", payload);

      var grpCodes = JSON.parse(payload);
      var groupcodeArray = [];
      grpCodes.forEach((grp) => {
        var links = grp.Text.split(" / ");
        let g = { code: grp.Code, desc: grp.Text };
        groupcodeArray.push(g);
      });
      state.groupcodeArray = groupcodeArray;
      if (state.tabValue == "drg") {
        state.drgGroupCodeCollection = groupcodeArray;
      }
      var strData = "";
      if (state.groupCodeValue.trim().startsWith("-")) {
        strData = state.groupCodeValue.trim().substr(1);
      } else {
        strData = state.groupCodeValue.trim();
      }
      const dataForModalDialog = {
        groupCodeHeader: state.subCode,
        groupCodeValue: strData.replace(state.subCode, ""),
        groupCodeInfo: state.groupcodeArray,
        priceInfo: state.priceArray,
        visualid: state.selectedVisualID,
        year: state.selectedVisualYear,
      };
      state.dataForModalDialog = dataForModalDialog;

      //console.log("dataForModalDialog:", state.dataForModalDialog);
    },
    [getDRGPopUpJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getDRGPopUpJSON", action.error.message);
    },
    [getMDCGroupCodeJSON.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMDCGroupCodeJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getMDCGroupCodeJSON:", payload);
      var grpCodes = JSON.parse(payload);
      var groupcodeArray = [];
      grpCodes.forEach((grp) => {
        let g = { code: grp.Code, desc: grp.Text };
        groupcodeArray.push(g);
      });
      state.groupcodeArray = groupcodeArray;
      var strData = "";
      if (state.groupCodeValue.trim().startsWith("-")) {
        strData = state.groupCodeValue.trim().substr(1);
      } else {
        strData = state.groupCodeValue.trim();
      }
      const dataForModalDialog = {
        groupCodeHeader: state.subCode,
        groupCodeValue: strData.replace(state.subCode, ""),
        groupCodeInfo: state.groupcodeArray,
      };
      state.dataForModalDialog = dataForModalDialog;
      //console.log("dataForModalDialog:", state.dataForModalDialog);
    },
    [getMDCGroupCodeJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getMDCGroupCodeJSON", action.error.message);
    },
    [getGroupCodeJSON.pending]: (state, action) => {
      state.status = "loading";
      state.isHourGlass = true;
    },
    [getGroupCodeJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getGroupCodeJSON:", payload);
      state.isHourGlass = false;
      var groupcodeArray = [];
      if (state.sCode != "") {
        if (payload != null && payload != [] && payload != "[]") {
          var grpCodes = JSON.parse(payload);
          grpCodes.forEach((grp) => {
            let g = { code: grp.Code, desc: grp.Text };
            groupcodeArray.push(g);
          });
        }
      } else {
        var grpCodes = JSON.parse(payload);
        grpCodes.forEach((grp) => {
          let g = { code: grp.Code, desc: grp.Text };
          groupcodeArray.push(g);
        });
      }
      state.groupcodeArray = groupcodeArray;
      var strData = "";
      if (state.groupCodeValue.trim().startsWith("-")) {
        strData = state.groupCodeValue.trim().substr(1);
      } else {
        strData = state.groupCodeValue.trim();
      }
      const dataForModalDialog = {
        groupCodeHeader: state.subCode,
        groupCodeValue: strData.replace(state.subCode, ""),
        groupCodeInfo: state.groupcodeArray,
      };
      state.dataForModalDialog = dataForModalDialog;
      //console.log("dataForModalDialog:", state.dataForModalDialog);
    },
    [getGroupCodeJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getGroupCodeJSON", action.error.message);
    },
    [getshowTariff.pending]: (state, action) => {
      state.status = "loading";
    },
    [getshowTariff.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getshowTariff:", payload);
      if (payload) {
        state.isShowTariff = payload;
      }
    },
    [getshowTariff.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getshowTariff", action.error.message);
    },
    [getDRGTypeSpecifications.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGTypeSpecifications.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGTypeSpecifications:", payload);
      if (payload) {
        state.drgTypeTree = payload.typeTree;
        state.drgSpecificationsTree = payload.specificationTree;
      }
    },
    [getDRGTypeSpecifications.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getDRGTypeSpecifications", action.error.message);
    },
    [getDRGTypeSpecificationsSearch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGTypeSpecificationsSearch.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGTypeSpecificationsSearch:", payload);
      if (payload) {
        state.drgSearchTree = payload.specificationTree;
      }
    },
    [getDRGTypeSpecificationsSearch.rejected]: (state, action) => {
      state.status = "failed";
      console.log(
        "Error from getDRGTypeSpecificationsSearch",
        action.error.message
      );
    },
    [getDRGDiagramData.pending]: (state, action) => {
      state.status = "loading";
      state.isDrgData = false;
    },
    [getDRGDiagramData.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGDiagramData:", payload);
      state.drgDataForGoJs = payload;
      state.isDrgData = true;
    },
    [getDRGDiagramData.rejected]: (state, action) => {
      state.status = "failed";
      state.isDrgData = false;
      console.log("Error from getDRGDiagramData", action.error.message);
    },
    [getAllParentSKS.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllParentSKS.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getAllParentSKS:", payload);
      if (!state.hasChildren) {
        state.sksParentCollection = payload;
      } else {
        state.sksChildrenCollection = payload;
      }
    },
    [getAllParentSKS.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getAllParentSKS", action.error.message);
    },
    [getSKSSearch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSKSSearch.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getSKSSearch:", payload);
      if (payload) {
        state.sksSearchTree = payload;
      }
    },
    [getSKSSearch.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getSKSSearch", action.error.message);
    },
    [getSKSTable.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSKSTable.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getSKSTable:", payload);
      if (payload) {
        state.sksMapData = payload;
      }
    },
    [getSKSTable.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getSKSTable", action.error.message);
    },
    [getSKSCodeDiagramData.pending]: (state, action) => {
      state.status = "loading";
      state.isDrgData = false;
    },
    [getSKSCodeDiagramData.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getSKSCodeDiagramData:", payload);
      state.drgDataForGoJs = payload;
      state.isDrgData = true;
    },
    [getSKSCodeDiagramData.rejected]: (state, action) => {
      state.status = "failed";
      state.isDrgData = false;
      console.log("Error from getSKSCodeDiagramData", action.error.message);
    },
    [getCountries.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      // console.log("Countries", payload);
      state.countries = payload;
      state.status = "success";
    },
    [getCountries.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getCountries", action.error.message);
    },
    [GetAllProductionVisual.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetAllProductionVisual.fulfilled]: (state, { payload }) => {
      if (payload.length > 0) {
        state.isValidData = true;
        state.visualCollection = payload;
        state.yearsCollection = payload
          .map((item) => item.year)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((one, two) => (one > two ? -1 : 1));
        var currentYear = new Date().getFullYear();
        let upcomingYear = currentYear + 1;
        let previousYear = currentYear - 1;
        if (state.yearsCollection.includes(JSON.stringify(upcomingYear))) {
          state.selectedVisualYear = JSON.stringify(upcomingYear);
        } else if (
          state.yearsCollection.includes(JSON.stringify(currentYear))
        ) {
          state.selectedVisualYear = JSON.stringify(currentYear);
        } else if (
          state.yearsCollection.includes(JSON.stringify(previousYear))
        ) {
          state.selectedVisualYear = JSON.stringify(previousYear);
        } else {
          state.selectedVisualYear = state.yearsCollection[state.yearsCollection.length - 1];
        }
        var currentYearObj = payload.filter((a) => a.year == state.selectedVisualYear)[0];
        console.log(currentYearObj);
        state.selectedVisualObj = currentYearObj;
        state.selectedVisualID = currentYearObj.id;
        state.selectedVisualName = currentYearObj.visualName;
        // state.selectedVisualYear = currentYearObj.year;
        state.enableTabs = true;
        state.status = "success";
      } else {
        state.isInvalidData = true;
      }
    },
    [GetAllProductionVisual.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from GetAllProductionVisual", action.error.message);
    },
    [GetVisualFrontPageContent.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetVisualFrontPageContent.fulfilled]: (state, { payload }) => {
      if (payload.length > 0 && payload[0].languageValue != "") {
        state.viewerFrontPageContent = payload[0].languageValue;
      }
      else {
        state.viewerFrontPageContent = "";
      }
    },
    [GetVisualFrontPageContent.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getVisualNodesJSON", action.error.message);
    },
    [GetVisualDatabyId.pending]: (state, action) => {
      state.status = "loading";
    },
    [GetVisualDatabyId.fulfilled]: (state, { payload }) => {
      if (payload!= null && payload.length > 0) {
        state.visualCollection = payload.filter(item => item.status == 3);
        if (state.visualCollection.length > 0) {
          state.isValidData = true;
          var viewerObj = state.visualCollection[0];
          state.selectedVisualObj = viewerObj;
          state.selectedVisualID = viewerObj.id;
          state.selectedVisualName = viewerObj.visualName;
          state.selectedVisualYear = viewerObj.year;
          state.enableTabs = true;
          state.status = "success";
        } else {
          state.isInvalidData = true;
        }
      } else {
        state.isInvalidData = true;
      }
    },
    [GetVisualDatabyId.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from GetVisualDatabyId", action.error.message);
    },
  },
});

export const {
  setSelectedVisual,
  setTabValue,
  setVisualDetails,
  setSelectedSpecification,
  setSelectedFileToDownload,
  clearValues,
  errorMessageClose,
  setView,
  testCall,
  saveCodeValues,
  setModalActive,
  setDRGSelected,
  setDRGLoading,
  setDRGDiagramValues,
  setChildrenNode,
  setSKSSelected,
  endUserSession,
  setEnvironment,
  setPanel,
  setResetValues,
  resetSKS,
  resetViewer,
  setPagination,
} = publicViewerSlice.actions;
export default publicViewerSlice.reducer;
