import React from "react";
import "./Interactive.css";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector, useDispatch } from "react-redux";
import {
  setChildrenStatus,
  getAllProcedures,
  setSelectionButton,
  setProcedureData,
} from "./publicInteractiveSlice";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      expandIcon={<ArrowRightIcon />}
      collapseIcon={<ArrowDropDownIcon />}
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          {labelInfo === "Selected" ? (
            <DoneIcon fontSize="small" color="inherit" sx={{ pr: "5px" }} />
          ) : null}
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const { useState, useCallback } = React;

function MyTreeItem(props) {
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();
  const interactive = useSelector((state) => state.publicInteractive);
  const { procedure, procedureData } = interactive;
  const { id, name, node } = props;

  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter((x) => !expanded.includes(x));
    setExpanded(nodes);
    if (expandingNodes[0]) {
      const parentId = expandingNodes[0];
      if (node.hasChildren) {
        dispatch(setChildrenStatus({ name: "procedures", value: true }));
        dispatch(getAllProcedures({ method: "GET", parentId: parentId })).then(
          (res) => {
            setChildNodes(
              res ?.payload ?.map((node) => (
                <MyTreeItem
                  key={node.id}
                  node={node}
                  id={node.id}
                  name={node.name}
                  {...node}
                />
              ))
            );
          }
        );
      }
    }
  };
  const handleTreeSelection = (obj) => () => {
    if (obj.isSelectable == true || obj.isSelectable.toLowerCase() == "true") {
      dispatch(setSelectionButton({ name: "procedure", value: false }));
      if (procedure.length > 0) {
        dispatch(setSelectionButton({ name: "procAdditional", value: false }));
      }
      dispatch(setProcedureData(obj));
    } else {
      dispatch(setSelectionButton({ name: "procedure", value: true }));
      dispatch(setSelectionButton({ name: "procAdditional", value: true }));
    }
  };
  let labelInfo = "";
  let bgColor = "#fff";
  if (node.isSelectable.toLowerCase() && id === procedureData.id) {
    bgColor = "#e8f0fe";
  } else {
    bgColor = "#fff";
  }

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      expanded={expanded}
      onNodeToggle={handleChange}
      sx={{ width: "98%" }}
    >
      {/*The node below should act as the root node for now */}
      <StyledTreeItem
        key={id}
        nodeId={id}
        labelText={name}
        labelInfo={node.isLeaf.toLowerCase() == "true" ? labelInfo : ""}
        color="#1a73e8"
        bgColor={bgColor}
        expandIcon={<ArrowRightIcon />}
        collapseIcon={<ArrowDropDownIcon />}
        onClick={handleTreeSelection(node)}
      >
        {node.isLeaf.toLowerCase() == "true"
          ? childNodes
          : childNodes || [<div key="stub" />]}
      </StyledTreeItem>
    </TreeView>
  );
}

export default function ProcedureTreeView(props) {
  const dispatch = useDispatch();
  const interactive = useSelector((state) => state.publicInteractive);
  const { proceduresParentCollection, procedureSearchTree, procedureSearch } =
    interactive;
  if (props.search && procedureSearch) {
    return (
      <div className="diagnosis-tree-container">
        {procedureSearchTree ?.map((b) => {
          return <MyTreeItem key={b.id} id={b.id} name={b.name} node={b} />;
        })}
      </div>
    );
  } else {
    return (
      <div className="diagnosis-tree-container">
        {proceduresParentCollection ?.map((b) => {
          return <MyTreeItem key={b.id} id={b.id} name={b.name} node={b} />;
        })}
      </div>
    );
  }
}
