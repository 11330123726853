import React, { useState, useEffect } from "react";
import "./Viewer.css";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import GridOnIcon from "@mui/icons-material/GridOn";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalActive, getshowTariff, setSelectedSpecification, setDRGDiagramValues } from "./publicViewerSlice";
import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import PdfIcon from "../../../assets/icons/PdfIcon.svg";
import ExcelIcon from "../../../assets/icons/ExcelIcon.svg";
import Currency from 'react-currency-formatter';
import Link from "@mui/material/Link";

export default function NodeModal() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const viewer = useSelector((state) => state.publicViewer);
  const {
    groupCode,
    groupCodeValue,
    dataForModalDialog,
    modalActive,
    selectedVisualID,
    isShowTariff,
    menuItems,
    selectedSpecification,
    subCode,
    visualMenuCollection,
  } = viewer;
  const [items, setItems] = useState(dataForModalDialog ?.groupCodeInfo);
  const [date, setDate] = useState("");
  const [mdcDescription, setMdcDescription] = useState("");
  const { language } = useSelector((state) => state.language);

  const handleClose = () => {
    dispatch(setModalActive(false));
  };

  const saveAsPDF = () => {
    // initialize jsPDF
    const doc = new jsPDF();
    // define the columns we want and their titles
    const tableColumn = [language == "dk" ? "Kode" : "Code", language == "dk" ? "Beskrivelse" : "Description"];
    // define an empty array of rows
    const tableRows = [];
    items.forEach((item) => {
      const dataToPrint = [item.code, item.desc];
      tableRows.push(dataToPrint);
    });
    //doc.setFont("Montserrat");
    doc.setFontSize(14);
    //title. and margin-top + margin-left
    doc.text(groupCodeValue, 14, 15);
    // startY is margin-top
    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save(`VisualDRG.pdf`);
  };

  const exportToExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(items);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "exportedData");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "VisualDRG-Excel.xlsx");
  };

  const handlePrint = () => {
    let popupWin;
    let printContentBody = document.getElementById("generateTable").innerHTML;
    popupWin = window.open("", "_blank");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <h2>  ${date} </h2>
          <title>Visual DRG</title>
           
          <style>          
          </style>      
          <div style="border: 1px solid black;padding-left:10px;">    
            <h2>${groupCodeValue}</h2>            
          </div>
        </head>
        <body onload="window.print();window.close()"> 
          <div style="border: 1px solid black;padding-left:10px">
            ${printContentBody}
          </div>
        </body>
      </html>`);
    popupWin.document.close();
  };

  useEffect(() => {
    dispatch(getshowTariff({ visualID: selectedVisualID, method: "GET" }));
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    setDate(dd + "/" + mm + "/" + yyyy);
  }, []);

  const requestSearch = (event) => {
    const filteredItems = dataForModalDialog ?.groupCodeInfo.filter((item) => {
      return (
        item.code ?.toLowerCase().includes(event.target.value ?.toLowerCase()) ||
          item.desc ?.toLowerCase().includes(event.target.value ?.toLowerCase())
      );
    });
    setItems(filteredItems);
  };

  const drgDescClick = (drg, desc) => {
    // dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    // dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
    dispatch(setModalActive(false));
    var link = 0;
    var mdcCode = desc.substring(0, 2);
    var regel = drg.code.replace("REGEL", "").trim();
    if ((desc.includes("EX")) && (mdcCode >= 1 && mdcCode <= 27)) link = 2;
    else if (mdcCode > 27) link = 1;
    else if (mdcCode >= 1 && mdcCode <= 27) link = 1;
    else link = 3;
    var selectedSpecification = visualMenuCollection.filter(
      (a) => a.name == mdcCode
    );
    var result = selectedSpecification.map(obj => ({ ...obj, language: language }));
    dispatch(setSelectedSpecification(result[0]));
    let view = "";
    if (link == 2) {
      view = "AdvancedView";
    } else {
      view = "SimpleView";
    }
    dispatch(
      setDRGDiagramValues({ view: view, search: subCode, regel: regel })
    );
  };

  function showPriceInfo() {
    if (dataForModalDialog ?.priceInfo ?.length > 0) {
      var mdcDescription = "";
      var linkGroupCode = [];
      let formattedData = "";
      var mdcCode = dataForModalDialog ?.groupCodeHeader.substring(0, 2);
      if (mdcCode > 27) {
        mdcDescription = menuItems[1]["value"].filter(
          (a) => a.name == mdcCode
        )[0].value;
      } else {
        mdcDescription = menuItems[0]["value"].filter(
          (a) => a.name == mdcCode
        )[0].value;
      }

      var codeInfo = dataForModalDialog ?.groupCodeInfo;
      // for (let i = 0; i < codeInfo ?.length; i++) {
      //   formattedData =
      //     codeInfo[i].desc.split(" / ")[0] + " - " + mdcDescription;
      //   let count = codeInfo[i].desc.split(" / ").length;
      //   for (let j = 1; j < count; j++) {
      //     if (codeInfo[i].desc.split(" / ")[j] != "") {
      //       formattedData =
      //         formattedData + " / " + codeInfo[i].desc.split(" / ")[j];
      //     }
      //   }
      //   linkGroupCode.push({
      //     code: codeInfo[i].code,
      //     desc: formattedData,
      //   });
      // }
      for (let i = 0; i < codeInfo ?.length; i++) {
        var descCollection = [];
        descCollection.push({
          id: 1,
          formattedData:
            codeInfo[i].desc.split(" / ")[0] + " - " + mdcDescription,
          item: codeInfo[i],
          linkValue: codeInfo[i].desc.split(" / ")[0],
        });
        let count = codeInfo[i].desc.split(" / ").length;
        for (let j = 1; j < count; j++) {
          if (codeInfo[i].desc.split(" / ")[j] != "") {
            descCollection.push({
              id: j + 1,
              formattedData: codeInfo[i].desc.split(" / ")[j],
              item: codeInfo[i],
              linkValue: codeInfo[i].desc.split(" / ")[j],
            });
          }
        }
        linkGroupCode.push({
          id: i,
          item: codeInfo[i],
          code: codeInfo[i].code,
          desc: descCollection,
        });
      }
      return (
        <div id="dataToPrint">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              textAlign: "left",
            }}
          >
            {dataForModalDialog ?.priceInfo.map((x) => {
              let infoText;
              if (x.code == "Takst") {
                infoText = t("viewer.tariff");
              } else if (x.code == "Trimpunkt") {
                infoText = t("viewer.averageStay");
              } else if (x.code == "Langliggertakst") {
                infoText = t("viewer.thresholdRate");
              }
              return (
                <div>
                  <ListItem key={x.code}>
                    <ListItemText
                      edge="start"
                      className="modal-infoText"
                      sx={{ width: "90%" }}
                      primary={infoText}
                    />
                    <ListItemText
                      edge="start"
                      className="modal-infoText"
                      sx={{ width: "70%" }}
                      primary={isShowTariff ? x.code == "Trimpunkt" ? x.desc.trim() : <Currency quantity={x.desc.trim()} currency="DKK" symbol="" locale={language == "en" ? "en" : "da_DK"} />: " "}
                    />
                  </ListItem>
                </div>
              );
            })}
          </List>
          <Typography className="drg-rules-count" gutterBottom>
            {t("Global.Total_Rules")} : {linkGroupCode ?.length}
          </Typography>
          {/* <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              textAlign: "left",
            }}
          >
            {linkGroupCode ?.map((x) => {
              return (
                <div>
                  <ListItem key={x.code}>
                    <ListItemText
                      edge="start"
                      className="modal-infoText"
                      sx={{ width: "30%" }}
                      primary={x.code.trim()}
                    />

                    <ListItemText
                      edge="start"
                      className="modal-infoText"
                      sx={{ width: "70%" }}
                      primary={x.desc.trim()}
                    />
                  </ListItem>
                </div>
              );
            })}
          </List> */}
          <table>
            {linkGroupCode.map((row) => {
              return (
                <tr key={row.id}>
                  <td width="300px">
                    <ListItemText
                      edge="start"
                      className="modal-infoText"
                      sx={{ width: "30%" }}
                      primary={row.code}
                    />
                  </td>
                  <td width="500px">
                    {row.desc.map((a) => {
                      return (
                        <>
                          <Link
                            component="button"
                            variant="body2"
                            className="drg-groupCode-linkContent"
                            onClick={() => {
                              drgDescClick(a.item, a.linkValue);
                            }}
                          >
                            {a.formattedData}
                          </Link>
                          { row.desc.indexOf(a) < row.desc.length - 1 && ( <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>)}
                        </>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      );
    } else {
      return (
        <div id="dataToPrint">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              textAlign: "left",
            }}
          >
            {items ?.map((x) => {
              return (
                <div>
                  <ListItem key={x.code}>
                    <ListItemText
                      edge="start"
                      className="modal-infoText"
                      sx={{ width: "15%" }}
                      primary={x.code.trim()}
                    />
                    <ListItemText
                      edge="start"
                      className="modal-infoText"
                      sx={{ width: "85%" }}
                      primary={x.desc.trim()}
                    />
                  </ListItem>
                </div>
              );
            })}
          </List>
        </div>
      );
    }
  }

  return (
    <div>
      <Dialog
        open={modalActive}
        onClose={handleClose}
        className="viewer-modal-dialog"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <div className="modal-header-section">
            <div className="modal-header-left">
              <Typography id="headerLabel" className="viewer-text" gutterBottom>
                {groupCodeValue}
              </Typography>
            </div>
            <div className="modal-header-right">
              <Typography className="modal-info-countText" gutterBottom>
                {"  " +
                  t("Global.total_codes") + " : " +
                  dataForModalDialog ?.groupCodeInfo ?.length}
              </Typography>
              <IconButton onClick={saveAsPDF}>
                {" "}
                {/* <PictureAsPdfIcon sx={{ paddingRight: "2px" }} /> */}
                <img
                  src={PdfIcon}
                  alt="PDF-Icon"
                  sx={{ paddingRight: "2px" }}
                />
              </IconButton>
              <IconButton onClick={exportToExcel}>
                {" "}
                {/* <GridOnIcon sx={{ paddingRight: "2px" }} /> */}
                <img
                  src={ExcelIcon}
                  alt="Excel-Icon"
                  sx={{ paddingRight: "2px" }}
                />
              </IconButton>
              <IconButton onClick={handlePrint}>
                {" "}
                <PrintIcon sx={{ paddingRight: "2px" }} />
              </IconButton>

              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {dataForModalDialog ?.priceInfo != null ? ("") : (
            <TextField
              variant="outlined"
              placeholder={t("Global.search")}
              onChange={requestSearch}
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
              }}
            />
          )}
          {showPriceInfo()}
        </DialogContent>
      </Dialog>
      <div id="generateTable" style={{ display: "none" }}>
        <table className="myprint">
          {items ?.map((x) => {
            return (
              <tr>
                <td>
                  {x.code}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td>{x.desc}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}
