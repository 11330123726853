
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import * as React from 'react';
import { DiagramProps } from './viewerModel'
import './Diagram.css';
import "./Viewer.css";
import Modal from 'react-modal';
import { store } from "../../../app/store";
import { testCall, saveCodeValues, getDRGPriceJSON, getMetadataForLogicName, getDRGPopUpJSON, getMDCGroupCodeJSON, getGroupCodeJSON, setModalActive } from "./publicViewerSlice";
import NodeModal from './NodeModal';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { withTranslation } from 'react-i18next';

const customStyles = {
    content: {
        top: '13%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        position: 'absolute',
        background: 'lightYellow',
        border: '1px solid'
    },
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


 class DiagramWrapper extends React.Component {
    /**
     * Ref to keep a reference to the Diagram component, which provides access to the GoJS diagram via getDiagram().
     */
    diagramRef;
    searchNodesArray = [];
    myDiagram;
    myWholeModel;
    state;
    popupText = "";

    /** @internal */
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            modalIsOpen: false,
            setIsOpen: false,
            metadataCollection: [],
            groupCode: '',
            groupCodeValue: '',
            tableData: [],
            page: 0,
            rowsPerPage: 5,
            expanded: "Show",
            regelValue: this.props.regelValue,
            searchVal: this.props.searchValue
        };
        this.updateInputValue = this.updateInputValue.bind(this);
        this.showModalDialog = this.showModalDialog.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.diagramRef = React.createRef();
    }

    componentDidMount() {
        this.invokeSearch(this);
        let value;
        store.subscribe(function () {
            let state = store.getState();
            value = state.publicViewer;
            if (!value.modalActive) {
                var btn = document.getElementById("mdCloseBtn");
                if (btn != null) {
                    btn.click();
                }
            }
        });

    }

    /**
     * Diagram initialization method, which is passed to the ReactDiagram component.
     * This method is responsible for making the diagram and initializing the model, any templates,
     * and maybe doing other initialization tasks like customizing tools.
     * The model's data should not be set here, as the ReactDiagram component handles that.
     */
    initDiagram() {
        const $ = go.GraphObject.make;

        function showMessage(str, groupcode, yellowbox, groupCodeVal) {

            if (!yellowbox) {
                document.getElementById("inputEventsMsg").textContent = groupcode;
                document.getElementById("infoBoxHolder").textContent = groupCodeVal;
                // console.log(groupCodeVal);
                var btn = document.getElementById("mdBtn");
                if (btn != null) {
                    btn.click();
                }
            }
        }

        function OffsetLink() {
            go.Link.call(this);
          }
          go.Diagram.inherit(OffsetLink, go.Link);
          OffsetLink.prototype.computeMidOrthoPosition = function (
            fromX,
            fromY,
            toX,
            toY,
            vertical
          ) {
            if (vertical) return toY - 15;
            return go.Link.prototype.computeMidOrthoPosition.call(
              this,
              fromX,
              fromY,
              toX,
              toY,
              vertical
            );
          };

        go.Shape.defineFigureGenerator("TipOutline", function (sh, w, h) {
            var alen = 10;  // horizontal length of pointer
            var t = h / 8;  // breadth (height) of pointer
            var geo = new go.Geometry()
                .add(new go.PathFigure(0, 0, true, true)  // filled and shadowed
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, 0))
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, h / 2 - t))
                    .add(new go.PathSegment(go.PathSegment.Line, w, h / 2))
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, h / 2 + t))
                    .add(new go.PathSegment(go.PathSegment.Line, w - alen, h))
                    .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
            geo.spot2 = new go.Spot(1, 1, -alen, 0);  // content should not overlap right side
            return geo;
        });
        // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
      // go.Diagram.licenseKey = "73f14fe5b00537c702d90776423d6af919a475609b8417a30b0446f6ef083f1c249cee7101d6d8c6d1fc1efb4f7fc7cbcdc3617ac24c556be76583de47e284a9b73275bb15084788f4032ec5c9f82ef2ab7c79a596b77ea58a2d8ca7ecadc2cc0efae3d21d9a08bb7b280723467dae49b7e4d964fa03";
       // go.Diagram.licenseKey =
     //"2bf843e4b76658c511d35a25403e7efb0fab2d3bcf804df7590417a3ec0a3d12229dbd2b51d38cc1d2ab49f81974908dd9c53e7c944f033bb231868e47b6d5ffb63372b315581789f70122c68bea2da9af2d71f791e620a2d87e8cf6e2acc6cb55ebf7d44e9a0aeb2e2d04630676ab4be2f8da7ffe50994f3e3f9da4a9eaab4cfb697280ceec0781eb5977d8baeaa60c247405cf3ca82ca61b6411d9cc43e92be460eca88839";
    go.Diagram.licenseKey = "2bf843e4b76658c511d35a25403f7efb0fab2d3bcf804df7590417a3ec0a3d12229dbd2b51d38dc1d2ab49f9086dc6d18e906979c74c516be535d3de13e783ffbb3673b212584786a25324c7caad2ea9ab2c73fbc5e522a28f7f89f3bbfad18c5abda3d248985eba3b6805305676b04ea8f8dc";

       const diagram =
            $(go.Diagram,
                {
                    // 'undoManager.isEnabled': true,  // must be set to allow for model change listening        
                    // 'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },        
                    layout: $(go.Layout,
                        { isInitial: false, isOngoing: false }),
                    model: $(go.GraphLinksModel,
                        {
                            linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                            // positive keys for nodes
                            makeUniqueKeyFunction: (m, data) => {
                                let k = data.key || 1;
                                while (m.findNodeDataForKey(k)) k++;
                                data.key = k;
                                return k;
                            },
                            // negative keys for links
                            makeUniqueLinkKeyFunction: (m, data) => {
                                let k = data.key || -1;
                                while (m.findLinkDataForKey(k)) k--;
                                data.key = k;
                                return k;
                            }
                        })
                });

        // define a simple Node template
        diagram.nodeTemplate =

            $(go.Node, "Auto",
                { visible: true, movable: false, deletable: false, locationSpot: go.Spot.Center, alignment: go.Spot.Center, },
                { resizable: false },
                { isShadowed: true },
                {
                    selectionAdornmentTemplate:
                        $(go.Adornment, "Spot",
                            $(go.Panel, "Auto",
                                // this Adornment has a rectangular blue Shape around the selected node
                                $(go.Shape, { fill: null, stroke: "dodgerblue", strokeWidth: 3, strokeDashArray: [4, 2] })
                                , $(go.Placeholder)
                            )

                        )  // end Adornment
                },

                $(go.Panel, "Horizontal",
                    {
                        visible: true, alignment: go.Spot.Center, defaultAlignment: go.Spot.Center
                    },
                    $(go.Shape,
                        { name: "SHAPE", visible: true, alignment: go.Spot.Center },
                        new go.Binding("cursor", "cursor"),
                        new go.Binding("geometryString", "fig"),
                        new go.Binding("figure", "fig"),
                        new go.Binding("width", "width"),
                        new go.Binding("height", "height"),
                        new go.Binding("fill", "color"),
                        new go.Binding("stroke", "isHighlighted", function (h) { return h ? "#0d0033" : "black"; }).ofObject(),
                        //new go.Binding("strokeWidth", "isHighlighted", function(h) { return h ? 2 : 0 }).ofObject(),
                        new go.Binding("strokeDashArray", "isHighlighted", function (h) { return h ? [4, 2] : [0, 0]; }).ofObject())
                ),
                new go.Binding("position", "bounds", function (b) { return b.position; })
                    .makeTwoWay(function (p, d) { return new go.Rect(p.x, p.y, d.bounds.width, d.bounds.height); }),

                {
                    click: function (e, obj) {


                        var data = obj ?.part ?.data;
                        //console.log("Node Data:", data);
                        showMessage(data.text, data.groupCode, data.yellowbox, data.groupCodeValue);
                        // var node = myDiagram.findNodeForKey(obj.part.data.key);
                    }
                },

                $(go.TextBlock,
                    { margin: 1, font: "normal 9px Montserrat",stretch: go.GraphObject.Horizontal, maxSize: new go.Size(120, NaN), textAlign: "center", alignment: go.Spot.Center, overflow: go.TextBlock.OverflowEllipsis, maxLines: 5 },
                    new go.Binding("cursor", "cursor"),
                    new go.Binding("text", "text")),
                {
                    toolTip:
                        // myToolTip
                        $(go.Adornment, "Spot",
                            new go.Binding("visible", "tooltip", function (t, obj) { return (t.length >= 0 && obj.data.leafnode) }),
                            { isShadowed: true },
                            $(go.Placeholder),
                            $(go.Panel, "Auto",
                                { alignment: go.Spot.TopLeft, alignmentFocus: go.Spot.TopRight, minSize: new go.Size(140, NaN) },
                                $(go.Shape, "TipOutline",
                                    { fill: "white", stroke: "gray", strokeWidth: 0.5, shadowVisible: true }),
                                $(go.Panel, "Table",
                                    $(go.RowColumnDefinition, { row: 0, background: "#F2F1EC" }),
                                    $(go.TextBlock,
                                        { row: 0, margin: 10, stretch: go.GraphObject.Horizontal, font: "normal 12px Montserrat", textAlign: "left", minSize: new go.Size(140, NaN) },
                                        new go.Binding("text", "rownumber")),
                                    $(go.TextBlock,
                                        { row: 1, margin: 10, alignment: go.Spot.Left, font: "normal 12px Montserrat", textAlign: "left", minSize: new go.Size(140, NaN), visible: false },
                                        new go.Binding("text", "tooltip"),
                                        new go.Binding("visible", "tooltip", function (t) { return !!t; })
                                    )
                                )
                            )
                        )
                }
            );

        // relinking depends on modelData
        diagram.linkTemplate =

            $(OffsetLink,
                { routing: go.Link.Orthogonal, corner: 10 },
                // { fromSpot: go.Spot.Bottom, toSpot: go.Spot.Top },
                new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
                new go.Binding("toSpot", "toSpot", go.Spot.parse),
                $(go.Shape),
                $(go.Shape, { toArrow: "OpenTriangle" })
            );

        return diagram;
    }

    // called whenever on change of search text
    async searchMyDiagram(elm, evt) {
        if (evt.target.value!= null && evt.target.value!= "" ) {
            await this.setState({
                searchValue: evt.target.value
            });
            elm.increment = 0;
            this.searchDiagram(elm.regel);
            if (elm.increment > this.searchNodesArray.length) elm.increment = 1;
            var inc = ++elm.increment;
            if (inc > this.searchNodesArray.length) inc = 1;

            //document.getElementById("counter").textContent = (inc).toString() + " of " + this.searchNodesArray.length.toString();
            if (inc == this.searchNodesArray.length) { this.searchDiagramExtended(this.searchNodesArray.length - 1) }
            else { this.searchDiagramExtended(inc - 1); }
        }
        else if (evt.target.value == "") {
            this.closeSearch(this);
        }
    }

    searchDiagram(regel) {
        const diagram = this.diagramRef ?.current ?.getDiagram();
        var pt = diagram ?.position;
        var n = null;
        this.searchNodesArray = [];

        diagram ?.startTransaction("highlight search");
        if (regel == "close") {
            diagram ?.clearHighlighteds();
            diagram ?.clearSelection();
        }
        var xpos = 0;
        var ypos = 0;
        let multipleSelectData = [];
        var ndata = this.props.nodeDataArray;
        if (regel != undefined && regel.length > 0) {
            // var regex1 = new RegExp(input.innerText, "i");
            // var alpha1 = diagram.findNodesByExample({regel : regex1});
        }
        else {

            var regex = new RegExp(this.state.searchValue, "i");
            multipleSelectData = diagram ?.findNodesByExample({ text: regex });
            //console.log(multipleSelectData.count);
        }

        for (var i = 0; i < ndata.length; i++) {
            n = ndata[i];
            if (regel != undefined && regel.length > 0 && regel != "close") {
                if (n.text.trim().toUpperCase().includes(this.state.searchValue.trim().toUpperCase()) && n.regel == regel) {
                    //n.isSelected = true;
                    this.searchNodesArray.push(n);
                }
            }
            else {
                if (n.text != undefined && n.text != null && this.state.searchValue != undefined && this.state.searchValue != null) {
                    if (n.text.trim().toUpperCase().includes(this.state.searchValue.trim().toUpperCase())) {
                        //n.isSelected = true;
                        this.searchNodesArray.push(n);
                    }
                }
            }
        }
        if (this.searchNodesArray.length > 0) {
            var arrow = document.getElementById("arrows");
            arrow.style.display = "flex";
            var searchresult = document.getElementById("searchresult");
            searchresult.style.display = "inline";
            var searchresultCaption = document.getElementById("searchresultCaption");
            searchresultCaption.style.display = "inline";
            searchresult.textContent = "Result:" + this.searchNodesArray.length;
            searchresult.textContent = this.searchNodesArray.length;
            //  var btnview = document.getElementById("btnview");
            var searchCounter = document.getElementById("counter");
            searchCounter.style.display = "inline";
            var inc = 1;
            document.getElementById("counter").textContent = (inc).toString() + " of " + this.searchNodesArray.length.toString();
            //  btnview.style.display = "block";
            if (regel != undefined && regel.length > 0 && regel != "close") {
                n = this.searchNodesArray[0];
                xpos = n.bounds.x;
                ypos = n.bounds.y;
                diagram.position = new go.Point(Math.floor(xpos), Math.floor(ypos));
                diagram?.select(diagram?.findNodeForKey(n.key));
            }
            else
                //diagram.selectCollection(multipleSelectData);
                diagram ?.highlightCollection(multipleSelectData);
        }
        diagram ?.commitTransaction("highlight search");
        this.searchTable();
    }

    // called by search previous & next button click
    searchDiagramExtended(increment) {
        const diagram = this.diagramRef.current?.getDiagram();
        var n = null;
        diagram?.startTransaction("highlight search");
        var xpos = 0;
        var ypos = 0;
        if (this.state.searchValue) {
            if (this.searchNodesArray.length > 0) {
                for (var i = increment; i <= this.searchNodesArray.length; i++) {
                    n = this.searchNodesArray[i];
                    //console.log(n);
                    // n.isSelected = true;
                    xpos = n.bounds.x;
                    ypos = n.bounds.y;
                    diagram.position = new go.Point(Math.floor(xpos), Math.floor(ypos));
                    diagram?.select(diagram?.findNodeForKey(n.key));
                    break;
                }
            }
        }
        else {  // empty string only clears highlighteds collection
            diagram?.clearHighlighteds();
        }
        diagram?.commitTransaction("highlight search");
    }

    searchDiagramNext(elm) {
        if (elm.increment > this.searchNodesArray.length) elm.increment = 1;
        var inc = ++elm.increment;
        if (inc > this.searchNodesArray.length) inc = 1;
        document.getElementById("counter").textContent = (inc).toString() + " of " + this.searchNodesArray.length.toString();
        if (inc == this.searchNodesArray.length) { this.searchDiagramExtended(this.searchNodesArray.length - 1) }
        else { this.searchDiagramExtended(inc - 1); }
        document.getElementById("counter").style.display = "inline";
    }

    searchDiagramPrevious(elm) {
        if (elm.increment == 0) elm.increment = this.searchNodesArray.length + 1;
        if (elm.increment > this.searchNodesArray.length) elm.increment = this.searchNodesArray.length;
        var inc = --elm.increment;
        if (elm.increment == 0) inc = this.searchNodesArray.length;
        if (inc < 1) inc = 0;
        document.getElementById("counter").textContent = (inc).toString() + " of " + this.searchNodesArray.length.toString();
        if (inc == this.searchNodesArray.length) { this.searchDiagramExtended(this.searchNodesArray.length - 1) }
        else { this.searchDiagramExtended(inc - 1); }
        document.getElementById("counter").style.display = "inline";
    }

    closeSearch(elm) {
        this.searchDiagram('close');
        document.getElementById("arrows").style.display = "none";
        document.getElementById("searchresult").style.display = "none";
        document.getElementById("counter").style.display = "none";
        document.getElementById("searchresultCaption").style.display = "none";
        elm.searchvalue = ' ';
        elm.searchvalue = null;
        this.setState({
            searchValue: '',
            tableData: [],
            page: 0,
            rowsPerPage: 5,
            expanded: "Show",
            searchVal: '',
            regelValue: ''
        });
    }

    updateInputValue(evt) {
        this.setState({
            searchValue: evt.target.value
        });
    }

    searchTable() {
        var tablearray = [];
        for (var i = 0; i < this.searchNodesArray.length; i++) {
            var n = this.searchNodesArray[i];
            let group = "";
            if (n.leafnode) {
                group = "CasemixGroup"
            }
            else {
                group = n.groupCodeText;
            }
            let trvalue = {
                code: n.text.trim().split(" ")[0],
                desc:
                    n.text.trim() + " / " + n.groupCodeText + " / " + n.DiagramRelation,
                leafnode: n.leafnode,
                group: group,
                description: n.text.trim(),
                rule: n.regel,
                id: i
            };
            tablearray.push(trvalue);
        }
        this.setState({
            tableData: tablearray
        });
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });

    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });

    };
    toggleResultsPanel = (event) => {
        if (this.state.expanded == "Hide") {
            this.setState({ expanded: "Show" });
        } else {
            this.setState({ expanded: "Hide" });
        }

    }

    invokeSearch(elm) {

        if (this.state.searchVal != "" && this.state.regelValue != "") {
            this.setState({
                searchValue: this.state.searchVal,
                expanded: "Hide"
            });
            elm.increment = 0;
            this.searchDiagram(this.state.regelValue);
            if (elm.increment > this.searchNodesArray.length) elm.increment = 1;
            var inc = ++elm.increment;
            if (inc > this.searchNodesArray.length) inc = 1;
            if (inc == this.searchNodesArray.length) { setTimeout(() => { this.searchHighlighter(this.searchNodesArray.length - 1); }, 3000); }
            else { setTimeout(() => { this.searchHighlighter(inc - 1); }, 3000); }

        }
    }
    searchHighlighter(increment) {
        const diagram = this.diagramRef.current?.getDiagram();
        var n = null;
        diagram?.startTransaction("highlight search");
        var xpos = 0;
        var ypos = 0;
        if (this.state.searchVal != "") {
            if (this.searchNodesArray.length > 0) {
                for (var i = increment; i <= this.searchNodesArray.length; i++) {
                    n = this.searchNodesArray[i];
                    //console.log(n);
                    // n.isSelected = true;
                    xpos = n.bounds.x;
                    ypos = n.bounds.y;
                    diagram.position = new go.Point(Math.floor(xpos), Math.floor(ypos));
                    diagram?.select(diagram?.findNodeForKey(n.key));
                    break;
                }
            }
        }
        else {  // empty string only clears highlighteds collection
            diagram?.clearHighlighteds();
        }
        diagram?.commitTransaction("highlight search");
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="search-row">
                    <div className="node-search">
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            value={this.state.searchValue}
                            onChange={(e) => this.searchMyDiagram(this, e)}
                            sx={{ width: "100%" }}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        sx={{ color: "#00000040" }}
                                    />
                                ),
                                endAdornment: (
                                    <CancelRoundedIcon fontSize="small" sx={{ color: "#00000040", cursor: "pointer" }} onClick={() => this.closeSearch(this)} />
                                ),
                            }}
                        />

                        <Typography
                            id="searchresultCaption"
                            style={{ display: 'none' }}
                            className="search-result-text"
                            gutterBottom
                        >
                            Total:
                        </Typography>

                        <label id="searchresult" style={{ display: 'none' }} className="form-check-label" > </label>

                    </div>
                    <div className="search-navigation" id="arrows" style={{ display: 'none' }} >

                        <Typography
                            className="search-result-text"
                            gutterBottom
                        >
                            {t("viewer.navigate")} to &nbsp;

                        </Typography>
                        <label id="counter" className="form-check-label"> </label> &nbsp;&nbsp;&nbsp;
                        <ArrowBackIosIcon fontSize="small" id="btnUp" sx={{ cursor: "pointer" }} onClick={() => this.searchDiagramPrevious(this)} />
                        <ArrowForwardIosIcon fontSize="small" id="btnDown" sx={{ cursor: "pointer" }} onClick={() => this.searchDiagramNext(this)} />


                    </div>
                </div>

                < Accordion expanded={this.state.expanded == "Hide"} onChange={this.toggleResultsPanel} >
                    <AccordionSummary aria-controls="tablePanel-content" id="tablePanel-header">
                        <Typography>{this.state.expanded == "Hide"? t("viewer.hide") : t("viewer.show")} {t("viewer.result")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <TableContainer className="viewer-search-table">
                            <Table stickyHeader aria-label="sticky table" className="viewer-search-table">
                                <TableHead className="tableHead">
                                    <TableRow>
                                        <TableCell className="viewer-search-tableCell head" sx={{ width: 600 }}>
                                        {t("viewer.group")}
                                        </TableCell>
                                        <TableCell className="viewer-search-tableCell head" sx={{ width: 400 }}>
                                        {t("viewer.description")}
                                        </TableCell>
                                        <TableCell className="viewer-search-tableCell head" sx={{ width: 400, textAlign: "center" }}>
                                        {t("viewer.rule")}
                                        </TableCell>
                                        <TableCell className="viewer-search-tableCell head" sx={{ width: 400, textAlign: "center" }}>
                                        {t("viewer.view")}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.state.tableData
                                        .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow>
                                                    <TableCell className="viewer-search-tableCell">
                                                        {row.group}
                                                    </TableCell>
                                                    <TableCell className="viewer-search-tableCell" sx={{ width: "70%" }}>
                                                        {row.description}
                                                    </TableCell>
                                                    <TableCell className="viewer-search-tableCell" sx={{ textAlign: "center" }}>
                                                        {row.rule}
                                                    </TableCell>
                                                    <TableCell className="viewer-search-tableCell" sx={{ textAlign: "center" }}>
                                                        <VisibilityIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => this.searchDiagramExtended(row.id)} />
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                </TableBody>

                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10]}
                                component="div"
                                count={this.state.tableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                labelRowsPerPage = {t("Global.row_per_page")}
                            />
                        </TableContainer>
                    </AccordionDetails>
                </Accordion >
                <br></br>
                <div className="diagram-container">
                    <ReactDiagram
                        ref={this.diagramRef}
                        divClassName='diagram-component'
                        initDiagram={this.initDiagram}
                        nodeDataArray={this.props.nodeDataArray}
                        linkDataArray={this.props.linkDataArray}
                        modelData={this.props.modelData}
                        skipsDiagramUpdate={this.props.skipsDiagramUpdate}
                    />
                    <div id="inputEventsMsg" style={{ display: "none" }}></div>
                    <div id="infoBoxHolder" style={{ display: "none" }}></div>
                    <button id="mdBtn" onClick={this.showModalDialog} style={{ display: "none" }}>Show dialog</button>
                    <button id="mdCloseBtn" onClick={this.closeModal} style={{ display: "none" }}>Close dialog</button>

                    {this.state.modalIsOpen ? <NodeModal /> : null}
                </div>

            </div >

        );
    }

    showModalDialog() {
        this.popupText = "";
        var t = document.getElementById("infoBoxHolder");
        if (t != undefined && t != null) {
            this.popupText = t.textContent;
        }
        var groupCode = document.getElementById("inputEventsMsg").textContent;
        var groupCodeValue = document.getElementById("infoBoxHolder").textContent;
        var fields = groupCodeValue?.trim().split(" ");
        var subCode = fields[0].replace("-", "");

        store.dispatch(saveCodeValues({ groupCode: groupCode, groupCodeValue: groupCodeValue, subCode: subCode }));
        if (
            subCode.includes("AK") ||
            subCode.includes("HJ") ||
            subCode.includes("OP") ||
            subCode.includes("PR") ||
            subCode.includes("SP") ||
            subCode.includes("TE") ||
            subCode.includes("MP") ||
            subCode.includes("MA") ||
            subCode.includes("UA")
        ) {
            store.dispatch(getDRGPriceJSON()).then(() => {
                store.dispatch(getMetadataForLogicName()).then(() => {
                    store.dispatch(getDRGPopUpJSON()).then(() => {
                        store.dispatch(setModalActive(true));
                        this.setState({ modalIsOpen: true });
                    });
                })
            });
        }
        else {
            store.dispatch(getMetadataForLogicName()).then(() => {
                if (
                    groupCode == "MDC" ||
                    groupCode == "mdc"
                ) {
                    store.dispatch(getMDCGroupCodeJSON()).then(() => {
                        store.dispatch(setModalActive(true));
                        this.setState({ modalIsOpen: true });
                    });
                } else {
                    store.dispatch(getGroupCodeJSON()).then(() => {
                        store.dispatch(setModalActive(true));
                        this.setState({ modalIsOpen: true });
                    });
                }
            })
        }
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }
}

export default withTranslation("language")(DiagramWrapper);
