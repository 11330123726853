import React from 'react'
import "./Manager.css";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import { clearStatus } from "./managerSlice";

function SharedComponent() {
    const { t } = useTranslation("language");
    const dispatch = useDispatch();
    const manager = useSelector((state) => state.manager);
    const {
        isSuccess,
        isFailed,
        isError,
        isServerError,
        isUploadSuccess,
        isUploadFailed,
        isRemoveSuccess,
        isRemoveFailed,
        isUploadError,
        isRemoveError,
        isFileExist,
        isIvalidFile,
        isEmptyFile,
        isClinicalCode,
        isCasemixFile,
        isSupportFile,
        isDRG,
        isGGrouper,
        isGInteractive,
        isGViewer,
        isSaveUsersSuccess,
        isSaveUsersFailed,
        isSaveLogicSuccess,
        isSaveLogicFailed,
        isSaveSubListSuccess,
        isRemoveSubListSuccess,
        isRemoveSubListFailed,
        isGSaveLogicFailed,
        isGGrouperCheck,
        isGGrouperError,
        isGInteractiveCheckGrouper,
        isGInteractiveCheckInterkoder,
        isGenerateSuccess,
        isGViewerCheck,
        isDownloadError,
        isUploadxlsx,
        isEditLogicInfo,
        isUploadPDF,
    } = manager;

    const handleCloseSnackbar = () => {
        dispatch(clearStatus());
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={isSuccess}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
                    {isUploadSuccess && t("Manager_Upload.Successful")}
                    {isRemoveSuccess && (isClinicalCode || isCasemixFile || isSupportFile) && t("Manager_Remove.file_removed")}
                    {isRemoveSuccess && isDRG && t("Manager_Remove.Successful")}
                    {isSaveUsersSuccess && t("DRG.SaveUsers_Successful")}
                    {isSaveLogicSuccess && t("Manager_Common.SaveLogic_Success")}
                    {isSaveSubListSuccess && t("DRG.SaveSubList_Success")}
                    {isRemoveSubListSuccess && t("Manager_Common.subset_remove_success")}
                    {isRemoveSuccess && isGGrouper && t("GGrouper.Remove_success")}
                    {isRemoveSuccess && isGInteractive && t("GInteractive.Remove_success")}
                    {isGenerateSuccess && isGInteractive && t("GInteractive.GInteractive_success")}
                    {isRemoveSuccess && isGViewer && t("GViewer.Remove_success")}
                    {isGenerateSuccess && isGViewer && t("GViewer.GViewer_success")}

                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={isFailed}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: "100%" }}>
                    {isFileExist && t("Manager_Upload.File_exists")}
                    {isIvalidFile && t("Manager_Upload.Invalid_file")}
                    {isEmptyFile && t("Manager_Upload.Empty_File")}
                    {isRemoveFailed && isClinicalCode && t("Manager_Remove.Failed_isClinicalCode")}
                    {isSaveLogicFailed && t("DRG.SaveLogic_Failed")}
                    {isGSaveLogicFailed && t("Manager_Common.SaveLogic_Failed")}
                    {isRemoveFailed && isGGrouper && t("GGrouper.Remove_failed")}
                    {isGGrouperCheck && t("GGrouper.GGrouper_check")}
                    {isGInteractiveCheckGrouper && t("GInteractive.GInteractive_check_grouper")}
                    {isGInteractiveCheckInterkoder && t("GInteractive.GInteractive_check_interkoder")}
                    {isGViewerCheck && t("GViewer.GViewer_check")}
                    {isRemoveSubListFailed && "Subset cannot be removed"}
                    {isRemoveFailed && t("Manager_Remove.Failed_isDRGlogic")}
                    {isUploadxlsx && t("GViewer.upload_xlsx")}
                    {isEditLogicInfo && t("Manager_Common.edit_logic_info")}
                    {isUploadPDF && t("Manager_Common.upload_pdf")}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={isError}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
                    {isUploadError && t("Manager_Upload.Upload_error")}
                    {isUploadFailed && t("Manager_Upload.Upload_failed")}
                    {isRemoveError && t("Manager_Remove.Remove_error")}
                    {isSaveUsersFailed && t("DRG.SaveUsers_Failed")}
                    {isGGrouperError && t("GGrouper.GGrouper_error")}
                    {isDownloadError && t("Manager_Common.Error")}
                    {isServerError && t("Manager_Common.server_error")}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SharedComponent
