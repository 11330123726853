import React from "react";

export default function PrivacyPolicy() {
  return (
    <div>
      <h2 style={{ paddingTop: 100, height: 465 }}>
        Casemix360 - Privacy Policy
      </h2>
    </div>
  );
}
