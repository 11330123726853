import React, { useState, useEffect } from 'react'
import "./Manager.css";
import { useTranslation } from "react-i18next";
import SharedComponent from "./SharedComponent";
import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import Paper from '@mui/material/Paper';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Dropzone from "react-dropzone";
import { FileNames, FileNamesUpload } from "./AdminFiles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setFilterData,
  loadlogic,
  loadUsers,
  getRoles,
  saveUsers,
  saveLogic,
  exportLogic,
  GetAllDRGLogics,
  loadtill,
  loaddg1,
  loaddrglogik,
  loaddrgtpt,
  loadCountingCode,
  loadegenskaber,
  loadsks,
  setIsTill,
  setIsDg1,
  setIsDrglogik,
  setIsDrgtpt,
  setIsCountingCode,
  setIsEgenskaber,
  setIsSks,
  setSelFileType,
  setSelFileDetails,
  SaveSubList,
  DeleteDRGSubList,
  setIsDRGLogic,
  setIsDrgRender,
  loadFiles,
  upload,
  clearStatus,
  setSelectedYear,
  setSelectedUploadYear,
  setMasterFilterData,
  setIsDRGLogicUsers,
  setDrgUsers,
  setIsGetAllDRGLogicsLoaded,
  remove,
  download,
  DeleteMDCSpecs,
  setEditLogicInfo,
  setExpanded,
  setIsDisabled,
  setIsDrgDataUpdated,
  setIsDrgDataRemoved,
  setDefaultIsDrgLogic,
  GetUpdatedDRGLogic,
  setIsMDCSpecification
} from "./managerSlice";
import {
  setEditorDetails,
} from "../editor/editorSlice";
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    padding: "0px",
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
      padding: '0px',
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      margin: "0px",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      //paddingLeft: theme.spacing(2),
      padding: "0px",
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function DRG() {
  const { t } = useTranslation("language");
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const { user, isAdminPrivileges } = useSelector((state) => state.authentication);
  const manager = useSelector((state) => state.manager);
  const history = useHistory();
  const {
    isInitialLoadAPI,
    data,
    distinctUploadYear,
    isRender,
    selectedYear,
    isDRG,
    isDRGLogic,
    isDrgRender,
    isGetAllDRGLogicsLoaded,
    DrgUsers,
    DrgUsersMaster,
    isdrgLogicUsers,
    isTillMaster,
    isTill,
    isDg1Master,
    isDg1,
    isDrglogikMaster,
    isDrglogik,
    isDrgtptMaster,
    isDrgtpt,
    isCountingCodeMaster,
    isCountingCode,
    isEgenskaberMaster,
    isEgenskaber,
    isSksMaster,
    isSks,
    selFileType,
    selFileDetails,
    allDrgLogicList,
    selDrgLogicList,
    isGetAllDRGLogicsRender,
    isExpanded,
    isDisabled,
    isDrgDataAdded,
    isDrgDataRemoved,
    isSaveUsersSuccess,
    isSaveSubListSuccess,
    isRemoveSubListSuccess,
    isDrgUsersUpdated,
    isDrgLogicRender,
    isMDCSpecification,
    isMDCSpecificationMaster
  } = manager;
  const [addLogic, setAddLogic] = useState(false);
  const [removeLogic, setRemoveLogic] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [selected, setSelected] = useState([]);
  const [addSubset, setAddSubset] = useState(false);
  const [accessUsers, setAccessUsers] = useState([]);
  const [check, setCheck] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [adminFileNamesDRG, setAdminFileNamesDRG] = useState(FileNames.filter((x) => x != "casemixDRG.csv" && x != "interne koder.yaml"));
  const files = ["till.csv", "dg1.csv", "drglogik.csv", "drgtpt.csv", "CountingCode.yaml", "egenskaber.csv", "sks.csv"];
  const [ropen, setRopen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});


  useEffect(() => {
    let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
    dispatch(setIsDRGLogic(0));
    dispatch(loadtill({ method: "GET", url: url + "till.csv" }));
  }, []);

  useEffect(() => {
    if (isInitialLoadAPI || isGetAllDRGLogicsRender) {
      let url = "api/GetAllDRGList?userId=" + user[0]['email'] + "&isAdmin= true";
      dispatch(GetAllDRGLogics({ method: "GET", url: url }))
    }
  }, [isInitialLoadAPI, isGetAllDRGLogicsRender]);

  useEffect(() => {
    if (isDRGLogic ?.length > 0) {

      let url = "api/GetDRGLogicById?drgLogicId=" + isDRGLogic[0] ?._id;
      dispatch(GetUpdatedDRGLogic({ method: "GET", url: url }))
    }

  }, [isDrgLogicRender]);

  useEffect(() => {
    if (isRender && isGetAllDRGLogicsLoaded) {
      let selectedGrpCodeValue = "DrgUserMap.csv";
      let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=" + selectedGrpCodeValue;
      dispatch(loadlogic({ method: "GET", url: url }));
    }
  }, [isRender, isDRG, isGetAllDRGLogicsLoaded]);

  useEffect(() => {
    if (isDrgRender) {

      if (isDRGLogic != null && isDRGLogic.length > 0) {
        dispatch(setIsDRGLogic(isDRGLogic[0] ?._id));
        //dispatch(setIsDrgDataUpdated(false));    
      }

    }
  }, [isDrgRender]);

  useEffect(() => {
    if (isdrgLogicUsers != null && isdrgLogicUsers.length > 0) {
      if (DrgUsers != null && DrgUsers.length > 0) {
        const result = DrgUsers.filter(({ email }) => isdrgLogicUsers.includes(email));
        setAccessUsers(result);
      }
    }
  }, [isDRGLogic, isDrgUsersUpdated]);

  useEffect(() => {
    if (isDrgDataAdded) {

      dispatch(setDefaultIsDrgLogic());
      dispatch(setIsDrgDataUpdated(false));
    }
  }, [isDrgDataAdded]);

  useEffect(() => {
    if (isDrgDataRemoved) {

      dispatch(setIsDRGLogic(0));
      dispatch(setIsDrgDataRemoved(false));

    }
  }, [isDrgDataRemoved]);

  const handleYearSelect = (e) => {
    const value = e.target.value;
    dispatch(setSelectedYear(value));
    if (value) {
      const result = data.filter((item) => {
        return item.Year == value;
      });
      dispatch(setMasterFilterData(result));
      dispatch(setFilterData(result));
      dispatch(setIsDRGLogic(0));
    }
    if (value) {
      const result = isTillMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsTill(result));
    }
    if (value) {
      const result = isDg1Master.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsDg1(result));
    }
    if (value) {
      const result = isDrglogikMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsDrglogik(result));
    }
    if (value) {
      const result = isDrgtptMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsDrgtpt(result));
    }
    if (value) {
      const result = isCountingCodeMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsCountingCode(result));
    }
    if (value) {
      const result = isEgenskaberMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsEgenskaber(result));
    }
    if (value) {
      const result = isSksMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsSks(result));
    }
    if (value) {
      const result = isMDCSpecificationMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsMDCSpecification(result));
    }
  }

  const handleClose = () => {
    setAddLogic(false);
    setRemoveLogic(false);
    setAddUser(false);
    setAddSubset(false);
    dispatch(setDrgUsers(DrgUsersMaster));
    dispatch(setExpanded([]));
    dispatch(setIsDisabled(true));
    setRopen(false);
  }

  const handleEditLogic = () => {
    const selDrgLogic = allDrgLogicList.filter(
      (x) => x.id == isDRGLogic[0] ?._id
    )[0];
    if (
      selDrgLogic.dG1Master != null &&
      selDrgLogic.drglogikMaster != null &&
      selDrgLogic.drgtptMaster != null &&
      selDrgLogic.tillMaster != null &&
      selDrgLogic.countingcodeMaster != null
    ) {
      dispatch(
        setEditorDetails({
          id: selDrgLogic.id,
          name: selDrgLogic.drgLogicName,
          year: selDrgLogic.year,
          version: selDrgLogic.drgLogicVersion,
        })
      );
      history.push("/editor");
    }
    else {
      dispatch(setEditLogicInfo());
    }
  }

  const onSubmit = (values) => {
    if (values.name !== null && values.name !== '' && values.name !== undefined) {
      addDrgLogic(values);
      // reset();
      setAddLogic(false);
    }
  }

  async function addDrgLogic(values) {
    let logicName = values.name;
    let logicVersion = values.version;
    let logicYear = values.year;
    dispatch(setSelectedUploadYear(logicYear));
    var metaData = {
      Name: logicName,
      version: logicVersion,
      year: logicYear,
      Editor_name: user[0]['email'],
    };
    var data = {
      pdata: null,
      drgLogicName: logicName + "$" + logicVersion + "$" + logicYear,
      drgMetaData: metaData,
      drgLogicLog: null,
    };
    let url = "api/SaveNewLogic";
    dispatch(saveLogic({ method: "POST", url: url, body: data }));
    dispatch(setIsGetAllDRGLogicsLoaded());
  }

  const onOk = (id) => (e) => {
    setRemoveLogic(false);
    removeSelectedLogic(id);
    // setRemoveFile(true);
  }

  async function removeSelectedLogic(id) {
    let selectedID = id;
    let selectedGrpCodeValue = "";
    if (isDRG) {
      selectedGrpCodeValue = "DrgUserMap.csv";
    }
    let url = "api/removeLogicNameAndVersion?&selectedGrpCodeVal=" + selectedGrpCodeValue +
      "&logicNameAndVersion=" + selectedID;
    dispatch(remove({ method: "GET", url: url }));
  }

  const exportSelectedLogic = (id) => (e) => {
    let selectedID = id;
    let url = "api/ExportLogic?&drgLogicID=" + selectedID;
    dispatch(exportLogic({ method: "GET", url: url }));
  }

  const isChecked = (email) => {
    if (isDRGLogic != null && isDRGLogic.length > 0) {
      if (isdrgLogicUsers != null && isdrgLogicUsers.length > 0) {
        let isChecked = isdrgLogicUsers.findIndex(
          (item) => item == email
        );
        if (isChecked >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  const handleIsChecked = (email) => (e) => {
    let checkedValue = e.target.checked;
    let userIndex = isdrgLogicUsers.findIndex((item) => item == email);
    const result = [...isdrgLogicUsers];
    if (checkedValue == true && userIndex < 0) {
      result.push(email);
      dispatch(setIsDRGLogicUsers(result));
    } else {
      result.splice(userIndex, 1);
      dispatch(setIsDRGLogicUsers(result));
    }
  }

  const handleSelectAll = (e) => {
    let checkedValue = e.target.checked;
    setCheck(checkedValue);
    const result = [...isdrgLogicUsers];
    if (checkedValue == true) {
      const filteredResult = DrgUsers.filter(({ email }) => !isdrgLogicUsers.includes(email));
      filteredResult.map((user) => result.push(user.email));
      dispatch(setIsDRGLogicUsers(result));
    } else {
      dispatch(setIsDRGLogicUsers([]));
    }
  }

  const requestSearch = (event) => {
    const result = DrgUsersMaster.filter((user) => {
      return (
        user.firstName
          ?.toLowerCase()
            .includes(event.target.value ?.toLowerCase()) ||
            user.lastName ?.toLowerCase().includes(event.target.value ?.toLowerCase())
);
    });
    dispatch(setDrgUsers(result));
  };

  const onAddAccess = (id) => (e) => {
    let selectedID = id;
    let selectedGrpCodeValue = "";
    if (isDRG) {
      selectedGrpCodeValue = "DrgUserMap.csv";
    }
    let url = "api/addUserRights?logicNameAndVersion=" + selectedID +
      "&selectedGrpCodeVal=" + selectedGrpCodeValue + "&users=" + isdrgLogicUsers;
    dispatch(saveUsers({ method: "POST", url: url }));
    setAddUser(false);
  }

  const onDelete = (values) => (e) => {
    e.preventDefault();
    setDeleteItem(values);
    setRopen(true);
  }

  const onDeleteOk = (values) => (e) => {
    setRopen(false);
    let url = "api/DeleteDRGSubList?drglogicid=" + isDRGLogic[0] ?._id + "&name=" + values.type;
    dispatch(DeleteDRGSubList({ method: "POST", url: url }));
    dispatch(setIsGetAllDRGLogicsLoaded());
  }

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
      dispatch(setIsDisabled(false));
    }
  };

  const onSelect = (nodeID, fileType) => () => {
    dispatch(setIsDisabled(true));
    setSelectedFiles("");
    if (isExpanded[0] == nodeID) {
      dispatch(setExpanded([]));
    } else {
      dispatch(setExpanded([nodeID]));
      if (fileType == "") {
        dispatch(setSelFileType(fileType));
      }
    }
  };

  const onSubsetSelect = (details, fileType) => () => {
    dispatch(setSelFileDetails(details));
    dispatch(setSelFileType(fileType));
    dispatch(setIsDisabled(false));
  }

  const onAddSubset = () => {
    if (selFileType !== null && selFileType !== '' && selFileType !== undefined) {
      const mdata = {
        _id: selFileDetails._id,
        Name: selFileDetails.Name,
        version: selFileDetails.version,
        Year: selFileDetails.Year,
        editor_name: selFileDetails.editor_name,
        users: JSON.stringify(selFileDetails.users),
        isPublished: selFileDetails.isPublished,
        isPreProd: selFileDetails.isPreProd,
        UploadedDate: selFileDetails.UploadedDate,
        UploadedDateFormat: selFileDetails.UploadedDateFormat,
      }
      const data = {
        drgLogicID: isDRGLogic[0]._id,
        DRGLogic: selFileDetails.Name + " " + selFileDetails.version,
        MasterName: selFileType,
        MasterMetaData: mdata
      };
      let url = "api/SaveDRGSubList";
      dispatch(SaveSubList({ method: "POST", url: url, body: data }));
      setAddSubset(false);
      dispatch(setIsDisabled(true));
      dispatch(setExpanded([]));
      dispatch(setSelFileDetails([]));
      dispatch(setIsGetAllDRGLogicsLoaded());
    }
    else {
      let currentFile = selectedFiles[0];
      setCurrentFile(currentFile);
      setSelectedFiles("");
      let drgLogicID = isDRGLogic[0]._id;
      let url = "api/UploadMDCSpecs?drgLogicID=" + drgLogicID;
      let formData = new FormData();
      formData.append('file', currentFile, currentFile.name);
      dispatch(upload({ method: "POST", url: url, body: formData }));
      setAddSubset(false);
      dispatch(setIsDisabled(true));
      dispatch(setExpanded([]));
      dispatch(setIsGetAllDRGLogicsLoaded());
    }
  }
  return (
    <div className="DRG">
      <Paper className="paperContainer">
        <div className="right-section-container">
          <div className="right-section-header left">
            <Typography variant="h5" className="typography-title" gutterBottom sx={{ margin: "0 !important" }}>
              {t("Manager.Diagnose_Related_Logics")}
            </Typography>
          </div>
          <div className="right-section-header right">
            <div className="right-section-header-container">
              <TextField
                label={t("Manager.Year")}
                variant="outlined"
                size="small"
                select
                onChange={handleYearSelect}
                sx={{ marginRight: 1 }}
                value={selectedYear}
              >
                {distinctUploadYear.map((year) => {
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button
                variant="contained"
                size="small"
                className="manager-button"
                onClick={() => { setAddLogic(true), reset({ year: selectedYear }) }}
              >
                {t("Manager_Common.add_logic")}
              </Button>
            </div>
          </div>
        </div>
        <Divider
        />

        {isDRGLogic.length > 0 && (
          <div className="right-section-container-one">
            <div className="right-section-container">
              <div className="right-section-header left">
                <Typography variant="h6" className="typography-title" gutterBottom sx={{ margin: "0 !important" }}>
                  {isDRGLogic[0] ?.Name}-{isDRGLogic[0] ?.version}
                </Typography>
              </div>
              <div className="right-section-header right">
                <div className="right-section-header-container">

                  {isAdminPrivileges && (isDRGLogic[0] ?.isPublished == false && isDRGLogic[0] ?.isPreProd == false && isDRGLogic[0] ?.isTest == false) && (
                    <Button
                      variant="contained"
                      size="small"
                      className="manager-button"
                      onClick={() => setRemoveLogic(true)}
                    >
                      {t("Manager.Remove")}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    className="manager-button"
                    onClick={exportSelectedLogic(isDRGLogic[0] ?._id)}
                  >
                    {t("Manager.Export")}
                  </Button>
                  {(isAdminPrivileges || isDRGLogic[0] ?.users ?.includes(user[0] ?.email)) && (
                    <Button
                      variant="contained"
                      size="small"
                      className="manager-button"
                      onClick={handleEditLogic}
                    >
                      {t("Manager_Common.show_in_editor")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {/* <Divider/> */}
            <div className="container-block">
              <Typography className="typography-title" color="#00000099" gutterBottom>
                {t("Manager.publisher_status")}:
              <Typography variant="string" color="#000000DE" sx={{ marginLeft: "2px" }}>

                  {isDRGLogic[0] ?.isPublished == true && t("Global.production")}
                  {isDRGLogic[0] ?.isPreProd == true && t("Global.hearing")}
                  {isDRGLogic[0] ?.isTest == true && t("Global.test")}                  
                  {isDRGLogic[0] ?.isPublished == false && isDRGLogic[0] ?.isPreProd == false && isDRGLogic[0] ?.isTest==false && t("Global.notpublished")} 

                </Typography>
              </Typography>
              <Typography className="typography-title" color="#00000099" gutterBottom>
                {t("Manager.Owner")}:
            <Typography variant="string" color="#000000DE" sx={{ marginLeft: "2px" }}>
                  {isDRGLogic[0] ?.editor_name}
                </Typography>
              </Typography>
              {(isDRGLogic[0] ?.UsedGrouper.length > 0 || isDRGLogic[0] ?.UsedInteractive.length > 0 || isDRGLogic[0] ?.UsedViwer.length > 0) && (
                <Typography className="typography-title" color="#00000099">
                  {t("Manager_Common.used_in")}:
              {isDRGLogic[0] ?.UsedGrouper.length > 0 && (
                    <Tooltip
                      arrow
                      sx={{ backgroundColor: "#FFFFFF" }}
                      title={
                        <div>
                          {isDRGLogic[0] ?.UsedGrouper.map((row) => {
                            return (
                              <Typography color="#99DAF2">{row.grouperName}{" "}{row.grouperVersion}{" "}{row.year}</Typography>
                            );
                          })}
                        </div>
                      } >
                      <Link
                        component="button"
                        color="#00A3E0"
                        sx={{ padding: "0 1%" }}
                      >
                        {isDRGLogic[0] ?.UsedGrouper.length} {t("Manager_Common.Groupers")}
                      </Link>
                    </Tooltip>
                  )}
                  {isDRGLogic[0] ?.UsedInteractive.length > 0 && (
                    <Tooltip
                      arrow
                      sx={{ backgroundColor: "#FFFFFF" }}
                      title={
                        <div>
                          {isDRGLogic[0] ?.UsedInteractive.map((row) => {
                            return (
                              <Typography color="#99DAF2">{row.interactiveName}{" "}{row.interactiveVersion}{" "}{row.year}</Typography>
                            );
                          })}
                        </div>
                      } >
                      <Link
                        component="button"
                        color="#00A3E0"
                        sx={{ padding: "0 1%" }}
                      >
                        {isDRGLogic[0] ?.UsedInteractive.length} {t("Manager_Common.Interactives")}
                      </Link>
                    </Tooltip>
                  )}
                  {isDRGLogic[0] ?.UsedViwer.length > 0 && (
                    <Tooltip
                      arrow
                      sx={{ backgroundColor: "#FFFFFF" }}
                      title={
                        <div>
                          {isDRGLogic[0] ?.UsedViwer.map((row) => {
                            return (
                              <Typography color="#99DAF2">{row.visualName}{" "}{row.visualVersion}{" "}{row.year}</Typography>
                            );
                          })}
                        </div>
                      } >
                      <Link
                        component="button"
                        color="#00A3E0"
                        sx={{ padding: "0 1%" }}
                      >
                        {isDRGLogic[0] ?.UsedViwer.length} Viewer
</Link>
                    </Tooltip>
                  )}
                </Typography>
              )}
            </div>
            <Divider
            />
            <div className="container-block">
              <div className="section">
                <Typography color="#00000099">
                  {t("Manager.Subset")}: {t("Global.Total")} {selDrgLogicList.length}
                </Typography>
                {isDRGLogic[0] ?.isPublished == false && isDRGLogic[0] ?.isPreProd == false && isDRGLogic[0] ?.isTest == false && (
                  <Link
                    component="button"
                    color="#00A3E0"
                    onClick={() => setAddSubset(true)}
                  >
                    {t("Manager.Add_Subset")}
                  </Link>
                )}
              </div>
              <Table className="drgTable">
                <TableBody>
                  {selDrgLogicList
                    .map((row, index) => {
                      return (
                        <TableRow className="tableRow" key={index} >
                          <TableCell className="tableCell" sx={{ flexGrow: 1 }} >{row.name}{row ?.version ? "-" + row.version : " " }</TableCell>
                          {isAdminPrivileges && (isDRGLogic[0] ?.isPublished == false && isDRGLogic[0] ?.isPreProd == false && isDRGLogic[0] ?.isTest == false) && (
                            <TableCell className="tableCell" >{<div><DeleteIcon className="test" onClick={onDelete(row)} sx={{ color: "#959CA0", cursor: "pointer", "&:hover": { color: "#EC948D" } }} /></div>}</TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <Divider
            />
            <div className="container-block ">
              <div className="section">
                <Typography color="#00000099">
                  {t("Manager.User_rights")}
                </Typography>
                {(isAdminPrivileges || isDRGLogic[0] ?.editor_name == user[0] ?.email) && isDRGLogic[0] ?.isPublished == false && isDRGLogic[0] ?.isPreProd == false && isDRGLogic[0] ?.isTest == false &&
                  <Link
                    component="button"
                    color="#00A3E0"
                    onClick={() => setAddUser(true)}
                  >
                    {t("Manager.AddRemoveAccess")}
                  </Link>
                }
              </div>
              <Typography variant="caption" gutterBottom color="#00000099"> {accessUsers.length} {t("Manager_Common.access_logic")} </Typography>
              <Table className="drgTable">
                <TableBody>
                  {accessUsers
                    .map((row) => {
                      return (
                        <TableRow className="tableRow" >
                          <TableCell className="tableCell" sx={{ flexGrow: 1 }} >{row.firstName} {row.lastName}</TableCell>
                          {/* <TableCell className="tableCell" >{<div><DeleteIcon className="test" onClick={onRemove(row)} sx={{ color: "#959CA0", cursor: "pointer" }} /></div>}</TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </div>
        )}


        <Dialog open={addLogic} onClose={handleClose}>
          <DialogTitle>{t("Manager_Common.add_logic")}</DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={{ paddingTop: "5px !important", paddingBottom: "12px !important" }}>
              <div className="form-section">
                <TextField
                  id="name"
                  label={t("Manager_Common.Name")}
                  fullWidth
                  variant="outlined"
                  className="form-section"
                  {...register("name", {
                    required: t("Manager_Common.Logic_Name_Required"),
                    // pattern: {
                    //   value: /^[a-zA-Z0-9_.-]*$/,
                    //   message: t("Manager_Common.Validataion"),
                    // },
                  })}
                  error={Boolean(errors.name)}
                  helperText={errors ?.name ?.message}
                  inputProps={{ maxLength: 20 }}
                />
              </div>
              <div className="form-section-double">
                <div className="form-sub-section">
                  <TextField
                    label="Version"
                    variant="outlined"
                    className="form-sub-section"
                    {...register("version", {
                      required: t("Manager.Version_Required"),
                      pattern: {
                        value: /^[a-zA-Z0-9]*$/,
                        message: t("Manager.Invalid_Version"),
                      },
                    })}
                    error={Boolean(errors.version)}
                    helperText={errors ?.version ?.message}
                    inputProps={{ maxLength: 5 }}
                  />
                </div>
                <div className="form-sub-section">
                  <TextField
                    label={t("Manager.yearLabel")}
                    variant="outlined"
                    className="form-sub-section"
                    {...register("year", {
                      required: t("Manager.Year_Required"),
                      pattern: {
                        value: /^[0-9]{4,4}$/,
                        message: t("Manager.entryDigits"),
                      },
                    })}
                    error={Boolean(errors.year)}
                    helperText={errors ?.year ?.message}
                    type="Number"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions sx={{ padding: "12px 24px !important" }}>
              <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
              <Button variant="contained" onClick={onSubmit} type="submit">{t("Manager.Save")}</Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog open={removeLogic} onClose={handleClose} maxWidth="xs">
          <DialogTitle>{t("Manager_Common.remove_logic")}</DialogTitle>
          <DialogContent>
            <Typography gutterBottom>{t("Manager_Common.remove_validation")}</Typography>
            <Typography gutterBottom>{t("Manager_Common.Logic_Name")}: {isDRGLogic[0] ?.Name}</Typography>
            <Typography>{t("Manager_Common.Uploaded_By")}: {isDRGLogic[0] ?.editor_name}</Typography>
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" onClick={onOk(isDRGLogic[0] ?._id)} type="submit">{t("Manager.Remove")}</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={addUser} onClose={handleClose}>
          <DialogTitle>{t("Manager_Common.Add_User_Access")}
          </DialogTitle>
          <DialogTitle>
            <TextField
              variant="outlined"
              placeholder={t("Global.search")}
              size="small"
              onChange={requestSearch}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    sx={{ color: "#00000040" }}
                  />
                ),
              }}
            />
          </DialogTitle>
          <DialogContent>
            <Checkbox
              onClick={handleSelectAll}
              checked={check}
            />
            {t("Manager_Common.select_all")}
            {DrgUsers.map((user) => (
              <MenuItem key={user.email} disableGutters className="listUsers">
                <Checkbox
                  size="small"
                  checked={isChecked(user.email)}
                  onChange={handleIsChecked(user.email)}
                />
                <Typography variant="string">{user.firstName} {user.lastName}</Typography ><Typography variant="caption">{user.RoleName}</Typography>
              </MenuItem>
            ))}
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" onClick={onAddAccess(isDRGLogic[0] ?._id)} >{t("Manager.Add")}</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={addSubset} onClose={handleClose}>
          <DialogTitle>{t("Manager.Add_Subset")}</DialogTitle>
          <DialogContent>
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              sx={{ minWidth: 300 }}
              expanded={isExpanded}
            >
              <StyledTreeItem nodeId="till.csv" labelText="till.csv" labelIcon={FolderIcon} labelInfo={isTill.length} onClick={onSelect("till.csv")} >
                {isTill
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "till.csv")} />
                    );
                  })}
              </StyledTreeItem>
              <StyledTreeItem nodeId="dg1.csv" labelText="dg1.csv" labelIcon={FolderIcon} labelInfo={isDg1.length} onClick={onSelect("dg1.csv")} >
                {isDg1
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "dg1.csv")} />
                    );
                  })}
              </StyledTreeItem>
              <StyledTreeItem nodeId="drglogik.csv" labelText="drglogik.csv" labelIcon={FolderIcon} labelInfo={isDrglogik.length} onClick={onSelect("drglogik.csv")}>
                {isDrglogik
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "drglogik.csv")} />
                    );
                  })}
              </StyledTreeItem>
              <StyledTreeItem nodeId="drgtpt.csv" labelText="drgtpt.csv" labelIcon={FolderIcon} labelInfo={isDrgtpt.length} onClick={onSelect("drgtpt.csv")} >
                {isDrgtpt
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "drgtpt.csv")} />
                    );
                  })}
              </StyledTreeItem>
              <StyledTreeItem nodeId="CountingCode.yaml" labelText="CountingCode.yaml" labelIcon={FolderIcon} labelInfo={isCountingCode.length} onClick={onSelect("CountingCode.yaml")}>
                {isCountingCode
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "CountingCode.yaml")} />
                    );
                  })}
              </StyledTreeItem>
              <StyledTreeItem nodeId="egenskaber.csv" labelText="egenskaber.csv" labelIcon={FolderIcon} labelInfo={isEgenskaber.length} onClick={onSelect("egenskaber.csv")}>
                {isEgenskaber
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "egenskaber.csv")} />
                    );
                  })}
              </StyledTreeItem>
              <StyledTreeItem nodeId="MDCSpecification.yaml" labelText="MDCSpecification.yaml" labelIcon={FolderIcon} labelInfo={isMDCSpecification.length} onClick={onSelect("MDCSpecification.yaml")}>
                {isMDCSpecification
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "MDCSpecification.yaml")} />
                    );
                  })}
              </StyledTreeItem>
              <StyledTreeItem nodeId="sks.csv" labelText="sks.csv" labelIcon={FolderIcon} labelInfo={isSks.length} onClick={onSelect("sks.csv")}>
                {isSks
                  .map((item) => {
                    return (
                      <StyledTreeItem nodeId={item.Name + "-" + item.version} labelText={item.Name + "-" + item.version} labelInfo={item.UploadedDateFormat} onClick={onSubsetSelect(item, "sks.csv")} />
                    );
                  })}
              </StyledTreeItem>
            </TreeView>
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" onClick={onAddSubset} disabled={isDisabled}>{t("Manager.Add")}</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={ropen} onClose={handleClose}>
          <DialogContent>
            {t("Manager_Common.deleteItem")}
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" onClick={onDeleteOk(deleteItem)} type="submit">{t("Manager.Ok")}</Button>
          </DialogActions>
        </Dialog>
      </Paper>


      {isDRGLogic.length == 0 && (<div className="right-section-container-one" >
        <Typography align="center" color="#00000099" sx={{ marginLeft: "2px" }}>
          {t("Manager.No_Logic_Selected")}
        </Typography>
      </div>)}
      <SharedComponent />
    </div>
  )
}


