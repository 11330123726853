import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
//import { createFalse } from "typescript";

export const getInteractiveNames = createAsyncThunk(
  "interactive/getInteractiveNames",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetActiveInteractiveDetails?isLogin=" + obj.login;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getZipFile = createAsyncThunk(
  "interactive/getZipFile",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((response) => {
      if (response.status === 200) {
        return response.blob();
      }
      if (response.status === 401) {
        dispatch(endUserSession());
      }
    });
  }
);

export const getAllMasterData = createAsyncThunk(
  "interactive/getAllMasterData",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/GetInteractiveMasterData", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getAllDiagnosis = createAsyncThunk(
  "interactive/getAllDiagnosis",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let interactive = state.interactive.selectedInteractiveObj;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let parentId = obj.parentId ? obj.parentId : "";
    let url =
      "api/GetAllSKSDiagnosis?grouperName=" +
      interactive.grouperName +
      "&grouperVersion=" +
      interactive.grouperVersion +
      "&year=" +
      interactive.year +
      "&parentid=" +
      parentId;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getAllProcedures = createAsyncThunk(
  "interactive/getAllProcedures",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let interactive = state.interactive.selectedInteractiveObj;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let parentId = obj.parentId ? obj.parentId : "";
    let url =
      "api/GetAllSKSProcedures?grouperName=" +
      interactive.grouperName +
      "&grouperVersion=" +
      interactive.grouperVersion +
      "&year=" +
      interactive.year +
      "&parentid=" +
      parentId;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDiagnosisSearch = createAsyncThunk(
  "interactive/getDiagnosisSearch",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let interactive = state.interactive.selectedInteractiveObj;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetSKSDiagSearch?grouperName=" +
      interactive.grouperName +
      "&grouperVersion=" +
      interactive.grouperVersion +
      "&year=" +
      interactive.year +
      "&search=" +
      obj.search;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getProcedureSearch = createAsyncThunk(
  "interactive/getProcedureSearch",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let interactive = state.interactive.selectedInteractiveObj;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetSKSProcSearch?grouperName=" +
      interactive.grouperName +
      "&grouperVersion=" +
      interactive.grouperVersion +
      "&year=" +
      interactive.year +
      "&search=" +
      obj.search;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const interactiveProcessResult = createAsyncThunk(
  "interactive/interactiveProcessResult",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;

    let url = "api/ProcessResult?isHearing=" + false;
    return await fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getVisualDatabyID = createAsyncThunk(
  "interactive/getVisualDatabyID",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetVisualDatabyId?id=" + obj.id;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getVisualMetadata = createAsyncThunk(
  "interactive/getVisualMetadata",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;

    let url =
      "api/GetMetadata?visualname=" +
      obj.name +
      "&visualversion=" +
      obj.version +
      "&year=" +
      obj.year;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getVisualNodesJSON = createAsyncThunk(
  "interactive/getVisualNodesJSON",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;

    let url =
      "api/GetViewerData/?_logicName=" +
      obj.logicName +
      "&_logicVersion=" +
      obj.logicVersion +
      "&_year=" +
      obj.year +
      "&pricename=" +
      obj.priceName +
      "&priceversion=" +
      obj.priceVersion;
    return await fetch(url, reqOptions).then((res) => {
      if (res.status === 401) {
        dispatch(endUserSession());
      } else {
        return res.json();
      }
    });
  }
);

const initialState = {
  interactiveCollection: [],
  yearsCollection: [],
  selectedInteractiveObj: [],
  selectedInteractiveID: "",
  selectedInteractiveName: "",
  selectedInteractiveYear: "",
  isYearGreaterThan2021: false,
  blobToDownload: null,
  selectedFileType: "",
  fileNotExists: false,
  patientInfoPanel: true,
  diseasesPanel: true,
  episodesPanel: false,
  spellsPanel: false,
  resultsPanel: false,
  patientInputs: [],
  gender: "",
  birthValue: "No",
  disableBirth: true,
  enableBirthInfo: false,
  isMale: true,
  age: "32",
  ageUnit: "",
  gestationAge: "",
  discharge: "",
  contactType: "",
  birthType: "",
  height: "163",
  weight: "64",
  hospitalisationMethod: "",
  disableContactReason: true,
  reasonForContact: "",
  contactDays: "",
  contactDaysStartDate: null,
  contactDaysEndDate: null,
  varighed: "",
  disableContactDays: true,
  dateError: false,
  diagnosisParentCollection: null,
  proceduresParentCollection: null,
  diagnosisChildCollection: null,
  diagnosisHasChildren: false,
  proceduresChildCollection: null,
  procedureHasChildren: false,
  isChooseDiagnosis: true,
  isChooseDiagAdditional: true,
  diagnosis: [],
  diagnosisData: [],
  diagnosisSet: false,
  isChooseProcedure: true,
  isChooseProcAdditional: true,
  procedure: [],
  procedureData: [],
  procedureSet: false,
  diagnosisSearchTree: null,
  diagnosisSearch: false,
  procedureSearchTree: null,
  procedureSearch: false,
  interactiveList: [],
  overWriteVarighedValue: "",
  autoIncrementProgressID: 0,
  spellsList: [],
  contactDaysNoProgress: 0,
  spellVarighed: "",
  updateSuccess: false,
  requestList: [],
  requestType: "",
  resultList: [],
  resultError: false,
  errorMessage: "",
  reportDialog: false,
  isVisualLoading: false,
  visualDialog: false,
  visualID: "",
  visualSearchValue: "",
  dataForGoJs: null,
  isResultLoading: false,
  noVisualIDError: false,
  diagnosisParentMaster: null,
  procedureParentMaster: null,
  loadDiagAndProc: false,
  isSessionValid: true,
};

const interactiveSlice = createSlice({
  name: "interactive",
  initialState,
  reducers: {
    endUserSession(state, action) {
      state.isSessionValid = false;
    },
    setSelectedInteractive(state, action) {
      state.selectedInteractiveObj = action.payload;
    },
    setInteractiveDetails(state, action) {
      state.selectedInteractiveID = action.payload.id;
      state.selectedInteractiveName = action.payload.name;
      state.selectedInteractiveYear = action.payload.year;
      let year = parseInt(action.payload.year);
      if (year > 2021) {
        state.isYearGreaterThan2021 = true;
        state.disableContactDays = true;
        state.contactDays = "";
      } else {
        state.isYearGreaterThan2021 = false;
        state.disableContactDays = false;
        state.contactDays = "1";
      }
    },
    setSelectedFileToDownload(state, action) {
      state.selectedFileType = action.payload;
    },
    clearValues(state, action) {
      state.selectedFileType = null;
      state.blobToDownload = null;
    },
    errorMessageClose(state, action) {
      state.fileNotExists = false;
    },
    setPanel(state, { payload }) {
      if (payload) {
        state[`${payload}`] = !state[`${payload}`];
      }
    },
    setChildrenStatus(state, { payload }) {
      if (payload.name === "diagnosis") {
        state.diagnosisHasChildren = payload.value;
      } else if (payload.name === "procedures") {
        state.procedureHasChildren = payload.value;
      }
    },
    setSelectionButton(state, { payload }) {
      if (payload.name === "diagnosis") {
        state.isChooseDiagnosis = payload.value;
      } else if (payload.name === "diagAdditional") {
        state.isChooseDiagAdditional = payload.value;
      } else if (payload.name === "procedure") {
        state.isChooseProcedure = payload.value;
      } else if (payload.name === "procAdditional") {
        state.isChooseProcAdditional = payload.value;
      }
    },
    setDiagnosisData(state, { payload }) {
      state.diagnosisData = payload;
    },
    setDiagnosis(state, action) {
      const { uniqueID, type, code, Id, name, selected } = action.payload;
      state.diagnosis.push({
        uniqueID: uniqueID,
        type: type,
        code: code,
        Id: Id,
        name: name,
        selected: selected,
        additionalCode: [],
      });
      state.diagnosisSet = true;
    },
    setDiagnosisAdditionalCode(state, action) {
      const { index, uniqueID, type, code, Id, name } = action.payload;
      state.diagnosis[index].additionalCode.push({
        uniqueID: uniqueID,
        type: type,
        code: code,
        Id: Id,
        name: name,
      });
    },
    updateSelectedDiagnosis(state, { payload }) {
      state.diagnosis.forEach((element) => {
        element.selected = false;
      });
      state.diagnosis[payload.index].selected = payload.value;
    },
    diagnosisMoveUp(state, action) {
      const index = action.payload;
      if (index > 0) {
        const tmp = state.diagnosis[index - 1];
        state.diagnosis[index - 1] = state.diagnosis[index];
        state.diagnosis[index] = tmp;
        if (state.diagnosis.length >= 1) {
          state.diagnosis.forEach((element, index) => {
            if (index === 0) {
              state.diagnosis[index].type = "A";
            } else {
              state.diagnosis[index].type = "B";
            }
          });
        }
      }
    },
    diagnosisMoveDown(state, action) {
      const index = action.payload;
      if (index < state.diagnosis.length) {
        const tmp = state.diagnosis[index + 1];
        state.diagnosis[index + 1] = state.diagnosis[index];
        state.diagnosis[index] = tmp;
        if (state.diagnosis.length >= 1) {
          state.diagnosis.forEach((element, index) => {
            if (index === 0) {
              state.diagnosis[index].type = "A";
            } else {
              state.diagnosis[index].type = "B";
            }
          });
        }
      }
    },
    deleteDiagnosis(state, { payload }) {
      state.diagnosis.splice(payload.index, 1);
      if (state.diagnosis.length >= 1) {
        state.diagnosis[0].type = "A";
      }
      state.diagnosisSet = true;
    },
    deleteAdditionalDiagnosis(state, { payload }) {
      state.diagnosis[payload.diagIndex].additionalCode.splice(
        payload.addDiagIndex,
        1
      );
    },

    setProcedureData(state, { payload }) {
      state.procedureData = payload;
    },
    setProcedure(state, action) {
      const { uniqueID, type, code, Id, name, selected } = action.payload;
      state.procedure.push({
        uniqueID: uniqueID,
        type: type,
        code: code,
        Id: Id,
        name: name,
        selected: selected,
        additionalCode: [],
      });
      state.procedureSet = true;
    },
    setProcedureAdditionalCode(state, action) {
      const { index, uniqueID, type, code, Id, name } = action.payload;
      state.procedure[index].additionalCode.push({
        uniqueID: uniqueID,
        type: type,
        code: code,
        Id: Id,
        name: name,
      });
    },
    updateSelectedProcedure(state, { payload }) {
      state.procedure.forEach((element) => {
        element.selected = false;
      });
      state.procedure[payload.index].selected = payload.value;
    },
    procedureMoveUp(state, action) {
      const index = action.payload;
      if (index > 0) {
        const tmp = state.procedure[index - 1];
        state.procedure[index - 1] = state.procedure[index];
        state.procedure[index] = tmp;
      }
    },
    procedureMoveDown(state, action) {
      const index = action.payload;
      if (index < state.procedure.length) {
        const tmp = state.procedure[index + 1];
        state.procedure[index + 1] = state.procedure[index];
        state.procedure[index] = tmp;
      }
    },
    deleteProcedure(state, { payload }) {
      state.procedure.splice(payload.index, 1);
      state.procedureSet = true;
    },
    deleteAdditionalProcedure(state, { payload }) {
      state.procedure[payload.procIndex].additionalCode.splice(
        payload.addProcIndex,
        1
      );
    },
    setSearchStatus(state, { payload }) {
      if (payload.name === "diagnosis") {
        state.diagnosisSearch = payload.value;
      } else if (payload.name === "procedure") {
        state.procedureSearch = payload.value;
      }
    },
    setGender(state, { payload }) {
      state.gender = payload;
      if (payload === "2") {
        state.disableBirth = false;
        state.isMale = false;
        const femaleGestationDefaultValue =
          state.patientInputs[0].femaleGestation.find(
            (item) => item.name.en === "" || item.name.dk === ""
          );
        if (femaleGestationDefaultValue) {
          state.gestationAge = femaleGestationDefaultValue.value;
        }
      } else {
        state.disableBirth = true;
        state.birthValue = "No";
        state.enableBirthInfo = false;
        state.isMale = true;
        const maleGestationDefaultValue =
          state.patientInputs[0].maleGestation.find(
            (item) => item.name.en === "" || item.name.dk === ""
          );
        if (maleGestationDefaultValue) {
          state.gestationAge = maleGestationDefaultValue.value;
        }
      }
    },
    setBirthValue(state, { payload }) {
      state.birthValue = payload;
      if (payload === "Yes") {
        state.enableBirthInfo = true;
      } else {
        state.enableBirthInfo = false;
      }
    },
    setValues(state, { payload }) {
      if (payload) {
        state[`${payload.name}`] = payload.value;
      }
      if (payload.name === "hospitalisationMethod") {
        state.disableContactReason = payload.value === "1" ? false : true;
      }
      if (payload.name === "varighed") {
        state.disableContactDays = payload.value === "1" ? true : false;
        state.contactDays = payload.value === "1" ? "" : "1";
        state.contactDaysStartDate =
          payload.value === "1" ? null : state.contactDaysStartDate;
        state.contactDaysEndDate =
          payload.value === "1" ? null : state.contactDaysEndDate;
      }
    },
    setDateToDefault(state, { payload }) {
      state.ontactDaysStartDate = null;
      state.contactDaysEndDate = null;
      state.contactDays = "1";
      state.dateError = true;
    },
    dateErrorClose(state, { payload }) {
      state.dateError = false;
    },
    clearPatientInformation(state, { payload }) {
      state.gender = "";
      state.birthValue = "No";
      state.disableBirth = true;
      state.enableBirthInfo = false;
      state.isMale = true;
      state.age = "32";
      state.ageUnit = "";
      state.gestationAge = "";
      state.discharge = "";
      state.contactType = "";
      state.birthType = "";
      state.height = "163";
      state.weight = "64";
      state.hospitalisationMethod = "";
      state.disableContactReason = true;
      state.reasonForContact = "";
      state.contactDaysStartDate = null;
      state.contactDaysEndDate = null;
      state.varighed = "";
      if (state.isYearGreaterThan2021) {
        state.contactDays = "";
        state.disableContactDays = true;
      } else {
        state.contactDays = "1";
        state.disableContactDays = false;
      }
      state.dateError = false;
    },
    addToSpell(state, { payload }) {
      let refId = state.interactiveList.length;
      refId++;
      state.autoIncrementProgressID = state.autoIncrementProgressID + 1;
      let clone = JSON.parse(JSON.stringify(payload.data));
      clone["Id"] = refId;
      state.interactiveList.push(clone);
      localStorage.setItem(
        "interactiveList",
        JSON.stringify(state.interactiveList)
      );
      if (payload.handler === "AddToSpell") {
        state.spellsList.push({
          id: state.autoIncrementProgressID,
          val: clone,
          refId: refId,
        });
        localStorage.setItem("spellsList", JSON.stringify(state.spellsList));

        if (state.isYearGreaterThan2021) {
          if (state.spellsList.length > 0) {
            state.overWriteVarighedValue = Math.max(
              ...state.spellsList.map((o) => parseInt(o.val[0].varighed)),
              0
            ).toString();
          } else {
            state.overWriteVarighedValue = null;
          }
        }
        let contDays = 0;
        if (clone[0].contactDays > 0) {
          state.spellsList.forEach((element) => {
            contDays += element.val[0].contactDays;
          });
          state.contactDaysNoProgress = contDays;
        } else {
          state.contactDaysNoProgress = state.spellsList.length;
        }

        state.spellsPanel = true;
        state.episodesPanel = true;
      } /* else if (payload.handler === "RunQuery") {
        state.episodesPanel = true;
        state.spellsPanel = true;
        state.resultsPanel = true;
      } */
      state.diagnosis = [];
      state.procedure = [];
    },
    spellMoveLeft(state, action) {
      const index = action.payload;
      if (index > 0) {
        const tmp = state.spellsList[index - 1];
        state.spellsList[index - 1] = state.spellsList[index];
        state.spellsList[index] = tmp;
      }
    },
    spellMoveRight(state, action) {
      const index = action.payload;
      if (index < state.spellsList.length) {
        const tmp = state.spellsList[index + 1];
        state.spellsList[index + 1] = state.spellsList[index];
        state.spellsList[index] = tmp;
      }
    },
    deleteSpell(state, { payload }) {
      var index = state.spellsList.findIndex(
        (item) => item.id == payload.refId
      );
      state.spellsList.splice(index, 1);
      let contDays = 0;
      if (state.spellsList.length > 0 && state.isYeargreaterThan2021) {
        state.overWriteVarighedValue = Math.max(
          ...state.spellsList.map((o) => parseInt(o.val[0].varighed)),
          0
        ).toString();
      } else {
        state.overWriteVarighedValue = null;
      }
      state.spellsList.forEach((element) => {
        contDays += element.val[0].contactDays;
      });
      state.contactDaysNoProgress = contDays;
    },
    clearSpellInformation(state, { payload }) {
      state.spellsList = [];
      state.contactDaysNoProgress = 0;
    },
    expandCollapseUpdate(state, { payload }) {
      state.interactiveList[payload.index][0].expandCollapseValue =
        payload.value;
    },
    addEpisodeToSpell(state, { payload }) {
      state.spellsList.push({
        id: state.autoIncrementProgressID + 1,
        val: payload.item,
        refId: payload.id,
      });
      localStorage.setItem("spellsList", JSON.stringify(state.spellsList));
      if (state.isYearGreaterThan2021) {
        if (state.spellsList.length > 0) {
          state.overWriteVarighedValue = Math.max(
            ...state.spellsList.map((o) => parseInt(o.val[0].varighed)),
            0
          ).toString();
        } else {
          state.overWriteVarighedValue = null;
        }
      }
      let contDays = 0;
      if (payload.item[0].contactDays > 0) {
        state.spellsList.forEach((element) => {
          contDays += element.val[0].contactDays;
        });
        state.contactDaysNoProgress = contDays;
      } else {
        state.contactDaysNoProgress = state.spellsList.length;
      }
    },
    editEpisode(state, { payload }) {
      state.age = payload.age;
      state.ageUnit = payload.ageTypeValue;
      state.contactType = payload.contactTypeValue;
      state.gender = payload.genderValue;
      state.birthValue = payload.isBirth == 1 ? "Yes" : "No";
      state.birthType = payload.birthTypeValue;
      state.height = payload.height;
      state.weight = payload.weight;
      state.hospitalisationMethod = payload.hospWayValue;
      state.reasonForContact = payload.reasonForContactValue;
      state.discharge = payload.dischargeValue;
      state.gestationAge =
        payload.genderValue === "1"
          ? payload.maleGestationAgeValue
          : payload.femaleGestationAgeValue;
      state.varighed = payload.varighed;
      state.contactDays = payload.contactDays;
      state.diagnosis = payload.diagnosis;
      state.procedure = payload.procedure;
    },
    deleteEpisode(state, { payload }) {
      /* var index = state.interactiveList.findIndex(
        (item) => item[0].Id == payload
      ); */
      state.interactiveList.splice(payload, 1);
    },
    clearEpisodesInformation(state, { payload }) {
      state.interactiveList = [];
    },
    setInteractiveList(state, { payload }) {
      let refId = state.interactiveList.length;
      refId++;
      let clone = JSON.parse(JSON.stringify(payload));
      clone["Id"] = refId;
      state.interactiveList.push(clone);
      localStorage.setItem(
        "interactiveList",
        JSON.stringify(state.interactiveList)
      );
      state.updateSuccess = true;
    },
    setRequestList(state, { payload }) {
      state.requestList.push(payload);
    },
    showPanels(state, { payload }) {
      state.episodesPanel = true;
      state.spellsPanel = true;
      state.resultsPanel = true;
    },
    setRequestType(state, { payload }) {
      state.requestType = payload;
    },
    setReportDialog(state, { payload }) {
      state.reportDialog = payload;
    },
    setVisualDialog(state, { payload }) {
      state.visualDialog = payload.status;
      state.visualID = payload.id;
      state.visualSearchValue = payload.search;
    },
    clearDiseaseInformation(state, { payload }) {
      state.diagnosisParentCollection = state.diagnosisParentMaster;
      state.proceduresParentCollection = state.procedureParentMaster;
      state.diagnosisChildCollection = null;
      state.proceduresChildCollection = null;
      state.diagnosis = [];
      state.procedure = [];
      state.loadDiagAndProc = false;
      state.isChooseDiagnosis = true;
      state.isChooseDiagAdditional = true;
      state.isChooseProcedure = true;
      state.isChooseProcAdditional = true;
      state.diagnosisSearch = false;
      state.procedureSearch = false;
      state.diagnosisHasChildren = false;
      state.procedureHasChildren = false;
    },
    clearResultInformation(state, { payload }) {
      state.resultList = [];
    },
    clearAllInformation(state, { payload }) {
      state.gender = "";
      state.birthValue = "No";
      state.disableBirth = true;
      state.enableBirthInfo = false;
      state.isMale = true;
      state.age = "32";
      state.ageUnit = "";
      state.gestationAge = "";
      state.discharge = "";
      state.contactType = "";
      state.birthType = "";
      state.height = "163";
      state.weight = "64";
      state.hospitalisationMethod = "";
      state.disableContactReason = true;
      state.reasonForContact = "";
      state.contactDaysStartDate = null;
      state.contactDaysEndDate = null;
      state.varighed = "";
      if (state.isYearGreaterThan2021) {
        state.contactDays = "";
        state.disableContactDays = true;
      } else {
        state.contactDays = "1";
        state.disableContactDays = false;
      }
      state.dateError = false;
      state.diagnosis = [];
      state.procedure = [];
      state.spellsList = [];
      state.contactDaysNoProgress = 0;
      state.interactiveList = [];
      state.resultList = [];
      state.episodesPanel = false;
      state.spellsPanel = false;
      state.resultsPanel = false;
      state.diagnosisParentCollection = state.diagnosisParentMaster;
      state.proceduresParentCollection = state.procedureParentMaster;
      state.diagnosisChildCollection = null;
      state.proceduresChildCollection = null;
      state.diagnosisHasChildren = false;
      state.procedureHasChildren = false;
      state.loadDiagAndProc = false;
      state.isChooseDiagnosis = true;
      state.isChooseDiagAdditional = true;
      state.isChooseProcedure = true;
      state.isChooseProcAdditional = true;
      state.diagnosisSearch = false;
      state.procedureSearch = false;
    },
    setInteractiveLoading(state, { payload }) {
      state.isResultLoading = payload;
    },
    handleErrorClose(state, action) {
      state.resultError = false;
      state.noVisualIDError = false;
    },
    noVisualError(state, action) {
      state.visualDialog = false;
      state.resultError = true;
      state.noVisualIDError = true;
    },
    setExpand(state, action) {
      if (state.resultList.length >= 1) {
        state.resultList.forEach((element, index) => {
          if (action.payload === index) {
            state.resultList[index].expand = !state.resultList[index].expand;
          }
        });
      }
    },
    clearOnContact(state, action) {
      state.diagnosis = [];
    },
    resetInteractive: () => initialState,
  },
  extraReducers: {
    [getInteractiveNames.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInteractiveNames.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getInteractiveNames:", payload);
      state.interactiveCollection = payload;
      state.yearsCollection = payload
        .map((item) => item.year)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((one, two) => (one > two ? -1 : 1));
      //console.log("yearsCollection:", state.yearsCollection);
      var currentYear = new Date().getFullYear();
      let upcomingYear = currentYear + 1;
      let previousYear = currentYear - 1;
      if (state.yearsCollection.includes(JSON.stringify(upcomingYear))) {
        state.selectedInteractiveYear = JSON.stringify(upcomingYear);
      } else if (state.yearsCollection.includes(JSON.stringify(currentYear))) {
        state.selectedInteractiveYear = JSON.stringify(currentYear);
      } else if (state.yearsCollection.includes(JSON.stringify(previousYear))) {
        state.selectedInteractiveYear = JSON.stringify(previousYear);
      } else {
        state.selectedInteractiveYear =
          state.yearsCollection[state.yearsCollection.length - 1];
      }
      var currentYearObj = payload.filter(
        (a) => a.year == state.selectedInteractiveYear
      )[0];
      state.selectedInteractiveObj = currentYearObj;
      state.selectedInteractiveID = currentYearObj.id;
      state.selectedInteractiveName = currentYearObj.interactiveName;
      state.selectedInteractiveYear = currentYearObj.year;
      let year = parseInt(currentYearObj.year);
      if (year > 2021) {
        state.isYearGreaterThan2021 = true;
      } else {
        state.isYearGreaterThan2021 = false;
      }
    },
    [getInteractiveNames.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getInteractiveNames", action.error.message);
    },
    [getZipFile.pending]: (state, action) => {
      state.status = "loading";
    },
    [getZipFile.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getZipFile:", payload);
      if (payload.type == "application/json") {
        state.fileNotExists = true;
      } else {
        state.blobToDownload = payload;
      }
    },
    [getZipFile.rejected]: (state, action) => {
      state.status = "failed";
      state.blobToDownload = null;
      console.log("Error from getZipFile", action.error.message);
    },
    [getAllMasterData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllMasterData.fulfilled]: (state, { payload }) => {
      console.log("Payload from getAllMasterData:", JSON.parse(payload));
      if (payload != null) {
        var data = JSON.parse(payload);
        console.log(data);
        var gender = [];
        Object.entries(data["Gender"]).forEach(([key, value]) => {
          gender.push({
            value: key,
            name: value,
          });
        });
        state.gender = gender[0].value;

        var birthType = [];
        Object.entries(data["BirthType"]).forEach(([key, value]) => {
          birthType.push({
            value: key,
            name: value,
          });
        });
        state.birthType = birthType[0].value;

        var contactType = [];
        Object.entries(data["ContactType"]).forEach(([key, value]) => {
          contactType.push({
            value: key,
            name: value,
            order: value.order,
          });
        });
        contactType.sort(function (a, b) {
          return a.order - b.order;
        });
        state.contactType = contactType[0].value;

        var femaleGestation = [];
        Object.entries(data["FemaleGestation"]).forEach(([key, value]) => {
          femaleGestation.push({
            value: key,
            name: value,
          });
        });
        const femaleGestationDefaultValue = femaleGestation.find(
          (item) => item.name.en === "" || item.name.dk === ""
        );
        if (femaleGestationDefaultValue) {
          state.femaleGestation = femaleGestationDefaultValue.value;
        }

        var hospitalisationMethod = [];
        Object.entries(data["HospitalisationMethod"]).forEach(
          ([key, value]) => {
            hospitalisationMethod.push({
              value: key,
              name: value,
              order: value.order,
            });
          }
        );
        hospitalisationMethod.sort(function (a, b) {
          return a.order - b.order;
        });
        state.hospitalisationMethod = hospitalisationMethod[0].value;

        var maleGestation = [];
        Object.entries(data["MaleGestation"]).forEach(([key, value]) => {
          maleGestation.push({
            value: key,
            name: value,
          });
        });
        const maleGestationDefaultValue = maleGestation.find(
          (item) => item.name.en === "" || item.name.dk === ""
        );
        if (maleGestationDefaultValue) {
          state.gestationAge = maleGestationDefaultValue.value;
        }

        var reasonForContact = [];
        Object.entries(data["ReasonForContact"]).forEach(([key, value]) => {
          reasonForContact.push({
            value: key,
            name: value,
          });
        });
        state.reasonForContact = reasonForContact[0].value;

        var releaseMethod = [];
        Object.entries(data["ReleaseMethod"]).forEach(([key, value]) => {
          releaseMethod.push({
            value: key,
            name: value,
          });
        });
        state.discharge = releaseMethod[0].value;

        var unit = [];
        Object.entries(data["Unit"]).forEach(([key, value]) => {
          unit.push({
            value: key,
            name: value,
          });
        });
        state.ageUnit = unit[0].value;

        var varighed = [];
        Object.entries(data["Varighed"]).forEach(([key, value]) => {
          varighed.push({
            value: key,
            name: value,
          });
        });
        state.varighed = varighed[0].value;
        state.spellVarighed = varighed[0].value;
        state.patientInputs.push({
          gender: gender,
          birthType: birthType,
          contactType: contactType,
          femaleGestation: femaleGestation,
          hospitalisationMethod: hospitalisationMethod,
          maleGestation: maleGestation,
          reasonForContact: reasonForContact,
          releaseMethod: releaseMethod,
          unit: unit,
          varighed: varighed,
        });
      }
    },
    [getAllMasterData.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getAllMasterData", action.error.message);
    },
    [getAllDiagnosis.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllDiagnosis.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getAllDiagnosis:", payload);
      if (payload != null) {
        if (!state.diagnosisHasChildren) {
          state.diagnosisParentCollection = payload;
          state.diagnosisParentMaster = payload;
          state.loadDiagAndProc = true;
        } else {
          state.diagnosisChildCollection = payload;
        }
      }
    },
    [getAllDiagnosis.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getAllDiagnosis", action.error.message);
    },
    [getAllProcedures.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllProcedures.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getAllProcedures:", payload);
      if (!state.procedureHasChildren) {
        state.proceduresParentCollection = payload;
        state.procedureParentMaster = payload;
        state.loadDiagAndProc = true;
      } else {
        state.proceduresChildCollection = payload;
      }
    },
    [getAllProcedures.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getAllProcedures", action.error.message);
    },
    [getDiagnosisSearch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDiagnosisSearch.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDiagnosisSearch:", payload);
      if (payload) {
        state.diagnosisSearchTree = payload;
      }
    },
    [getDiagnosisSearch.rejected]: (state, action) => {
      state.status = "failed";
      state.diagnosisSearch = false;
      console.log("Error from getDiagnosisSearch", action.error.message);
    },
    [getProcedureSearch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getProcedureSearch.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDiagnosisSearch:", payload);
      if (payload) {
        state.procedureSearchTree = payload;
      }
    },
    [getProcedureSearch.rejected]: (state, action) => {
      state.status = "failed";
      state.procedureSearch = false;
      console.log("Error from getDiagnosisSearch", action.error.message);
    },
    [interactiveProcessResult.pending]: (state, action) => {
      state.status = "loading";
    },
    [interactiveProcessResult.fulfilled]: (state, { payload }) => {
      //console.log("Payload from interactiveProcessResult:", payload);
      state.isResultLoading = false;
      if (payload == "Not a valid inputs") {
        state.resultError = true;
        state.errorMessage = "interactive.invalidInput";
      } else if (payload == "No internal codes found") {
        state.resultError = true;
        state.errorMessage = "interactive.noInternalCode";
      } else if (payload.indexOf("Logic does not exist") >= 0) {
        state.resultError = true;
        state.errorMessage = "interactive.noLogic";
      } else if (payload.indexOf("Error. No VisualDRG found.") >= 0) {
        state.resultError = true;
        state.errorMessage = "interactive.noVisual";
      } else {
        let reqId = 1;
        let resLength = state.resultList.length;
        if (resLength > 0) {
          let res = state.resultList[resLength - 1];
          reqId = Number(res.reqestid) + 1;
        }
        if (state.requestType != "episode") {
          for (var i = 0; i < payload.length; i++) payload[i].RId = 0;
        }
        let names = {
          interactiveName: state.selectedInteractiveName,
          interactiveVer: state.selectedInteractiveObj.interactiveVersion,
          year: state.selectedInteractiveYear,
        };
        state.resultList.push({
          reqestid: reqId,
          val: payload,
          names: names,
          expand: true,
        });
        localStorage.setItem("ResultList", JSON.stringify(state.resultList));
        state.episodesPanel = true;
        state.spellsPanel = true;
        state.resultsPanel = true;
        state.isResultLoading = false;
      }
      //console.log("Result List:", state.resultList);
    },
    [interactiveProcessResult.rejected]: (state, action) => {
      state.status = "failed";
      state.isResultLoading = false;
      state.resultError = true;
      state.errorMessage = "interactive.errorOccured";
      console.log("Error from interactiveProcessResult", action.error.message);
    },
    [getVisualDatabyID.pending]: (state, action) => {
      state.status = "loading";
    },
    [getVisualDatabyID.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getVisualDatabyID:", payload);
    },
    [getVisualDatabyID.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getVisualDatabyID", action.error.message);
    },
    [getVisualMetadata.pending]: (state, action) => {
      state.status = "loading";
    },
    [getVisualMetadata.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getVisualMetadata:", payload);
    },
    [getVisualMetadata.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getVisualMetadata", action.error.message);
    },
    [getVisualNodesJSON.pending]: (state, action) => {
      state.status = "loading";
    },
    [getVisualNodesJSON.fulfilled]: (state, { payload }) => {
      console.log("Payload from getVisualNodesJSON:", payload);

      state.dataForGoJs = payload;
      state.isVisualLoading = true;
    },
    [getVisualNodesJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getVisualNodesJSON", action.error.message);
    },
  },
});
export const {
  setSelectedInteractive,
  setInteractiveDetails,
  setSelectedFileToDownload,
  clearValues,
  errorMessageClose,
  setChildrenStatus,
  setPanel,
  setSelectionButton,
  setDiagnosisData,
  setDiagnosis,
  setDiagnosisAdditionalCode,
  updateSelectedDiagnosis,
  diagnosisMoveUp,
  diagnosisMoveDown,
  deleteDiagnosis,
  deleteAdditionalDiagnosis,
  setProcedureData,
  setProcedure,
  setProcedureAdditionalCode,
  updateSelectedProcedure,
  procedureMoveUp,
  procedureMoveDown,
  deleteProcedure,
  deleteAdditionalProcedure,
  setSearchStatus,
  setGender,
  setBirthValue,
  setValues,
  dateErrorClose,
  setDateToDefault,
  clearPatientInformation,
  addToSpell,
  spellMoveLeft,
  spellMoveRight,
  deleteSpell,
  clearSpellInformation,
  expandCollapseUpdate,
  addEpisodeToSpell,
  editEpisode,
  deleteEpisode,
  clearEpisodesInformation,
  setInteractiveList,
  setRequestList,
  showPanels,
  setRequestType,
  setReportDialog,
  setVisualDialog,
  clearDiseaseInformation,
  clearResultInformation,
  clearAllInformation,
  setInteractiveLoading,
  handleErrorClose,
  noVisualError,
  endUserSession,
  setExpand,
  resetInteractive,
  clearOnContact,
} = interactiveSlice.actions;
export default interactiveSlice.reducer;
