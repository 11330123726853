import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as services from "../../services/apiServices";
import { languageChanged } from "../../translations/languageSlice";
import Grid from "@mui/material/Grid";
import ftLogo from "../../assets/logos/logo-footer.svg";
import "./footer.css";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCountries } from "../login/loginSlice";

export default function FooterLayout() {
  const { t, i18n } = useTranslation("language");
  const login = useSelector((state) => state.login);
  const { status, countries } = login;
  const [languageId, setLanguageId] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const language = localStorage.getItem("language");

  useEffect(() => {
    dispatch(getCountries({ method: "GET" }));
    if (language != undefined && language != null) {
      i18n.changeLanguage(language);
      if (language == "en") {
        setLanguageId(1);
      } else if (language == "dk") {
        setLanguageId(2);
      }
    } else localStorage.setItem("language", "en");
  }, []);

  const handleLanguageChange = (event) => {
    if (countries != null && countries.length > 0) {
      var language = countries.find((x) => x.id == event.target.value);
      setLanguageId(language.id);
      i18n.changeLanguage(language.name);
      dispatch(
        languageChanged({
          language: language.name,
        })
      );
    }
  };

  return (
    <div className="footerContainer">
      <Grid container spacing={0}>
        <Grid item xs={2} md={2} lg={2} xl={2}>
          <div className="logo-container">
            <img className="logo-footer" src={ftLogo}></img>
          </div>
        </Grid>

        <Grid item xs={8} md={8} lg={8} xl={8}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonGroup
              variant="text"
              aria-label="outlined primary button group"
            >
              {/* <Button
                style={{
                  borderRight: "none",
                  fontSize: 12,
                  color: "#0768FD",
                }}
                onClick={() => history.push("/terms")}
              >
                {t("footer.terms_use")}
              </Button>
              <Button
                style={{
                  borderRight: "none",
                  fontSize: 12,
                  color: "#0768FD",
                }}
                onClick={() => history.push("/privacy")}
              >
                {t("footer.privacy")}
              </Button> */}
              <Button
                style={{
                  borderRight: "none",
                  fontSize: 12,
                  color: "#0768FD",
                }}
                onClick={() =>
                  window.open(
                    "https://www.customerservice.iqvia.com/support",
                    "_blank"
                  )
                }
              >
                {t("footer.contact")}
              </Button>
            </ButtonGroup>
            <Select
              style={{
                color: "#0768FD",
                fontSize: 12,
                fontWeight: 500,
                width: 80,
                paddingLeft: 10,
              }}
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={languageId}
              onChange={(e) => handleLanguageChange(e)}
            >
              {countries ? (
                countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>{country.language}</MenuItem>
                ))
              ) : (
                <div></div>
              )}
            </Select>
          </Box>
        </Grid>

        <Grid item xs={2} md={2} lg={2} xl={2}>
          <div className="copyrightContainer">
            <span className="copyrightText">© IQVIA</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
