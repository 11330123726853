import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

export const GetDRGLogicList = createAsyncThunk(
  "editor/GetDRGLogicList",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    return fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetDRGLogic = createAsyncThunk(
  "editor/GetDRGLogic",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    let url = "api/GetDRGLogic?drgLogic=" + selectedEditorID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const CheckDrgLogicLockStatus = createAsyncThunk(
  "editor/CheckDrgLogicLockStatus",
  async (obj, { dispatch, getState }) => {
    const { user } = getState().authentication;
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    let url =
      "api/CheckDrgLogicLockStatus?drgLogicID=" +
      selectedEditorID +
      "&EmailID=" +
      user[0]["email"] +
      "&isAdmin=" +
      user[0].isAdmin;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetEditorName = createAsyncThunk(
  "editor/GetEditorName",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetEditorName?drgLogicID=" + selectedEditorID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetLockedRows = createAsyncThunk(
  "editor/GetLockedRows",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    let url = "api/GetLockedRows?DrgLogicID=" + selectedEditorID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetLockedRow = createAsyncThunk(
  "editor/GetLockedRow",
  async (obj, { dispatch, getState }) => {
    const { user } = getState().authentication;
    const { lockBtnText } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    return fetch(obj.url, reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null && payload.length > 0) {
          if (
            payload[0].createdByUser == user[0]["email"] ||
            user[0].isAdmin == "true"
          ) {
            dispatch(setIsEditorEditable(true));
          } else {
            dispatch(
              setSatusMessages({
                method: "GetLockedRow",
                lockedBy: payload[0].createdByUser,
              })
            );
            dispatch(setIsEditorEditable(false));
          }
        } else {
          dispatch(setIsEditorEditable(true));
        }
      });
  }
);

export const GetPublishAndPreProd = createAsyncThunk(
  "editor/GetPublishAndPreProd",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    let url = "api/GetPublishAndPreProd?drgLogicID=" + selectedEditorID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const DrgLogicLock = createAsyncThunk(
  "editor/DrgLogicLock",
  async (obj, { dispatch, getState, state }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return fetch(obj.url, reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null && payload == true) {
          dispatch(CheckDrgLogicLockStatus({ method: "GET" })).then(() => {
            dispatch(setSatusMessages({ method: "CheckDrgLogicLockStatus" }));
            dispatch(GetLockedRows({ method: "GET" }));
          });
        }
      });
  }
);

export const LockDrgRules = createAsyncThunk(
  "editor/LockDrgRules",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    let url = "api/Editor/LockDrgRules";
    return fetch(url, reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null && payload == true) {
          dispatch(GetLockedRows({ method: "GET" })).then(() => {
            dispatch(
              setSatusMessages({ method: "LockDrgRules", status: true })
            );
          });
        }
      });
  }
);

export const DeleteLockedRows = createAsyncThunk(
  "editor/DeleteLockedRows",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return fetch(obj.url, reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null && payload == true) {
          dispatch(GetLockedRows({ method: "GET" })).then(() => {
            dispatch(
              setSatusMessages({ method: "DeleteLockedRows", status: true })
            );
          });
        } else {
          dispatch(
            setSatusMessages({ method: "DeleteLockedRows", status: false })
          );
        }
      });
  }
);

export const GetGroupCodes = createAsyncThunk(
  "editor/GetGroupCodes",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = obj.body;
    return fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetGroupCodesSubList = createAsyncThunk(
  "editor/GetGroupCodesSubList",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = obj.body;
    return fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GEtSKSForLogic = createAsyncThunk(
  "editor/GEtSKSForLogic",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    //reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = obj.body;
    let url = "api/GEtSKSForLogic?id=" + selectedEditorID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const SaveDrgRule = createAsyncThunk(
  "editor/SaveDrgRule",
  async (obj, { dispatch, getState }) => {
    const { masterSelectedEditorData } = getState().editor;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return fetch(obj.url, reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null && payload == true) {
          dispatch(setSatusMessages({ method: "SaveDrgRule", status: true }));
          dispatch(setSelectedEditorData(masterSelectedEditorData));
        } else {
          dispatch(setSatusMessages({ method: "SaveDrgRule", status: false }));
        }
      });
  }
);

export const CheckDrgLogicExists = createAsyncThunk(
  "editor/CheckDrgLogicExists",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const SaveAsDrgRule = createAsyncThunk(
  "editor/SaveAsDrgRule",
  async (obj, { dispatch, getState }) => {
    const { selectedEditorID } = getState().editor;
    const { user } = getState().authentication;
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // const token = sessionStorage.getItem("token");
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsIm5iZiI6MTY1MjI2MzE1MiwiZXhwIjoxNjUyODY3OTUyLCJpYXQiOjE2NTIyNjMxNTJ9.s8e_XfHMQEj5H2nAFRKX9Lx9koRepy0sJ74CHT2cTIw";
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return fetch(obj.url, reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null) {
          dispatch(
            setSatusMessages({
              method: "SaveAsDrgRule",
              status: true,
              id: payload,
            })
          );
          let url =
            "api/GetDRGLogicList?userId=" +
            user[0]["email"] +
            "&isAdmin=" +
            user[0].isAdmin;
          dispatch(GetDRGLogicList({ method: "GET", url: url })).then(() => {
            dispatch(GetDRGLogic({ method: "GET" })).then(() => {
              dispatch(CheckDrgLogicLockStatus({ method: "GET" }));
              dispatch(GetEditorName({ method: "GET" }));
              dispatch(GetLockedRows({ method: "GET" }));
              dispatch(GetPublishAndPreProd({ method: "GET" })).then(() => {
                dispatch(setReadOnly(user));
                dispatch(GEtSKSForLogic({ method: "GET" }));
              })
            });
          });
        }
      });
  }
);

export const getAllCodes = createAsyncThunk(
  "editor/getAllCodes",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetAllCodes?groupCode=" +
      obj.groupCode +
      "&drgLogicName=" +
      obj.drgLogicID;

    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const checkLockforClassificationCode = createAsyncThunk(
  "editor/checkLockforClassificationCode",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/CheckLockforClassificationCode?emailId=" +
      obj.emailID +
      "&logicId=" +
      obj.drgLogicID +
      "&grpType=" +
      obj.masterGroupCode +
      "&grpCode=" +
      obj.groupCode;

    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const saveGroupCodes = createAsyncThunk(
  "editor/saveGroupCodes",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = sessionStorage.getItem("token");

    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    console.log("body:", obj.body);
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    let url =
      "api/Editor/SaveGroupCodes?masterCode=" +
      obj.masterGroupCode +
      "&drgLogicName=" +
      obj.drgLogicID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getCountingCodes = createAsyncThunk(
  "editor/getCountingCodes",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetCountingCodes?drglogicName=" + obj.drgLogicID;

    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const updateCountingCodes = createAsyncThunk(
  "editor/updateCountingCodes",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = sessionStorage.getItem("token");

    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    console.log("body:", obj.body);
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return fetch("api/UpdateCountingCodes", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGLogicLogs = createAsyncThunk(
  "editor/getDRGLogicLogs",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetAllLog?drgLogic=" + obj.drgLogicID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetGroupCodesLog = createAsyncThunk(
  "editor/GetGroupCodesLog",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetGroupCodesLog?drgLogic=" + obj.drgLogicID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  isError: false,
  isServerError: false,
  editorCollection: [],
  yearsCollection: [],
  selectedEditorObj: [],
  selectedEditorID: "",
  selectedEditorName: "",
  selectedEditorYear: "",
  selectedEditorVersion: "",
  masterSelectedEditorData: [],
  selectedEditorData: [],
  selectedLockedRows: [],
  lockBtnText: "",
  selectedEditorPerson: "",
  isPublished: false,
  isPreProd: false,
  isTest: false,
  selectedEditorRule: {},
  masterGroupCodesList: [],
  groupCodesList: [],
  selectedGroupCode: "",
  masterGroupCodesSubList: [],
  groupCodesSubList: [],
  SKSList: [],
  openContainsLogic: false,
  selectedCellDetails: {},
  openCellEdit: false,
  selectedRows: [],
  DrgLogicComments: [],
  isDrgLogicExists: null,
  metaData: {},
  selectionModel: [],
  isDelete: false,
  isDuplicate: false,
  isPaste: false,
  isSelectedRow: {},
  isEditor: false,
  isViewer: false,
  isClassificationCodesEnabled: true,
  countingCodes: "",
  isSaveAs: false,
  isSaveAsId: "",
  isRowLocked: false,
  isRowUnlocked: false,
  isRowUnlockFailed: false,
  isPasted: false,
  isDuplicated: false,
  isDeleted: false,
  isSaveSuccess: false,
  isSaveFailed: false,
  isLogicLocked: false,
  isLogicUnlocked: false,
  isCountingCodeUpdated: false,
  isCountingCodeFileError: false,
  countingCodeUpdatedFailed: false,
  isRowLockedByStatus: false,
  isRowLockedBy: "",
  logsLoading: true,
  masterDRGLogicLogs: [],
  drgLogicLogs: [],
  masterGroupCodesLogs: [],
  groupCodesLogs: [],
  masterAllGroupCodes: [],
  grpCodesList: [],
  grpCodesListMaster: [],
  selectedGrpCode: "",
  canModify: false,
  grpCodesSubList: [],
  grpCodesSubListMaster: [],
  grpCodeLoading: true,
  groupCodeLogs: [],
  groupCodeExists: false,
  groupCodeEditWarning: false,
  currentEditGrpCode: "",
  subCodeExists: false,
  currentEditSubCode: "",
  groupCodesUpdated: false,
  groupCodesUpdateWarning: false,
  groupCodesWarningMessage: "",
  isEditorEditable: null,
  saveGroupCodeError: false,
  isInfoBulkActions: false,
  isSessionValid: true,
  existingSubCodes: "",
  isUnSavedData: false,
  isSearchFilter: false,
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setExistingSubcodes(state, action) {
      state.existingSubCodes = action.payload;
    },
    endUserSession(state, action) {
      state.isSessionValid = false;
    },
    clearStatus(state, action) {
      state.isSuccess = false;
      state.isFailed = false;
      state.isError = false;
      state.isServerError = false;
      state.openCellEdit = false;
      state.openCellEditContains = false;
      state.isDrgLogicExists = null;
      state.selectionModel = [];
      state.isSelectedRow = {};
      state.isDuplicate = false;
      state.isDelete = false;
      state.isPaste = false;
      state.isSaveAs = false;
      state.isRowLocked = false;
      state.isRowUnlocked = false;
      state.isPasted = false;
      state.isDuplicated = false;
      state.isDeleted = false;
      state.isSaveSuccess = false;
      state.isSaveFailed = false;
      state.isLogicLocked = false;
      state.isLogicUnlocked = false;
      state.isCountingCodeUpdated = false;
      state.isCountingCodeFileError = false;
      state.countingCodeUpdatedFailed = false;
      state.isRowLockedByStatus = false;
      state.isRowLockedBy = "";
      state.groupCodeExists = false;
      state.groupCodeEditWarning = false;
      state.subCodeExists = false;
      state.groupCodesUpdated = false;
      state.groupCodesUpdateWarning = false;
      state.saveGroupCodeError = false;
      state.isEditorEditable = null;
      state.isInfoBulkActions = false;
    },
    setSelectedEditor(state, action) {
      state.selectedEditorObj = action.payload;
    },
    setEditorDetails(state, action) {
      state.selectedEditorID = action.payload.id;
      state.selectedEditorName = action.payload.name;
      state.selectedEditorYear = action.payload.year;
      state.selectedEditorVersion = action.payload.version;

      // let year = parseInt(action.payload.year);
      // if (year > 2021) {
      //     state.isYearGreaterThan2021 = true;
      // } else {
      //     state.isYearGreaterThan2021 = false;
      // }
    },
    setMasterSelectedEditorData(state, action) {
      state.masterSelectedEditorData = action.payload;
    },
    setSelectedEditorData(state, action) {
      state.selectedEditorData = action.payload;
      if (state.isSaveSuccess == false && state.isSearchFilter == false) {
        state.isUnSavedData = true;
      }
    },
    setSelectedEditorRule(state, action) {
      state.selectedEditorRule = action.payload;
    },
    setSelectedGroupCode(state, action) {
      state.selectedGroupCode = action.payload;
    },
    setGroupCodesFilter(state, action) {
      state.groupCodesList = action.payload;
    },
    setDiagonisFilter(state, action) {
      state.groupCodesSubList = action.payload;
    },
    setOpenCellEditContains(state, action) {
      state.openCellEditContains = action.payload;
      state.masterGroupCodesList = [];
      state.groupCodesList = [];
      state.selectedGroupCode = "";
      state.masterGroupCodesSubList = [];
      state.groupCodesSubList = [];
    },
    setOpenCellEdit(state, action) {
      state.openCellEdit = action.payload;
    },
    setOnCellDoubleClick(state, action) {
      state.selectedCellDetails = action.payload;
      state.selectedRows = action.payload.selectedRows;
    },
    setDrgLogicComments(state, action) {
      if (action.payload.length > 1) {
        action.payload.forEach((element) => {
          state.DrgLogicComments.push(element);
        });
      } else {
        state.DrgLogicComments.push(action.payload);
      }
    },
    setMetaData(state, action) {
      state.metaData = action.payload;
      console.log(state.metaData);
    },
    setSelectionModel(state, action) {
      state.selectionModel = action.payload;
    },
    setGridActions(state, action) { },
    setIsPaste(state, action) {
      state.isPaste = true;
      state.isSelectedRow = action.payload;
    },
    setIsDuplicate(state, action) {
      state.isDuplicate = true;
      state.isSelectedRow = action.payload;
    },
    setIsDelete(state, action) {
      state.isDelete = true;
      state.isSelectedRow = action.payload;
    },
    setReadOnly(state, action) {
      if (action.payload[0].roleId == 2) {
        state.isEditor = true;
      } else if (action.payload[0].roleId == 3) {
        state.isViewer = true;
      }
      if (
        state.isPublished == true ||
        state.isPreProd == true ||
        state.isTest == true ||
        state.isViewer == true
      ) {
        state.isClassificationCodesEnabled = false;
      }
      if (state.lockBtn == "Unlock Logic") {
        if (action.payload[0].isAdmin || state.isEditor == true) {
          // this.isDisabled = false;
          // this.isMenuDisabled = false;
          // this.isUserLocked = false;
          state.isClassificationCodesEnabled = true;
        } else {
          // this.isDisabled = true;
          // this.isMenuDisabled = true;
          // this.isUserLocked = true;
          state.isClassificationCodesEnabled = false;
        }
      } else {
        state.isClassificationCodesEnabled = true;
      }
    },
    setSatusMessages(state, action) {
      state.isLoading = false;
      if (
        action.payload ?.method == "CheckDrgLogicLockStatus" &&
          state.lockBtnText == "Lock Logic"
      ) {
        state.isSuccess = true;
        state.isLogicUnlocked = true;
      } else if (
        action.payload ?.method == "CheckDrgLogicLockStatus" &&
          state.lockBtnText == "Unlock Logic"
      ) {
        state.isSuccess = true;
        state.isLogicLocked = true;
      } else if (
        action.payload ?.method == "LockDrgRules" &&
          action.payload ?.status == true
      ) {
        state.isSuccess = true;
        state.isRowLocked = true;
      } else if (
        action.payload ?.method == "DeleteLockedRows" &&
          action.payload ?.status == true
      ) {
        state.isSuccess = true;
        state.isRowUnlocked = true;
      } else if (
        action.payload ?.method == "DeleteLockedRows" &&
          action.payload ?.status == false
      ) {
        state.isFailed = true;
        state.isRowUnlockFailed = true;
      } else if (
        action.payload ?.method == "Pasted" &&
          action.payload ?.status == true
      ) {
        state.isSuccess = true;
        state.isPasted = true;
      } else if (
        action.payload ?.method == "Duplicated" &&
          action.payload ?.status == true
      ) {
        state.isSuccess = true;
        state.isDuplicated = true;
      } else if (
        action.payload ?.method == "Deleted" &&
          action.payload ?.status == true
      ) {
        state.isSuccess = true;
        state.isDeleted = true;
      } else if (
        action.payload ?.method == "SaveDrgRule" &&
          action.payload ?.status == true
      ) {
        state.isSuccess = true;
        state.isSaveSuccess = true;
        state.DrgLogicComments = [];
      } else if (
        action.payload ?.method == "SaveDrgRule" &&
          action.payload ?.status == false
      ) {
        state.isSuccess = true;
        state.isSaveFailed = true;
        state.DrgLogicComments = [];
      } else if (
        action.payload ?.method == "SaveAsDrgRule" &&
          action.payload ?.status == true
      ) {
        state.isSuccess = true;
        state.isSaveSuccess = true;
        state.DrgLogicComments = [];
        state.isSaveAs = true;
        state.isSaveAsId = action.payload ?.id;
      } else if (action.payload ?.method == "GetLockedRow") {
        console.log("lockedBy:", action.payload.lockedBy);
        state.isError = true;
        state.isRowLockedByStatus = true;
        state.isRowLockedBy = action.payload ?.lockedBy;
      } else if (action.payload ?.method == "info_bulkactions") {
        state.isFailed = true;
        state.isInfoBulkActions = true;
      }
    },
    setdrgLogicLogs(state, action) {
      state.drgLogicLogs = action.payload;
    },
    setgroupCodesLogs(state, action) {
      state.groupCodesLogs = action.payload;
    },
    addnewGroupCode(state, { payload }) {
      state.grpCodesList.splice(0, 0, {
        name: payload.newGrpCode,
        isDisabled: true,
      });
      state.masterAllGroupCodes.push({ name: payload.newGrpCode, value: "" });
      state.groupCodeLogs.push(payload.log);
    },
    setGroupCodeWarning(state, { payload }) {
      if (payload == "groupCodeExist") {
        state.isFailed = true;
        state.groupCodeExists = true;
      } else if (payload == "groupCodeEditWarning") {
        state.isFailed = true;
        state.groupCodeEditWarning = true;
      } else if (payload == "subCodeExist") {
        state.isFailed = true;
        state.subCodeExists = true;
      }
    },
    setSelectedGrpCode(state, { payload }) {
      state.selectedGrpCode = payload;
    },
    setGrpCodeEditable(state, { payload }) {
      for (let i = 0; i < state.grpCodesList.length; i++) {
        state.grpCodesList[i].isDisabled = true;
      }
      state.grpCodesList.find((p) => p.name == payload).isDisabled = false;
      state.currentEditGrpCode = payload;
    },
    saveGroupCode(state, { payload }) {
      var index = state.masterAllGroupCodes.findIndex(
        (a) => a.name == payload.name
      );
      var values = state.masterAllGroupCodes[index].value;
      state.grpCodesList.find((p) => p.name == payload.name).isDisabled = true;
      state.grpCodesList.find((p) => p.name == payload.name).name =
        payload.newCode;

      state.masterAllGroupCodes.splice(index, 1);
      state.masterAllGroupCodes.splice(index, 0, {
        name: payload.newCode,
        value: values,
      });
      state.groupCodeLogs.push(payload.log);
      state.selectedGrpCode = state.grpCodesList[0].name;
    },
    cancelEditGroupCode(state, { payload }) {
      state.grpCodesList.find((p) => p.name == payload).isDisabled = true;
    },
    setCurrentGroupCode(state, { payload }) {
      state.currentEditGrpCode = payload;
    },
    deleteGroupCode(state, { payload }) {
      if (state.grpCodesList != undefined && state.grpCodesList != null) {
        var index1 = state.grpCodesList.findIndex(
          (a) => a.name == payload.name
        );
        var allCodeIndex = state.masterAllGroupCodes.findIndex(
          (a) => a.name == payload.name
        );
        state.grpCodesList.splice(index1, 1);
        state.masterAllGroupCodes.splice(allCodeIndex, 1);
        if (state.grpCodesList.length > 0) {
          state.selectedGrpCode = state.grpCodesList[0].name;
        } else {
          state.selectedGrpCode = "";
        }
        var recExist = state.groupCodeLogs.findIndex(
          (p) =>
            p.masterGroupCode == payload.masterGroupCode &&
            p.GroupCode == payload.selectedGroupCode &&
            p.NewValue == payload.name.toUpperCase()
        );
        if (recExist >= 0) {
          state.groupCodeLogs.splice(recExist, 1);
        } else {
          state.groupCodeLogs.push(payload.log);
        }
      }
    },
    addnewSubCode(state, { payload }) {
      const { newDgCode, newDgCodeText, selectedGrpCode, newSubList, log } =
        payload;
      if (!newSubList) {
        state.grpCodesSubList.splice(0, 0, {
          name: newDgCode.toUpperCase(),
          Description: newDgCodeText,
          isDisabled: true,
        });
        var subGrpCodes = [];
        for (let j = 0; j < state.grpCodesSubList.length; j++) {
          subGrpCodes.push(state.grpCodesSubList[j].name);
        }
        var allGrpCodeIndex = state.masterAllGroupCodes.findIndex(
          (i) => i.name == selectedGrpCode.toUpperCase()
        );
        state.masterAllGroupCodes.splice(allGrpCodeIndex, 1);
        state.masterAllGroupCodes.splice(allGrpCodeIndex, 0, {
          name: selectedGrpCode.toUpperCase(),
          value: subGrpCodes,
        });
        state.groupCodeLogs.push(log);
      } else {
        if (state.grpCodesSubList.length == 0) {
          state.grpCodesSubList = [];
        }
        state.grpCodesSubList.splice(0, 0, {
          name: newDgCode.toUpperCase(),
          Description: newDgCodeText,
          isDisabled: true,
        });
        var subGrpCodes = [];
        for (let j = 0; j < state.grpCodesSubList.length; j++) {
          subGrpCodes.push(state.grpCodesSubList[j].name);
        }
        var allGrpCodeIndex = state.masterAllGroupCodes.findIndex(
          (i) => i.name == selectedGrpCode.toUpperCase()
        );
        state.masterAllGroupCodes.splice(allGrpCodeIndex, 1);
        state.masterAllGroupCodes.push({
          name: selectedGrpCode.toUpperCase(),
          value: subGrpCodes,
        });
        state.groupCodeLogs.push(log);
      }
    },
    setSubCodeEditable(state, { payload }) {
      for (let i = 0; i < state.grpCodesSubList.length; i++) {
        state.grpCodesSubList[i].isDisabled = true;
      }
      state.grpCodesSubList.find(
        (p) => p.name == payload.name
      ).isDisabled = false;
      state.currentEditSubCode = payload;
    },
    saveSubCode(state, { payload }) {
      const { name, updatedCode, log } = payload;
      state.grpCodesSubList.find((p) => p.name == name).isDisabled = true;
      state.grpCodesSubList.find((p) => p.name == name).name = updatedCode;

      var grpCodeIndex = state.masterAllGroupCodes.findIndex(
        (a) => a.name == state.selectedGrpCode
      );
      state.masterAllGroupCodes.splice(grpCodeIndex, 1);
      var subGrpCodes = [];
      for (let j = 0; j < state.grpCodesSubList.length; j++) {
        subGrpCodes.push(state.grpCodesSubList[j].name);
      }
      state.masterAllGroupCodes.splice(grpCodeIndex, 0, {
        name: state.selectedGrpCode.toUpperCase(),
        value: subGrpCodes,
      });
      state.groupCodeLogs.push(log);
    },
    cancelEditSubCode(state, { payload }) {
      state.grpCodesSubList.find((p) => p.name == payload).isDisabled = true;
    },
    setCurrentSubCode(state, { payload }) {
      state.currentEditSubCode = payload;
    },
    deleteSubCode(state, { payload }) {
      if (state.grpCodesSubList != undefined && state.grpCodesSubList != null) {
        var index = state.grpCodesSubList.findIndex(
          (a) => a.name == payload.name
        );
        var allCodeIndex = state.masterAllGroupCodes.findIndex(
          (a) => a.name == payload.name
        );
        var grpCodeIndex = state.masterAllGroupCodes.findIndex(
          (a) => a.name == state.selectedGrpCode
        );
        state.grpCodesSubList.splice(index, 1);
        state.masterAllGroupCodes.splice(grpCodeIndex, 1);
        var subGrpCodes = [];
        for (let j = 0; j < state.grpCodesSubList.length; j++) {
          subGrpCodes.push(state.grpCodesSubList[j].name);
        }
        state.masterAllGroupCodes.splice(grpCodeIndex, 0, {
          name: state.selectedGrpCode.toUpperCase(),
          value: subGrpCodes,
        });
        var recExist = state.groupCodeLogs.findIndex(
          (p) =>
            p.masterGroupCode == payload.masterGroupCode &&
            p.GroupCode == state.selectedGrpCode &&
            p.NewValue == payload.name.toUpperCase()
        );
        if (recExist >= 0) {
          state.groupCodeLogs.splice(recExist, 1);
        } else {
          state.groupCodeLogs.push(payload.log);
        }
      }
    },
    setgroupCodesWarning(state, { payload }) {
      state.groupCodesWarningMessage = payload;
      state.groupCodesUpdateWarning = true;
      state.isFailed = true;
    },
    setGroupCodesList(state, { payload }) {
      state.grpCodesList = payload;
    },
    setSubCodesList(state, { payload }) {
      state.grpCodesSubList = payload;
    },
    setIsEditorEditable(state, action) {
      state.isEditorEditable = action.payload;
    },
    clearBackToEditor(state, action) {
      state.drgLogicLogs = [];
      state.masterDRGLogicLogs = [];
      state.groupCodesLogs = [];
      state.masterGroupCodesLogs = [];
    },
    setUnSavedData(state, action) {
      state.isUnSavedData = action.payload;
    },
    setSearchFilter(state, action){
      state.isSearchFilter = action.payload;
    },
    resetEditor: () => initialState,
  },
  extraReducers: {
    [GetDRGLogicList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetDRGLogicList.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        state.editorCollection = payload;
        state.yearsCollection = payload
          .map((item) => item.year)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((one, two) => (one > two ? -1 : 1));
        if (state.selectedEditorID == "") {
          if (state.isSaveAs) {
            var selectedYearObj = payload.filter(
              (a) => a.id == state.isSaveAsId
            )[0];
            state.selectedEditorYear = selectedYearObj.year;
          } else {
            var currentYear = new Date().getFullYear();
            let upcomingYear = currentYear + 1;
            let previousYear = currentYear - 1;
            if (state.yearsCollection.includes(JSON.stringify(upcomingYear))) {
              state.selectedEditorYear = JSON.stringify(upcomingYear);
            } else if (
              state.yearsCollection.includes(JSON.stringify(currentYear))
            ) {
              state.selectedEditorYear = JSON.stringify(currentYear);
            } else if (
              state.yearsCollection.includes(JSON.stringify(previousYear))
            ) {
              state.selectedEditorYear = JSON.stringify(previousYear);
            } else {
              state.selectedEditorYear =
                state.yearsCollection[state.yearsCollection.length - 1];
            }
            var selectedYearObj = payload.filter(
              (a) => a.year == state.selectedEditorYear
            )[0];
          }
          state.selectedEditorObj = selectedYearObj;
          state.selectedEditorID = selectedYearObj.id;
          state.selectedEditorName = selectedYearObj.name;
          state.selectedEditorVersion = selectedYearObj.version;
        }
        else {
          var selectedYearObj = payload.filter(
            (a) => a.year == state.selectedEditorYear
          )[0];
          state.selectedEditorObj = selectedYearObj;
        }
      }
    },
    [GetDRGLogicList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetDRGLogicList", action.error.message);
    },
    [GetDRGLogic.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetDRGLogic.fulfilled]: (state, { payload }) => {
      if (payload != null) {
        state.masterSelectedEditorData = payload;
        state.selectedEditorData = payload;
      }
    },
    [GetDRGLogic.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetDRGLogic", action.error.message);
    },
    [CheckDrgLogicLockStatus.pending]: (state, action) => { },
    [CheckDrgLogicLockStatus.fulfilled]: (state, { payload }) => {
      if (payload != null) {
        state.lockBtnText = payload;
      }
    },
    [CheckDrgLogicLockStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from CheckDrgLogicLockStatus", action.error.message);
    },
    [GetEditorName.pending]: (state, action) => {
      // state.isLoading = true;
    },
    [GetEditorName.fulfilled]: (state, { payload }) => {
      if (payload != null) {
        state.selectedEditorPerson = payload;
      }
    },
    [GetEditorName.rejected]: (state, action) => {
      // state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetEditorName", action.error.message);
    },
    [GetLockedRows.pending]: (state, action) => {
      // state.isLoading = true;
    },
    [GetLockedRows.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length >= 0) {
        state.selectedLockedRows = payload;
      }
    },
    [GetLockedRows.rejected]: (state, action) => {
      // state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetLockedRows", action.error.message);
    },
    [GetLockedRow.pending]: (state, action) => {
      // state.isLoading = true;
    },
    [GetLockedRow.fulfilled]: (state, { payload }) => { },
    [GetLockedRow.rejected]: (state, action) => {
      // state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetLockedRows", action.error.message);
    },
    [GetPublishAndPreProd.pending]: (state, action) => {
      // state.isLoading = true;
    },
    [GetPublishAndPreProd.fulfilled]: (state, { payload }) => {
      if (payload != null) {
        state.isPublished = payload.isPublished;
        state.isPreProd = payload.isPreProd;
        state.isTest = payload.isTest;
      } else {
        state.isPublished = false;
        state.isPreProd = false;
      }
    },
    [GetPublishAndPreProd.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetPublishAndPreProd", action.error.message);
    },
    [DrgLogicLock.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DrgLogicLock.fulfilled]: (state, { payload }) => { },
    [DrgLogicLock.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from DrgLogicLock", action.error.message);
    },
    [LockDrgRules.pending]: (state, action) => {
      state.isLoading = true;
    },
    [LockDrgRules.fulfilled]: (state, { payload }) => { },
    [LockDrgRules.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from LockDrgRules", action.error.message);
    },
    [DeleteLockedRows.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DeleteLockedRows.fulfilled]: (state, { payload }) => { },
    [DeleteLockedRows.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from DeleteLockedRows", action.error.message);
    },
    [GetGroupCodes.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetGroupCodes.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload != null && payload.length > 0) {
        state.masterGroupCodesList = payload;
        state.groupCodesList = payload;
      }
      // else if (payload != null && payload == false) {

      // }
      // else{
      //     state.isError = true;
      //     state.isServerError = true;
      // }
    },
    [GetGroupCodes.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetGroupCodes", action.error.message);
    },
    [GetGroupCodesSubList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetGroupCodesSubList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload != null && payload.length > 0) {
        state.groupCodesSubList = [];
        // for (let i = 0; i < payload.length; i++) {
        //   if (payload[i].trim().length > 0) {
        //     var description = state.SKSList.find((p) => p.code == payload[i]);
        //     state.masterGroupCodesSubList.push({
        //       name: payload[i],
        //       Description: description == undefined ? "" : description.text,
        //       isDisabled: true,
        //     });
        //     state.groupCodesSubList.push({
        //       name: payload[i],
        //       Description: description == undefined ? "" : description.text,
        //       isDisabled: true,
        //     });
        //   }
        // }
        for (let i = 0; i < payload.length; i++) {
          if (payload[i] ?.trim().length > 0) {
            state.groupCodesSubList.push({
              name: payload[i],
              Description: "",
              isDisabled: true,
            });
          }
        }
        state.masterGroupCodesSubList = state.groupCodesSubList;
      } else {
        state.groupCodesSubList = [];
      }
    },
    [GetGroupCodesSubList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetGroupCodesSubList", action.error.message);
    },
    [GEtSKSForLogic.pending]: (state, action) => {
      // state.isLoading = true;
    },
    [GEtSKSForLogic.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload != null) {
        state.SKSList = payload;
      }
    },
    [GEtSKSForLogic.rejected]: (state, action) => {
      state.isLoading = false;
      //state.isError = true;
      //state.isServerError = true;
      console.log("Error from GEtSKSForLogic", action.error.message);
    },
    [SaveDrgRule.pending]: (state, action) => {
      state.isLoading = true;
    },
    [SaveDrgRule.fulfilled]: (state, { payload }) => { },
    [SaveDrgRule.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from SaveDrgRule", action.error.message);
    },
    [CheckDrgLogicExists.pending]: (state, action) => { },
    [CheckDrgLogicExists.fulfilled]: (state, { payload }) => {
      if (payload != null) {
        state.isDrgLogicExists = payload;
      }
    },
    [CheckDrgLogicExists.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from CheckDrgLogicExists", action.error.message);
    },
    [SaveAsDrgRule.pending]: (state, action) => {
      state.isLoading = true;
    },
    [SaveAsDrgRule.fulfilled]: (state, { payload }) => { },
    [SaveAsDrgRule.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from SaveAsDrgRule", action.error.message);
    },
    [getAllCodes.pending]: (state, action) => {
      state.grpCodeLoading = true;
    },
    [getAllCodes.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getAllCodes:", payload);
      state.grpCodeLoading = false;
      if (payload != null) {
        if (payload != 0) {
          state.masterAllGroupCodes = payload;
          state.grpCodesList = [];
          var grpCodes = payload.map((a) => a.name);
          for (let i = 0; i < grpCodes.length; i++) {
            state.grpCodesList.push({ name: grpCodes[i], isDisabled: true });
          }
          if (state.grpCodesList != undefined && state.grpCodesList != null) {
            state.selectedGrpCode = state.grpCodesList[0].name;
          }
          state.grpCodesListMaster = state.grpCodesList;
        } else {
          state.selectedGrpCode = "";
        }
      }
    },
    [getAllCodes.rejected]: (state, action) => {
      //state.isLoading = false;
      state.grpCodeLoading = false;
      state.isServerError = true;
      console.log("Error from getAllCodes", action.error.message);
    },
    [checkLockforClassificationCode.pending]: (state, action) => {
      state.grpCodeLoading = true;
    },
    [checkLockforClassificationCode.fulfilled]: (state, { payload }) => {
      //console.log("Payload from checkLockforClassificationCode:", payload);
      if (payload != null) {
        state.canModify = payload;
      }

      if (state.selectedGrpCode != "" && state.selectedGrpCode != null) {
        var allGrpCodeSubList = state.masterAllGroupCodes.filter(
          (item) => item.name == state.selectedGrpCode
        )[0].value;
        state.grpCodesSubList = [];
        for (let i = 0; i < allGrpCodeSubList.length; i++) {
          if (allGrpCodeSubList[i] ?.trim().length > 0) {
            //commented this because of performance issue, need to add description along with 
            //the allGrpCodeSubList rather than filtering SKSList
            // var description = state.SKSList.find(
            //   (p) => p.code == allGrpCodeSubList[i]
            // );
            state.grpCodesSubList.push({
              name: allGrpCodeSubList[i],
              Description: "",//description == undefined ? "" : description.text,
              isDisabled: true,
            });
          }
        }
        state.grpCodesSubListMaster = state.grpCodesSubList;
      }
      state.grpCodeLoading = false;
    },
    [checkLockforClassificationCode.rejected]: (state, action) => {
      state.grpCodeLoading = false;
      state.isServerError = true;
      console.log(
        "Error from checkLockforClassificationCode",
        action.error.message
      );
    },
    [saveGroupCodes.pending]: (state, action) => {
      state.grpCodeLoading = true;
      // state.isLoading = true;
    },
    [saveGroupCodes.fulfilled]: (state, { payload }) => {
      // console.log("Payload from saveGroupCodes:", payload);
      state.grpCodeLoading = false;
      if (payload != null && payload == true) {
        state.groupCodeLogs = [];
        state.groupCodesUpdated = true;
        state.isSuccess = true;
      }
      else {
        state.groupCodesUpdated = false;
        state.saveGroupCodeError = true;
        state.isError = true;
      }
    },
    [saveGroupCodes.rejected]: (state, action) => {
      //state.isLoading = false;
      state.groupCodesUpdated = false;
      state.saveGroupCodeError = true;
      state.isError = true;
      console.log("Error from saveGroupCodes", action.error.message);
    },

    [getCountingCodes.pending]: (state, action) => {
      // state.isLoading = true;
    },
    [getCountingCodes.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getCountingCodes:", payload);
      if (payload != null) {
        state.countingCodes = payload;
      }
    },
    [getCountingCodes.rejected]: (state, action) => {
      //state.isLoading = false;
      state.isServerError = true;
      console.log("Error from getCountingCodes", action.error.message);
    },
    [updateCountingCodes.pending]: (state, action) => {
      // state.isLoading = true;
    },
    [updateCountingCodes.fulfilled]: (state, { payload }) => {
      //  console.log("Payload from updateCountingCodes:", payload);
      if (payload.indexOf("Invalid value") >= 0) {
        state.isFailed = true;
        state.isCountingCodeFileError = true;
      } else if (payload.indexOf("Failed") >= 0) {
        state.isFailed = true;
        state.countingCodeUpdatedFailed = true;
      } else {
        state.isCountingCodeUpdated = true;
        state.isSuccess = true;
      }
    },
    [updateCountingCodes.rejected]: (state, action) => {
      //state.isLoading = false;
      state.isServerError = true;
      console.log("Error from updateCountingCodes", action.error.message);
    },
    [getDRGLogicLogs.pending]: (state, action) => {
      state.logsLoading = true;
    },
    [getDRGLogicLogs.fulfilled]: (state, { payload }) => {
      state.logsLoading = false;
      if (payload != null && payload.length >= 1) {
        state.drgLogicLogs = [];
        for (var i = 0; i < payload.length; i++) {
          for (var j = 0; j < payload[i].logicComments.length; j++) {
            state.drgLogicLogs.push(
              {
                drgLogicID: payload[i].drgLogicID,
                ruleNo: payload[i].logicComments[j] ?.ruleNo,
                fieldName: payload[i].logicComments[j] ?.fieldName,
                oldValue: payload[i].logicComments[j] ?.oldValue,
                newValue: payload[i].logicComments[j] ?.newValue,
                comments: payload[i].logicComments[j] ?.comments,
                modifiedOn: payload[i].modifiedOn,
                modifiedBy: payload[i].modifiedBy,
                name: payload[i].name,
              }
            );
          }
        }
        state.masterDRGLogicLogs = state.drgLogicLogs;
      }
    },
    [getDRGLogicLogs.rejected]: (state, action) => {
      //state.isLoading = false;
      state.isServerError = true;
      state.logsLoading = false;
      console.log("Error from getDRGLogicLogs", action.error.message);
    },
    [GetGroupCodesLog.pending]: (state, action) => {
      state.logsLoading = true;
    },
    [GetGroupCodesLog.fulfilled]: (state, { payload }) => {
      state.logsLoading = false;
      if (payload != null && payload.length >= 1) {
        state.groupCodesLogs = [];
        for (var i = 0; i < payload.length; i++) {
          for (var j = 0; j < payload[i].logicComments.length; j++) {
            state.groupCodesLogs.push(
              {
                drgLogicID: payload[i].drgLogicID,
                oldValue: payload[i].logicComments[j] ?.oldValue,
                newValue: payload[i].logicComments[j] ?.newValue,
                comments: payload[i].logicComments[j] ?.userComments,
                groupCode: payload[i].logicComments[j] ?.groupCode,
                masterGroupCode: payload[i].logicComments[j] ?.masterGroupCode,
                modifiedOn: payload[i].modifiedOn,
                modifiedBy: payload[i].modifiedBy,
                name: payload[i].name,
              }
            );
          }
        }
        state.masterGroupCodesLogs = state.groupCodesLogs;
      }
    },
    [GetGroupCodesLog.rejected]: (state, action) => {
      //state.isLoading = false;
      state.isServerError = true;
      state.logsLoading = false;
      console.log("Error from GetGroupCodesLog", action.error.message);
    },
  },
});
export const {
  clearStatus,
  setSelectedEditor,
  setEditorDetails,
  setMasterSelectedEditorData,
  setSelectedEditorData,
  setSelectedEditorRule,
  setSelectedGroupCode,
  setOnCellDoubleClick,
  setOpenCellEdit,
  setOpenCellEditContains,
  setDrgLogicComments,
  setGroupCodesFilter,
  setDiagonisFilter,
  setMetaData,
  setSelectionModel,
  setIsPaste,
  setIsDuplicate,
  setIsDelete,
  setReadOnly,
  setSatusMessages,
  setdrgLogicLogs,
  setgroupCodesLogs,
  addnewGroupCode,
  setGroupCodeWarning,
  setSelectedGrpCode,
  setGrpCodeEditable,
  saveGroupCode,
  cancelEditGroupCode,
  setCurrentGroupCode,
  deleteGroupCode,
  addnewSubCode,
  setSubCodeEditable,
  saveSubCode,
  cancelEditSubCode,
  setCurrentSubCode,
  deleteSubCode,
  setgroupCodesWarning,
  setGroupCodesList,
  setSubCodesList,
  setIsEditorEditable,
  endUserSession,
  setExistingSubcodes,
  clearBackToEditor,
  setUnSavedData,
  resetEditor,
  setSearchFilter,
} = editorSlice.actions;
export default editorSlice.reducer;
