import React, { useState, useEffect, useRef } from "react";
import "./Interactive.css";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function PatientInformation(props) {
  const { t, i18n } = useTranslation("language");
  const { a } = props;
  return (
    <div>
      <Typography
        gutterBottom
        sx={{ padding: "10px" }}
        className="grid-header-text"
      >
        {t("interactive.patientInformation")}
      </Typography>

      <div className="result-patient-info">
        <div className="grid-patientInfo">
          <Typography
            gutterBottom
            sx={{ padding: "10px" }}
            className="report-episode-content-bold"
          >
            {t("interactive.sex")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
              {a.genderDescription}
            </Typography>
            <Typography
              gutterBottom
              sx={{ padding: "10px" }}
              className="report-episode-content-bold"
            >
              {t("interactive.age")}:&nbsp;
            </Typography>
            <Typography gutterBottom className="report-episode-content">
              {a.age} {a.ageTypeText}
            </Typography>
          </Typography>
          <Typography
            gutterBottom
            sx={{ padding: "10px" }}
            className="report-episode-content-bold"
          >
            {t("interactive.gestationAge")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
              {a.gestationAgeDescription}
            </Typography>
          </Typography>
          <Typography
            gutterBottom
            sx={{ padding: "10px" }}
            className="report-episode-content-bold"
          >
            {t("interactive.dischargeType")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
              {a.dischargeTypeDescription}
            </Typography>
          </Typography>
          <Typography
            gutterBottom
            sx={{ padding: "10px" }}
            className="report-episode-content-bold"
          >
            {t("interactive.contactType")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
              {a.contactTypeDescription}
            </Typography>
          </Typography>
          <Typography
            gutterBottom
            sx={{ padding: "10px" }}
            className="report-episode-content-bold"
          >
            {t("interactive.priority")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
              {a.admissionTypeDescription}
            </Typography>
          </Typography>
        </div>
        <div className="grid-patientInfo">
          {a.contactReasonDescription && (
            <Typography
              gutterBottom
              sx={{ padding: "10px" }}
              className="report-episode-content-bold"
            >
              {t("interactive.contactReason")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
                {a.contactReasonDescription}
              </Typography>
            </Typography>
          )}
          {a.durationBedDays && (
            <Typography
              gutterBottom
              sx={{ padding: "10px" }}
              className="report-episode-content-bold"
            >
              {t("interactive.contactDays")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
                {a.durationBedDays}
              </Typography>
            </Typography>
          )}
          {a.birthType && (
            <Typography
              gutterBottom
              sx={{ padding: "10px" }}
              className="report-episode-content-bold"
            >
              {t("interactive.birthType")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
                {a.birthType}
              </Typography>
            </Typography>
          )}
          {a.birthHeight && (
            <Typography
              gutterBottom
              sx={{ padding: "10px" }}
              className="report-episode-content-bold"
            >
              {t("interactive.height")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
                {a.birthHeight}
              </Typography>
            </Typography>
          )}
          {a.birthWeight && (
            <Typography
              gutterBottom
              sx={{ padding: "10px" }}
              className="report-episode-content-bold"
            >
              {t("interactive.weight")}:&nbsp;
            <Typography gutterBottom className="report-episode-content">
                {a.birthWeight}
              </Typography>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
