import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DRGDiagramBase } from "./DRGDiagramBase";
import LinearProgress from "@mui/material/LinearProgress";

export default function FullDiagram(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const viewer = useSelector((state) => state.viewer);
  const { isDrgData, drgDataForGoJs } = viewer;

  return (
    <div>
      {isDrgData ? (
        <DRGDiagramBase dataForGoJs={drgDataForGoJs} />
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}
