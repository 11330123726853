/*
 *  Copyright (C) 1998-2021 by Northwoods Software Corporation. All Rights Reserved.
 */

import * as go from "gojs";
import * as React from "react";
import { DRGDiagramWrapper } from "./DRGDiagramWrapper";
import { DiagramState, node, link } from "./viewerModel";
import "./Diagram.css";
import CircularProgress from "@mui/material/CircularProgress";

// export interface DiagramBaseProps {
//     dataForGoJs: any;
// }
export class DRGDiagramBase extends React.Component {
    nodearr = [];
    linkarr = [];
    nodeIgnoreText = "Not allowed";

    constructor(props) {
        super(props);
        this.state = {
            nodeDataArray: this.nodearr,
            linkDataArray: this.linkarr,
            modelData: {
                canRelink: true
            },
            selectedData: null,
            skipsDiagramUpdate: false,
            dataLoaded: false
        };
    }

    componentDidMount() {

        //console.log("dataForGoJs:", this.props.dataForGoJs);
        var nodeDataArray = JSON.parse(this.props.dataForGoJs.shapeData);
        var linkDataArray = JSON.parse(this.props.dataForGoJs.linkData);
        nodeDataArray.forEach((element) => {
            var nodeText = "";
            var cursorType = "default";
            if (element.text.length > 80) {
                nodeText = element.text.substring(0, 80) + "...";
            } else {
                nodeText = element.text;
            }
            if (element.text.trim().startsWith("-")) {
                nodeText = nodeText.trim().substr(1);
                nodeText = nodeText + " " + this.nodeIgnoreText;
            }
            if (element.leafnode == true) {
                var firstSpaceIndex = nodeText.indexOf(" ");
                var firstWord = nodeText.substring(0, firstSpaceIndex + 1) + "\n";
                var restWords = nodeText.substring(firstSpaceIndex + 1);
                nodeText = firstWord + restWords;
            }
            if (element.yellowbox == false) {
                cursorType = "pointer";
            }
            let n = {
                key: element.key,
                text: nodeText,
                bounds: new go.Rect(element.xloc, element.yloc, 160, 100),
                color: element.color,
                fig: element.fig,
                width: element.width,
                height: element.height,
                groupCode: element.groupCode,
                regel: element.rownumber,
                tooltip: element.tooltip,
                yellowbox: element.yellowbox,
                leafnode: element.leafnode,
                rownumber: "Regel:" + element.rownumber,
                cursor: cursorType,
                groupCodeValue: element.text,
                loc: new go.Point(element.xloc, element.yloc),
                groupCodeText: element.groupCodeText
            };
            this.nodearr.push(n);
        });
        linkDataArray.forEach((element) => {
            let l = {
                from: element.from,
                to: element.to,
                routing: go.Link.Orthogonal,
                fromSpot: element.fromSpot,
                toSpot: element.toSpot
            };
            this.linkarr.push(l);
        });

        this.setState({ dataLoaded: true });

    }

    render() {
        if (!this.state.dataLoaded) {
            return (
                <div style={{ marginLeft: 750, marginTop: 300 }}>
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div style={{ backgroundColor: "white" }}>
                <div style={{ textAlign: "center" }}>

                </div>

                <DRGDiagramWrapper
                    nodeDataArray={this.state.nodeDataArray}
                    linkDataArray={this.state.linkDataArray}
                    modelData={this.state.modelData}
                    skipsDiagramUpdate={this.state.skipsDiagramUpdate}
                />
            </div>
        );
    }
}

