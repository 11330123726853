import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import NavBar from "./features/Layout/NavBar";
import FooterLayout from "./features/Layout/Footer";
import Logout from "./features/Logout/Logout";
import { Login } from "./features/login/Login";
import Dashboard from "./features/Dashboard/Dashboard";
import { useSelector, useDispatch } from "react-redux";
import RegisterUser from "./features/registerUser/RegisterUser";
import Publisher from "./features/publisher/Publisher";
import ManageUsers from "./features/manageUsers/ManageUsers";
import Manager from "./features/manager/Manager";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import ContentUpload from "./features/contentUpload/ContentUpload";
import Viewer from "./features/viewer/Viewer";
import Interactive from "./features/interactive/Interactive";
import DRG from "./features/manager/DRG";
import Editor from "./features/editor/Editor";
import ClassificationCodes from "./features/editor/ClassificationCodes";
import ChangeLogs from "./features/editor/ChangeLogs";
import IdleTimeOut from "./features/idleTimeOut/IdleTimeOut";
import Profile from "./features/profile/Profile";
import { clearMangerStatus } from "./features/manager/managerSlice";
import { resetEditor } from "./features/editor/editorSlice";
import DRGTest from "./features/publicPages/DRGTest";
import DRGHearing from "./features/publicPages/DRGHearing";
import DRGProd from "./features/publicPages/DRGProd";
import GrouperTest from "./features/publicPages/GrouperTest";
import GrouperHearing from "./features/publicPages/GrouperHearing";
import GrouperProd from "./features/publicPages/GrouperProd";
import InteractiveTest from "./features/publicPages/interactive/InteractiveTest";
import InteractiveHearing from "./features/publicPages/interactive/InteractiveHearing";
import InteractiveProd from "./features/publicPages/interactive/InteractiveProd";
import ViewerTest from "./features/publicPages/viewer/ViewerTest";
import ViewerHearing from "./features/publicPages/viewer/ViewerHearing";
import ViewerProd from "./features/publicPages/viewer/ViewerProd";

export default function AppRouter() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, isAuthorised, shouldRedirect, isViewerRole } = useSelector(
    (state) => state.authentication
  );

  useEffect(() => {
    dispatch(clearMangerStatus());
    if (
      location.pathname != "/groupcodes" &&
      location.pathname != "/editor" &&
      location.pathname != "/logs"
    ) {
      dispatch(resetEditor());
    }
  }, [location.pathname]);

  return (
    <div>
      <Switch>
        <Route path="/terms" component={TermsOfUse}></Route>
        <Route path="/privacy" component={PrivacyPolicy}></Route>
        <Route path="/DrgTest" component={DRGTest}></Route>
        <Route path="/DrgHearing" component={DRGHearing}></Route>
        <Route path="/DrgProd" component={DRGProd}></Route>
        <Route path="/GrouperTest" component={GrouperTest}></Route>
        <Route path="/GrouperHearing" component={GrouperHearing}></Route>
        <Route path="/GrouperProd" component={GrouperProd}></Route>
        <Route path="/InteractiveTest" component={InteractiveTest}></Route>
        <Route
          path="/InteractiveHearing"
          component={InteractiveHearing}
        ></Route>
        <Route path="/InteractiveProd" component={InteractiveProd}></Route>
        <Route path="/ViewerTest" component={ViewerTest}></Route>
        <Route path="/ViewerHearing" component={ViewerHearing}></Route>
        <Route path="/ViewerProd" component={ViewerProd}></Route>
      </Switch>
      {user[0] &&
      user[0]?.token &&
      !window.location.pathname.startsWith("/login") ? (
        <div>
          <React.Fragment>
            {!window.location.pathname.startsWith("/Drg") &&
            !window.location.pathname.startsWith("/Grouper") &&
            !window.location.pathname.startsWith("/Interactive") &&
            !window.location.pathname.startsWith("/Viewer") ? (
              <NavBar></NavBar>
            ) : null}
          </React.Fragment>
          <Switch>
            <Route path="/" exact component={Dashboard}></Route>
            <Route path="/dashboard" exact component={Dashboard}></Route>
            {/* <Route path="/login" component={Login} /> */}
            <Route path="/viewer" component={Viewer} />
            <Route path="/publisher" component={Publisher} />
            <Route path="/manage" component={ManageUsers} />
            <Route path="/content" component={ContentUpload} />
            {!isViewerRole && <Route path="/manager" component={Manager} />}
            <Route path="/interactive" component={Interactive} />
            <Route path="/editor" component={Editor} />
            <Route path="/groupcodes" exact component={ClassificationCodes} />
            <Route path="/logs" exact component={ChangeLogs} />
            <Route
              path="/register"
              component={() => <RegisterUser IsAdmin={true}></RegisterUser>}
            />
            <Route path="/myprofile" exact component={Profile} />
            <Route path="/logout" component={Logout}></Route>
            {/* <Route path="/terms" component={TermsOfUse}></Route>
            <Route path="/privacy" component={PrivacyPolicy}></Route> */}
            <Redirect from="/authentication/login-callback" to="/"></Redirect>
          </Switch>
          {user[0] &&
          user[0]?.token &&
          !window.location.pathname.startsWith("/login") &&
          !window.location.pathname.startsWith("/Drg") &&
          !window.location.pathname.startsWith("/Grouper") &&
          !window.location.pathname.startsWith("/Interactive") &&
          !window.location.pathname.startsWith("/Viewer") ? (
            <FooterLayout></FooterLayout>
          ) : null}
          <IdleTimeOut />
        </div>
      ) : (
        <div>
          {!["/drg", "/grouper", "/interactive", "/viewer"].some((prefix) =>
            window.location.pathname.toLowerCase().startsWith(prefix)
          ) && <Login />}
        </div>
      )}
    </div>
  );
}
