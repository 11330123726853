import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./ManageUsers.css";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    getRoles,
    getUsers,
    setRows,
    saveChanges,
    setLoading,
    clearStatus,
    UserActivation,
} from "./manageUserSlice";
import {redirectToLogin} from ".././authentication/authenticationSlice";

export default function ManageUsers() {
    const { t } = useTranslation("language");
    const history = useHistory();
    const dispatch = useDispatch();
    const manageUser = useSelector((state) => state.manageUser);
    const { roles, userStatus, users, loading, rows, isSuccess, isFailed } =
        manageUser;
    const [select, setselect] = useState("1");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        dispatch(getUsers({ method: "GET" }));
    }, [isSuccess]);
    useEffect(() => {
        dispatch(getRoles({ method: "GET" }));
    }, []);

    const handleSelect = (e) => {
        const value = e.target.value;
        if (value === 0) {
            const result = users.filter((user) => {
                return user.active == value;
            });
            dispatch(setRows(result));
            setselect(value);
        } else {
            dispatch(setRows(users));
            setselect(value);
        }
    };
    const requestSearch = (event) => {
        setSearchValue(event.target.value);
        setselect(1);
        const result = users.filter((user) => {
            return (
                user.firstName
                    ?.toLowerCase()
                        .includes(event.target.value ?.toLowerCase()) ||
                        user.email ?.toLowerCase().includes(event.target.value ?.toLowerCase()) ||
                            user.phoneNumber
                            ?.toLowerCase()
                                .includes(event.target.value ?.toLowerCase())
      );
        });
        dispatch(setRows(result));
    };
    const handleSwitch = (key, attribute) => (e) => {
        var value = e.target.checked;
        if (value == true) {
            value = 1;
            let user = rows.filter(row => row.email == key)[0];
            dispatch(UserActivation({ method: "POST", body: user }));
        } else value = 0;
        let result = rows.map((row) => {
            if (key == row.email) {
                return { ...row, [attribute]: value };
            } else {
                return row;
            }
        });
        dispatch(setRows(result));
    };
    const handleRole = (key, attribute) => (e) => {
        let result = rows.map((row) => {
            if (key == row.email) {
                return { ...row, [[attribute]]: [e.target.value] };
            } else {
                return row;
            }
        });
        dispatch(setRows(result));
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSave = () => {
        setSearchValue("");
        setselect(1);
        dispatch(setLoading(true));
        let language = localStorage.getItem("language");
        let url = "home/api/SaveUsers?lang=" + language + "";
        dispatch(saveChanges({ method: "POST", url: url, body: rows }));
    };

    const handleClose = () => {
        dispatch(clearStatus());
    };

    if(!manageUser.isSessionValid){
        dispatch(redirectToLogin({ isAuthorised: false }));
      }

    return (
        <div className="manageuser">
            <Paper className="paperContainer">
                <div className="section-header">
                    <Typography variant="h5" className="typography-title" gutterBottom>
                        {t("ManageUser.Manage_Users")}
                    </Typography>
                    <Button
                        variant="contained"
                        size="small"
                        className="form-button-primary"
                        onClick={() => history.push("/register")}
                    >
                        {t("ManageUser.register_user")}
                    </Button>
                </div>
                <Divider />
                <div className="table-container">
                    <div className="table-section-container">
                        <div className="table-section-header left">
                            <Typography
                                variant="h5"
                                className="typography-title"
                            >
                                {t("Global.users")}
              </Typography>
                            <Typography className="typography-title" gutterBottom>
                                {rows.length} {t("Global.Total")}
              </Typography>
                        </div>
                        <div className="table-section-header right">
                            <div className="right-container">
                                <Select
                                    onChange={handleSelect}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                    value={select}
                                    sx={{ marginRight: "20px", width: "40%" }}
                                >
                                    <MenuItem key={1} value={1}>{t("ManageUser.AllUsers")}</MenuItem>
                                    <MenuItem key={0} value={0}>
                                        {t("ManageUser.Pending_Approval")}
                                    </MenuItem>
                                </Select>
                                <TextField
                                    variant="outlined"
                                    placeholder={t("Global.search")}
                                    value={searchValue}
                                    onChange={requestSearch}
                                    sx={{ width: "40%" }}
                                    InputProps={{
                                        startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <TableContainer className="table">
                        <Table stickyHeader aria-label="sticky table" className="table">
                            <TableHead className="tableHead">
                                <TableRow>
                                    <TableCell className="tableCell head" sx={{ width: 600 }}>
                                        {t("ManageUser.Name")}
                                    </TableCell>
                                    <TableCell className="tableCell head" sx={{ width: 400 }}>
                                    {t("manageUser.email")} 
                  </TableCell>
                                    <TableCell className="tableCell head" sx={{ width: 400 }}>
                                        {t("ManageUser.Phone")}
                                    </TableCell>
                                    <TableCell className="tableCell head" sx={{ width: 400 }}>
                                        {t("ManageUser.Organization")}
                                    </TableCell>
                                    <TableCell className="tableCell head" sx={{ width: 400 }}>
                                        {t("ManageUser.Purpose")}
                                    </TableCell>
                                    <TableCell className="tableCell head" sx={{ width: 40 }}>
                                        {t("ManageUser.Activate")}
                                    </TableCell>
                                    <TableCell className="tableCell head" sx={{ width: 200 }}>
                                        {t("ManageUser.Role")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell />
                                        <TableCell>
                                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                                <CircularProgress />
                                            </Box>
                                        </TableCell>
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                            ) : (
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ?rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            :rows
                                            ).map((row) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell className="tableCell">
                                                            {row.firstName} {row.lastName}
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            {row.email}
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            {row.phoneNumber}
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            {row.organization}
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            {row.purpose}
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            <Switch
                                                                checked={row.active == 0 ? false : true}
                                                                onChange={handleSwitch(row.email, "active")}
                                                                size="small"
                                                            />
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            <Select
                                                                value={[row.role != null ? row.role[0] : 0]}
                                                                onChange={handleRole(row.email, "role")}
                                                                sx={{
                                                                    width: 200,
                                                                }}
                                                            >
                                                                {roles.map((role) => (
                                                                    <MenuItem key={role.id} value={role.id}>
                                                                        {role.roleName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                )}
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100, {value: -1, label: 'All'}]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage = {t("Global.row_per_page")}
                        />
                    </TableContainer>
                </div>
                <div className="section-footer">
                    <Button
                        variant="contained"
                        size="small"
                        onClick={onSave}
                        className="form-button-primary"
                    >
                        {t("ManageUser.save")}
                    </Button>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={isSuccess}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        {t("ManageUser.save_changes")}
                    </Alert>
                </Snackbar>
            </Paper>
        </div>
    );
}
