import React, { useEffect } from "react";
import AppRouter from "./AppRouter";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getUserDetails,
  resetSession,
} from "./features/authentication/authenticationSlice";
import { useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  // console.log("Initial value at store:", authentication);
  // console.log("isAuthorised:", authentication.isAuthorised);
  localStorage.setItem("siteUrl", window.location.href);
  localStorage.setItem("language", "dk");

  const MINUTE_MS = 900000;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(resetSession({ method: "GET" }));
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getUserDetails({ method: "GET" }));
  }, [dispatch]);

  if (authentication.status == "" || authentication.status == "loading") {
    console.log("Loading...");
  }
  //To check user is already authorized to access the application
  if (!authentication.isAuthorised && authentication.status == "success") {
    if (
      !window.location.pathname.startsWith("/Drg") &&
      !window.location.pathname.startsWith("/Grouper") &&
      !window.location.pathname.startsWith("/Interactive") &&
      !window.location.pathname.startsWith("/")
    ) {
      history.push("/login");
    }
  }
  return (
    <div>
      {authentication.status == "success" ? (
        <AppRouter />
      ) : (
        <div>
          <Backdrop
            sx={{
              color: "#ffffff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={authentication.isLoading}
          >
            <CircularProgress />
          </Backdrop>
        </div>
      )}
    </div>
  );
}
