import React, { useState, useEffect, useRef } from "react";
import "./Interactive.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import {
  spellMoveLeft,
  spellMoveRight,
  deleteSpell,
  setValues,
  interactiveProcessResult,
  setRequestList,
  showPanels,
  setRequestType,
  setInteractiveLoading,
  clearResultInformation,
} from "./interactiveSlice";

export default function Spells() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const interactive = useSelector((state) => state.interactive);
  const {
    patientInputs,
    spellsList,
    isYearGreaterThan2021,
    contactDaysNoProgress,
    spellVarighed,
    selectedInteractiveObj,
    isMale,
  } = interactive;
  const languageState = useSelector((state) => state.language);
  const { language } = languageState;
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  const handleSpellMoveLeft = (id) => () => {
    var index = spellsList.findIndex((item) => item.id === id);
    dispatch(spellMoveLeft(index));
  };
  const handleSpellMoveRight = (id) => () => {
    var index = spellsList.findIndex((item) => item.id === id);
    dispatch(spellMoveRight(index));
  };
  const handleDeleteSpell = (val) => () => {
    dispatch(deleteSpell(val));
  };
  const handleContactDaysChange = (e) => {
    dispatch(
      setValues({ name: "contactDaysNoProgress", value: e.target.value })
    );
  };
  const handleDurationChange = (e) => {
    dispatch(setValues({ name: "spellVarighed", value: e.target.value }));
  };
  const handleRunRequest = (e) => {
    dispatch(setInteractiveLoading(true));
    let count = 1;
    let requestList = [];
    spellsList.forEach((ele) => {
      var request = createRequestList(ele.val[0], count);
      dispatch(setRequestList(request[0]));
      requestList.push(request[0]);
      count++;
    });
    postInteractiveRequest(requestList, "episode");
    // dispatch(clearResultInformation());
  };

  function createRequestList(obj, rid) {
    var reqData = [];

    let gestationAge = obj.isMale
      ? {
          Code: obj.maleGestationAgeValue,
          Description: obj.gestationText[language],
          Significance: "",
        }
      : {
          Code: obj.femaleGestationAgeValue,
          Description: obj.gestationText[language],
          Significance: "",
        };
    let reasonForContact =
      obj.hospWayValue === "1"
        ? generateListItems(obj, "ReasonForContact")
        : null;
    let contactType = generateListItems(obj, "ContactType");
    var liggeDage = "";
    if (isYearGreaterThan2021) {
      if (obj.varighed == "0") {
        liggeDage = "";
      } else if (obj.varighed == "1") {
        liggeDage = "1";
      } else {
        liggeDage = "12";
      }
    }
    var diagnosis = [];
    obj.diagnosis.forEach((ele) => {
      let addlCodes = [];
      if (ele.additionalCode != undefined) {
        for (var i = 0; i < ele.additionalCode.length; i++) {
          addlCodes.push({
            Code: ele.additionalCode[i].code,
            Description: ele.additionalCode[i].name,
          });
        }
      }
      diagnosis.push({
        Type: ele.type,
        Code: ele.code,
        Description: ele.name,
        AdditionalCodes: addlCodes,
      });
    });
    var procedure = [];
    obj.procedure.forEach((ele) => {
      let addlCodes = [];
      if (ele.additionalCode != undefined) {
        for (var i = 0; i < ele.additionalCode.length; i++) {
          addlCodes.push({
            Code: ele.additionalCode[i].code,
            Description: ele.additionalCode[i].name,
          });
        }
      }
      procedure.push({
        Type: ele.type,
        Code: ele.code,
        Description: ele.name,
        AdditionalCodes: addlCodes,
      });
    });
    if (isYearGreaterThan2021) {
      reqData.push({
        RId: rid,
        Age: obj.age,
        AgeTypeText: obj.ageTypeText[language],
        AgeTypeValue: obj.ageTypeValue,
        Gender: {
          Code: obj.genderValue,
          Description: obj.genderText[language],
          Significance: "",
        },
        GestationAge: gestationAge,
        AdmissionType: {
          Code: obj.hospWayValue,
          Description: obj.hospWayText[language],
          Significance: "",
        },
        ContactReason: reasonForContact,
        DischargeType: {
          Code: obj.dischargeValue,
          Description: obj.dischargeText[language],
          Significance: "",
        },
        ContactType: contactType,
        BedinDays: obj.contactDays == "" ? null : obj.contactDays,
        SurvivalDays: null,
        LiggidageBedInDays: liggeDage,
        Varighed: {
          Code: obj.varighed,
          Description: obj.varighedDescription[language],
          Significance: "",
        },
        isYearGreaterthan2021: isYearGreaterThan2021,
        IsBirth: obj.isBirth === 1 ? true : false,
        Height: obj.isBirth === 1 ? Number(obj.height) : null,
        Weight: obj.isBirth === 1 ? Number(obj.weight) : null,
        BirthType: obj.isBirth === 1 ? Number(obj.birthTypeValue) : 0,
        BirthTypeText: obj.isBirth === 1 ? obj.birthTypeText[language]: null,
        Diagnosis: diagnosis,
        Procedure: procedure,
      });
    } else {
      reqData.push({
        RId: rid,
        Age: obj.age,
        AgeTypeText: obj.ageTypeText[language],
        AgeTypeValue: obj.ageTypeValue,
        Gender: {
          Code: obj.genderValue,
          Description: obj.genderText[language],
          Significance: "",
        },
        GestationAge: gestationAge,
        AdmissionType: {
          Code: obj.hospWayValue,
          Description: obj.hospWayText[language],
          Significance: "",
        },
        ContactReason: reasonForContact,
        DischargeType: {
          Code: obj.dischargeValue,
          Description: obj.dischargeText[language],
          Significance: "",
        },
        ContactType: contactType,
        BedinDays: obj.contactDays == "" ? null : obj.contactDays,
        SurvivalDays: null,
        isYearGreaterthan2021: isYearGreaterThan2021,
        IsBirth: obj.isBirth === 1 ? true : false,
        Height: obj.isBirth === 1 ? Number(obj.height) : null,
        Weight: obj.isBirth === 1 ? Number(obj.weight) : null,
        BirthType: obj.isBirth === 1 ? Number(obj.birthTypeValue) : 0,
        BirthTypeText: obj.isBirth === 1 ? obj.birthTypeText[language]: null,
        Diagnosis: diagnosis,
        Procedure: procedure,
      });
    }

    return reqData;
  }

  function generateListItems(obj, col) {
    let retObj = {};
    if (col == "ContactType") {
      if (isYearGreaterThan2021) {
        if (obj.contactTypeValue == "6") {
          retObj.Code = "2";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else if (obj.contactTypeValue == "5") {
          retObj.Code = "1";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else {
          retObj.Code = obj.contactTypeValue;
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        }
      } else {
        if (obj.contactTypeValue == "3") {
          retObj.Code = "5";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else if (obj.contactTypeValue == "6") {
          retObj.Code = "5";
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        } else {
          retObj.Code = obj.contactTypeValue;
          retObj.Description = obj.contactTypeText[language];
          retObj.Significance = "";
        }
      }
    } else if (col == "ReasonForContact") {
      if (obj.reasonForContactValue == "0") {
        retObj.Code = " ";
        retObj.Description = obj.reasonForContactText[language];
        retObj.Significance = "";
      } else {
        retObj.Code = obj.reasonForContactValue;
        retObj.Description = obj.reasonForContactText[language];
        retObj.Significance = "";
      }
    }
    return retObj;
  }

  function postInteractiveRequest(requestList, type) {
    var viewModel = {};
    let overWriteBedDays, overWriteVarighed;
    dispatch(setRequestType(type));
    if (type == "episode") {
      overWriteBedDays = contactDaysNoProgress;
      if (isYearGreaterThan2021) {
        //varighed
        overWriteVarighed = requestList[0].Varighed;
      }
    } else {
      overWriteBedDays = null;
      overWriteVarighed = null;
    }

    viewModel = {
      Name: selectedInteractiveObj.interactiveName,
      Version: selectedInteractiveObj.interactiveVersion,
      Year: selectedInteractiveObj.year,
      OverWriteBedDays: overWriteBedDays,
      OverWriteVarighed: overWriteVarighed,
      interactive: requestList,
    };

    dispatch(
      interactiveProcessResult({ method: "POST", body: viewModel })
    ).then(() => {
      //console.log("Res Run Req");
    });
  }

  return (
    <div>
      {spellsList.length > 0 ? (
        <div className="spells">
          <div className="spell-navigation">
            <Typography
              gutterBottom
              sx={{ padding: "10px" }}
              className="spell-header-bold"
            >
              {t("interactive.Episode")}&nbsp;&nbsp; {spellsList.length}
            </Typography>
            <div>
              {scrollX !== 0 && (
                <KeyboardArrowLeftIcon
                  sx={{
                    fill: "#959CA0",
                    cursor: "pointer",
                  }}
                  className="prev"
                  onClick={() => slide(-50)}
                  onMouseEnter={(e) => anim(e)}
                  onMouseLeave={(e) => anim2(e)}
                />
              )}
              {!scrolEnd && (
                <KeyboardArrowRightIcon
                  sx={{
                    fill: "#959CA0",
                    cursor: "pointer",
                  }}
                  className="next"
                  onClick={() => slide(+50)}
                  onMouseEnter={(e) => anim(e)}
                  onMouseLeave={(e) => anim2(e)}
                />
              )}
            </div>
          </div>

          <ul ref={scrl} onScroll={scrollCheck}>
            {spellsList?.map((a, index) => {
              return (
                <li>
                  <Paper elevation={0} className="spell-paper">
                    <div className="spell-header">
                      <Typography
                        gutterBottom
                        sx={{ padding: "10px", width: "75%" }}
                        className="spell-header-bold"
                      >
                        {t("interactive.Episode")}&nbsp;&nbsp; {index + 1}
                      </Typography>
                      <div>
                        {index != 0 ? (
                          <KeyboardArrowLeftIcon
                            sx={{
                              fill: "#959CA0",
                              cursor: "pointer",
                            }}
                            onClick={handleSpellMoveLeft(a.id)}
                          />
                        ) : (
                          <span className="spell-hidden-span"></span>
                        )}

                        {index != spellsList.length - 1 ? (
                          <KeyboardArrowRightIcon
                            sx={{
                              fill: "#959CA0",
                              cursor: "pointer",
                            }}
                            onClick={handleSpellMoveRight(a.id)}
                          />
                        ) : (
                          <span className="spell-hidden-span"></span>
                        )}
                        <DeleteIcon
                          sx={{
                            fill: "#959CA0",
                            cursor: "pointer",
                            paddingRight: "10px",
                          }}
                          onClick={handleDeleteSpell(a)}
                        />
                      </div>
                    </div>
                    <div className="spell-primary-diagnosis">
                      <Typography
                        gutterBottom
                        sx={{ padding: "10px" }}
                        className="spell-content-bold"
                      >
                        {t("interactive.primaryDiagnosis")}:&nbsp;
                      </Typography>
                      <Typography gutterBottom className="spell-content">
                        {a?.val[0].diagnosis[0]?.name}
                      </Typography>
                    </div>
                    <div className="spell-patientInfo">
                      <Typography
                        gutterBottom
                        sx={{ padding: "10px" }}
                        className="spell-content-bold"
                      >
                        {t("interactive.Patient")}:&nbsp;
                        <Typography gutterBottom className="spell-content">
                          {a.val[0].genderText[language]},&nbsp;
                          {a.val[0].age}&nbsp;{a.val[0].ageTypeText[language]}
                        </Typography>
                      </Typography>
                    </div>
                  </Paper>
                </li>
              );
            })}
          </ul>
          <div className="spell-request-container">
            <div className="spell-contact-days">
              {/* {isYearGreaterThan2021 ? (
                <TextField
                  label={t("interactive.duration")}
                  variant="outlined"
                  select
                  value={
                    patientInputs.length > 0 && spellVarighed == ""
                      ? Object.values(patientInputs[0].varighed)[0].value
                      : spellVarighed
                  }
                  onChange={handleDurationChange}
                  sx={{ paddingRight: "20px" }}
                >
                  {patientInputs.length > 0
                    ? Object.values(patientInputs[0].varighed).map((a) => {
                        return (
                          <MenuItem value={a.value}>
                            {a.name[language]}
                          </MenuItem>
                        );
                      })
                    : null}
                </TextField>
              ) : null} */}
              <TextField
                label={
                  isYearGreaterThan2021
                    ? t("interactive.lengthOfStay")
                    : t("interactive.contactDays")
                }
                variant="outlined"
                value={contactDaysNoProgress}
                onChange={handleContactDaysChange}
              />
            </div>
            <div className="spell-run-request">
              <Button
                variant="contained"
                size="small"
                className="interactive-button-clear"
                onClick={handleRunRequest}
                value="runRequest"
              >
                {t("interactive.runRequest")}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
