import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "../features/authentication/authenticationSlice";
import loginReducer from "../features/login/loginSlice";
import languageReducer from "../translations/languageSlice";
import registerUserReducer from "../features/registerUser/registerUserSlice";
import manageUserReducer from "../features/manageUsers/manageUserSlice";
import publisherReducer from "../features/publisher/publisherSlice";
import contentUploadReducer from "../features/contentUpload/contentUploadSlice";
import viewerReducer from "../features/viewer/viewerSlice";
import managerReducer from "../features/manager/managerSlice";
import interactiveReducer from "../features/interactive/interactiveSlice";
import editorReducer from "../features/editor/editorSlice";
import profileReducer from "../features/profile/profileSlice";
import publicPageReducer from "../features/publicPages/publicPageSlice";
import publicInteractiveReducer from "../features/publicPages/interactive/publicInteractiveSlice";
import publicViewerReducer from "../features/publicPages/viewer/publicViewerSlice";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    login: loginReducer,
    language: languageReducer,
    registerUser: registerUserReducer,
    manageUser: manageUserReducer,
    publisher: publisherReducer,
    contentUpload: contentUploadReducer,
    manager: managerReducer,
    viewer: viewerReducer,
    interactive: interactiveReducer,
    editor: editorReducer,
    profile: profileReducer,
    publicPage: publicPageReducer,
    publicInteractive: publicInteractiveReducer,
    publicViewer: publicViewerReducer,
  },
});
