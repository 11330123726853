import React from "react";

export default function TermsOfUse() {
  return (
    <div>
      <h2 style={{ paddingTop: 100, height: 465 }}>
        Casemix360 - Terms of Use
      </h2>
    </div>
  );
}
