import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./Profile.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UpdateProfile, clearStatus } from "./profileSlice";
import { getUserDetails } from "../authentication/authenticationSlice";
import {redirectToLogin} from ".././authentication/authenticationSlice";
import RegisterUser from "../registerUser/RegisterUser";
import registerUserSlice from "../registerUser/registerUserSlice";

export default function Profile() {
  const { t } = useTranslation("language");
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const { isSuccess, isFailed, isError, isServerError,isSessionValid } = useSelector((state) => state.profile);

  const defaultValues = {
    email: user[0] ?.email,
    organization: user[0] ?.organization,
    firstName: user[0] ?.firstName,
    lastName: user[0] ?.lastName,
    phone: user[0] ?.phoneNumber,
  }

  console.log(isEdit);
  useEffect(() => {
    reset(defaultValues);
  }, []);

  const handleClose = () => {
    dispatch(clearStatus());
  };

  const handleOnCancel = () => (event) => {
    event.preventDefault();
    setIsEdit(false);
    reset(defaultValues);
  }
  const onSubmit = (values) => {
    let user = {};
    user.Email = values.email;
    user.Organization = values.organization;
    user.FirstName = values.firstName;
    user.LastName = values.lastName;
    user.PhoneNumber = values.phone;
    user.UserName = values.email;

    setTimeout(() => {
      updateProfile(user);
    }, 400);
  };

  if(!isSessionValid){
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  return (
    <div className="registerContainer admin" >
      <Card className="card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-section-header">
            <Typography variant="h5" className="form-title" gutterBottom>
              {t("Profile.my_profile")}
            </Typography>
          </div>
          <div className="form-section">
            <TextField
              id="outlined-basic"
              label={t("registerUser.email")}
              variant="outlined"
              disabled
              className="form-section"
              {...register("email", {
                required:t("registerUser.emailRequired"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("registerUser.Email_Validation"),
                },
              })}
              error={Boolean(errors.email)}
              helperText={errors ?.email ?.message}
              inputProps={{ maxLength: 60 }}
              required
            />
          </div>
          <div className="form-section">
            <TextField
              id="outlined-basic"
              label={t("registerUser.organization")}
              variant="outlined"
              disabled={!isEdit}
              className="form-section"
              name="organization"
              {...register("organization", {
                required: t("registerUser.Oraganization_Required"),
              })}
              error={Boolean(errors.organization)}
              helperText={errors ?.organization ?.message}
              inputProps={{ maxLength: 20 }}
              required
            />
          </div>
          <div className="form-section">
            <TextField
              label={t("registerUser.firstName")}
              variant="outlined"
              disabled={!isEdit}
              className="form-section"
              {...register("firstName", {
                required: t("registerUser.First_Name_Required"),
                pattern: {
                  value: /^[a-zA-Z '.-]*$/,
                  message: t("registerUser.entryLetters"),
                },
              })}
              error={Boolean(errors.firstName)}
              helperText={errors ?.firstName ?.message}
              inputProps={{ maxLength: 15 }}
              required
            />
          </div>
          <div className="form-section">
            <TextField
              label={t("registerUser.lastName")}
              variant="outlined"
              disabled={!isEdit}
              className="form-section"
              {...register("lastName", {
                required: t("registerUser.Last_Name_Required"),
                pattern: {
                  value: /^[a-zA-Z '.-]*$/,
                  message: t("registerUser.entryLetters"),
                },
              })}
              error={Boolean(errors.lastName)}
              helperText={errors ?.lastName ?.message}
              inputProps={{ maxLength: 15 }}
              required
            />
          </div>
          <div className="form-section">
            <TextField
              label={t("registerUser.phone")}
              variant="outlined"
              type="Number"
              disabled={!isEdit}
              className="form-section"
              {...register("phone", {
                pattern: {
                  value: /^[0-9]{1,20}$/,
                  message: t("registerUser.entryMaxChar"),
                },
              })}
              inputProps={{ maxLength: 20 }}
              error={Boolean(errors.phone)}
              helperText={errors ?.phone ?.message}
            />
          </div>
          <div className="form-button-section">
            {isEdit &&
              <div className="form-button-container">
                <Button
                  size="small"
                  onClick={handleOnCancel()}
                >
                  {t("registerUser.cancel")}
                </Button>
              </div>
            }
            <div className="form-button-container">
              {isEdit &&
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  {t("Global.save")}
                </Button>
              }
              {!isEdit &&
                <Button
                  variant="contained"
                  size="small"
                  // className="form-button-primary"
                  onClick={() => setIsEdit(true)}
                >
                  {t("Global.edit")}
                </Button>
              }
            </div>
          </div>
        </form>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSuccess}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {t("Profile.success")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isFailed}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          {t("Profile.failed")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isError}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {isServerError && t("Manager_Common.server_error")}
        </Alert>
      </Snackbar>
    </div>
  );
  async function updateProfile(user) {
    let body = user;
    console.log(body);
    let url = "home/api/UpdateProfile";
    dispatch(UpdateProfile({ method: "POST", url: url, body: user })).then(() => {
      dispatch(getUserDetails({ method: "GET" })).then(() => {
        setIsEdit(false);
      });
    });
  }
}
