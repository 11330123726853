import React, { useState, useEffect } from "react";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./ContentUpload.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import {
  getCountries,
  setYearsArray,
  getVisualContent,
  getInteractiveContent,
  getDRGContent,
  getGrouperContent,
  setYear,
  setLanguage,
  updateContent,
  setDestEnvironment
} from "./contentUploadSlice";

export default function RichTextEditor(props) {
  const [editorState, setEditorState] = useState();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [flag, setFlag] = useState(false);
  //const [destEnvironment, setDestinationEnvironment] = useState("Test");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const contentUpload = useSelector((state) => state.contentUpload);
  const {
    countries,
    years,
    year,
    language,
    destEnvironment,
    interactiveContent,
    visualContent,
    DRGContent,
    grouperContent,
    isReRender
  } = contentUpload;

  useEffect(() => {
    dispatch(getCountries({ method: "GET" }));
    dispatch(setYearsArray());
    if (props.tab == "interactive") {
      let url =
        "api/GetInteractiveFrontPageContent?language=" +
        "en" +
        "&year=" +
        year +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getInteractiveContent({ url: url, method: "GET" }));
    } else if (props.tab == "viewer") {
      let url =
        "api/GetVisualFrontPageContent?language=" + "en" + "&year=" + year + "&destEnvironment=" + destEnvironment + "";
      dispatch(getVisualContent({ url: url, method: "GET" }));
    }
    else if (props.tab == "DRG") {
      let url =
        "api/GetDRGFrontPageContent?language=" + "en" + "&year=" + year + "&destEnvironment=" + destEnvironment + "";
      dispatch(getDRGContent({ url: url, method: "GET" }));
    }
    else if (props.tab == "grouper") {
      let url =
        "api/GetGrouperFrontPageContent?language=" + "en" + "&year=" + year + "&destEnvironment=" + destEnvironment + "";
      dispatch(getGrouperContent({ url: url, method: "GET" }));
    }
  }, []);

  useEffect(() => {
    if (props.tab == "interactive") {
      setUpEditor(interactiveContent);
    } else if (props.tab == "viewer") {
      setUpEditor(visualContent);
    } else if (props.tab == "DRG") {
      setUpEditor(DRGContent);
    } else if (props.tab == "grouper") {
      setUpEditor(grouperContent);
    }
  }, [interactiveContent, visualContent, DRGContent, grouperContent, isReRender]);

  useEffect(() => {
    if (flag) {
      console.log("test", convertToRaw(editorState.getCurrentContent()));
      const message = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      console.log(message);
      dispatch(updateContent({ content: message, tab: props.tab }));
    }
  }, [editorState]);

  const handleYearChange = (e) => {
    dispatch(setYear(e.target.value));
    var lang = "";
    if (language == "2") {
      lang = "dk";
    } else {
      lang = "en";
    }
    if (props.tab == "interactive") {
      let url =
        "api/GetInteractiveFrontPageContent?language=" +
        lang +
        "&year=" +
        e.target.value +
        "&destEnvironment=" +
        destEnvironment +
        "";
      console.log("Calling getInteractiveContent:", url);
      dispatch(getInteractiveContent({ url: url, method: "GET" }));
    } else if (props.tab == "viewer") {
      let url =
        "api/GetVisualFrontPageContent?language=" +
        lang +
        "&year=" +
        e.target.value +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getVisualContent({ url: url, method: "GET" }));
    } else if (props.tab == "DRG") {
      let url =
        "api/GetDRGFrontPageContent?language=" +
        lang +
        "&year=" +
        e.target.value +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getDRGContent({ url: url, method: "GET" }));
    } else if (props.tab == "grouper") {
      let url =
        "api/GetGrouperFrontPageContent?language=" +
        lang +
        "&year=" +
        e.target.value +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getGrouperContent({ url: url, method: "GET" }));
    }
  };

  const handlEnvironmentChange = (e) => {
    dispatch(setDestEnvironment(e.target.value));
    var lang = "";
    if (language == "2") {
      lang = "dk";
    } else {
      lang = "en";
    }
    if (props.tab == "interactive") {
      let url =
        "/api/GetInteractiveFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        e.target.value +
        "";
      dispatch(getInteractiveContent({ url: url, method: "GET" }));
    } else if (props.tab == "viewer") {
      let url =
        "/api/GetVisualFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        e.target.value +
        "";
      dispatch(getVisualContent({ url: url, method: "GET" }));
    }
    else if (props.tab == "DRG") {
      let url =
        "/api/GetDRGFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        e.target.value +
        "";
      dispatch(getDRGContent({ url: url, method: "GET" }));
    }
    else if (props.tab == "grouper") {
      let url =
        "/api/GetGrouperFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        e.target.value +
        "";
      dispatch(getGrouperContent({ url: url, method: "GET" }));
    }
  };

  const handleLanguageChange = (e) => {
    dispatch(setLanguage(e.target.value));
    var lang = "";
    if (e.target.value == "2") {
      lang = "dk";
    } else {
      lang = "en";
    }
    if (props.tab == "interactive") {
      let url =
        "/api/GetInteractiveFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getInteractiveContent({ url: url, method: "GET" }));
    } else if (props.tab == "viewer") {
      let url =
        "/api/GetVisualFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getVisualContent({ url: url, method: "GET" }));
    }
    else if (props.tab == "DRG") {
      let url =
        "/api/GetDRGFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getDRGContent({ url: url, method: "GET" }));
    }
    else if (props.tab == "grouper") {
      let url =
        "/api/GetGrouperFrontPageContent?language=" +
        lang +
        "&year=" +
        year +
        "&destEnvironment=" +
        destEnvironment +
        "";
      dispatch(getGrouperContent({ url: url, method: "GET" }));
    }
  };
  const setUpEditor = (serverMessage) => {
    try {
      const contentBlock = htmlToDraft(serverMessage);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      } else {
        setEditorState(EditorState.createEmpty());
      }
    } catch (e) {
      setEditorState(EditorState.createEmpty());
      console.log("Error at setUpEditor:", e.message);
    }
  };
  const handleEditorChange = async (state) => {
    await setEditorState(state);
    await setFlag(true);
    // const message = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    // dispatch(updateContent({ content: message, tab: props.tab }));
  };

  const wrapperStyle = {
    border: "1px solid #969696",
  };
  const editorStyle = {
    padding: "10px",
  };
  const toolbarStyle = {
    background: "#F2F2F2 0% 0% no-repeat padding-box",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    opacity: 1,
  };

  const _uploadImageCallBack = (file) => {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = [];

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    setUploadedImages(uploadedImages);

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  return (
    <div className="editorContainer">
      <div className="editor-section-container">
        <div className="editor-section-header left">
          <Typography
            variant="h5"
            className="editing-text"
            gutterBottom
            sx={{ margin: "0 !important" }}
          >
            {t("Global.edit")}
          </Typography>
          <Typography className="editing-description" gutterBottom>
            {props.tab == "interactive"
              ? t("contentUpload.interactiveContentLabel")
              : props.tab == "viewer" ? t("contentUpload.viewerContentLabel")
                : props.tab == "DRG" ? t("contentUpload.DRGContentLabel")
                  : t("contentUpload.grouperContentLabel")}
          </Typography>
        </div>
        <div className="editor-section-header right">
          <div className="editor-right-container">
            <TextField
              label={t("contentUpload.yearLabel")}
              variant="outlined"
              select
              value={year}
              onChange={handleYearChange}
            >
              {years ? (
                years.map((year) => (
                  <MenuItem key={year.value} value={year.value}>{year.text}</MenuItem>
                ))
              ) : (
                  <div></div>
                )}
            </TextField>
            <div style={{ padding: "10px" }}></div>
            <TextField
              label={t("contentUpload.languageLabel")}
              variant="outlined"
              select
              className="dropdown-section"
              value={language}
              onChange={handleLanguageChange}
            >
              {countries ? (
                countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>{country.language}</MenuItem>
                ))
              ) : (
                  <div></div>
                )}
            </TextField>
            <div style={{ padding: "10px" }}></div>
            <TextField
              label={t("contentUpload.destEnvironmentLabel")}
              variant="outlined"
              select
              className="dropdown-section"
              value={destEnvironment}
              onChange={handlEnvironmentChange}
            >
              <MenuItem key="Test" value="Test">{t("contentUpload.Test")}</MenuItem>
              <MenuItem key="Hearing" value="Hearing">{t("publisher.Hearing")}</MenuItem>
              <MenuItem key="Production" value="Production">{t("publisher.Production")}</MenuItem>
            </TextField>
          </div>
        </div>
      </div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperStyle={wrapperStyle}
        editorStyle={editorStyle}
        toolbarStyle={toolbarStyle}
        toolbar={{
          options: [
            "fontFamily",
            "fontSize",
            "colorPicker",
            "inline",
            "list",
            "link",
            "embedded",
            "image",
            "textAlign",
            "remove",
            "history",
          ],
          link: {
            showOpenOptionOnHover: false,
            defaultTargetOption: "_self",
          },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: _uploadImageCallBack,
            previewImage: true,
            alt: { present: true, mandatory: false },
          },
          remove: { title: t("Editor.ClearFormatting") },
        }}
      />
    </div>
  );
}
