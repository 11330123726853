import React from "react";
import "./Viewer.css";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMetadata,
  getMetadataForLogicName,
  getAllParentSKS,
  setChildrenNode,
  getSKSTable,
  setSKSSelected,
  setDRGLoading,
  setPagination,
} from "./viewerSlice";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    /* paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    }, */
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    /* [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  }, */
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      expandIcon={<ArrowRightIcon />}
      collapseIcon={<ArrowDropDownIcon />}
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          {labelInfo === "Selected" ? (
            <DoneIcon fontSize="small" color="inherit" sx={{ pr: "5px" }} />
          ) : null}
          {labelInfo === "Selected" ? (
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          ) : (
            <Typography variant="caption" color="#BFBFB4">
              {labelInfo}
            </Typography>
          )}
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const { useState, useCallback } = React;

function MyTreeItem(props) {
  const { t, i18n } = useTranslation("language");
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  // const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();
  const viewer = useSelector((state) => state.viewer);
  const {
    selectedVisualObj,
    sksParentCollection,
    sksChildrenCollection,
    sksSelectedId,
  } = viewer;
  const { id, name, node } = props;

  const handleChange = (event, nodes) => {
    console.log("Nodes:", nodes);
    const expandingNodes = nodes.filter((x) => !expanded.includes(x));
    console.log("expandingNodes:", expandingNodes);
    setExpanded(nodes);
    if (expandingNodes[0]) {
      const parentId = expandingNodes[0];
      if (node.hasChildren) {
        dispatch(setChildrenNode(true));
        dispatch(getAllParentSKS({ method: "GET", parentId: parentId })).then(
          (res) => {
            console.log("Res:", res);

            setChildNodes(
              res?.payload?.map((node) => (
                <MyTreeItem
                  key={node.id}
                  node={node}
                  id={node.id}
                  name={node.name}
                  {...node}
                />
              ))
            );
          }
        );
      }
    }
  };
  const handleTreeSelection = (obj) => () => {
    console.log("Selected tree item:", obj);
    if (
      obj.isLeaf.toLowerCase() == "true" ||
      obj.isSelectable.toLowerCase() == "true"
    ) {
      dispatch(setSKSSelected(obj));
      dispatch(setDRGLoading({ name: "showSKSTable", value: false }));
      dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
      dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
      dispatch(getSKSTable({ method: "GET", code: obj.code })).then(() => {
        dispatch(setDRGLoading({ name: "showSKSTable", value: true }));
        dispatch(setPagination({ page: 0, rowsPerPage: 5 }));
      });
    }
  };
  let labelInfo = "";
  let bgColor = "#fff";
  if (node.isSelectable.toLowerCase() == "true" && id === sksSelectedId) {
    labelInfo = t("viewer.selected");
    bgColor = "#e8f0fe";
  } else if (node.isSelectable.toLowerCase() == "true") {
    labelInfo = t("viewer.select");
  }

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      expanded={expanded}
      onNodeToggle={handleChange}
      sx={{ width: "99%" }}
    >
      {/*The node below should act as the root node for now */}
      <StyledTreeItem
        key={id}
        nodeId={id}
        labelText={name}
        labelInfo={
          node.isLeaf.toLowerCase() == "true" ||
          node.isLeaf.toLowerCase() == "false"
            ? labelInfo
            : ""
        }
        color="#1a73e8"
        bgColor={bgColor}
        expandIcon={<ArrowRightIcon />}
        collapseIcon={<ArrowDropDownIcon />}
        onClick={handleTreeSelection(node)}
      >
        {node.isLeaf.toLowerCase() == "true"
          ? childNodes
          : childNodes || [<div key="stub" />]}
      </StyledTreeItem>
    </TreeView>
  );
}

export default function SKSTreeView(props) {
  const dispatch = useDispatch();
  const viewer = useSelector((state) => state.viewer);
  const { sksParentCollection, sksSearchTree, isSKSSearch } = viewer;
  if (props.search && isSKSSearch) {
    return (
      <div className="sksnodes-tree-container">
        {sksSearchTree?.map((b) => {
          return <MyTreeItem key={b.id} id={b.id} name={b.name} node={b} />;
        })}
      </div>
    );
  } else {
    return (
      <div className="sksnodes-tree-container">
        {sksParentCollection?.map((b) => {
          return <MyTreeItem key={b.id} id={b.id} name={b.name} node={b} />;
        })}
      </div>
    );
  }
}
