import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

export const loadlogic = createAsyncThunk(
  "manager/loadlogic",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const upload = createAsyncThunk(
  "manager/upload",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.body = obj.body;
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const remove = createAsyncThunk(
  "manager/remove",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const download = createAsyncThunk(
  "manager/download",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.blob();
      }
    });
  }
);

export const getRoles = createAsyncThunk(
  "manager/getRoles",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/Registration/GetRoles", reqOptions).then((r) => {
      if (r.status === 200) {
        dispatch(loadUsers({ method: "GET" }));
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadUsers = createAsyncThunk(
  "manager/loadUsers",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("home/api/GetUsers", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const saveUsers = createAsyncThunk(
  "manager/saveUsers",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const saveLogic = createAsyncThunk(
  "manager/saveLogic",
  async (obj, { dispatch, getState, state }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      // console.log(r);
      // const { isDRG } = getState().manager;
      // if (r.status === 200 && isDRG) {
      //     dispatch(setDrgAddLogic());
      // }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const exportLogic = createAsyncThunk(
  "manager/exportLogic",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.blob();
      }
    });
  }
);

export const GetAllDRGLogics = createAsyncThunk(
  "manager/GetAllDRGLogics",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetUpdatedDRGLogic = createAsyncThunk(
  "manager/GetUpdatedDRGLogic",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadtill = createAsyncThunk(
  "manager/loadtill",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(loaddg1({ method: "GET", url: url + "dg1.csv" }));
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loaddg1 = createAsyncThunk(
  "manager/loaddg1",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(loaddrglogik({ method: "GET", url: url + "drglogik.csv" }));
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loaddrglogik = createAsyncThunk(
  "manager/loaddrglogik",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(loaddrgtpt({ method: "GET", url: url + "drgtpt.csv" }));
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loaddrgtpt = createAsyncThunk(
  "manager/loaddrgtpt",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(
          loadCountingCode({ method: "GET", url: url + "CountingCode.yaml" })
        );
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadCountingCode = createAsyncThunk(
  "manager/loadCountingCode",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(
          loadegenskaber({ method: "GET", url: url + "egenskaber.csv" })
        );
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadegenskaber = createAsyncThunk(
  "manager/loadegenskaber",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(loadsks({ method: "GET", url: url + "sks.csv" }));
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadsks = createAsyncThunk(
  "manager/loadsks",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(
          loadMDCSpecification({
            method: "GET",
            url: url + "MDCSpecification.yaml",
          })
        );
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadMDCSpecification = createAsyncThunk(
  "manager/loadMDCSpecification",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadDrg = createAsyncThunk(
  "manager/loadDrg",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 200) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(
          loadcasemixDRG({ method: "GET", url: url + "casemixDRG.csv" })
        );
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadcasemixDRG = createAsyncThunk(
  "manager/loadcasemixDRG",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const SaveSubList = createAsyncThunk(
  "manager/SaveSubList",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const DeleteDRGSubList = createAsyncThunk(
  "manager/DeleteDRGSubList",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetAllGrouperDetails = createAsyncThunk(
  "manager/GetAllGrouperDetails",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      const { isGInteractive } = getState().manager;
      if (r.status === 200 && isGInteractive) {
        let url = "api/getLogicNameAndVersion?&selectedGrpCodeVal=";
        dispatch(
          loadinternekoder({ method: "GET", url: url + "interne%20koder.yaml" })
        );
      }
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetUpdatedGrouper = createAsyncThunk(
  "manager/GetUpdatedGrouper",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      const { isGInteractive } = getState().manager;
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GGrouper = createAsyncThunk(
  "manager/GGrouper",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.blob();
      }
    });
  }
);

export const UpdateGrouper = createAsyncThunk(
  "manager/UpdateGrouper",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const DeleteGrouperSublist = createAsyncThunk(
  "manager/DeleteGrouperSublist",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetAllInteractiveDetails = createAsyncThunk(
  "manager/GetAllInteractiveDetails",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetUpdatedInteractive = createAsyncThunk(
  "manager/GetUpdatedInteractive",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const loadinternekoder = createAsyncThunk(
  "manager/loadinternekoder",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GInteractive = createAsyncThunk(
  "manager/GInteractive",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const UpdateInteractive = createAsyncThunk(
  "manager/UpdateInteractive",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const DeleteInteractiveSublist = createAsyncThunk(
  "manager/DeleteInteractiveSublist",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetAllViewerDetails = createAsyncThunk(
  "manager/GetAllViewerDetails",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GetUpdatedVisual = createAsyncThunk(
  "manager/GetUpdatedVisual",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const GViewer = createAsyncThunk(
  "manager/GViewer",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const UpdateViewer = createAsyncThunk(
  "manager/UpdateViewer",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    reqOptions.body = JSON.stringify(obj.body);
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const DeleteVisualSublist = createAsyncThunk(
  "manager/DeleteVisualSublist",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const DeleteMDCSpecs = createAsyncThunk(
  "manager/DeleteMDCSpecs",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

const managerSlice = createSlice({
  name: "manager",
  initialState: {
    isInitialLoadAPI: false,
    isLoadAPIs: false,
    isClinicalCode: false,
    isCasemixFile: false,
    isSupportFile: false,
    isDRG: false,
    isDrgRender: false,
    isDRGLogic: [],
    isGetAllDRGLogicsLoaded: false,
    isGetAllDRGLogicsRender: false,
    DrgUsers: [],
    DrgUsersMaster: [],
    isdrgLogicUsers: [],
    isGGrouper: false,
    isGGrouperRender: false,
    isGGrouperLogic: [],
    isGInteractive: false,
    isGInteractiveRender: false,
    isGInteractiveCheckGrouper: false,
    isGInteractiveLogic: [],
    isGViewer: false,
    isGViewerRender: false,
    isGInteractiveCheckInterkoder: false,
    isGViewerLogic: [],
    data: [],
    isNoData: false,
    selectedGrpCodeValue: "",
    selectedYear: "",
    distinctUploadYear: [],
    selectedUploadYear: "",
    filterdata: [],
    masterfilterdata: [],
    roles: [],
    allDrgLogicList: [],
    selDrgLogicList: [],
    selFileType: "",
    selFileDetails: [],
    isTillMaster: [],
    isTill: [],
    isDg1Master: [],
    isDg1: [],
    isDrglogikMaster: [],
    isDrglogik: [],
    isDrgtptMaster: [],
    isDrgtpt: [],
    isCountingCodeMaster: [],
    isCountingCode: [],
    isEgenskaberMaster: [],
    isEgenskaber: [],
    isSksMaster: [],
    isSks: [],
    isDrgMaster: [],
    isDrg: [],
    isCasemixDRGMaster: [],
    isCasemixDRG: [],
    isGrouperMaster: [],
    isGrouper: [],
    isInternekoderMaster: [],
    isInternekoder: [],
    isLoading: false,
    isSuccess: false,
    isFailed: false,
    isError: false,
    isServerError: false,
    isRender: true,
    isUploadSuccess: false,
    isUploadFailed: false,
    isRemoveSuccess: false,
    isRemoveFailed: false,
    isUploadError: false,
    isRemoveError: false,
    isGenerateSuccess: false,
    isFileExist: false,
    isIvalidFile: false,
    isEmptyFile: false,
    isSaveUsersSuccess: false,
    isSaveUsersFailed: false,
    isSaveLogicSuccess: false,
    isSaveLogicFailed: false,
    isSaveSubListSuccess: false,
    isRemoveSubListSuccess: false,
    isRemoveSubListFailed: false,
    isGSaveLogicFailed: false,
    isGGrouperCheck: false,
    isGGrouperError: false,
    isDownloadError: false,
    isUploadxlsx: false,
    isEditLogicInfo: false,
    isSessionValid: true,
    isUploadPDF: false,
    isExpanded: [],
    isDisabled: true,
    isDrgDataAdded: false,
    isDrgDataRemoved: false,
    isGGrouperDataAdded: false,
    isGGrouperDataRemoved: false,
    isGInteractiveDataAdded: false,
    isGInteractiveDataRemoved: false,
    isGViewerDataAdded: false,
    isGViewerDataRemoved: false,
    isDrgUsersUpdated: false,
    isGrouperLogicRender: false,
    isDrgLogicRender: false,
    isInteractiveLogicRender: false,
    isViewerLogicRender: false,
    isGViewerDataRemoved: false,
    isMDCSpecificationMaster: [],
    isMDCSpecification: [],
    viewerTariffFlag: false,
    grouperTariffFlag: false,
  },
  reducers: {
    endUserSession(state, action) {
      state.isSessionValid = false;
    },
    clearMangerStatus(state, action) {
      state.isClinicalCode = false;
      state.isCasemixFile = false;
      state.isSupportFile = false;
      state.isDRG = false;
      state.isGGrouper = false;
      state.isGInteractive = false;
      state.isGViewer = false;
    },
    setIsRender(state, action) {
      state.isRender = true;
    },
    setIsGetAllDRGLogicsLoaded(state, action) {
      state.isGetAllDRGLogicsLoaded = false;
      state.isGetAllDRGLogicsRender = false;
    },
    setIsDrgDataUpdated(state, action) {
      state.isDrgDataAdded = action.payload;
    },
    setIsDrgRender(state, action) {
      state.isDrgRender = action.payload;
    },
    setIsGGrouperDataUpdated(state, action) {
      state.isGGrouperDataAdded = action.payload;
    },
    setIsGGrouperDataRemoved(state, action) {
      state.isGGrouperDataRemoved = action.payload;
    },
    setIsGInteractiveDataUpdated(state, action) {
      state.isGInteractiveDataAdded = action.payload;
    },
    setIsGInteractiveDataRemoved(state, action) {
      state.isGInteractiveDataRemoved = action.payload;
    },
    setIsGViewerDataUpdated(state, action) {
      state.isGViewerDataAdded = action.payload;
    },
    setIsGViewerDataRemoved(state, action) {
      state.isGViewerDataRemoved = action.payload;
    },
    setIsClinicalCode(state, action) {
      state.isClinicalCode = true;
      state.isRender = true;
      state.isCasemixFile = false;
      state.isSupportFile = false;
      state.isDRG = false;
      state.isGGrouper = false;
      state.isGInteractive = false;
      state.isGViewer = false;
      state.selectedGrpCodeValue = "";
    },
    setIsCasemixFile(state, action) {
      state.isCasemixFile = true;
      state.isRender = true;
      state.isClinicalCode = false;
      state.isSupportFile = false;
      state.isDRG = false;
      state.isGGrouper = false;
      state.isGInteractive = false;
      state.isGViewer = false;
      state.selectedGrpCodeValue = "";
    },
    setIsSupportFile(state, action) {
      state.isSupportFile = true;
      state.isRender = true;
      state.isClinicalCode = false;
      state.isCasemixFile = false;
      state.isDRG = false;
      state.isGGrouper = false;
      state.isGInteractive = false;
      state.isGViewer = false;
      state.selectedGrpCodeValue = "";
    },
    setIsDRG(state, action) {
      state.isDRG = true;
      state.isInitialLoadAPI = false;
      state.isRender = true;
      state.isDrgRender = false;
      state.isGetAllDRGLogicsLoaded = false;
      state.isDRGLogic = [];
      state.isClinicalCode = false;
      state.isCasemixFile = false;
      state.isSupportFile = false;
      state.isGGrouper = false;
      state.isGInteractive = false;
      state.isGViewer = false;
      state.selectedYear = "";
      state.data = [];
      state.isNoData = false;
      state.filterdata = [];
      state.masterfilterdata = [];
      state.selFileType = "";
      state.selFileDetails = [];
      state.allDrgLogicList = [];
      state.selDrgLogicList = [];
    },
    setIsGGrouper(state, action) {
      state.isGGrouper = true;
      state.isInitialLoadAPI = false;
      state.isRender = true;
      state.isGGrouperLogic = [];
      state.data = [];
      state.isNoData = false;
      state.filterdata = [];
      state.masterfilterdata = [];
      state.selectedYear = "";
      state.selectedUploadYear = "";
      state.selFileType = "";
      state.selFileDetails = [];
      state.isClinicalCode = false;
      state.isCasemixFile = false;
      state.isSupportFile = false;
      state.isDRG = false;
      state.isGInteractive = false;
      state.isGViewer = false;
      state.isDrgRender = false;
      state.isGetAllDRGLogicsLoaded = false;
      state.isDRGLogic = [];
      state.isDrgMaster = [];
      state.isCasemixDRGMaster = [];
    },
    setIsGInteractive(state, action) {
      state.isGInteractive = true;
      state.isInitialLoadAPI = false;
      state.isRender = true;
      state.isGInteractiveLogic = [];
      state.data = [];
      state.isNoData = false;
      state.filterdata = [];
      state.masterfilterdata = [];
      state.selectedYear = "";
      state.selectedUploadYear = "";
      state.selFileType = "";
      state.selFileDetails = [];
      state.isClinicalCode = false;
      state.isCasemixFile = false;
      state.isSupportFile = false;
      state.isDRG = false;
      state.isGGrouper = false;
      state.isGViewer = false;
      state.isDrgRender = false;
      state.isGetAllDRGLogicsLoaded = false;
      state.isDRGLogic = [];
      state.isGrouperMaster = [];
      state.isGrouper = [];
    },
    setIsGViewer(state, action) {
      state.isGViewer = true;
      state.isInitialLoadAPI = false;
      state.isRender = true;
      state.isGViewerLogic = [];
      state.data = [];
      state.isNoData = false;
      state.filterdata = [];
      state.masterfilterdata = [];
      state.selectedYear = "";
      state.selectedUploadYear = "";
      state.selFileType = "";
      state.selFileDetails = [];
      state.isClinicalCode = false;
      state.isCasemixFile = false;
      state.isSupportFile = false;
      state.isDRG = false;
      state.isGGrouper = false;
      state.isGInteractive = false;
      state.isDrgRender = false;
      state.isGetAllDRGLogicsLoaded = false;
      state.isDRGLogic = [];
      state.isGrouperMaster = [];
      state.isGrouper = [];
    },
    setIsGGrouperCheck(state, action) {
      state.isGGrouperCheck = true;
      state.isFailed = true;
    },
    setSelectedGrpCodeValue(state, action) {
      state.selectedGrpCodeValue = action.payload;
    },
    setIsDrgDataRemoved(state, action) {
      state.isDrgDataRemoved = action.payload;
    },
    setIsDRGLogic(state, action) {
      state.isDRGLogic = state.masterfilterdata.filter(
        (data) => data._id == action.payload
      );
      state.isDrgRender = false;
      state.selDrgLogicList = [];
      console.log(state.isDRGLogic);
      if (state.isDRGLogic != null && state.isDRGLogic.length > 0) {
        //state.isdrgLogicUsers = state.isDRGLogic[0].users;
        const DrgList = [];
        const selDrgLogic = state.allDrgLogicList.filter(
          (x) => x.id == state.isDRGLogic[0]._id
        )[0];
        console.log(selDrgLogic);
        if (selDrgLogic) {
          state.isdrgLogicUsers = selDrgLogic.users;
          var propertyList = Object.getOwnPropertyNames(selDrgLogic);
          console.log(propertyList);
          var newlist = propertyList.filter(
            (a) => a.endsWith("Master") || a.includes("mdcSpecsAdded")
          );
          console.log(newlist);
          console.log(selDrgLogic);
          newlist.forEach((element) => {
            var data = selDrgLogic[element];
            if (data != null) {
              if (element == "mdcSpecsAdded" && data == true) {
                DrgList.push({ name: "MdcSpecification" });
              } else if (element != "mdcSpecsAdded") {
                var splitted = element.split("Master");
                data.type = splitted[0];
                DrgList.push(data);
              }
            }
          });
        }

        state.selDrgLogicList = DrgList;
      }
      console.log(state.drgLogicUsers);
      console.log(state.selDrgLogicList);
    },
    setDrgAddLogic(state, action) {
      state.isLoading = false;
      state.isSuccess = true;
      state.isSaveLogicSuccess = true;
      state.isRender = true;
      state.isDRGLogic = [];
      state.isGetAllDRGLogicsRender = true;
    },
    setDrgUsers(state, action) {
      state.DrgUsers = action.payload;
    },
    setIsDRGLogicUsers(state, action) {
      state.isdrgLogicUsers = action.payload;
    },
    setMasterFilterData(state, action) {
      state.masterfilterdata = action.payload;
    },
    setFilterData(state, action) {
      state.filterdata = action.payload;
    },
    setSelectedUploadYear(state, action) {
      state.selectedUploadYear = action.payload;
      console.log(state.selectedUploadYear);
    },
    setSelectedYear(state, action) {
      state.selectedYear = action.payload;
    },
    setEditLogicInfo(state, action) {
      state.isFailed = true;
      state.isEditLogicInfo = true;
    },
    clearStatus(state, action) {
      state.isSuccess = false;
      state.isFailed = false;
      state.isError = false;
      state.isServerError = false;
      state.isUploadSuccess = false;
      state.isUploadFailed = false;
      state.isRemoveSuccess = false;
      state.isRemoveFailed = false;
      state.isUploadError = false;
      state.isRemoveError = false;
      state.isGenerateSuccess = false;
      state.isFileExist = false;
      state.isIvalidFile = false;
      state.isEmptyFile = false;
      state.isSaveUsersSuccess = false;
      state.isSaveUsersFailed = false;
      state.isSaveLogicSuccess = false;
      state.isSaveLogicFailed = false;
      state.isSaveSubListSuccess = false;
      state.isGSaveLogicFailed = false;
      state.isGGrouperCheck = false;
      state.isDownloadError = false;
      state.isRemoveSubListSuccess = false;
      state.isRemoveSubListFailed = false;
      state.isUploadxlsx = false;
      state.isEditLogicInfo = false;
      state.isGInteractiveCheckGrouper = false;
      state.isGInteractiveCheckInterkoder = false;
      state.isUploadPDF = false;
    },
    setSelFileType(state, action) {
      state.selFileType = action.payload;
      console.log(state.selFileType);
    },
    setExpanded(state, action) {
      state.isExpanded = action.payload;
      console.log(state.isExpanded);
    },
    setIsDisabled(state, action) {
      state.isDisabled = action.payload;
    },
    setSelFileDetails(state, action) {
      state.selFileDetails = action.payload;
      console.log(state.selFileDetails);
    },
    /* DRG: Start Initial API's load */
    setIsTill(state, action) {
      state.isTill = action.payload;
      console.log(state.isTill);
    },
    setIsDg1(state, action) {
      state.isDg1 = action.payload;
      console.log(state.isDg1);
    },
    setIsDrglogik(state, action) {
      state.isDrglogik = action.payload;
      console.log(state.isDrglogik);
    },
    setIsDrgtpt(state, action) {
      state.isDrgtpt = action.payload;
      console.log(state.isDrgtpt);
    },
    setIsCountingCode(state, action) {
      state.isCountingCode = action.payload;
      console.log(state.isCountingCode);
    },
    setIsEgenskaber(state, action) {
      state.isEgenskaber = action.payload;
      console.log(state.isEgenskaber);
    },
    setIsSks(state, action) {
      state.isSks = action.payload;
      console.log(state.isSks);
    },
    setIsMDCSpecification(state, action) {
      state.isMDCSpecification = action.payload;
      console.log(state.isMDCSpecification);
    },
    /* DRG: End Initial API's load */
    setIsDrg(state, action) {
      state.isDrg = action.payload;
      console.log(state.isDrg);
    },
    setIsCasemixDRG(state, action) {
      state.isCasemixDRG = action.payload;
      console.log(state.isCasemixDRG);
    },
    setIsGGrouperLogic(state, action) {
      state.isGGrouperLogic = state.masterfilterdata.filter(
        (data) => data.id == action.payload
      );
    },
    setDefaultIsGrouperLogic(state, action) {
      let result = [];
      let last = state.filterdata.length - 1;
      result[0] = state.filterdata[last];
      state.isGGrouperLogic = Array.from(result);
    },
    setDefaultIsDrgLogic(state, action) {
      let result = [];
      let last = state.filterdata.length - 1;
      result[0] = state.filterdata[last];
      state.isDRGLogic = Array.from(result);
      state.selDrgLogicList = [];
      if (state.isDRGLogic != null && state.isDRGLogic.length > 0) {
        const DrgList = [];
        const selDrgLogic = state.allDrgLogicList.filter(
          (x) => x.id == state.isDRGLogic[0]._id
        )[0];
        if (selDrgLogic) {
          state.isdrgLogicUsers = selDrgLogic.users;
          var propertyList = Object.getOwnPropertyNames(selDrgLogic);
          console.log(propertyList);
          var newlist = propertyList.filter(
            (a) => a.endsWith("Master") || a.includes("mdcSpecsAdded")
          );
          newlist.forEach((element) => {
            var data = selDrgLogic[element];
            if (data != null) {
              if (element == "mdcSpecsAdded" && data == true) {
                DrgList.push({ name: "MdcSpecification" });
              } else if (element != "mdcSpecsAdded") {
                var splitted = element.split("Master");
                data.type = splitted[0];
                DrgList.push(data);
              }
            }
          });
        }

        state.selDrgLogicList = DrgList;
      }
    },
    setDefaultIsInteractiveLogic(state, action) {
      let result = [];
      let last = state.filterdata.length - 1;
      result[0] = state.filterdata[last];
      state.isGInteractiveLogic = Array.from(result);
    },
    setDefaultIsViewerLogic(state, action) {
      let result = [];
      let last = state.filterdata.length - 1;
      result[0] = state.filterdata[last];
      state.isGViewerLogic = Array.from(result);
    },
    setIsGGrouperLogicTariff(state, action) {
      state.isGGrouperLogic = action.payload;
      state.grouperTariffFlag = true;
    },
    setGGrouperTariffFlag(state, action) {
      state.grouperTariffFlag = false;
    },
    setIsGGrouperRender(state, action) {
      state.isGGrouperRender = false;
    },
    /* GInteractive: Start  */
    setIsGInteractiveLogic(state, action) {
      state.isGInteractiveLogic = state.masterfilterdata.filter(
        (data) => data.id == action.payload
      );
    },
    setIsGInteractiveRender(state, action) {
      state.isGInteractiveRender = false;
    },
    setIsGInteractiveCheck(state, action) {
      if (action.payload?.method == "CheckGrouper") {
        state.isGInteractiveCheckGrouper = true;
        state.isFailed = true;
      } else if (action.payload?.method == "CheckInterkoder") {
        state.isFailed = true;
        state.isGInteractiveCheckInterkoder = true;
      }
    },
    setIsGInteractiveLogicTariff(state, action) {
      state.isGInteractiveLogic = action.payload;
      state.interactiveTariffFlag = true;
    },
    setGInteractiveTariffFlag(state, action) {
      state.interactiveTariffFlag = false;
    },
    /* GInteractive: End  */
    /* GInteractive: Start Initial API's load */
    setIsGrouper(state, action) {
      state.isGrouper = action.payload;
    },
    setIsInternekoder(state, action) {
      state.isInternekoder = action.payload;
    },
    /* GInteractive: End Initial API's load */
    /* GViewer: Start  */
    setIsGViewerLogic(state, action) {
      state.isGViewerLogic = state.masterfilterdata.filter(
        (data) => data.id == action.payload
      );
    },
    setIsGViewerRender(state, action) {
      state.isGViewerRender = false;
    },
    setIsGViewerCheck(state, action) {
      state.isGViewerCheck = true;
      state.isFailed = true;
    },
    setIsGViewerLogicTariff(state, action) {
      state.isGViewerLogic = action.payload;
      state.viewerTariffFlag = true;
    },
    setGViewerTariffFlag(state, action) {
      state.viewerTariffFlag = false;
    },
    /* GViewer: Start  */
  },
  extraReducers: {
    [loadlogic.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loadlogic.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isRender = false;
      if (payload != null && payload.length > 0) {
        state.isNoData = false;
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.data = payload;
        state.distinctUploadYear = payload
          .map((item) => item.Year)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((one, two) => (one > two ? -1 : 1));
        if (!state.distinctUploadYear.includes(state.selectedYear)) {
          let currentYear = new Date().getFullYear();
          let upcomingYear = currentYear + 1;
          let previousYear = currentYear - 1;
          if (state.distinctUploadYear.includes(JSON.stringify(upcomingYear))) {
            state.selectedYear = JSON.stringify(upcomingYear);
          } else if (
            state.distinctUploadYear.includes(JSON.stringify(currentYear))
          ) {
            state.selectedYear = JSON.stringify(currentYear);
          } else if (
            state.distinctUploadYear.includes(JSON.stringify(previousYear))
          ) {
            state.selectedYear = JSON.stringify(previousYear);
          } else {
            state.selectedYear =
              state.distinctUploadYear[state.distinctUploadYear.length - 1];
          }
          state.filterdata = payload.filter(
            (item) => item.Year == state.selectedYear
          );
        } else if (
          state.distinctUploadYear.includes(state.selectedUploadYear)
        ) {
          state.selectedYear = state.selectedUploadYear;
          state.filterdata = payload.filter(
            (item) => item.Year == state.selectedYear
          );
          state.selectedUploadYear = "";
          if (state.isDRG) {
            // let result = [];
            // let last = state.filterdata.length - 1;
            // result[0] = state.filterdata[last];
            // state.isDRGLogic = Array.from(result);
            state.isDrgDataAdded = true;
          }
        } else {
          state.filterdata = payload.filter(
            (item) => item.Year == state.selectedYear
          );
        }
        state.masterfilterdata = state.filterdata;
        if (state.isDRG) {
          state.isTill = state.isTillMaster.filter(
            (item) => item.Year == state.selectedYear
          );
          state.isDg1 = state.isDg1Master.filter(
            (item) => item.Year == state.selectedYear
          );
          state.isDrglogik = state.isDrglogikMaster.filter(
            (item) => item.Year == state.selectedYear
          );
          state.isDrgtpt = state.isDrgtptMaster.filter(
            (item) => item.Year == state.selectedYear
          );
          state.isCountingCode = state.isCountingCodeMaster.filter(
            (item) => item.Year == state.selectedYear
          );
          state.isEgenskaber = state.isEgenskaberMaster.filter(
            (item) => item.Year == state.selectedYear
          );
          state.isSks = state.isSksMaster.filter(
            (item) => item.Year == state.selectedYear
          );
          state.isMDCSpecification = state.isMDCSpecificationMaster.filter(
            (item) => item.Year == state.selectedYear
          );
        }
        if (state.isDRG && state.isDRGLogic.length <= 0) {
          // let result = [];
          // result[0] = state.masterfilterdata[0];
          // state.isDRGLogic = Array.from(result);
          state.isDrgRender = false;
        } else if (state.isDRG) {
          state.isDrgRender = true;
        }
      } else {
        state.isNoData = true;
      }
    },
    [loadlogic.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from loadLogic", action.error.message);
    },
    [upload.pending]: (state, action) => {
      state.isLoading = true;
    },
    [upload.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.indexOf("Upload Successful") >= 0) {
        state.isSuccess = true;
        state.isUploadSuccess = true;
        state.isRender = true;
        state.isGetAllDRGLogicsRender = true;
      } else if (payload == "File exists.") {
        state.isFailed = true;
        state.isFileExist = true;
      } else if (payload.indexOf("Invalid file") >= 0) {
        state.isFailed = true;
        state.isIvalidFile = true;
      } else if (payload == "Upload Failed") {
        state.isError = true;
        state.isUploadFailed = true;
      } else if (payload == "Empty File") {
        state.isFailed = true;
        state.isEmptyFile = true;
      } else if (payload == "Error Occured") {
        state.isUploadError = true;
        state.isError = true;
      } else if (payload == "Upload xlsx file only.") {
        state.isFailed = true;
        state.isUploadxlsx = true;
      } else if (payload == "Upload PDF") {
        state.isFailed = true;
        state.isUploadPDF = true;
      } else {
        state.isError = true;
        state.isServerError = true;
      }
    },
    [upload.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from uploadLogic", action.error.message);
    },
    [remove.pending]: (state, action) => {
      state.isLoading = true;
    },
    [remove.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload != null && payload == true) {
        state.isSuccess = true;
        state.isRemoveSuccess = true;
        state.isRender = true;
        state.isDRGLogic = [];
        state.isGGrouperLogic = [];
        state.isGInteractiveLogic = [];
        state.isGViewerLogic = [];

        if (state.isDrg) {
          state.isDrgDataRemoved = true;
        } else if (state.isGGrouper) {
          state.isGGrouperDataRemoved = true;
        } else if (state.isGInteractive) {
          state.isGInteractiveDataRemoved = true;
        } else if (state.isGViewer) {
          state.isGViewerDataRemoved = true;
        }
      } else if (payload != null && payload == false) {
        state.isFailed = true;
        state.isRemoveFailed = true;
      } else {
        state.isError = true;
        state.isServerError = true;
      }
    },
    [remove.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from removeLogic", action.error.message);
    },
    [download.pending]: (state, action) => {
      state.regStatus = "loading";
    },
    [download.fulfilled]: (state, { payload }) => {
      if (payload.type == "application/json") {
        console.log("Error in downloading");
        state.isError = true;
        state.isDownloadError = true;
      } else if (payload.type == "application/octet-stream") {
        saveAs(payload, state.selectedGrpCodeValue);
      } else {
        state.isError = true;
        state.isServerError = true;
      }
    },
    [download.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error in downloadFile", action.error.message);
    },
    /* Manager Common: Start  */
    [getRoles.pending]: (state, action) => {},
    [getRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload;
    },
    [getRoles.rejected]: (state, action) => {
      console.log("Error from getRoles", action.error.message);
    },
    [loadUsers.pending]: (state, action) => {},
    [loadUsers.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        let data = payload;
        let nonAdminUsers = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].role != null) {
            var role = state.roles.filter((a) => a.id == data[i]["role"][0]);
            if (role.length > 0) {
              data[i].RoleName = role[0]["roleName"];
            } else {
              data[i].RoleName = "No role assigned";
            }
          } else {
            data[i].RoleName = "No role assigned";
          }
        }
        data.filter(function (element) {
          if (element.hasOwnProperty("role")) {
            if (element.role != null) {
              if (element.role.length > 0) {
                if (
                  !element.role.includes(1) &&
                  !element.role.includes(4) &&
                  element?.active != 0
                ) {
                  nonAdminUsers.push(element);
                }
              }
            }
          }
        });
        state.DrgUsers = nonAdminUsers;
        state.DrgUsersMaster = state.DrgUsers;
      }
    },
    [loadUsers.rejected]: (state, action) => {
      console.log("Error in loadUsers", action.error.message);
    },
    /* Manager Common: End  */
    /* DRG: Start  */
    [saveUsers.pending]: (state, action) => {},
    [saveUsers.fulfilled]: (state, { payload }) => {
      console.log(payload);
      if (payload != null && payload == true) {
        //state.isRender = true;
        state.isSuccess = true;
        state.isSaveUsersSuccess = true;
        state.isDrgLogicRender = !state.isDrgLogicRender;
        // state.isDrgDataAdded=true;
      } else {
        state.isFailed = true;
        state.isSaveUsersFailed = true;
      }
    },
    [saveUsers.rejected]: (state, action) => {
      console.log("Error from saveUsers", action.error.message);
    },
    [saveLogic.pending]: (state, action) => {
      console.log(action);
      state.isLoading = true;
    },
    [saveLogic.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (state.isDRG) {
        if (payload == "Success") {
          state.isLoading = false;
          state.isSuccess = true;
          state.isSaveLogicSuccess = true;
          state.isRender = true;
          state.isDRGLogic = [];
          state.isGetAllDRGLogicsRender = true;
        } else if (payload == "logic exists.") {
          state.isFailed = true;
          state.isSaveLogicFailed = true;
        }
      } else {
        if (payload == true) {
          state.isSuccess = true;
          state.isSaveLogicSuccess = true;
          state.isRender = true;
          state.isGGrouperLogic = [];
          state.isGInteractiveLogic = [];
          state.isGViewerLogic = [];
        } else if (payload == false) {
          state.isFailed = true;
          state.isGSaveLogicFailed = true;
        }
      }
      /* End: GGrouper Logic response */
    },
    [saveLogic.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log(action);
    },
    [exportLogic.pending]: (state, action) => {
      state.isLoading = true;
    },
    [exportLogic.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.type == "application/json") {
        console.log("Error from exportLogic");
      } else if (payload.type == "application/octet-stream") {
        saveAs(payload, state.isDRGLogic[0].Name + ".zip");
      }
    },
    [exportLogic.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from exportLogic", action.error.message);
    },
    [GetUpdatedDRGLogic.pending]: (state, action) => {},
    [GetUpdatedDRGLogic.fulfilled]: (state, { payload }) => {
      if (payload != null) {
        state.allDrgLogicList = state.allDrgLogicList.map((u) =>
          u.id !== payload.id ? u : payload
        );

        const DrgList = [];
        var propertyList = Object.getOwnPropertyNames(payload);
        console.log(propertyList);
        var newlist = propertyList.filter(
          (a) => a.endsWith("Master") || a.includes("mdcSpecsAdded")
        );
        console.log(newlist);
        newlist.forEach((element) => {
          var data = payload[element];
          if (data != null) {
            if (element == "mdcSpecsAdded" && data == true) {
              DrgList.push({ name: "MdcSpecification" });
            } else if (element != "mdcSpecsAdded") {
              var splitted = element.split("Master");
              data.type = splitted[0];
              DrgList.push(data);
            }
          }
        });
        state.selDrgLogicList = DrgList;
        state.isdrgLogicUsers = payload.users;
        // state.isSaveSubListSuccess=false;
        // state.isSaveUsersSuccess=false;
        // state.isRemoveSubListSuccess=false;
        state.isDrgUsersUpdated = !state.isDrgUsersUpdated;
      }
    },
    [GetUpdatedDRGLogic.rejected]: (state, action) => {
      console.log("Error from GetUpdatedDRGLogic", action.error.message);
    },
    [GetAllDRGLogics.pending]: (state, action) => {},
    [GetAllDRGLogics.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        state.allDrgLogicList = payload;
        state.isGetAllDRGLogicsLoaded = true;
        state.isInitialLoadAPI = false;
      }
    },
    [GetAllDRGLogics.rejected]: (state, action) => {
      console.log("Error from GetAllDRGLogics", action.error.message);
    },
    [SaveSubList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [SaveSubList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload) {
        state.isSuccess = true;
        state.isSaveSubListSuccess = true;
        state.isDrgLogicRender = !state.isDrgLogicRender;
        //state.isRender = true;
        // state.isGetAllDRGLogicsRender = true;
        //state.isDrgDataAdded=true;
      }
    },
    [SaveSubList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from SaveSubList", action.error.message);
    },
    [DeleteDRGSubList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DeleteDRGSubList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.isSuccess = true;
        state.isRemoveSubListSuccess = true;
        state.isDrgLogicRender = !state.isDrgLogicRender;
        //state.isRender = true;
        // state.isGetAllDRGLogicsRender = true;
        // state.isDrgDataAdded=true;
      } else {
        state.isFailed = true;
        state.isRemoveSubListFailed = true;
      }
    },
    [DeleteDRGSubList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from DeleteDRGSubList", action.error.message);
    },
    [DeleteMDCSpecs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DeleteMDCSpecs.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload == "MDC file Deleted Successful") {
        state.isSuccess = true;
        state.isRemoveSubListSuccess = true;
        state.isDrgLogicRender = !state.isDrgLogicRender;
        // state.isRender = true;
        // state.isGetAllDRGLogicsRender = true;
        // state.isDrgDataAdded=true;
      } else {
        state.isFailed = true;
        state.isRemoveSubListFailed = true;
      }
    },
    [DeleteMDCSpecs.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from DeleteMDCSpecs", action.error.message);
    },
    /* DRG: End  */
    /* DRG: Start Initial API's load */
    [loadtill.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loadtill.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isTillMaster = payload;
      }
    },
    [loadtill.rejected]: (state, action) => {
      console.log("Error from loadtill", action.error.message);
    },
    [loaddg1.pending]: (state, action) => {},
    [loaddg1.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isDg1Master = payload;
      }
    },
    [loaddg1.rejected]: (state, action) => {
      console.log("Error from loaddg1", action.error.message);
    },
    [loaddrglogik.pending]: (state, action) => {},
    [loaddrglogik.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isDrglogikMaster = payload;
      }
    },
    [loaddrglogik.rejected]: (state, action) => {
      console.log("Error from loaddrglogik", action.error.message);
    },
    [loaddrgtpt.pending]: (state, action) => {},
    [loaddrgtpt.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isDrgtptMaster = payload;
      }
    },
    [loaddrgtpt.rejected]: (state, action) => {
      console.log("Error from loaddrgtpt", action.error.message);
    },
    [loadCountingCode.pending]: (state, action) => {},
    [loadCountingCode.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isCountingCodeMaster = payload;
      }
    },
    [loadCountingCode.rejected]: (state, action) => {
      console.log("Error from loadCountingCode", action.error.message);
    },
    [loadegenskaber.pending]: (state, action) => {},
    [loadegenskaber.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isEgenskaberMaster = payload;
      }
    },
    [loadegenskaber.rejected]: (state, action) => {
      console.log("Error from loadegenskaber", action.error.message);
    },
    [loadsks.pending]: (state, action) => {},
    [loadsks.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isSksMaster = payload;
      }
      // state.isInitialLoadAPI = true;
    },
    [loadsks.rejected]: (state, action) => {
      console.log("Error from loadsks", action.error.message);
    },
    [loadMDCSpecification.pending]: (state, action) => {},
    [loadMDCSpecification.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        payload = payload.map((item) => {
          let UploadedDateFormat;
          if (item.UploadedDate != null) {
            let date = new Date(item.UploadedDate);
            let dateFormat =
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear();
            return { ...item, UploadedDateFormat: dateFormat };
          } else {
            return { ...item, UploadedDateFormat: "" };
          }
        });
        state.isMDCSpecificationMaster = payload;
      }
      state.isInitialLoadAPI = true;
    },
    [loadMDCSpecification.rejected]: (state, action) => {
      console.log("Error from loadMDCSpecification", action.error.message);
    },
    /* DRG: End Initial API's load */
    /* GGrouper: Start Initial API's load */
    [loadDrg.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loadDrg.fulfilled]: (state, { payload }) => {
      console.log(payload);
      if (payload != null && payload.length > 0) {
        state.isDrgMaster = payload;
      }
    },
    [loadDrg.rejected]: (state, action) => {
      console.log("Error from loadGetDRGLogicList", action.error.message);
    },
    [loadcasemixDRG.pending]: (state, action) => {},
    [loadcasemixDRG.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        state.isCasemixDRGMaster = payload;
      }
      state.isInitialLoadAPI = true;
    },
    [loadcasemixDRG.rejected]: (state, action) => {
      console.log("Error from loadcasemixDRG", action.error.message);
    },
    [GetUpdatedGrouper.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetUpdatedGrouper.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload != null) {
        state.data = state.data.map((u) => (u.id !== payload.id ? u : payload));

        state.masterfilterdata = state.masterfilterdata.map((u) =>
          u.id !== payload.id ? u : payload
        );

        state.isGGrouperLogic = state.masterfilterdata.filter(
          (data) => data.id == payload.id
        );
      }
    },
    [GetUpdatedGrouper.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetUpdatedGrouper", action.error.message);
    },
    /* GGrouper: End Initial API's load */
    /* GGrouper: Start  */
    [GetAllGrouperDetails.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetAllGrouperDetails.fulfilled]: (state, { payload }) => {
      if (state.isGGrouper) {
        state.isLoading = false;
        state.isRender = false;
        if (payload != null && payload.length > 0) {
          state.isNoData = false;
          state.data = payload;
          state.distinctUploadYear = payload
            .map((item) => item.year)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((one, two) => (one > two ? -1 : 1));
          if (!state.distinctUploadYear.includes(state.selectedYear)) {
            let currentYear = new Date().getFullYear();
            let upcomingYear = currentYear + 1;
            let previousYear = currentYear - 1;
            if (
              state.distinctUploadYear.includes(JSON.stringify(upcomingYear))
            ) {
              state.selectedYear = JSON.stringify(upcomingYear);
            } else if (
              state.distinctUploadYear.includes(JSON.stringify(currentYear))
            ) {
              state.selectedYear = JSON.stringify(currentYear);
            } else if (
              state.distinctUploadYear.includes(JSON.stringify(previousYear))
            ) {
              state.selectedYear = JSON.stringify(previousYear);
            } else {
              state.selectedYear =
                state.distinctUploadYear[state.distinctUploadYear.length - 1];
            }
            state.filterdata = payload.filter(
              (item) => item.year == state.selectedYear
            );
          } else if (
            state.distinctUploadYear.includes(state.selectedUploadYear)
          ) {
            state.selectedYear = state.selectedUploadYear;
            state.filterdata = payload.filter(
              (item) => item.year == state.selectedYear
            );
            state.selectedUploadYear = "";
            // let result = [];
            // let last = state.filterdata.length - 1;
            // result[0] = state.filterdata[last];
            // state.isGGrouperLogic = Array.from(result);
            state.isGGrouperDataAdded = true;
          } else {
            state.filterdata = payload.filter(
              (item) => item.year == state.selectedYear
            );
          }
          state.masterfilterdata = state.filterdata;
          state.isDrg = state.isDrgMaster.filter(
            (item) => item.year == state.selectedYear
          );
          state.isCasemixDRG = state.isCasemixDRGMaster.filter(
            (item) => item.Year == state.selectedYear
          );
          if (state.isGGrouper && state.isGGrouperLogic.length <= 0) {
            // let result = [];
            // result[0] = state.masterfilterdata[0];
            // state.isGGrouperLogic = Array.from(result);
          } else {
            state.isGGrouperRender = true;
          }
        } else {
          state.isNoData = true;
        }
      }
      /* Start: For Generate Interactive logic */
      if (state.isGInteractive || state.isGViewer) {
        state.isGrouperMaster = payload.filter(
          (x) => x.hasSKS == true && x.isGenerated == true
        );
      }
      if (state.isGViewer) {
        state.isInitialLoadAPI = true;
      }
      /* End: For Generate Interactive logic */
    },
    [GetAllGrouperDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetAllGrouperDetails", action.error.message);
    },
    [GGrouper.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GGrouper.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload.type == "application/json") {
        state.isError = true;
        state.isGGrouperError = true;
      } else if (payload.type == "application/octet-stream") {
        saveAs(payload, state.isGGrouperLogic[0]?.grouperName + ".zip");
        //state.isRender = true;
        state.isGrouperLogicRender = !state.isGrouperLogicRender;
      }
    },
    [GGrouper.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GenerateGrouper", action.error.message);
    },
    [UpdateGrouper.pending]: (state, action) => {
      state.isLoading = true;
    },
    [UpdateGrouper.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload == true) {
        state.isSuccess = true;
        state.isSaveLogicSuccess = true;
        //state.isRender = true;
        state.isGrouperLogicRender = !state.isGrouperLogicRender;
      }
    },
    [UpdateGrouper.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from UpdateGrouper", action.error.message);
    },
    [DeleteGrouperSublist.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DeleteGrouperSublist.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.isSuccess = true;
        state.isRemoveSubListSuccess = true;
        state.isGrouperLogicRender = !state.isGrouperLogicRender;
        //state.isRender = true;
      } else {
        state.isFailed = true;
        state.isRemoveSubListFailed = true;
      }
    },
    [DeleteGrouperSublist.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from SaveSubList", action.error.message);
    },
    [GetUpdatedInteractive.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetUpdatedInteractive.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload != null) {
        state.data = state.data.map((u) => (u.id !== payload.id ? u : payload));

        state.masterfilterdata = state.masterfilterdata.map((u) =>
          u.id !== payload.id ? u : payload
        );

        state.isGInteractiveLogic = state.masterfilterdata.filter(
          (data) => data.id == payload.id
        );
      }
    },
    [GetUpdatedInteractive.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetUpdatedInteractive", action.error.message);
    },
    /* GGrouper: End  */
    /* GInteractive: Start  */
    [GetAllInteractiveDetails.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetAllInteractiveDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isRender = false;
      if (payload != null && payload.length > 0) {
        state.isNoData = false;
        state.data = payload;
        state.distinctUploadYear = payload
          .map((item) => item.year)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((one, two) => (one > two ? -1 : 1));
        if (!state.distinctUploadYear.includes(state.selectedYear)) {
          let currentYear = new Date().getFullYear();
          let upcomingYear = currentYear + 1;
          let previousYear = currentYear - 1;
          if (state.distinctUploadYear.includes(JSON.stringify(upcomingYear))) {
            state.selectedYear = JSON.stringify(upcomingYear);
          } else if (
            state.distinctUploadYear.includes(JSON.stringify(currentYear))
          ) {
            state.selectedYear = JSON.stringify(currentYear);
          } else if (
            state.distinctUploadYear.includes(JSON.stringify(previousYear))
          ) {
            state.selectedYear = JSON.stringify(previousYear);
          } else {
            state.selectedYear =
              state.distinctUploadYear[state.distinctUploadYear.length - 1];
          }
          state.filterdata = payload.filter(
            (item) => item.year == state.selectedYear
          );
        } else if (
          state.distinctUploadYear.includes(state.selectedUploadYear)
        ) {
          state.selectedYear = state.selectedUploadYear;
          state.filterdata = payload.filter(
            (item) => item.year == state.selectedYear
          );
          state.selectedUploadYear = "";
          // let result = [];
          // let last = state.filterdata.length - 1;
          // result[0] = state.filterdata[last];
          // state.isGInteractiveLogic = Array.from(result);
          state.isGInteractiveDataAdded = true;
        } else {
          state.filterdata = payload.filter(
            (item) => item.year == state.selectedYear
          );
        }
        state.masterfilterdata = state.filterdata;
        state.isGrouper = state.isGrouperMaster.filter(
          (item) => item.year == state.selectedYear
        );
        state.isInternekoder = state.isInternekoderMaster.filter(
          (item) => item.Year == state.selectedYear
        );
        if (state.isGInteractive && state.isGInteractiveLogic.length <= 0) {
          // let result = [];
          // result[0] = state.masterfilterdata[0];
          // state.isGInteractiveLogic = Array.from(result);
        } else {
          state.isGInteractiveRender = true;
        }
      } else {
        state.isNoData = true;
      }
    },
    [GetAllInteractiveDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetAllInteractiveDetails", action.error.message);
    },
    [loadinternekoder.pending]: (state, action) => {},
    [loadinternekoder.fulfilled]: (state, { payload }) => {
      console.log(payload);
      if (payload != null && payload.length > 0) {
        state.isInternekoderMaster = payload;
        state.isInternekoder = state.isInternekoderMaster.filter(
          (item) => item.Year == state.selectedYear
        );
      }
      state.isInitialLoadAPI = true;
    },
    [loadinternekoder.rejected]: (state, action) => {
      console.log("Error from loadinternekoder", action.error.message);
    },
    [GInteractive.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GInteractive.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload == "Success") {
        state.isSuccess = true;
        state.isGenerateSuccess = true;
        state.isInteractiveLogicRender = !state.isInteractiveLogicRender;
        //state.isRender = true;
      }
    },
    [GInteractive.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GInteractive", action.error.message);
    },
    [UpdateInteractive.pending]: (state, action) => {
      state.isLoading = true;
    },
    [UpdateInteractive.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload == true) {
        state.isSuccess = true;
        state.isSaveLogicSuccess = true;
        state.isInteractiveLogicRender = !state.isInteractiveLogicRender;
        //state.isRender = true;
      }
    },
    [UpdateInteractive.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from UpdateInteractive", action.error.message);
    },
    [DeleteInteractiveSublist.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DeleteInteractiveSublist.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.isSuccess = true;
        state.isRemoveSubListSuccess = true;
        state.isInteractiveLogicRender = !state.isInteractiveLogicRender;
        // state.isRender = true;
      } else {
        state.isFailed = true;
        state.isRemoveSubListFailed = true;
      }
    },
    [DeleteInteractiveSublist.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from SaveSubList", action.error.message);
    },
    [GetUpdatedVisual.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetUpdatedVisual.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload != null) {
        state.data = state.data.map((u) => (u.id !== payload.id ? u : payload));

        state.masterfilterdata = state.masterfilterdata.map((u) =>
          u.id !== payload.id ? u : payload
        );

        state.isGViewerLogic = state.masterfilterdata.filter(
          (data) => data.id == payload.id
        );
      }
    },
    [GetUpdatedVisual.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetUpdatedVisual", action.error.message);
    },
    /* GInteractive: End  */
    /* GViewer: Start  */
    [GetAllViewerDetails.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GetAllViewerDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isRender = false;
      console.log(payload);
      if (payload != null && payload.length > 0) {
        state.isNoData = false;
        state.data = payload;
        state.distinctUploadYear = payload
          .map((item) => item.year)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((one, two) => (one > two ? -1 : 1));
        if (!state.distinctUploadYear.includes(state.selectedYear)) {
          let currentYear = new Date().getFullYear();
          let upcomingYear = currentYear + 1;
          let previousYear = currentYear - 1;
          if (state.distinctUploadYear.includes(JSON.stringify(upcomingYear))) {
            state.selectedYear = JSON.stringify(upcomingYear);
          } else if (
            state.distinctUploadYear.includes(JSON.stringify(currentYear))
          ) {
            state.selectedYear = JSON.stringify(currentYear);
          } else if (
            state.distinctUploadYear.includes(JSON.stringify(previousYear))
          ) {
            state.selectedYear = JSON.stringify(previousYear);
          } else {
            state.selectedYear =
              state.distinctUploadYear[state.distinctUploadYear.length - 1];
          }
          state.filterdata = payload.filter(
            (item) => item.year == state.selectedYear
          );
        } else if (
          state.distinctUploadYear.includes(state.selectedUploadYear)
        ) {
          state.selectedYear = state.selectedUploadYear;
          state.filterdata = payload.filter(
            (item) => item.year == state.selectedYear
          );
          state.selectedUploadYear = "";
          // let result = [];
          // let last = state.filterdata.length - 1;
          // result[0] = state.filterdata[last];
          // state.isGViewerLogic = Array.from(result);
          state.isGViewerDataAdded = true;
        } else {
          state.filterdata = payload.filter(
            (item) => item.year == state.selectedYear
          );
        }
        state.masterfilterdata = state.filterdata;
        state.isGrouper = state.isGrouperMaster.filter(
          (item) => item.year == state.selectedYear
        );
        if (state.isGViewer && state.isGViewerLogic.length <= 0) {
          // let result = [];
          // result[0] = state.masterfilterdata[0];
          // state.isGViewerLogic = Array.from(result);
        } else {
          state.isGViewerRender = true;
        }
      } else {
        state.isNoData = true;
      }
    },
    [GetAllViewerDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GetAllViewerDetails", action.error.message);
    },
    [GViewer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [GViewer.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload == "ok") {
        state.isSuccess = true;
        state.isGenerateSuccess = true;
        //state.isRender = true;
        state.isViewerLogicRender = !state.isViewerLogicRender;
      }
    },
    [GViewer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from GViewer", action.error.message);
    },
    [UpdateViewer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [UpdateViewer.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload == true) {
        state.isSuccess = true;
        state.isSaveLogicSuccess = true;
        //state.isRender = true;
        state.isViewerLogicRender = !state.isViewerLogicRender;
      }
    },
    [UpdateViewer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from UpdateViewer", action.error.message);
    },
    [DeleteVisualSublist.pending]: (state, action) => {
      state.isLoading = true;
    },
    [DeleteVisualSublist.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload) {
        state.isSuccess = true;
        state.isRemoveSubListSuccess = true;
        //state.isRender = true;
        state.isViewerLogicRender = !state.isViewerLogicRender;
      } else {
        state.isFailed = true;
        state.isRemoveSubListFailed = true;
      }
    },
    [DeleteVisualSublist.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isServerError = true;
      console.log("Error from SaveSubList", action.error.message);
    },
    /* GViewer: End  */
  },
});
export const {
  setIsRender,
  setIsClinicalCode,
  setIsCasemixFile,
  setIsSupportFile,
  setIsDRG,
  setIsDRGLogic,
  setDrgAddLogic,
  setDrgUsers,
  setIsDRGLogicUsers,
  setIsGGrouper,
  setIsGGrouperLogic,
  setIsGGrouperCheck,
  clearStatus,
  setIsTill,
  setIsDg1,
  setIsDrglogik,
  setIsDrgtpt,
  setIsCountingCode,
  setIsEgenskaber,
  setIsSks,
  setIsDrg,
  setIsCasemixDRG,
  setSelFileType,
  setSelFileDetails,
  setFilterData,
  setSelectedGrpCodeValue,
  setSelectedYear,
  setSelectedUploadYear,
  setMasterFilterData,
  setIsDrgRender,
  setIsGetAllDRGLogicsLoaded,
  setIsGGrouperRender,
  setIsGGrouperLogicTariff,
  setIsGInteractive,
  setIsGInteractiveLogic,
  setIsGInteractiveRender,
  setIsGInteractiveCheck,
  setIsGrouper,
  setIsInternekoder,
  setIsGViewer,
  setIsGViewerLogic,
  setIsGViewerRender,
  setIsGViewerCheck,
  clearMangerStatus,
  setEditLogicInfo,
  endUserSession,
  setExpanded,
  setIsDisabled,
  setIsDrgDataUpdated,
  setIsDrgDataRemoved,
  setDefaultIsGrouperLogic,
  setIsGGrouperDataUpdated,
  setIsGGrouperDataRemoved,
  setDefaultIsDrgLogic,
  setIsGInteractiveDataUpdated,
  setIsGInteractiveDataRemoved,
  setDefaultIsInteractiveLogic,
  setIsGViewerDataUpdated,
  setIsGViewerDataRemoved,
  setDefaultIsViewerLogic,
  setIsMDCSpecification,
  setIsGViewerLogicTariff,
  setGViewerTariffFlag,
  setIsGInteractiveLogicTariff,
  setGInteractiveTariffFlag,
  setGGrouperTariffFlag,
} = managerSlice.actions;
export default managerSlice.reducer;
