import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getRoles = createAsyncThunk(
  "registerUser/getRoles",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/Registration/GetRoles", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const registration = createAsyncThunk(
  "registerUser/registration",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const EmailValidation = createAsyncThunk(
  "registerUser/EmailValidation",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

const registerUserSlice = createSlice({
  name: "registerUser",
  initialState: {
    roles: [],
    status: null,
    regStatus: null,
    isRegSuccess: false,
    isRegFailed: false,
    isSessionValid: true,
  },
  reducers: {
    endUserSession(state,action){
      state.isSessionValid = false;
   },
    clearStatus(state, action) {
      state.isRegSuccess = false;
      state.isRegFailed = false;
    },
  },
  extraReducers: {
    [getRoles.pending]: (state, action) => {
      state.status = "loading";
    },
    [getRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload;
      state.status = "success";
    },
    [getRoles.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getRoles", action.error.message);
    },
    [registration.pending]: (state, action) => {
      state.regStatus = "loading";
    },
    [registration.fulfilled]: (state, { payload }) => {
      if (payload == true) {
        state.isRegSuccess = payload;
      }
      else{
        state.isRegFailed = true;
      }
      state.regStatus = "success";
    },
    [registration.rejected]: (state, action) => {
      state.regStatus = "failed";
      state.isRegFailed = true;
      state.isRegSuccess = false;
      console.log("Error from userRegistration", action.error.message);
    },
    // [EmailValidation.pending]: (state, action) => {
    //   state.regStatus = "loading";
    // },
    // [EmailValidation.fulfilled]: (state, { payload }) => {
    //   if (payload == true) {
    //     state.isRegSuccess = payload;
    //   }
    //   else{
    //     state.isRegFailed = true;
    //   }
    //   state.regStatus = "success";
    // },
    // [EmailValidation.rejected]: (state, action) => {
    //   console.log("Error from EmailValidation", action.error.message);
    // },
  },
});
export const { clearStatus, endUserSession } = registerUserSlice.actions;
export default registerUserSlice.reducer;
