import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCountries = createAsyncThunk(
  "contentUpload/getCountries",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("home/GetCountry", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getVisualContent = createAsyncThunk(
  "contentUpload/getVisualContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getInteractiveContent = createAsyncThunk(
  "contentUpload/getInteractiveContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGContent = createAsyncThunk(
  "contentUpload/getDRGContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getGrouperContent = createAsyncThunk(
  "contentUpload/getGrouperContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const SaveContent = createAsyncThunk(
  "contentUpload/SaveContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      console.log("Response: ", r);
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

const  initialState = {
  countries: [],
  years: [],
  year: (new Date().getFullYear() + 1).toString(),
  language: "1",
  destEnvironment: "Test",
  status: null,
  interactiveContent: "",
  visualContent: "",
  DRGContent: "",
  grouperContent: "",
  updatedInteractiveContent: "",
  updatedVisualContent: "",
  updatedDRGContent: "",
  updatedGrouperContent: "",
  uploadSuccess: false,
  uploadFailed: false,
  isSessionValid: true,
  isReRender: false,
};

const contentUploadSlice = createSlice({
  name: "contentUpload",
  initialState,
  reducers: {
    endUserSession(state,action){
      state.isSessionValid = false;
   },
    setYearsArray(state, action) {
      var currentYear = new Date().getFullYear();
      var max = currentYear + 5;
      var option = [];
      for (var y = currentYear - 4; y <= max; y++) {
        option.push({ text: y, value: y });
      }
      state.years = option.sort().reverse();
    },
    setYear(state, { payload }) {
      state.year = payload;
    },
    setLanguage(state, { payload }) {
      state.language = payload;
    },
    setDestEnvironment(state, { payload }) {
      state.destEnvironment = payload;
    },
    updateContent(state, { payload }) {
      if (payload.tab == "interactive") {
        state.updatedInteractiveContent = payload.content;
      } else if (payload.tab == "viewer") {
        state.updatedVisualContent = payload.content;
      }else if (payload.tab == "DRG") {
        state.updatedDRGContent = payload.content;
      }else if (payload.tab == "grouper") {
        state.updatedGrouperContent = payload.content;
      }
    },
    clearValues(state, action) {
      state.year = (new Date().getFullYear() + 1).toString();
      state.language = "1";
    },
    handleClose(state, action) {
      state.uploadSuccess = false;
      state.uploadFailed = false;
    },
    resetContentUpload:() => initialState
  },
  extraReducers: {
    [getCountries.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countries = payload;
      state.status = "success";
    },
    [getCountries.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getCountries", action.error.message);
    },
    [getInteractiveContent.pending]: (state, action) => {
      state.status = "loading";
      state.isReRender = false;
    },
    [getInteractiveContent.fulfilled]: (state, { payload }) => {
      console.log("payload-Interactive:", payload);
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(/(<([^>]+)>)(&nbsp;)*/ig, '');
        if(content.length == 1){
          state.interactiveContent = "";
          state.isReRender = true;
        }else{
          state.interactiveContent = payload[0].languageValue;
        }
      } else {
        state.interactiveContent = "";
        state.isReRender = true;
      }
      state.status = "success";
    },
    [getInteractiveContent.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getInteractiveContent", action.error.message);
    },
    [getVisualContent.pending]: (state, action) => {
      state.status = "loading";
      state.isReRender = false;
    },
    [getVisualContent.fulfilled]: (state, { payload }) => {
      console.log("payload-Viewer:", payload);
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(/(<([^>]+)>)(&nbsp;)*/ig, '');
        if(content.length == 1){
          state.visualContent = "";
          state.isReRender = true;
        }else{
          state.visualContent = payload[0].languageValue;
        }
      } else {
        state.visualContent = "";
        state.isReRender = true;
      }
      state.status = "success";
    },
    [getVisualContent.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getVisualContent", action.error.message);
    },

    [getDRGContent.pending]: (state, action) => {
      state.status = "loading";
      state.isReRender = false;
    },
    [getDRGContent.fulfilled]: (state, { payload }) => {
      console.log("payload-DRG:", payload);
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(/(<([^>]+)>)(&nbsp;)*/ig, '');
        if(content.length == 1){
          state.DRGContent = "";
          state.isReRender = true;
        }else{
          state.DRGContent = payload[0].languageValue;
        }
      } else {
        state.DRGContent = "";
        state.isReRender = true;
      }
      state.status = "success";
    },
    [getDRGContent.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getDRGContent", action.error.message);
    },

    [getGrouperContent.pending]: (state, action) => {
      state.status = "loading";
      state.isReRender = false;
    },
    [getGrouperContent.fulfilled]: (state, { payload }) => {
      console.log("payload-Grouper:", payload);
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(/(<([^>]+)>)(&nbsp;)*/ig, '');
        if(content.length == 1){
          state.grouperContent = "";
          state.isReRender = true;
        }else{
          state.grouperContent = payload[0].languageValue;
        }
      } else {
        state.grouperContent = "";
        state.isReRender = true;
      }
      state.status = "success";
    },
    [getGrouperContent.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getGrouperContent", action.error.message);
    },

    [SaveContent.pending]: (state, action) => {
      state.status = "loading";
    },
    [SaveContent.fulfilled]: (state, { payload }) => {
      console.log("Payload:", payload);
      if (payload) {
        state.uploadSuccess = true;
      } else {
        state.uploadFailed = true;
      }
    },
    [SaveContent.rejected]: (state, action) => {
      state.uploadFailed = true;
      console.log("Error from SaveContent", action.error.message);
    },
  },
});
export const {
  setYearsArray,
  setYear,
  setLanguage,
  updateContent,
  clearValues,
  handleClose,
  endUserSession,
  setDestEnvironment,
  resetContentUpload
} = contentUploadSlice.actions;
export default contentUploadSlice.reducer;
