import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getUserDetails = createAsyncThunk(
  "authentication/getUserDetails",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("Auth/Secure", reqOptions).then((r) => {
      console.log("Response:", r);
      if (r.status === 401) {
        dispatch(redirectToLogin({ isAuthorised: false }));
      } else {
        return r.json();
      }
    });
  }
);

export const resetSession = createAsyncThunk(
  "authentication/resetSession",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("Auth/ResetSession", reqOptions).then((r) => {   
    
      if (r.status === 401) {
        dispatch(redirectToLogin({ isAuthorised: false }));
      } else {
        return r.json();
      }
      
    });
  }
);

export const onLogout = createAsyncThunk(
  "authentication/onLogout",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch("authentication/Logout", reqOptions).then((r) => {
      if (r.status === 200) {
        dispatch(
          logoutSuccess({
            user: {},
            isAuthorised: false,
            shouldRedirect: true,
          })
        );
      } else {
        return r.json();
      }
    });
  }
);

const initialState = {
  user: {},
  isAuthorised: false,
  shouldRedirect: true,
  response: [],
  isLoading: false,
  status: "",
  logoutStatus: null,
  isSuperAdminRole: false,
  isAdminRole: false,
  isEditorRole: false,
  isViewerRole: false,
  isAdminPrivileges: false,
  isFailed: false,
  isInactive: false,
  isUnauthorized: false,
};
export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    tokenReceived(state, action) {
      const { user, isAuthorised, shouldRedirect } = action.payload;
      localStorage.setItem("token", user.token);
      state.user = user;
      state.isAuthorised = isAuthorised;
      state.shouldRedirect = shouldRedirect;
    },
    logoutSuccess(state, action) {
      localStorage.removeItem("token");
      state.user = {};
      state.isAuthorised = false;
      state.shouldRedirect = true;
    },
    redirectToLogin(state, action) {
      const { isAuthorised } = action.payload;
      state.isAuthorised = isAuthorised;
      if(localStorage.getItem("isUnauthorized"))
      {
        state.isFailed = true;
        state.isUnauthorized = true;
        localStorage.removeItem("isUnauthorized");
      }
    },
    clearStatus(state, action){
      state.isFailed = false;
      state.isInactive = false;
      state.isUnauthorized = false;
    }
  },
  extraReducers: {
    [getUserDetails.pending]: (state, action) => {
      // state.status = "loading";
      state.isLoading = true;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      if (payload) {
        localStorage.removeItem("isUnauthorized");
        console.log(payload);
        state.user = payload;
        if (payload[0] ?.roleId == 1 || payload[0] ?.roleId == 4) {
          state.isAdminPrivileges = true;
        }
        if (payload[0] ?.roleId == 1 || payload[0] ?.roleName == "admin") {
          state.isAdminRole = true;
        }
        else if (payload[0] ?.roleId == 2 || payload[0] ?.roleName == "editor") {
          state.isEditorRole = true;
        }
        else if (payload[0] ?.roleId == 3 || payload[0] ?.roleName == "viewer") {
          state.isViewerRole = true;
        }
        else if (payload[0] ?.roleId == 4) {
          state.isSuperAdminRole = true;
        }
        state.isAuthorised = true;
        if (payload[0] ?.userId == "" || payload[0] ?.userId == null ) {
          state.isFailed = true;
          state.isInactive = true;
        }
      }
      state.status = "success";
      state.isLoading = false;
    },
    [getUserDetails.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getUserDetails", action.error.message);
    },
    [onLogout.pending]: (state, action) => {
      state.logoutStatus = "loading";
    },
    [onLogout.fulfilled]: (state, { payload }) => {
      state.logoutStatus = "success";
    },
    [onLogout.rejected]: (state, action) => {
      state.logoutStatus = "failed";
      console.log("Error from onLogout", action.error.message);
    },
  },
});

export const { tokenReceived, logoutSuccess, redirectToLogin, clearStatus } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
