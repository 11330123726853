import React, { useState, useEffect } from "react";
import "./Manager.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SharedComponent from "./SharedComponent";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FolderIcon from "@mui/icons-material/Folder";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import Link from "@mui/material/Link";
import Dropzone from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import {
  setMasterFilterData,
  setFilterData,
  setSelectedYear,
  GetAllViewerDetails,
  setIsGViewerLogic,
  GetAllGrouperDetails,
  setSelectedUploadYear,
  saveLogic,
  remove,
  setSelFileType,
  setSelFileDetails,
  UpdateViewer,
  DeleteVisualSublist,
  setIsGViewerRender,
  setIsGrouper,
  setIsInternekoder,
  setIsGViewerCheck,
  GViewer,
  upload,
  setExpanded,
  setIsDisabled,
  setDefaultIsViewerLogic,
  setIsGViewerDataUpdated,
  setIsGViewerDataRemoved,
  GetUpdatedVisual,
  setIsGViewerLogicTariff,
  setGViewerTariffFlag,
} from "./managerSlice";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    padding: "0px",
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
      padding: "0px",
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      margin: "0px",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      //paddingLeft: theme.spacing(2),
      padding: "0px",
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function GenerateViewer() {
  const { t } = useTranslation("language");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { user, isAdminPrivileges } = useSelector(
    (state) => state.authentication
  );
  const manager = useSelector((state) => state.manager);
  const {
    isInitialLoadAPI,
    isRender,
    data,
    isNoData,
    selectedYear,
    distinctUploadYear,
    selFileType,
    selFileDetails,
    isGViewerRender,
    isGViewerLogic,
    isGrouperMaster,
    isGrouper,
    isGViewer,
    isExpanded,
    isDisabled,
    isGViewerDataAdded,
    isGViewerDataRemoved,
    isViewerLogicRender,
    viewerTariffFlag,
  } = manager;
  const [addLogic, setAddLogic] = useState(false);
  const [removeLogic, setRemoveLogic] = useState(false);
  const [addSubset, setAddSubset] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [ropen, setRopen] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [deleteFile, setDeleteFile] = useState();

  useEffect(() => {
    if (isGViewer) {
      let url = "api/ViewerUsedGrouper";
      dispatch(GetAllGrouperDetails({ method: "GET", url: url }));
    }
  }, []);

  useEffect(() => {
    if (isInitialLoadAPI && isRender) {
      let url = "api/GetVisualDetails";
      dispatch(GetAllViewerDetails({ method: "GET", url: url }));
    } else {
      dispatch(setIsGViewerLogic(isGViewerLogic[0]?.id));
    }
  }, [isInitialLoadAPI, isRender, isGViewerRender]);

  useEffect(() => {
    if (isGViewerLogic?.length > 0) {
      let url = "api/GetVisualDetailsById?visualId=" + isGViewerLogic[0]?.id;
      dispatch(GetUpdatedVisual({ method: "GET", url: url }));
    }
  }, [isViewerLogicRender]);

  useEffect(() => {
    if (isGViewerDataAdded) {
      dispatch(setDefaultIsViewerLogic());
      dispatch(setIsGViewerDataUpdated(false));
    }
  }, [isGViewerDataAdded]);

  useEffect(() => {
    if (isGViewerDataRemoved) {
      dispatch(setIsGViewerLogic(0));
      dispatch(setIsGViewerDataRemoved(false));
    }
  }, [isGViewerDataRemoved]);

  const handleYearSelect = (e) => {
    const value = e.target.value;
    dispatch(setSelectedYear(value));
    if (value) {
      const result = data.filter((item) => {
        return item.year == value;
      });
      dispatch(setMasterFilterData(result));
      dispatch(setFilterData(result));
      dispatch(setIsGViewerLogic(0));
    }
    if (value) {
      const result = isGrouperMaster.filter((item) => {
        return item.year == value;
      });
      dispatch(setIsGrouper(result));
    }
  };

  const handleClose = () => {
    // reset();
    setSelectedFiles("");
    setAddLogic(false);
    setRemoveLogic(false);
    setAddSubset(false);
    dispatch(setExpanded([]));
    dispatch(setIsDisabled(true));
    setRopen(false);
  };

  const onSubmit = (values) => {
    if (
      values.name !== null &&
      values.name !== "" &&
      values.name !== undefined
    ) {
      addGViewerLogic(values);
      // reset();
      setAddLogic(false);
    }
  };

  async function addGViewerLogic(values) {
    let logicName = values.name;
    let logicVersion = values.version;
    let logicYear = values.year;
    dispatch(setSelectedUploadYear(logicYear));
    let url =
      "api/AddVisual?visualName=" +
      logicName +
      "&visualVersion=" +
      logicVersion +
      "&Year=" +
      logicYear;
    dispatch(saveLogic({ method: "POST", url: url, body: null }));
    dispatch(setIsGViewerRender());
  }

  const onOk = (values) => (e) => {
    setRemoveLogic(false);
    removeSelectedLogic(values);
  };

  async function removeSelectedLogic(values) {
    let url = "api/DeleteVisual?id=" + values.id;
    dispatch(remove({ method: "POST", url: url, body: null }));
  }

  const GenerateViewer = () => {
    if (
      isGViewerLogic[0]?.grouperName == null ||
      isGViewerLogic[0]?.grouperName == "" ||
      isGViewerLogic[0]?.grouperVersion == null ||
      isGViewerLogic[0]?.grouperVersion == ""
    ) {
      dispatch(setIsGViewerCheck());
    } else {
      let url =
        "api/ExecuteVisual?id=" +
        isGViewerLogic[0]?.id +
        "&grouperName=" +
        isGViewerLogic[0]?.grouperName +
        "&grouperVersion=" +
        isGViewerLogic[0]?.grouperVersion +
        "&Year=" +
        isGViewerLogic[0]?.year;
      dispatch(GViewer({ method: "GET", url: url }));
      dispatch(setIsGViewerRender());
    }
  };

  const onSelect = (nodeID, fileType) => () => {
    dispatch(setIsDisabled(true));
    setSelectedFiles("");
    if (isExpanded[0] == nodeID) {
      dispatch(setExpanded([]));
    } else {
      dispatch(setExpanded([nodeID]));
      if (
        fileType == "VisualGuide" ||
        fileType == "VisualNews" ||
        fileType == "InterneKoderPDF"
      ) {
        dispatch(setSelFileType(fileType));
      }
    }
  };

  const onSubsetSelect = (details, fileType) => () => {
    dispatch(setSelFileDetails(details));
    dispatch(setSelFileType(fileType));
    dispatch(setIsDisabled(false));
  };

  const onAddSubset = () => {
    console.log("onAddSubset-isGViewerLogic:", isGViewerLogic);
    let visualGrpName = "";
    let visualGrpVersion = "";
    let visualGrpYear = "";
    if (selFileType == "Grouper") {
      visualGrpName = selFileDetails.grouperName;
      visualGrpVersion = selFileDetails.grouperVersion;
      visualGrpYear = selFileDetails.year;
    }
    if (
      visualGrpName == undefined ||
      visualGrpVersion == undefined ||
      visualGrpYear == undefined ||
      visualGrpName == "" ||
      visualGrpVersion == "" ||
      visualGrpYear == ""
    ) {
      visualGrpName = isGViewerLogic[0]?.grouperName;
      visualGrpVersion = isGViewerLogic[0]?.grouperVersion;
      visualGrpYear = isGViewerLogic[0]?.year;
    }
    var visualData = {
      VisualName: isGViewerLogic[0]?.visualName,
      VisualVersion: isGViewerLogic[0]?.visualVersion,
      GrouperName: visualGrpName,
      GrouperVersion: visualGrpVersion,
      GrouperYear: visualGrpYear,
      Id: isGViewerLogic[0]?.id,
      ShowTariff: isGViewerLogic[0]?.showTariff,
    };
    if (
      selFileType !== "VisualGuide" &&
      selFileType !== "VisualNews" &&
      selFileType !== "InterneKoderPDF"
    ) {
      dispatch(setGViewerTariffFlag());
      console.log("visualData:", visualData);
      let url = "api/UpdateVisual";
      dispatch(UpdateViewer({ method: "POST", url: url, body: visualData }));
      setAddSubset(false);
      dispatch(setIsDisabled(true));
      dispatch(setExpanded([]));
      dispatch(setSelFileDetails([]));
      dispatch(setIsGViewerRender());
    } else {
      let currentFile = selectedFiles[0];
      setCurrentFile(currentFile);
      setSelectedFiles("");
      let viewerID = isGViewerLogic[0]?.id;
      let selectedType = selFileType;
      let url =
        "api/UploadVisualFiles?visualID=" +
        viewerID +
        "&selectedType=" +
        selectedType;
      let formData = new FormData();
      formData.append("file", currentFile, currentFile.name);
      dispatch(upload({ method: "POST", url: url, body: formData }));
      setAddSubset(false);
      dispatch(setIsDisabled(true));
      dispatch(setExpanded([]));
      dispatch(setIsGViewerRender());
    }
  };

  const onDelete = (value, name) => (e) => {
    e.preventDefault();
    setDeleteItem(value);
    if (name != "" && name != undefined && name != null) {
      setDeleteFile(name);
    }
    setRopen(true);
  };

  const onDeleteOk = () => (e) => {
    setRopen(false);
    var body = {
      visualName: isGViewerLogic[0]?.visualName,
      VisualVersion: isGViewerLogic[0]?.visualVersion,
      Year: isGViewerLogic[0]?.year,
    };
    let url = "api/DeleteVisualSublist";
    dispatch(DeleteVisualSublist({ method: "POST", url: url, body: body }));
  };

  const onDeleteFile = (value, fileName) => (e) => {
    setRopen(false);
    let url =
      "api/DeleteVisualFiles?visualName=" +
      isGViewerLogic[0]?.visualName +
      "&visualVersion=" +
      isGViewerLogic[0]?.visualVersion +
      "&year=" +
      isGViewerLogic[0]?.year +
      "&selectedType=" +
      value +
      "&fileName=" +
      fileName;
    dispatch(DeleteVisualSublist({ method: "POST", url: url }));
    setDeleteFile();
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
      dispatch(setIsDisabled(false));
    }
  };
  const handleTariff = (attribute) => (e) => {
    let checkedValue = e.target.checked;
    console.log(attribute);
    let result = isGViewerLogic.map((row) => {
      return { ...row, [attribute]: checkedValue };
    });
    console.log(result);
    dispatch(setIsGViewerLogicTariff(result));
    console.log("handleTariff:", isGViewerLogic);
  };
  if (viewerTariffFlag) {
    onAddSubset();
  }
  return (
    <div className="GenerateViewer">
      {isNoData == true && (
        <Paper className="paperContainer">
          <div className="right-section-container">
            <div className="right-section-header left">
              <Typography variant="h5">
                {t("Manager.Generate_Visual")}
              </Typography>
            </div>
            <div className="right-section-header right">
              <div className="right-section-header-container">
                <Button
                  variant="contained"
                  size="small"
                  className="manager-button"
                  onClick={() => setAddLogic(true)}
                >
                  {t("GViewer.Add_Viewer")}
                </Button>
              </div>
            </div>
          </div>
          <Divider />
          <div className="right-section-container-one">
            <div className="container-block two">
              <Typography
                align="center"
                color="#00000099"
                sx={{ marginLeft: "2px" }}
              >
                {t("GViewer.Viwer_Validation")}
              </Typography>
            </div>
          </div>
        </Paper>
      )}

      <Paper className="paperContainer">
        <div className="right-section-container">
          <div className="right-section-header left">
            <Typography variant="h5">{t("Manager.Generate_Visual")}</Typography>
          </div>
          <div className="right-section-header right">
            <div className="right-section-header-container">
              <TextField
                label={t("Manager.Year")}
                variant="outlined"
                size="small"
                select
                onChange={handleYearSelect}
                sx={{ marginRight: 1 }}
                value={selectedYear}
              >
                {distinctUploadYear.map((year) => {
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button
                variant="contained"
                size="small"
                className="manager-button"
                onClick={() => {
                  setAddLogic(true), reset({ year: selectedYear });
                }}
              >
                {t("GViewer.Add_Viewer")}
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        {isGViewerLogic.length > 0 && (
          <div className="right-section-container">
            <div className="right-section-header left">
              <Typography variant="h6">
                {isGViewerLogic[0]?.visualName}-
                {isGViewerLogic[0]?.visualVersion}
              </Typography>
            </div>
            <div className="right-section-header right">
              <div className="right-section-header-container">
                {isAdminPrivileges && isGViewerLogic[0]?.status == 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    className="manager-button"
                    onClick={() => setRemoveLogic(true)}
                  >
                    {t("Manager.Remove")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  className="manager-button"
                  onClick={GenerateViewer}
                >
                  {t("Manager.Generate_Visual")}
                </Button>
              </div>
            </div>
          </div>
        )}
        {isGViewerLogic.length > 0 && (
          <div className="right-section-container-one">
            <div className="container-block one">
              <Typography
                className="typography-title"
                color="#00000099"
                gutterBottom
              >
                {t("Manager.publisher_status")}:
                <Typography
                  variant="string"
                  color="#000000DE"
                  sx={{ marginLeft: "2px" }}
                >
                  {isGViewerLogic[0]?.status == 1 && t("Global.production")}
                  {isGViewerLogic[0]?.status == 2 && t("Global.hearing")}
                  {isGViewerLogic[0]?.status == 3 && t("Global.test")}
                  {isGViewerLogic[0]?.status == 0 && t("Global.notpublished")}
                </Typography>
              </Typography>
              <Typography color="#00000099">
                Status:
                <Typography
                  variant="string"
                  color="#000000DE"
                  sx={{ marginLeft: "2px" }}
                >
                  {isGViewerLogic[0]?.isGenerated == true
                    ? t("Manager_Common.generated")
                    : t("Manager_Common.not_generated")}
                </Typography>
                <Checkbox
                  checked={isGViewerLogic[0]?.showTariff == true ? true : false}
                  disabled={isGViewerLogic[0]?.status == 0 ? false : true}
                  onChange={handleTariff("showTariff")}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="string" color="#000000DE">
                  {t("Manager.show_Tarrifs")}
                </Typography>
              </Typography>
            </div>
            <Divider />
            <div className="container-block two">
              <div className="section">
                <Typography color="#00000099">{t("Manager.Subset")}</Typography>
                {isAdminPrivileges && isGViewerLogic[0]?.status == 0 && (
                  <Link
                    component="button"
                    color="#00A3E0"
                    onClick={() => setAddSubset(true)}
                  >
                    {t("Manager.Add_Subset")}
                  </Link>
                )}
              </div>
              {isGViewerLogic[0]?.grouperName != null &&
                isGViewerLogic[0]?.grouperName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager_Common.grouper_file")} :
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGViewerLogic[0]?.grouperName}{" "}
                        {isGViewerLogic[0]?.grouperVersion}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGViewerLogic[0]?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete()}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              {isGViewerLogic[0]?.visualGuide?.fileName != null &&
                isGViewerLogic[0]?.visualGuide?.fileName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager.Visual_Guidance_File")} :
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGViewerLogic[0]?.visualGuide?.fileName}{" "}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGViewerLogic[0]?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete(
                            "VisualGuide",
                            isGViewerLogic[0]?.visualGuide?.fileName
                          )}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              {isGViewerLogic[0]?.visualNews?.fileName != null &&
                isGViewerLogic[0]?.visualNews?.fileName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager.Visual_News_File")}:
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGViewerLogic[0]?.visualNews?.fileName}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGViewerLogic[0]?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete(
                            "VisualNews",
                            isGViewerLogic[0]?.visualNews?.fileName
                          )}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              {isGViewerLogic[0]?.interneKoderPdfFile?.fileName != null &&
                isGViewerLogic[0]?.interneKoderPdfFile?.fileName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager.Internal_Code_File")}:
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGViewerLogic[0]?.interneKoderPdfFile?.fileName}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGViewerLogic[0]?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete(
                            "InterneKoderPdfFile",
                            isGViewerLogic[0]?.interneKoderPdfFile?.fileName
                          )}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        )}
      </Paper>

      <Dialog open={addLogic} onClose={handleClose}>
        <DialogTitle>{t("GViewer.Add_Viewer")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              paddingTop: "5px !important",
              paddingBottom: "12px !important",
            }}
          >
            <div className="form-section">
              <TextField
                id="name"
                label={t("Manager_Common.Name")}
                fullWidth
                variant="outlined"
                className="form-section"
                {...register("name", {
                  required: "Name is required.",
                  // pattern: {
                  //   value: /^[a-zA-Z0-9_.-]*$/,
                  //   message: t("Manager_Common.Validataion"),
                  // },
                })}
                error={Boolean(errors.name)}
                helperText={errors?.name?.message}
                inputProps={{ maxLength: 20 }}
              />
            </div>
            <div className="form-section-double">
              <div className="form-sub-section">
                <TextField
                  label="Version"
                  variant="outlined"
                  className="form-sub-section"
                  {...register("version", {
                    required: t("Manager.Version_Required"),
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/,
                      message: t("Manager.Invalid_Version"),
                    },
                  })}
                  error={Boolean(errors.version)}
                  helperText={errors?.version?.message}
                  inputProps={{ maxLength: 5 }}
                />
              </div>
              <div className="form-sub-section">
                <TextField
                  label={t("Manager.yearLabel")}
                  variant="outlined"
                  className="form-sub-section"
                  {...register("year", {
                    required: t("Manager.Year_Required"),
                    pattern: {
                      value: /^[0-9]{4,4}$/,
                      message: t("Manager.entryDigits"),
                    },
                  })}
                  error={Boolean(errors.year)}
                  helperText={errors?.year?.message}
                  type="Number"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" onClick={onSubmit} type="submit">
              {t("Manager.Save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={removeLogic} onClose={handleClose} maxWidth="xs">
        <DialogTitle>{t("GViewer.Remove_Viewer")}</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>{t("GViewer.remove_validation")}</Typography>
          <Typography gutterBottom>
            Viewer {t("Manager_Common.Name")}: {isGViewerLogic[0]?.visualName}-
            {isGViewerLogic[0]?.visualVersion}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          <Button
            variant="contained"
            onClick={onOk(isGViewerLogic[0])}
            type="submit"
          >
            {t("Manager.Remove")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addSubset} onClose={handleClose}>
        <DialogTitle>{t("Manager.Add_Subset")}</DialogTitle>
        <DialogContent>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            sx={{ minWidth: 300 }}
            expanded={isExpanded}
          >
            <StyledTreeItem
              nodeId="Grouper"
              labelText="Grouper"
              labelIcon={FolderIcon}
              labelInfo={isGrouper.length}
              onClick={onSelect("Grouper")}
            >
              {isGrouper.map((item) => {
                return (
                  <StyledTreeItem
                    nodeId={item.grouperName + "-" + item.grouperVersion}
                    labelText={item.grouperName + "-" + item.grouperVersion}
                    onClick={onSubsetSelect(item, "Grouper")}
                  />
                );
              })}
            </StyledTreeItem>
            <StyledTreeItem
              nodeId="Visual_Guidance"
              labelText={t("Manager.visual_guidance")}
              labelIcon={FolderIcon}
              //onClick={onSubsetSelect("", "VisualGuide")}
              onClick={onSelect("Visual_Guidance", "VisualGuide")}
            >
              <Typography variant="caption" gutterBottom color="#F0B323">
                {t("Manager.Upload")} {t("Manager.Visual_Guidance_File")}
              </Typography>
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps({})}>
                      <input {...getInputProps()} />
                      {selectedFiles && selectedFiles[0].name ? (
                        <div className="selected-file">
                          {selectedFiles && selectedFiles[0].name}
                        </div>
                      ) : (
                        <div>
                          <UploadFileIcon fontSize="medium" />
                          <div>{t("Manager_Common.Drag")}</div>
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </StyledTreeItem>
            <StyledTreeItem
              nodeId="Visual_News"
              labelText={t("Manager.visual_news")}
              labelIcon={FolderIcon}
              //onClick={onSubsetSelect("", "VisualNews")}
              onClick={onSelect("Visual_News", "VisualNews")}
            >
              <Typography variant="caption" gutterBottom color="#F0B323">
                {t("Manager.Upload")} {t("Manager.Visual_News_File")}
              </Typography>
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps({})}>
                      <input {...getInputProps()} />
                      {selectedFiles && selectedFiles[0].name ? (
                        <div className="selected-file">
                          {selectedFiles && selectedFiles[0].name}
                        </div>
                      ) : (
                        <div>
                          <UploadFileIcon fontSize="medium" />
                          <div>{t("Manager_Common.Drag")}</div>
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </StyledTreeItem>
            <StyledTreeItem
              nodeId="InterneKoder"
              labelText="InterneKoder"
              labelIcon={FolderIcon}
              //onClick={onSubsetSelect("", "InterneKoderPDF")}
              onClick={onSelect("InterneKoder", "InterneKoderPDF")}
            >
              <Typography variant="caption" gutterBottom color="#F0B323">
                {t("Manager.Upload")} {t("Manager.Internal_Code_File")}
              </Typography>
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps({})}>
                      <input {...getInputProps()} />
                      {selectedFiles && selectedFiles[0].name ? (
                        <div className="selected-file">
                          {selectedFiles && selectedFiles[0].name}
                        </div>
                      ) : (
                        <div>
                          <UploadFileIcon fontSize="medium" />
                          <div>{t("Manager_Common.Drag")}</div>
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </StyledTreeItem>
          </TreeView>
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          <Button
            variant="contained"
            onClick={onAddSubset}
            disabled={isDisabled}
          >
            {t("Manager.Add")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={ropen} onClose={handleClose}>
        <DialogContent>{t("Manager_Common.deleteItem")}</DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          {deleteFile != "" && deleteFile != undefined && deleteFile != null ? (
            <Button
              variant="contained"
              onClick={onDeleteFile(deleteItem, deleteFile)}
              type="submit"
            >
              {t("Manager.Ok")}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={onDeleteOk(deleteItem)}
              type="submit"
            >
              {t("Manager.Ok")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {isGViewerLogic.length == 0 && (
        <div className="right-section-container-one">
          <Typography
            align="center"
            color="#00000099"
            sx={{ marginLeft: "2px" }}
          >
            {t("Manager.No_Viewer_Selected")}
          </Typography>
        </div>
      )}
      <SharedComponent />
    </div>
  );
}
