import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const UpdateProfile = createAsyncThunk(
    "registerUser/UpdateProfile",
    async (obj, { dispatch, getState }) => {
        let reqOptions = {};
        const token = localStorage.getItem("token");
        let headers = new Headers({
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: 0,
        });
        headers.set("Access-Control-Allow-Origin", "*");
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        if (token) {
            headers.append("Authorization", `Bearer ${token}`);
        }
        reqOptions.headers = headers;
        reqOptions.body = JSON.stringify(obj.body);
        reqOptions.method = obj.method;
        return await fetch(obj.url, reqOptions).then((r) => {
            if (r.status === 401) {
                dispatch(endUserSession());
              } else {
                return r.json();
              }
        });
    }
);

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        isLoading: false,
        isSuccess: false,
        isFailed: false,
        isError: false,
        isServerError: false,
        isSecureRerender: false,
        isSessionValid: true,
    },
    reducers: {
        endUserSession(state,action){
            state.isSessionValid = false;
         },
        clearStatus(state, action) {
            state.isSuccess = false;
            state.isFailed = false;
            state.isError = false;
            state.isServerError = false;
        },
    },
    extraReducers: {
        [UpdateProfile.pending]: (state, action) => {
            state.isLoading = true;
        },
        [UpdateProfile.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            if(payload != null && payload == true){
                state.isSuccess = true;
                state.isSecureRerender = false;
            }
            else{
                state.isFailed = true;
            }
        },
        [UpdateProfile.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isServerError = true;
            console.log("Error from UpdateProfile", action.error.message);
        },
    },
});

export const { clearStatus, endUserSession } = profileSlice.actions;
export default profileSlice.reducer;