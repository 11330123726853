import React, { useState, useEffect } from "react";
import "./Manager.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SharedComponent from "./SharedComponent";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FolderIcon from "@mui/icons-material/Folder";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import Link from "@mui/material/Link";
import Dropzone from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import {
  setMasterFilterData,
  setFilterData,
  setSelectedYear,
  GetAllInteractiveDetails,
  setIsGInteractiveLogic,
  GetAllGrouperDetails,
  setSelectedUploadYear,
  saveLogic,
  remove,
  setSelFileType,
  setSelFileDetails,
  UpdateInteractive,
  DeleteInteractiveSublist,
  setIsGInteractiveRender,
  setIsGrouper,
  setIsInternekoder,
  setIsGInteractiveCheck,
  GInteractive,
  upload,
  setExpanded,
  setIsDisabled,
  setDefaultIsInteractiveLogic,
  setIsGInteractiveDataUpdated,
  setIsGInteractiveDataRemoved,
  GetUpdatedInteractive,
  setIsGInteractiveLogicTariff,
  setGInteractiveTariffFlag,
} from "./managerSlice";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    padding: "0px",
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
      padding: "0px",
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      margin: "0px",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      //paddingLeft: theme.spacing(2),
      padding: "0px",
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function GenerateInteractive() {
  const { t } = useTranslation("language");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { user, isAdminPrivileges } = useSelector(
    (state) => state.authentication
  );
  const manager = useSelector((state) => state.manager);
  const {
    isInitialLoadAPI,
    isRender,
    data,
    isNoData,
    selectedYear,
    distinctUploadYear,
    selFileType,
    selFileDetails,
    isGInteractiveRender,
    isGInteractiveLogic,
    isGrouperMaster,
    isGrouper,
    isInternekoderMaster,
    isInternekoder,
    isExpanded,
    isDisabled,
    isGInteractiveDataAdded,
    isGInteractiveDataRemoved,
    isInteractiveLogicRender,
    interactiveTariffFlag
  } = manager;
  const [addLogic, setAddLogic] = useState(false);
  const [removeLogic, setRemoveLogic] = useState(false);
  const [addSubset, setAddSubset] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [currentFile, setCurrentFile] = useState(undefined);
  const [ropen, setRopen] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [deleteFile, setDeleteFile] = useState();

  useEffect(() => {
    let url = "api/GetGrouperUsedDetails";
    dispatch(GetAllGrouperDetails({ method: "GET", url: url }));
  }, []);

  useEffect(() => {
    if (isInitialLoadAPI && isRender) {
      let url = "api/GetInteractiveDetails";
      dispatch(GetAllInteractiveDetails({ method: "GET", url: url }));
    } else {
      dispatch(setIsGInteractiveLogic(isGInteractiveLogic[0] ?.id));
    }
  }, [isInitialLoadAPI, isRender, isGInteractiveRender]);

  useEffect(() => {
    if (isGInteractiveLogic?.length>0) {
      let url = "api/GetInteractiveDetailsById?interactiveId="+ isGInteractiveLogic[0] ?.id;
      dispatch(GetUpdatedInteractive({ method: "GET", url: url }));
    } 
  }, [isInteractiveLogicRender]);

  useEffect(() => {
    if ( isGInteractiveDataAdded) {
 
        dispatch(setDefaultIsInteractiveLogic());
        dispatch(setIsGInteractiveDataUpdated(false));       
                
    }
  }, [isGInteractiveDataAdded]);

  useEffect(() => {
    if ( isGInteractiveDataRemoved) {
 
        dispatch(setIsGInteractiveLogic(0));
        dispatch(setIsGInteractiveDataRemoved(false));       
                
    }
  }, [isGInteractiveDataRemoved]);

  // useEffect(() => {
  //     setCount(0);
  //     let value = 0;
  //     if (isGInteractiveLogic[0]?.grouperName != null && isGInteractiveLogic[0]?.grouperName != "" ) {
  //         //setCount(count+1);
  //         value++;
  //     }
  //     if (isGInteractiveLogic[0]?.interneKoderName != null && isGInteractiveLogic[0]?.interneKoderName != "" ) {
  //         //setCount(count+1);
  //         value++;
  //     }
  //     if (isGInteractiveLogic[0]?.interactiveGuide ?.fileName != null && isGInteractiveLogic[0].interactiveGuide ?.fileName != "" ) {
  //         //setCount(count+1);
  //         value++;
  //     }
  //     if (isGInteractiveLogic[0]?.interactiveNews ?.fileName != null && isGInteractiveLogic[0].interactiveNews ?.fileName != "" ) {
  //         //setCount(count+1);
  //         value++;
  //     }
  //     setCount(value);
  // }, [isGInteractiveLogic]);

  const handleYearSelect = (e) => {
    const value = e.target.value;
    dispatch(setSelectedYear(value));
    if (value) {
      const result = data.filter((item) => {
        return item.year == value;
      });
      dispatch(setMasterFilterData(result));
      dispatch(setFilterData(result));
      dispatch(setIsGInteractiveLogic(0));
    }
    if (value) {
      const result = isGrouperMaster.filter((item) => {
        return item.year == value;
      });
      dispatch(setIsGrouper(result));
    }
    if (value) {
      const result = isInternekoderMaster.filter((item) => {
        return item.Year == value;
      });
      dispatch(setIsInternekoder(result));
    }
  };

  const handleClose = () => {
    // reset();
    setSelectedFiles("");
    setAddLogic(false);
    setRemoveLogic(false);
    setAddSubset(false);
    dispatch(setExpanded([]));
    dispatch(setIsDisabled(true));
    setRopen(false);
  };

  const onSubmit = (values) => {
    if (
      values.name !== null &&
      values.name !== "" &&
      values.name !== undefined
    ) {
      addGInteractiveLogic(values);
      // reset();
      setAddLogic(false);
    }
  };

  async function addGInteractiveLogic(values) {
    let logicName = values.name;
    let logicVersion = values.version;
    let logicYear = values.year;
    dispatch(setSelectedUploadYear(logicYear));
    let url =
      "api/AddInteractive?interactiveName=" +
      logicName +
      "&interactiveVersion=" +
      logicVersion +
      "&Year=" +
      logicYear;
    dispatch(saveLogic({ method: "POST", url: url, body: null }));
    dispatch(setIsGInteractiveRender());
  }

  const onOk = (values) => (e) => {
    setRemoveLogic(false);
    removeSelectedLogic(values);
  };

  async function removeSelectedLogic(values) {
    let url =
      "/api/DeleteInteractive?grouperName=" +
      values.grouperName +
      "&grouperVersion=" +
      values.grouperVersion +
      "&interactiveName=" +
      values.interactiveName +
      "&interactiveVersion=" +
      values.interactiveVersion +
      "&interactiveYear=" +
      values.year +
      "&interneKoderName=" +
      values.interneKoderName +
      "&interneKoderVersion=" +
      values.interneKoderVersion;
    dispatch(remove({ method: "POST", url: url, body: null }));
  }

  const GenerateInteractive = () => {
    if (
      isGInteractiveLogic[0] ?.grouperName == null ||
        isGInteractiveLogic[0] ?.grouperName == "" ||
          isGInteractiveLogic[0] ?.grouperVersion == null ||
            isGInteractiveLogic[0] ?.grouperVersion == ""
    ) {
      dispatch(setIsGInteractiveCheck({ method: "CheckGrouper" }));
    } else if (
      isGInteractiveLogic[0] ?.interneKoderName == null ||
        isGInteractiveLogic[0] ?.interneKoderName == "" ||
          isGInteractiveLogic[0] ?.interneKoderVersion == null ||
            isGInteractiveLogic[0] ?.interneKoderVersion == ""
    ) {
      dispatch(setIsGInteractiveCheck({ method: "CheckInterkoder" }));
    } else {
      let url =
        "/api/ExecuteInteractive?id=" +
          isGInteractiveLogic[0] ?.id +
            "&grouperName=" +
            isGInteractiveLogic[0] ?.grouperName +
              "&grouperVersion=" +
              isGInteractiveLogic[0] ?.grouperVersion +
                "&Year=" +
                isGInteractiveLogic[0] ?.year +
                  "&interactiveName=" +
                  isGInteractiveLogic[0] ?.interactiveName +
                    "&interactiveVer=" +
                    isGInteractiveLogic[0] ?.interactiveVersion;
      dispatch(GInteractive({ method: "GET", url: url }));
      dispatch(setIsGInteractiveRender());
    }
  };

  const onSelect = (nodeID, fileType) => () => {
    dispatch(setIsDisabled(true));
    setSelectedFiles("");
    if (isExpanded[0] == nodeID) {
      dispatch(setExpanded([]));
    } else {
      dispatch(setExpanded([nodeID]));
      if ((fileType == "InteractiveGuide") || (fileType == "InteractiveNews")) {
        dispatch(setSelFileType(fileType));
      }
    }
  };

  const onSubsetSelect = (details, fileType) => () => {
    dispatch(setSelFileDetails(details));
    dispatch(setSelFileType(fileType));
    dispatch(setIsDisabled(false));
  };

  const onAddSubset = () => {
    let interactiveGrpName = "";
    let interactiveGrpVersion = "";
    let interactiveGrpYear = "";
    let interactiveIKName = "";
    let interactiveIKVersion = "";

    if (selFileType == "Grouper") {
      interactiveGrpName = selFileDetails.grouperName;
      interactiveGrpVersion = selFileDetails.grouperVersion;
      interactiveGrpYear = selFileDetails.year;
    } else if (selFileType == "Internal_Codes") {
      interactiveIKName = selFileDetails.Name;
      interactiveIKVersion = selFileDetails.version;
    }

    if (
      interactiveGrpName == undefined ||
      interactiveGrpVersion == undefined ||
      interactiveGrpYear == undefined ||
      interactiveGrpName == "" ||
      interactiveGrpVersion == "" ||
      interactiveGrpYear == ""
    ) {
      interactiveGrpName = isGInteractiveLogic[0] ?.grouperName;
      interactiveGrpVersion = isGInteractiveLogic[0] ?.grouperVersion;
      interactiveGrpYear = isGInteractiveLogic[0] ?.year;
    }
    if (
      interactiveIKName == undefined ||
      interactiveIKVersion == undefined ||
      interactiveIKName == "" ||
      interactiveIKVersion == ""
    ) {
      interactiveIKName = isGInteractiveLogic[0] ?.interneKoderName;
      interactiveIKVersion = isGInteractiveLogic[0] ?.interneKoderVersion;
    }

    var interactiveData = {
      InteractiveName: isGInteractiveLogic[0] ?.interactiveName,
      InteractiveVersion: isGInteractiveLogic[0] ?.interactiveVersion,
      GrouperName: interactiveGrpName,
      GrouperVersion: interactiveGrpVersion,
      GrouperYear: interactiveGrpYear,
      InterneKoderName: interactiveIKName,
      InterneKoderVersion: interactiveIKVersion,
      Id: isGInteractiveLogic[0] ?.id,
      ShowTariff: isGInteractiveLogic[0]?.showTariff,
    };
    if (
      selFileType !== "InteractiveGuide" &&
      selFileType !== "InteractiveNews"
    ) {
      dispatch(setGInteractiveTariffFlag());
      let url = "api/UpdateInteractive";
      dispatch(
        UpdateInteractive({ method: "POST", url: url, body: interactiveData })
      );
      setAddSubset(false);
      dispatch(setIsDisabled(true));
      dispatch(setExpanded([]));
      dispatch(setSelFileDetails([]));
      dispatch(setIsGInteractiveRender());
    } else {
      let currentFile = selectedFiles[0];
      setCurrentFile(currentFile);
      setSelectedFiles("");
      let interactiveID = isGInteractiveLogic[0] ?.id;
      let selectedType = selFileType;
      let url =
        "api/UploadInteractiveFiles?interactiveID=" +
        interactiveID +
        "&selectedType=" +
        selectedType;
      let formData = new FormData();
      formData.append("file", currentFile, currentFile.name);
      dispatch(upload({ method: "POST", url: url, body: formData }));
      setAddSubset(false);
      dispatch(setIsDisabled(true));
      dispatch(setExpanded([]));
    }
  };

  const onDelete = (value,name) => (e) => {
    e.preventDefault();
    setDeleteItem(value);
    if(name!= "" && name!= undefined && name!= null ){
      setDeleteFile(name);
    }
    setRopen(true);
  }

  const onDeleteOk = (value) => (e) => {
    setRopen(false);
    let url =
      "api/DeleteInteractiveSublist?interactiveName=" +
        isGInteractiveLogic[0] ?.interactiveName +
          "&interactiveVersion=" +
          isGInteractiveLogic[0] ?.interactiveVersion +
            "&year=" +
            isGInteractiveLogic[0] ?.year +
            "&selectedType=" +
            value;
    dispatch(DeleteInteractiveSublist({ method: "POST", url: url }));
  };

  const onDeleteFile = (value, fileName) => (e) => {
    setRopen(false);
    let url =
      "api/DeleteInteractiveFiles?interactiveName=" +
        isGInteractiveLogic[0] ?.interactiveName +
          "&interactiveVersion=" +
          isGInteractiveLogic[0] ?.interactiveVersion +
            "&year=" +
            isGInteractiveLogic[0] ?.year +
            "&selectedType=" +
            value +
            "&fileName=" +
            fileName;

    dispatch(DeleteInteractiveSublist({ method: "POST", url: url }));
    setDeleteFile();
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
      dispatch(setIsDisabled(false));
    }
  };

  const handleTariff = (attribute) => (e) => {
    let checkedValue = e.target.checked;
    let result = isGInteractiveLogic.map((row) => {
      return { ...row, [attribute]: checkedValue };
    });
    dispatch(setIsGInteractiveLogicTariff(result));
  };

  if (interactiveTariffFlag) {
    onAddSubset();
  }
  return (
    <div className="GenerateInteractive">
      {isNoData == true && (
        <Paper className="paperContainer">
          <div className="right-section-container">
            <div className="right-section-header left">
              <Typography variant="h5">
                {t("Manager.Generate_Interactive")}
              </Typography>
            </div>
            <div className="right-section-header right">
              <div className="right-section-header-container">
                <Button
                  variant="contained"
                  size="small"
                  className="manager-button"
                  onClick={() => setAddLogic(true)}
                >
                  {t("GInteractive.add_interactive")}
                </Button>
              </div>
            </div>
          </div>
          <Divider />
          <div className="right-section-container-one">
            <div className="container-block two">
              <Typography
                align="center"
                color="#00000099"
                sx={{ marginLeft: "2px" }}
              >
                {t("GInteractive.GInteractive_Validation")}
              </Typography>
            </div>
          </div>
        </Paper>
      )}
     
        <Paper className="paperContainer">
             <div className="right-section-container">
            <div className="right-section-header left">
              <Typography variant="h5">
              {t("Manager.Generate_Interactive")}
              </Typography>
            </div>
            <div className="right-section-header right">
              <div className="right-section-header-container">
                <TextField
                  label={t("Manager.Year")}
                  variant="outlined"
                  size="small"
                  select
                  onChange={handleYearSelect}
                  sx={{ marginRight: 1 }}
                  value={selectedYear}
                >
                  {distinctUploadYear.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <Button
                  variant="contained"
                  size="small"
                  className="manager-button"
                  onClick={() => { setAddLogic(true), reset({ year: selectedYear }) }}
                >
                  {t("GInteractive.add_interactive")}
                </Button>           
              </div>
            </div>
          </div>
          <Divider />
          {isGInteractiveLogic.length > 0 && (

          <div className="right-section-container">
            <div className="right-section-header left">
              <Typography variant="h6">
                {isGInteractiveLogic[0] ?.interactiveName}-
                {isGInteractiveLogic[0] ?.interactiveVersion}
              </Typography>
            </div>
            <div className="right-section-header right">
              <div className="right-section-header-container">
              
                {isAdminPrivileges && isGInteractiveLogic[0] ?.status == 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    className="manager-button"
                    onClick={() => setRemoveLogic(true)}
                  >
                    {t("Manager.Remove")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  className="manager-button"
                  onClick={GenerateInteractive}
                >
                  {t("Manager.Generate_Interactive")}
                </Button>
              </div>
            </div>
          </div>
         
          )}
           {isGInteractiveLogic.length > 0 && (
          <div className="right-section-container-one">
            <div className="container-block one">
            <Typography className="typography-title" color="#00000099" gutterBottom>
                {t("Manager.publisher_status")}:
              <Typography variant="string" color="#000000DE" sx={{ marginLeft: "2px" }}>
              {isGInteractiveLogic[0] ?.status == 1 && t("Global.production")}
                  {isGInteractiveLogic[0] ?.status == 2 && t("Global.hearing")}
                  {isGInteractiveLogic[0] ?.status == 3 && t("Global.test")}                  
                  {isGInteractiveLogic[0] ?.status == 0 && t("Global.notpublished")}     
                   </Typography>
              </Typography>
              <Typography color="#00000099">
                Status:
                <Typography
                  variant="string"
                  color="#000000DE"
                  sx={{ marginLeft: "2px" }}
                >
                  {isGInteractiveLogic[0] ?.isGenerated == true
                    ? t("Manager_Common.generated")
                    : t("Manager_Common.not_generated")}
                </Typography>
                <Checkbox
                  checked={isGInteractiveLogic[0]?.showTariff == true ? true : false}
                  disabled={isGInteractiveLogic[0]?.status == 0 ? false : true}
                  onChange={handleTariff("showTariff")}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="string" color="#000000DE">
                  {t("Manager.show_Tarrifs")}
                </Typography>
              </Typography>
            </div>
            <Divider />
            <div className="container-block two">
              <div className="section">
                <Typography color="#00000099">{t("Manager.Subset")}</Typography>
                {isAdminPrivileges && isGInteractiveLogic[0] ?.status == 0 &&
                <Link
                  component="button"
                  color="#00A3E0"
                  onClick={() => setAddSubset(true)}
                >
                  {t("Manager.Add_Subset")}
                </Link>
                }
              </div>
              {isGInteractiveLogic[0] ?.grouperName != null &&
                isGInteractiveLogic[0] ?.grouperName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager_Common.grouper_file")} :
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGInteractiveLogic[0] ?.grouperName}{" "}
                        {isGInteractiveLogic[0] ?.grouperVersion}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGInteractiveLogic[0] ?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete("DRGLogic")}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              {isGInteractiveLogic[0] ?.interneKoderName != null &&
                isGInteractiveLogic[0] ?.interneKoderName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager.Internal_Code_File")} :
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGInteractiveLogic[0] ?.interneKoderName}{" "}
                        {isGInteractiveLogic[0] ?.interneKoderVersion}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGInteractiveLogic[0] ?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete("InterneKoder")}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              {isGInteractiveLogic[0].interactiveGuide ?.fileName != null &&
                isGInteractiveLogic[0].interactiveGuide ?.fileName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager.Interactive_Guidance_File")} :
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGInteractiveLogic[0].interactiveGuide ?.fileName}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGInteractiveLogic[0] ?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete(
                            "InteractiveGuide",
                            isGInteractiveLogic[0].interactiveGuide ?.fileName
                          )}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              {isGInteractiveLogic[0].interactiveNews ?.fileName != null &&
                isGInteractiveLogic[0].interactiveNews ?.fileName != "" && (
                  <div className="manage_subset">
                    <Typography component="div">
                      {t("Manager.Interactive_News_File")} :
                      <Typography
                        variant="string"
                        gutterBottom
                        color="#00000099"
                      >
                        {" "}
                        {isGInteractiveLogic[0].interactiveNews ?.fileName}
                      </Typography>
                    </Typography>
                    {isAdminPrivileges && isGInteractiveLogic[0] ?.status == 0 && (
                      <div>
                        <DeleteIcon
                          className="test"
                          onClick={onDelete(
                            "InteractiveNews",
                            isGInteractiveLogic[0].interactiveNews ?.fileName
                          )}
                          sx={{
                            color: "#959CA0",
                            cursor: "pointer",
                            "&:hover": { color: "#EC948D" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
           )}
        </Paper>
      
      <Dialog open={addLogic} onClose={handleClose}>
        <DialogTitle>{t("GInteractive.add_interactive")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              paddingTop: "5px !important",
              paddingBottom: "12px !important",
            }}
          >
            <div className="form-section">
              <TextField
                id="name"
                label={t("Manager_Common.Name")}
                fullWidth
                variant="outlined"
                className="form-section"
                {...register("name", {
                  required: "Name is required.",
                  // pattern: {
                  //   value: /^[a-zA-Z0-9_.-]*$/,
                  //   message:
                  //     t("Manager_Common.Validataion"),
                  // },
                })}
                error={Boolean(errors.name)}
                helperText={errors ?.name ?.message}
                inputProps={{ maxLength: 20 }}
              />
            </div>
            <div className="form-section-double">
              <div className="form-sub-section">
                <TextField
                  label="Version"
                  variant="outlined"
                  className="form-sub-section"
                  {...register("version", {
                    required: t("Manager.Version_Required"),
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/,
                      message: t("Manager.Invalid_Version"),
                    },
                  })}
                  error={Boolean(errors.version)}
                  helperText={errors ?.version ?.message}
                  inputProps={{ maxLength: 5 }}
                />
              </div>
              <div className="form-sub-section">
                <TextField
                  label={t("Manager.yearLabel")}
                  variant="outlined"
                  className="form-sub-section"
                  {...register("year", {
                    required: t("Manager.Year_Required"),
                    pattern: {
                      value: /^[0-9]{4,4}$/,
                      message: t("Manager.entryDigits"),
                    },
                  })}
                  error={Boolean(errors.year)}
                  helperText={errors ?.year ?.message}
                  type="Number"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" onClick={onSubmit} type="submit">
              {t("Manager.Save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={removeLogic} onClose={handleClose} maxWidth="xs">
        <DialogTitle>{t("GInteractive.remove_interactive")}</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            {t("GInteractive.remove_validation")}
          </Typography>
          <Typography gutterBottom>
            {t("interactive.Interactive")} {t("Manager_Common.Name")}:{" "}
            {isGInteractiveLogic[0] ?.interactiveName}-
            {isGInteractiveLogic[0] ?.interactiveVersion}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          <Button
            variant="contained"
            onClick={onOk(isGInteractiveLogic[0])}
            type="submit"
          >
            {t("Manager.Remove")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addSubset} onClose={handleClose}>
        <DialogTitle>{t("Manager.Add_Subset")}</DialogTitle>
        <DialogContent>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            sx={{ minWidth: 300 }}
            expanded={isExpanded}
          >
            <StyledTreeItem
              nodeId="Grouper"
              labelText="Grouper"
              labelIcon={FolderIcon}
              labelInfo={isGrouper.length}
              onClick={onSelect("Grouper")}
            >
              {isGrouper.map((item) => {
                return (
                  <StyledTreeItem
                    nodeId={item.grouperName + "-" + item.grouperVersion}
                    labelText={item.grouperName + "-" + item.grouperVersion}
                    onClick={onSubsetSelect(item, "Grouper")}
                  />
                );
              })}
            </StyledTreeItem>
            <StyledTreeItem
              nodeId="Internal_Codes"
              labelText={t("Manager.internal_code")}
              labelIcon={FolderIcon}
              labelInfo={isInternekoder.length}
              onClick={onSelect("Internal_Codes")}
            >
              {isInternekoder.map((item) => {
                return (
                  <StyledTreeItem
                    nodeId={item.Name + "-" + item.version}
                    labelText={item.Name + "-" + item.version}
                    onClick={onSubsetSelect(item, "Internal_Codes")}
                  />
                );
              })}
            </StyledTreeItem>
            <StyledTreeItem
              nodeId="Interactive_Guidance"
              labelText={t("Manager.interactive_guidance")}
              labelIcon={FolderIcon}
              // onClick={onSubsetSelect("", "InteractiveGuide")}
              onClick={onSelect("Interactive_Guidance", "InteractiveGuide")}
            >
              <Typography variant="caption" gutterBottom color="#F0B323">
                {t("Manager.Upload")} {t("Manager.Interactive_Guidance_File")}
              </Typography>
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps({})}>
                      <input {...getInputProps()} />
                      {selectedFiles && selectedFiles[0].name ? (
                        <div className="selected-file">
                          {selectedFiles && selectedFiles[0].name}
                        </div>
                      ) : (
                          <div>
                            <UploadFileIcon fontSize="medium" />
                            <div>{t("Manager_Common.Drag")}</div>
                          </div>
                        )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </StyledTreeItem>
            <StyledTreeItem
              nodeId="Interactive_News"
              labelText={t("Manager.interactive_news")}
              labelIcon={FolderIcon}
              // onClick={onSubsetSelect("", "InteractiveNews")}
              onClick={onSelect("Interactive_News", "InteractiveNews")}
            >
              <Typography variant="caption" gutterBottom color="#F0B323">
                {t("Manager.Upload")} {t("Manager.Interactive_News_File")}
              </Typography>
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps({})}>
                      <input {...getInputProps()} />
                      {selectedFiles && selectedFiles[0].name ? (
                        <div className="selected-file">
                          {selectedFiles && selectedFiles[0].name}
                        </div>
                      ) : (
                          <div>
                            <UploadFileIcon fontSize="medium" />
                            <div>{t("Manager_Common.Drag")}</div>
                          </div>
                        )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </StyledTreeItem>
          </TreeView>
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          <Button variant="contained" onClick={onAddSubset} disabled={isDisabled}>
            {t("Manager.Add")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={ropen} onClose={handleClose}>
        <DialogContent>
          {t("Manager_Common.deleteItem")}
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
          {(deleteFile!= "" && deleteFile!= undefined && deleteFile!= null ) ?(<Button variant="contained" onClick={onDeleteFile(deleteItem, deleteFile)} type="submit">{t("Manager.Ok")}</Button>):
          (<Button variant="contained" onClick={onDeleteOk(deleteItem)} type="submit">{t("Manager.Ok")}</Button>)}
        </DialogActions>
      </Dialog>
      {isGInteractiveLogic.length == 0 && ( <div className="right-section-container-one" >
          <Typography align="center" color="#00000099" sx={{ marginLeft: "2px" }}>              
          {t("Manager.No_Interactive_Selected")}
              </Typography>
          </div>)}
      <SharedComponent />
    </div>
  );
}
