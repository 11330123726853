import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./Editor.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import MuiGrid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
//import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import SharedComponent from "./SharedComponent";
import { List } from "react-virtualized";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  getAllCodes,
  checkLockforClassificationCode,
  addnewGroupCode,
  setGroupCodeWarning,
  setSelectedGrpCode,
  setGrpCodeEditable,
  saveGroupCode,
  cancelEditGroupCode,
  setCurrentGroupCode,
  deleteGroupCode,
  GEtSKSForLogic,
  addnewSubCode,
  setSubCodeEditable,
  saveSubCode,
  cancelEditSubCode,
  setCurrentSubCode,
  deleteSubCode,
  saveGroupCodes,
  setgroupCodesWarning,
  setGroupCodesList,
  setSubCodesList,
  setExistingSubcodes
} from "./editorSlice";

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

export default function GroupCodes() {
  const { t, i18n } = useTranslation("language");
  const dispatch = useDispatch();
  const { user, isViewerRole } = useSelector((state) => state.authentication);
  const editor = useSelector((state) => state.editor);
  const { language } = useSelector((state) => state.language);
  const {
    selectedEditorObj,
    selectedEditorID,
    selectedEditorName,
    selectedEditorYear,
    selectedEditorVersion,
    selectedGrpCode,
    grpCodeLoading,
    grpCodesList,
    grpCodesSubList,
    masterSelectedEditorData,
    currentEditGrpCode,
    currentEditSubCode,
    groupCodeLogs,
    masterAllGroupCodes,
    grpCodesListMaster,
    grpCodesSubListMaster,
    SKSList,
    isPublished,
    isPreProd,
    isTest,
  } = editor;

  const [SKSFilteredListSource, setSKSFilteredListSource] = useState([]);
  const [SKSFilteredList, setSKSFilteredList] = useState(SKSList);

  const [selectedSKSCodes, setSelectedSKSCodes] = useState([]);

  const [disabledGroupCode, setDisabledGroupCodes] = useState(false);

  const groupCodesArray = [
    "DGALT",
    "DGCAT",
    "DGPROP",
    "POR",
    "DOR",
    "PDGPROP",
    "PROCPROP",
    "TILL",
    "ICD",
  ];
  const [masterCodeValue, setMasterCodeValue] = useState(groupCodesArray[0]);
  const [groupCodeSearchValue, setGroupCodeSearchValue] = useState("");
  const [subCodeSearchValue, setSubCodeSearchValue] = useState("");
  const [saveConfirm, setSaveConfirm] = useState(false);

  const ref = useRef(null);
  const [classCodeListWidth, setClassCodeListwidth] = useState(500);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleAddNewGC,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleEditGC,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const {
    register: register3,
    handleSubmit: handleDeleteGC,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm();
  const {
    register: register4,
    handleSubmit: handleAddSubCode,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm();
  const {
    register: register5,
    handleSubmit: editSubCode,
    reset: reset5,
    formState: { errors: errors5 },
  } = useForm();
  const {
    register: register6,
    handleSubmit: deleteSubCodeEvent,
    reset: reset6,
    formState: { errors: errors6 },
  } = useForm();
  const [addNewCode, setAddNewCode] = useState(false);
  const [addNewSubCode, setAddNewSubCode] = useState(false);
  const [deleteSubCodeConfirm, setDeleteSubCodeConfirm] = useState(false);

  useLayoutEffect(() => {
    setClassCodeListwidth(ref.current.offsetWidth);
  }, []);

  useEffect(() => {
    dispatch(
      getAllCodes({
        method: "GET",
        groupCode: groupCodesArray[0],
        drgLogicID: selectedEditorID,
      })
    ).then((r) => {
      if (selectedGrpCode != null) {
        dispatch(
          checkLockforClassificationCode({
            method: "GET",
            emailID: user[0]["email"],
            drgLogicID: selectedEditorID,
            masterGroupCode: groupCodesArray[0],
            groupCode: selectedGrpCode,
          })
        );
      }
    });
    //dispatch(GEtSKSForLogic({ method: "GET" }));
  }, []);

  const handleGroupCodeSearch = (event) => {
    setGroupCodeSearchValue(event.target.value);

    const result = grpCodesListMaster.filter((item) => {
      return item.name
        ?.toLowerCase()
          .includes(event.target.value ?.toLowerCase());
    });
    dispatch(setGroupCodesList(result));
  };
  const handleSubCodeSearch = (event) => {
    setSubCodeSearchValue(event.target.value);
    const result = grpCodesSubListMaster.filter((item) => {
      return (
        item.name ?.toLowerCase().includes(event.target.value ?.toLowerCase()) ||
          item.Description ?.toLowerCase().includes(
            event.target.value ?.toLowerCase()
        )
      );
    });
    dispatch(setSubCodesList(result));
  };
  const closeSearch = () => {
    setGroupCodeSearchValue("");
    dispatch(setGroupCodesList(grpCodesListMaster));
  };
  const closeSearchSubCode = () => {
    setSubCodeSearchValue("");
    dispatch(setSubCodesList(grpCodesSubListMaster));
  }
  const handleMasterCodeChange = (e) => {
    setGroupCodeSearchValue("");
    setMasterCodeValue(e.target.value);
    if(["ICD","POR","DOR"].includes(e.target.value)){
      setDisabledGroupCodes(true);
    }
    else{
      setDisabledGroupCodes(false)
    }
    dispatch(
      getAllCodes({
        method: "GET",
        groupCode: e.target.value,
        drgLogicID: selectedEditorID,
      })
    ).then((r) => {
      if (selectedGrpCode != null) {
        dispatch(
          checkLockforClassificationCode({
            method: "GET",
            emailID: user[0]["email"],
            drgLogicID: selectedEditorID,
            masterGroupCode: e.target.value,
            groupCode: selectedGrpCode,
          })
        );
      }
    });
  };

  const handleItemSelect = (event, item) => {
    dispatch(setSelectedGrpCode(item.name));
    if (selectedGrpCode != null) {
      dispatch(
        checkLockforClassificationCode({
          method: "GET",
          emailID: user[0]["email"],
          drgLogicID: selectedEditorID,
          masterGroupCode: masterCodeValue,
          groupCode: item,
        })
      );
    }
  };

  const handleAddGroupCode = (e) => {
    if (e.currentTarget.value == "groupCode") {
      setGroupCodeSearchValue("");
      dispatch(setGroupCodesList(grpCodesListMaster));
      setAddNewCode(true);
    } else if (e.currentTarget.value == "groupCodeSubList") {
      setSubCodeSearchValue("");
      dispatch(setSubCodesList(grpCodesSubListMaster));
      setSKSFilteredListSource([]);
      setSelectedSKSCodes([]);
      setSKSFilteredList(SKSList);
      setAddNewSubCode(true);
    }
  };

  const onAddNewCode = (values) => {
    const newGrpCode = values.addGroupCode;
    var isGrpCodeExists = grpCodesList.findIndex(
      (item) => item.name == newGrpCode.toUpperCase()
    );
    if (isGrpCodeExists < 0) {
      var log = {
        DRGLogicID: selectedEditorID,
        Comments: language == "en" ? "Added Group code" : "Tilføjet gruppekode",
        masterGroupCode: masterCodeValue,
        GroupCode: "",
        OldValue: "",
        NewValue: newGrpCode.toUpperCase(),
        UserComments: values.comment_msg,
      };

      dispatch(
        addnewGroupCode({ newGrpCode: newGrpCode.toUpperCase(), log: log })
      );
      setAddNewCode(false);
      reset1();
    } else {
      dispatch(setGroupCodeWarning("groupCodeExist"));
    }
  };

  const handleEditGroupCode = (e, item) => {
    //Disabled check for OR and ICD
    if (item.isDisabled) {
      var columns = [];
      // Get all the rules to check the value
      var drgRules = masterSelectedEditorData;
      for (var prop in drgRules[0]) {
        columns.push(prop);
      }
      // Get all distinct columns and get all realted columns for DGPROP and PROCPROP/Secprop
      var pdgprop = columns.filter(
        (item) => item.includes("dgpro") && item != "pdgprop"
      );
      var procpro = columns.filter(
        (item) => item.includes("procpr") || item.includes("secpro")
      );
      var index = -1;
      if (masterCodeValue == "DGPROP") {
        for (let p = 0; p < pdgprop.length; p++) {
          index = drgRules.findIndex(
            (a) =>
              a[pdgprop[p]] == item.name || a[pdgprop[p]] == "-" + item.name
          );
          if (index >= 0) {
            break;
          }
        }
      } else if (masterCodeValue == "PROCPROP") {
        for (let p = 0; p < procpro.length; p++) {
          index = drgRules.findIndex(
            (a) =>
              a[procpro[p]] == item.name || a[procpro[p]] == "-" + item.name
          );
          if (index >= 0) {
            break;
          }
        }
      } else {
        index = drgRules.findIndex(
          (a) =>
            a[masterCodeValue.toLowerCase()] == item.name ||
            a[masterCodeValue.toLowerCase()] == "-" + item.name
        );
      }
      if (e.currentTarget.value == "Edit") {
        dispatch(setGrpCodeEditable(item.name));
      } else if (e.currentTarget.value == "Delete") {
        dispatch(setCurrentGroupCode(item.name));
        setDeleteConfirm(true);
      }

      /*  if (index < 0) {
        dispatch(setGrpCodeEditable(item.name));
      } else {
        dispatch(setGroupCodeWarning("groupCodeEditWarning"));
      } */
    }
  };

  const onEditGroupCode = (values) => {
    const updatedCode = values.updatedGroupCode;
    var isGrpCodeExists = grpCodesList.findIndex(
      (item) => item.name == updatedCode.toUpperCase()
    );
    if (isGrpCodeExists < 0) {
      var log = {
        DRGLogicID: selectedEditorID,
        Comments:
          language == "en" ? "Modified Group code" : "Ændret gruppekode",
        masterGroupCode: masterCodeValue,
        GroupCode: "",
        OldValue: currentEditGrpCode.toUpperCase(),
        NewValue: updatedCode.toUpperCase(),
        UserComments:
          language == "en" ? "Modified Group code" : "Ændret gruppekode",
      };

      dispatch(
        saveGroupCode({
          newCode: updatedCode.toUpperCase(),
          name: currentEditGrpCode,
          log: log,
        })
      );

      reset2();
    } else {
      dispatch(setGroupCodeWarning("groupCodeExist"));
    }
  };

  const handleGrpCodeCancel = (e, name) => {
    dispatch(cancelEditGroupCode(name));
    reset2();
  };

  const onDeleteGroupCode = (values) => {
    const comment = values.comment_msg;
    var log = {
      DRGLogicID: selectedEditorID,
      Comments: language == "en" ? "Deleted Group code" : "Slettet gruppekode",
      masterGroupCode: masterCodeValue,
      GroupCode: "",
      OldValue: currentEditGrpCode.toUpperCase(),
      NewValue: "",
      UserComments: comment,
    };
    dispatch(
      deleteGroupCode({
        name: currentEditGrpCode,
        masterGroupCode: masterCodeValue,
        selectedGroupCode: selectedGrpCode,
        log: log,
      })
    );
    if (selectedGrpCode != null) {
      dispatch(
        checkLockforClassificationCode({
          method: "GET",
          emailID: user[0]["email"],
          drgLogicID: selectedEditorID,
          masterGroupCode: masterCodeValue,
          groupCode: selectedGrpCode,
        })
      );
      setDeleteConfirm(false);
      reset3();
    }
  };

  const onAddNewSubCode = (values) => {

    var addedSubCodes = [];

    var existingSubCodes = '';

    const newSubCodeText = "";

    for (let i = 0; i < selectedSKSCodes.length; i++) {

      const newSubCode = selectedSKSCodes[i].code;


      // var grpCodeIndex = grpCodesList.findIndex(
      //   (i) => i.name == selectedGrpCode.toUpperCase()
      // );
      if (grpCodesSubList.length >= 0) {
        var isGrpCodeExists = grpCodesSubList.findIndex(
          (item) => item.name.toUpperCase() == newSubCode.toUpperCase()
        );

        if (isGrpCodeExists != -1) {
          if (existingSubCodes == '') {

            existingSubCodes = newSubCode

          }
          else {
            existingSubCodes = existingSubCodes + ', ' + newSubCode
          }

        }

        addedSubCodes.push(newSubCode)
      }
    }

    const comments = values.comment_msg;

    if (existingSubCodes.length == 0) {

      for (let i = 0; i < addedSubCodes.length; i++) {

        if (grpCodesSubList.length > 0) {

          var log = {
            DRGLogicID: selectedEditorID,
            Comments:
              language == "en"
                ? "Added Diagnosis/Procedure code"
                : "Tilføjet diagnose/procedurekode",
            masterGroupCode: masterCodeValue,
            GroupCode: selectedGrpCode.toUpperCase(),
            OldValue: "",
            NewValue: addedSubCodes[i].toUpperCase(),
            UserComments: comments,
          };

          dispatch(
            addnewSubCode({
              newDgCode: addedSubCodes[i],
              newDgCodeText: newSubCodeText,
              selectedGrpCode: selectedGrpCode,
              newSubList: false,
              log: log,
            })
          );


        } else {
          var log = {
            DRGLogicID: selectedEditorID,
            Comments:
              language == "en"
                ? "Added Diagnosis/Procedure code"
                : "Tilføjet diagnose/procedurekode",
            masterGroupCode: masterCodeValue,
            GroupCode: selectedGrpCode.toUpperCase(),
            OldValue: "",
            NewValue: addedSubCodes[i].toUpperCase(),
            UserComments: comments,
          };

          dispatch(
            addnewSubCode({
              newDgCode: addedSubCodes[i],
              newDgCodeText: newSubCodeText,
              selectedGrpCode: selectedGrpCode,
              newSubList: true,
              log: log,
            })
          );
        }

      }
      setAddNewSubCode(false);
      reset4();

    }
    else {

      dispatch(setExistingSubcodes(existingSubCodes));

      dispatch(setGroupCodeWarning("subCodeExist"));

    }

  };

  const handleEditSubCode = (e, item) => {
    if (e.currentTarget.value == "Edit") {
      dispatch(setSubCodeEditable(item));
    } else if (e.currentTarget.value == "Delete") {
      dispatch(setCurrentSubCode(item.name));
      setDeleteSubCodeConfirm(true);
    }
  };
  const onEditSubCode = (values) => {
    const updatedSubCode = values.updatedSubCode;
    var isGrpCodeExists = grpCodesSubList.findIndex(
      (b) => b.name == updatedSubCode.toUpperCase()
    );
    if (isGrpCodeExists < 0) {
      var log = {
        DRGLogicID: selectedEditorID,
        Comments:
          language == "en"
            ? "Modified Diagnosis/Procedure code"
            : "Ændret gruppekode",
        masterGroupCode: masterCodeValue,
        GroupCode: selectedGrpCode,
        OldValue: currentEditSubCode.name.toUpperCase(),
        NewValue: updatedSubCode.toUpperCase(),
        UserComments:
          language == "en"
            ? "Modified Diagnosis/Procedure code"
            : "Ændret gruppekode",
      };

      dispatch(
        saveSubCode({
          name: currentEditSubCode.name,
          updatedCode: updatedSubCode,
          log: log,
        })
      );

      reset5();
    } else {
      dispatch(setGroupCodeWarning("subCodeExist"));
    }
  };
  const handleSubCodeCancel = (e, name) => {
    dispatch(cancelEditSubCode(name));
    reset5();
  };
  const onDeleteSubCode = (values) => {
    const comment = values.comment_msg;
    var log = {
      DRGLogicID: selectedEditorID,
      Comments:
        language == "en"
          ? "Deleted Diagnosis/Procedure code"
          : "Slettet diagnose/procedurekode",
      masterGroupCode: masterCodeValue,
      GroupCode: selectedGrpCode.toUpperCase(),
      OldValue: currentEditSubCode.toUpperCase(),
      NewValue: "",
      UserComments: comment,
    };

    dispatch(
      deleteSubCode({
        name: currentEditSubCode,
        masterGroupCode: masterCodeValue,
        selectedGroupCode: selectedGrpCode,
        log: log,
      })
    );
    setDeleteSubCodeConfirm(false);
    reset6();
  };

  const handleSaveGroupCodes = (e) => {
    setSaveConfirm(true);
  };

  const onSaveConfirm = (values) => {
    const comment = values.comment_msg;
    var date = new Date();
    if (
      masterCodeValue == "ICD" ||
      masterCodeValue == "POR" ||
      masterCodeValue == "DOR"
    ) {
      var data = masterAllGroupCodes;
      /* var grpCodeLogs = groupCodeLogs;
      grpCodeLogs.forEach((element) => {
        element.UserComments = comment;
      }); */

      let groupCodesLogList = {
        DRGLogicID: selectedEditorID,
        Name: selectedEditorName,
        Version: selectedEditorVersion,
        ModifiedBy: user[0]["email"],
        ModifiedOn: date.toString(),
        Comment: comment,
        LogicComments: groupCodeLogs,
      };

      var postData = { codes: data, logs: groupCodesLogList };
      dispatch(
        saveGroupCodes({
          method: "POST",
          body: postData,
          drgLogicID: selectedEditorID,
          masterGroupCode: masterCodeValue,
        })
      ).then(() => {
        dispatch(
          getAllCodes({
            method: "GET",
            groupCode: masterCodeValue,
            drgLogicID: selectedEditorID,
          })
        ).then((r) => {
          if (selectedGrpCode != null) {
            dispatch(
              checkLockforClassificationCode({
                method: "GET",
                emailID: user[0]["email"],
                drgLogicID: selectedEditorID,
                masterGroupCode: groupCodesArray[0],
                groupCode: selectedGrpCode,
              })
            );
          }
        });
      });
    } else {
      // Get all the rules to check the value
      console.log("break_point");
      var drgRules = masterSelectedEditorData;
      var index = 0;
      var columns = [];
      for (var prop in drgRules[0]) {
        columns.push(prop);
      }
      // Get all distinct columns and get all realted columns for DGPROP and PROCPROP/Secprop
      var pdgprop = columns.filter(
        (item) => item.includes("dgpro") && item != "pdgprop"
      );
      var procpro = columns.filter(
        (item) => item.includes("procpr") || item.includes("secpro")
      );

      var notExistGroupCode = "";
      if (masterCodeValue == "DGPROP") {
        for (let p = 0; p < pdgprop.length; p++) {
          for (let d = 0; d < masterAllGroupCodes.length; d++) {
            var editorMastercode = drgRules[d][pdgprop[p]];
            if (editorMastercode != null) {
              if (editorMastercode.substring(0, 1) == "-")
                editorMastercode = editorMastercode.substring(1);
              index = masterAllGroupCodes.findIndex(
                (a) => a.name == editorMastercode
              );
              if (index == -1) notExistGroupCode = editorMastercode;
            }
            if (index < 0) break;
          }
        }
      } else if (masterCodeValue == "PROCPROP") {
        for (let p = 0; p < procpro.length; p++) {
          for (let d = 0; d < drgRules.length; d++) {
            var editorMastercode = drgRules[d][procpro[p]];
            if (editorMastercode != null) {
              if (editorMastercode.substring(0, 1) == "-")
                editorMastercode = editorMastercode.substring(1);
              index = masterAllGroupCodes.findIndex(
                (a) => a.name == editorMastercode
              );
              if (index == -1) notExistGroupCode = editorMastercode;
            }
            if (index < 0) break;
          }
        }
      } else {
        for (let d = 0; d < drgRules.length; d++) {
          var editorMastercode = drgRules[d][masterCodeValue.toLowerCase()];
          if (editorMastercode != null) {
            if (editorMastercode.substring(0, 1) == "-")
              editorMastercode = editorMastercode.substring(1);
            index = masterAllGroupCodes.findIndex(
              (a) => a.name == editorMastercode
            );
            if (index == -1) notExistGroupCode = editorMastercode;
          }
          if (index < 0) break;
        }
      }
      if (index >= 0) {
        /*  var grpCodeLogs = groupCodeLogs;
        grpCodeLogs.forEach((element) => {
          element.UserComments = comment;
        });
 */
        let groupCodesLogList = {
          DRGLogicID: selectedEditorID,
          Name: selectedEditorName,
          Version: selectedEditorVersion,
          ModifiedBy: user[0]["email"],
          ModifiedOn: date.toString(),
          Comment: comment,
          LogicComments: groupCodeLogs,
        };
        var data = masterAllGroupCodes;
        var postData = { codes: data, logs: groupCodesLogList };
        setGroupCodeSearchValue("");
        setSubCodeSearchValue("");
        dispatch(
          saveGroupCodes({
            method: "POST",
            body: postData,
            drgLogicID: selectedEditorID,
            masterGroupCode: masterCodeValue,
          })
        ).then(() => {
          dispatch(
            getAllCodes({
              method: "GET",
              groupCode: masterCodeValue,
              drgLogicID: selectedEditorID,
            })
          ).then((r) => {
            if (selectedGrpCode != null) {
              dispatch(
                checkLockforClassificationCode({
                  method: "GET",
                  emailID: user[0]["email"],
                  drgLogicID: selectedEditorID,
                  masterGroupCode: groupCodesArray[0],
                  groupCode: selectedGrpCode,
                })
              );
            }
          });
        });
      } else {
        let message = "";
        if (notExistGroupCode.length > 0) {
          message =
            language == "en"
              ? "The group code " +
              notExistGroupCode +
              " is mapped to DRG Logic in editor. so you can't make any changes to " + masterCodeValue
              : "Gruppekoden " +
              notExistGroupCode +
              "  er tilknyttet til en DRG Logik i Editor, så du kan ikke foretage nogen ændringer for " + masterCodeValue;
        } else {
          message =
            language == "en"
              ? "This group code is mapped to a DRG Logic in the Editor so you cannot make any changes to DGALT."
              : "Denne gruppekode er tilknyttet til en DRG Logik i Editor, så du kan ikke foretage nogen ændringer for DGALT.";
        }
        dispatch(setgroupCodesWarning(message));
        setGroupCodeSearchValue("");
        setSubCodeSearchValue("");
        dispatch(
          getAllCodes({
            method: "GET",
            groupCode: masterCodeValue,
            drgLogicID: selectedEditorID,
          })
        ).then((r) => {
          if (selectedGrpCode != null) {
            dispatch(
              checkLockforClassificationCode({
                method: "GET",
                emailID: user[0]["email"],
                drgLogicID: selectedEditorID,
                masterGroupCode: groupCodesArray[0],
                groupCode: selectedGrpCode,
              })
            );
          }
        });
      }
    }
    setSaveConfirm(false);
    reset();
  };

  const handleClose = () => {
    setSaveConfirm(false);
    reset();
    setAddNewCode(false);
    reset1();
    setDeleteConfirm(false);
    reset3();
    setAddNewSubCode(false);
    reset4();
    reset5();
    setDeleteSubCodeConfirm(false);
    reset6();
  };

  const SearchSKSCodes = (value) => {
    if(value.length > 3 && value.charAt(value.length-1) == "*"){
      setSelectedSKSCodes(SKSFilteredListSource);
    }else{
    var keyLength = value.length;
    var searchValue = value.toUpperCase();
    if (SKSList.length > 0) {
      setSKSFilteredList(SKSList.filter((x) => x.code.substring(0, keyLength) == searchValue));
      if (keyLength > 2) {
        setSKSFilteredListSource(SKSList.filter((x) => x.code.substring(0, keyLength) == searchValue));
      }
      else {
        setSKSFilteredListSource([]);
      }
    }
    }

  };

  const renderRow = ({ index, key, style }) => {
    let sksData = SKSList.length > 0 ? SKSList.find(x => x.code == grpCodesSubList[index].name) : null;

    return (<div key={key} style={style}>
      {grpCodesSubList[index].isDisabled ? (
        <ListItem
          secondaryAction={
            <>
              <IconButton
                edge="end"
                aria-label="edit"
                value="Edit"
                disabled={isViewerRole || isPublished || isPreProd || isTest}
                onClick={(event) =>
                  handleEditSubCode(event, grpCodesSubList[index])
                }
              >
                <EditIcon size="small" />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                value="Delete"
                disabled={isViewerRole || isPublished || isPreProd || isTest}
                onClick={(event) =>
                  handleEditSubCode(event, grpCodesSubList[index])
                }
              >
                <DeleteIcon size="small" />
              </IconButton>
            </>
          }
        >
          <ListItemText
            primary={
              (sksData != null && sksData != undefined) ?
                (grpCodesSubList[index].name + " " + sksData.text) :
                grpCodesSubList[index].name}
          />
        </ListItem>
      ) : (
          <form onSubmit={editSubCode(onEditSubCode)}>
            <ListItem
              secondaryAction={
                <>
                  <Button
                    onClick={(event) =>
                      handleSubCodeCancel(event, grpCodesSubList[index].name)
                    }
                  >
                    {t("Global.cancel")}
                  </Button>
                  <Button variant="contained" type="submit">
                    {t("Global.save")}
                  </Button>
                </>
              }
              sx={{ cursor: "pointer" }}
            >
              <TextField
                id="outlined-multiline-static"
                label=""
                // margin="normal"
                defaultValue={grpCodesSubList[index].name}
                {...register5("updatedSubCode", {
                  required: t("ClassificationCode.noSubCode"),
                })}
                error={Boolean(errors5.updatedGroupCode)}
                helperText={errors5 ?.updatedGroupCode ?.message}
              />
            </ListItem>
          </form>
        )}
    </div>)
  }

  return (
    <div>
      <Grid container sx={{ padding: "0px 15px" }}>
        <Grid item xs className="groupcode-grid">
          <div className="groupcode-header-section">
            <Typography className="groupcode-content-header" gutterBottom>
              {t("ClassificationCode.groupCodes")}
            </Typography>
            <div>
              <TextField
                label={t("ChangeLogs.Master_Code")}
                variant="outlined"
                select
                value={masterCodeValue}
                onChange={handleMasterCodeChange}
                style={{ width: "150px", paddingRight: "20px" }}
                size="small"
              >
                {groupCodesArray ? (
                  groupCodesArray.map((code) => (
                    <MenuItem key={code} value={code}>{code}</MenuItem>
                  ))
                ) : (
                    <div></div>
                  )}
              </TextField>
              {!isViewerRole  && (
                <Button
                  variant="contained"
                  size="small"
                  className="groupcode-button"
                  value="groupCode"
                  onClick={handleAddGroupCode}
                  disabled={disabledGroupCode || isPublished || isPreProd || isTest}
                >
                  <AddIcon fontSize="small" sx={{ paddingRight: "10px" }} />
                  {t("Editor.AddCode")}
                </Button>
              )}
            </div>
          </div>
          <Divider />
          <TextField
            variant="outlined"
            placeholder={t("Global.search")}
            size="small"
            value={groupCodeSearchValue}
            onChange={handleGroupCodeSearch}
            sx={{ width: "97%", padding: "15px 10px" }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
              endAdornment: (
                <CancelRoundedIcon
                  fontSize="small"
                  sx={{ color: "#00000040", cursor: "pointer" }}
                  onClick={closeSearch}
                />
              ),
            }}
          />
          <Grid
            sx={{
              width: "100%",
              maxWidth: "99%",
              bgcolor: "background.paper",
              height: "300px",
              overflowY: "scroll",
            }}
            ref={ref}
          >
            {grpCodesList ?.map((item) => {
              return item.isDisabled ? (
                <ListItem
                  secondaryAction={
                    <>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        value="Edit"
                        disabled={isViewerRole|| disabledGroupCode || isPublished || isPreProd || isTest}
                        onClick={(event) => handleEditGroupCode(event, item)}
                      >
                        <EditIcon size="small" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        value="Delete"
                        disabled={isViewerRole|| disabledGroupCode || isPublished || isPreProd || isTest}
                        onClick={(event) => handleEditGroupCode(event, item)}
                      >
                        <DeleteIcon size="small" />
                      </IconButton>
                    </>
                  }
                  sx={{ cursor: "pointer", paddingRight: "75px !important" }}
                >
                  <ListItemButton
                    selected={item.name == selectedGrpCode}
                    onClick={(event) => handleItemSelect(event, item)}
                  >
                    <ListItemText
                      primary={
                        !masterCodeValue.endsWith("OR")
                          ? item.name
                          : item.name == "0"
                            ? "+"
                            : "-"
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                  <form onSubmit={handleEditGC(onEditGroupCode)}>
                    <ListItem
                      secondaryAction={
                        <>
                          <Button
                            onClick={(event) =>
                              handleGrpCodeCancel(event, item.name)
                            }
                          >
                            {t("Global.cancel")}
                          </Button>
                          <Button variant="contained" type="submit">
                            {t("Global.save")}
                          </Button>
                        </>
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <TextField
                        id="outlined-multiline-static"
                        label=""
                        margin="normal"
                        defaultValue={
                          !masterCodeValue.endsWith("OR")
                            ? item.name
                            : item.name == "0"
                              ? "+"
                              : "-"
                        }
                        {...register2("updatedGroupCode", {
                          required: t("ClassificationCode.noGroupCode"),
                        })}
                        error={Boolean(errors2.updatedGroupCode)}
                        helperText={errors2 ?.updatedGroupCode ?.message}
                      />
                    </ListItem>
                  </form>
                );
            })}
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs className="groupcode-grid">
          <div className="groupcode-header-section">
            <Typography className="groupcode-content-header" gutterBottom>
              {t("ClassificationCode.Classification_Codes")}
            </Typography>
            {!isViewerRole && (
              <div>
                <Button
                  variant="contained"
                  size="small"
                  className="groupcode-button"
                  value="groupCodeSubList"
                  onClick={handleAddGroupCode}
                  disabled = {isPublished || isPublished || isPreProd || isTest}
                >
                  <AddIcon fontSize="small" sx={{ paddingRight: "10px" }} />
                  {t("Editor.AddCode")}
                </Button>
              </div>
            )}
          </div>
          <Divider />
          <TextField
            variant="outlined"
            placeholder={t("Global.search")}
            size="small"
            value={subCodeSearchValue}
            onChange={handleSubCodeSearch}
            sx={{ width: "97%", padding: "15px 10px" }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
              endAdornment: (
                <CancelRoundedIcon
                  fontSize="small"
                  sx={{ color: "#00000040", cursor: "pointer" }}
                  onClick={closeSearchSubCode}
                />
              ),
            }}
          />

          <List
            width={classCodeListWidth}
            height={300}
            rowRenderer={renderRow}
            rowCount={grpCodesSubList.length}
            rowHeight={60}
          />
        </Grid>
      </Grid>
      <Divider sx={{ paddingTop: "10px" }} />
      {!isViewerRole && (
        <div className="countingCode-button-container">
          <Button
            variant="contained"
            size="small"
            className="editor-button"
            onClick={handleSaveGroupCodes}
            disabled = { isPublished || isPreProd || isTest}
          >
            {t("Global.save")}
          </Button>
        </div>
      )}

      <Dialog open={saveConfirm} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {t("Global.Message")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSaveConfirm)}>
          <DialogContent>
            <TextField
              id="outlined-multiline-static"
              label={t("Editor.comments")}
              multiline
              rows={3}
              margin="normal"
              fullWidth
              {...register("comment_msg", {
                required: t("Editor.comment_msg"),
              })}
              error={Boolean(errors.comment_msg)}
              helperText={errors ?.comment_msg ?.message}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Global.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={addNewCode} onClose={handleClose} maxWidth="30%">
        <DialogTitle sx={{ padding: "15px 24px" }}>
          {t("Editor.AddCode")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleAddNewGC(onAddNewCode)}>
          <DialogContent sx={{ padding: "5px 24px" }}>
            <TextField
              id="outlined-multiline-static"
              label={t("ClassificationCode.Code")}
              margin="normal"
              fullWidth
              {...register1("addGroupCode", {
                required: t("ClassificationCode.noGroupCode"),
              })}
              error={Boolean(errors1.addGroupCode)}
              helperText={errors1 ?.addGroupCode ?.message}
            />
            <TextField
              id="outlined-multiline-static"
              label={t("Editor.comments")}
              multiline
              rows={3}
              margin="normal"
              fullWidth
              {...register1("comment_msg", {
                required: t("Editor.comment_msg"),
              })}
              error={Boolean(errors1.comment_msg)}
              helperText={errors1 ?.comment_msg ?.message}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Global.Add")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={deleteConfirm}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {t("Editor.DeleteGroupCodeConfirm")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleDeleteGC(onDeleteGroupCode)}>
          <DialogContent>
            <TextField
              id="outlined-multiline-static"
              label={t("Editor.comments")}
              multiline
              rows={3}
              margin="normal"
              fullWidth
              {...register3("comment_msg", {
                required: t("Editor.comment_msg"),
              })}
              error={Boolean(errors3.comment_msg)}
              helperText={errors3 ?.comment_msg ?.message}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Global.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={addNewSubCode} onClose={handleClose} maxWidth="30%">
        <DialogTitle sx={{ padding: "15px 24px" }}>
          {t("Editor.AddCode")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleAddSubCode(onAddNewSubCode)}>
          <DialogContent sx={{ padding: "5px 24px" }}>
            {/* {SKSList.length > 0 &&
              <Autocomplete
                id="free-solo-demo"
                options={SKSList.length > 0 ? SKSList : SKSList}
                // options={groupCodesArray}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    margin="normal"
                    fullWidth
                    {...register4("addSubCode", {
                      required: t("ClassificationCode.noSubCode"),
                    })}
                    error={Boolean(errors4.addSubCode)}
                    helperText={errors4 ?.addSubCode ?.message}
                  />
                )}
              />
            } */}
            {/* <TextField
              id="outlined-multiline-static"
              label="Code"
              margin="normal"
              fullWidth
              {...register4("addSubCode", {
                required: t("ClassificationCode.noSubCode"),
              })}
              error={Boolean(errors4.addSubCode)}
              helperText={errors4?.addSubCode?.message}
            /> */}
            <Autocomplete
              noOptionsText={SKSFilteredList.length + " " + t("Editor.codes_available")}
              onChange={(event, value) => setSelectedSKSCodes(value)}
              multiple
              value= {selectedSKSCodes}
              limitTags={3}
              id="checkboxes-tags-demo"
              options={SKSFilteredListSource}
              disableCloseOnSelect
              getOptionLabel={(option) => option.code}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.code}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Editor.SKS_CODES")}
                  onChange={(e) => SearchSKSCodes(e.target.value)}
                  placeholder={t("Global.search")}
                />
              )}
            />

            <TextField
              id="outlined-multiline-static"
              label={t("Editor.comments")}
              multiline
              rows={3}
              margin="normal"
              fullWidth
              {...register4("comment_msg", {
                required: t("Editor.comment_msg"),
              })}
              error={Boolean(errors4.comment_msg)}
              helperText={errors4 ?.comment_msg ?.message}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Global.Add")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={deleteSubCodeConfirm}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {t("Editor.DeleteCodeConfirm")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={deleteSubCodeEvent(onDeleteSubCode)}>
          <DialogContent>
            <TextField
              id="outlined-multiline-static"
              label={t("Editor.comments")}
              multiline
              rows={3}
              margin="normal"
              fullWidth
              {...register6("comment_msg", {
                required: t("Editor.comment_msg"),
              })}
              error={Boolean(errors6.comment_msg)}
              helperText={errors6 ?.comment_msg ?.message}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Global.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Backdrop
        sx={{
          color: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={grpCodeLoading}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
