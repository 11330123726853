import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { onLogout } from "../authentication/authenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import * as services from "../../services/apiServices";

export default function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  useEffect(() => {
    dispatch(onLogout({ method: "GET" }));
  }, []);

  if (authentication.logoutStatus == "success") {
    history.push("/Login");
  }
  return <div></div>;
}
