import React, { useState, useEffect } from "react";
import "./Editor.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  getDRGLogicLogs,
  setdrgLogicLogs,
  GetGroupCodesLog,
  setgroupCodesLogs,
  clearBackToEditor,
} from "./editorSlice";

export default function ChangeLogs() {
  const { t, i18n } = useTranslation("language");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const editor = useSelector((state) => state.editor);
  const { language } = useSelector((state) => state.language);
  const {
    selectedEditorObj,
    selectedEditorID,
    selectedEditorName,
    selectedEditorYear,
    selectedEditorVersion,
    logsLoading,
    masterDRGLogicLogs,
    drgLogicLogs,
    groupCodesLogs,
    masterGroupCodesLogs,
  } = editor;
  const [value, setValue] = useState("drgLogic");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getDRGLogicLogs({
        method: "GET",
        drgLogicID: selectedEditorID,
      })
    ).then(() => {
      dispatch(
        GetGroupCodesLog({
          method: "GET",
          drgLogicID: selectedEditorID,
        })
      );
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };
  const requestSearch = (event) => {
    if (value == "groupCode") {
      const result = masterGroupCodesLogs.filter((log) => {
        return (
          log.modifiedBy
            ?.toLowerCase()
              .includes(event.target.value ?.toLowerCase()) ||
              log ?.groupCode
                ?.toLowerCase()
                  .includes(event.target.value ?.toLowerCase()) ||
                  log ?.comments
                    ?.toLowerCase()
                      .includes(event.target.value ?.toLowerCase())
        );
      });
      dispatch(setgroupCodesLogs(result));
    } else {
      const result = masterDRGLogicLogs.filter((log) => {
        return (
          log.modifiedBy
            ?.toLowerCase()
              .includes(event.target.value ?.toLowerCase()) ||
              log.fieldName
              ?.toLowerCase()
                .includes(event.target.value ?.toLowerCase()) ||
                log.comments
                ?.toLowerCase()
                  .includes(event.target.value ?.toLowerCase())
        );
      });
      dispatch(setdrgLogicLogs(result));
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBackToEditor = (e) => {
    e.preventDefault();
    dispatch(clearBackToEditor());
    history.push("/editor");
  }

  return (
    <div className="changeLog-container">
      <Paper className="changeLog-section-container">
        <div className="header-layout">
          <Typography id="headerLabel" className="groupcode-title" gutterBottom>
            {t("ChangeLogs.ChangeLog")}:{" "}
            {selectedEditorYear +
              " - " +
              selectedEditorName +
              " - " +
              selectedEditorVersion}
          </Typography>
          <Button
            variant="contained"
            size="small"
            className="editor-backButton"
            onClick={handleBackToEditor}
          >
            {t("Editor.BackToEditor")}
          </Button>
        </div>
        <Divider />
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "0px 24px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="content tabs"
              >
                <Tab
                  id="tabInteractiveLabel"
                  value="drgLogic"
                  label={t("ChangeLogs.DRG_Logic")}
                />
                <Tab value="groupCode" label={t("ChangeLogs.groupCodes")} />
              </Tabs>
            </Box>
            <TabPanel sx={{ padding: "5px" }} value="drgLogic">
              <div className="table-container">
                <div className="table-section-container">
                  <div className="table-section-header-left">
                    <Typography
                      variant="h5"
                      className="changeLog-title"
                      gutterBottom
                      sx={{ margin: "0 !important" }}
                    >
                      {t("Global.drgLogicFiles")}
                    </Typography>
                    <Typography className="changeLog-sub-text" gutterBottom>
                      {drgLogicLogs.length} {t("Global.Total")}
                    </Typography>
                  </div>
                  <div className="table-section-header right">
                    <div className="right-container">
                      <TextField
                        variant="outlined"
                        placeholder={t("Global.search")}
                        onChange={requestSearch}
                        sx={{ width: "40%" }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon sx={{ color: "#00000040" }} />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <TableContainer className="table">
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table"
                  >
                    <TableHead className="tableHead">
                      <TableRow>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 600 }}
                        >
                          {t("ChangeLogs.Modified_On")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 400 }}
                        >
                          {t("ChangeLogs.Modified_By")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 200 }}
                        >
                          {t("ChangeLogs.Rule_No")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 300 }}
                        >
                          {t("ChangeLogs.Field_Name")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 400 }}
                        >
                          {t("ChangeLogs.Old_Value")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 400 }}
                        >
                          {t("ChangeLogs.New_Value")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 300 }}
                        >
                          {t("ChangeLogs.Comments")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {logsLoading ? (
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            <Box
                              sx={{ display: "flex", justifyContent: "end" }}
                            >
                              <CircularProgress />
                            </Box>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                    ) : (
                        <TableBody>
                          {drgLogicLogs
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((log) => {
                              return (
                                <TableRow>
                                  <TableCell className="tableCell">
                                    {log.modifiedOn}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log.modifiedBy}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log.ruleNo}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log.fieldName}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log.oldValue}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log.newValue}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log.comments}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={drgLogicLogs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage = {t("Global.row_per_page")}
                  />
                </TableContainer>
              </div>
            </TabPanel>
            <TabPanel sx={{ padding: "5px" }} value="groupCode">
              <div className="table-container">
                <div className="table-section-container">
                  <div className="table-section-header-left">
                    <Typography
                      variant="h5"
                      className="changeLog-title"
                      gutterBottom
                      sx={{ margin: "0 !important" }}
                    >
                      {t("ChangeLogs.groupCodes")}
                    </Typography>
                    <Typography className="changeLog-sub-text" gutterBottom>
                      {groupCodesLogs.length} {t("Global.Total")}
                    </Typography>
                  </div>
                  <div className="table-section-header right">
                    <div className="right-container">
                      <TextField
                        variant="outlined"
                        placeholder={t("Global.search")}
                        onChange={requestSearch}
                        sx={{ width: "40%" }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon sx={{ color: "#00000040" }} />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <TableContainer className="table">
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table"
                  >
                    <TableHead className="tableHead">
                      <TableRow>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 600 }}
                        >
                          {t("ChangeLogs.Modified_On")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 400 }}
                        >
                          {t("ChangeLogs.Modified_By")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 200 }}
                        >
                          {t("ChangeLogs.Master_Code")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 300 }}
                        >
                          {t("ChangeLogs.groupCodes")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 400 }}
                        >
                          {t("ChangeLogs.Old_Value")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 400 }}
                        >
                          {t("ChangeLogs.New_Value")}
                        </TableCell>
                        <TableCell
                          className="tableCell head"
                          sx={{ width: 300 }}
                        >
                          {t("ChangeLogs.Comments")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {logsLoading ? (
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            <Box
                              sx={{ display: "flex", justifyContent: "end" }}
                            >
                              <CircularProgress />
                            </Box>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                    ) : (
                        <TableBody>
                          {groupCodesLogs
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((log) => {
                              return (
                                <TableRow>
                                  <TableCell className="tableCell">
                                    {log.modifiedOn}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log.modifiedBy}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log ?.masterGroupCode}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log ?.groupCode}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log ?.oldValue}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log ?.newValue}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {log ?.comments}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={groupCodesLogs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </div>
  );
}
