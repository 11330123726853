import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../../app/store";

export const getAllVisual = createAsyncThunk(
  "viewer/getAllVisual",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/GetAllVisual", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getVisualMenus = createAsyncThunk(
  "viewer/getVisualMenus",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetVisualMenus?visualId=" + obj.id;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getZipFile = createAsyncThunk(
  "viewer/getZipFile",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch(obj.url, reqOptions).then((response) => {
      if (response.status === 401) {
        dispatch(endUserSession());
      } else {
        return response.blob();
      }
    });
  }
);

export const getVisualDatabyID = createAsyncThunk(
  "viewer/getVisualDatabyID",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetVisualDatabyId?id=" + obj.id;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getMetadata = createAsyncThunk(
  "viewer/getMetadata",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetMetadata?visualname=" +
      obj.name +
      "&visualversion=" +
      obj.version +
      "&year=" +
      obj.year;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getNodesJSON = createAsyncThunk(
  "viewer/getNodesJSON",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    if (
      obj.specification === "" ||
      obj.specification === "Full Logic" ||
      obj.specification === "Komplet logik" ||
      obj.specification === undefined
    ) {
      let url =
        "api/GetViewerData/?_logicName=" +
        obj.logicName +
        "&_logicVersion=" +
        obj.logicVersion +
        "&_year=" +
        obj.year +
        "&pricename=" +
        obj.priceName +
        "&priceversion=" +
        obj.priceVersion;
      return await fetch(url, reqOptions).then((res) => {
        if (res.status === 401) {
          dispatch(endUserSession());
        } else {
          return res.json();
        }
      });
    } else {
      return await fetch(
        "api/GetViewerData/?_logicName=" +
        obj.logicName +
        "&_logicVersion=" +
        obj.logicVersion +
        "&_year=" +
        obj.year +
        "&_folder=" +
        obj.specification +
        "&_mdc=" +
        obj.mdc +
        "&_viewtype=" +
        obj.viewType +
        "&pricename=" +
        obj.priceName +
        "&priceversion=" +
        obj.priceVersion
      ).then((res) => {
        if (res.status === 401) {
          dispatch(endUserSession());
        } else {
          return res.json();
        }
      });
    }
  }
);

export const getDRGPriceJSON = createAsyncThunk(
  "viewer/getDRGPriceJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "api/GetDRGPriceData/?_drg=" +
      state.viewer.subCode +
      "&_price=" +
      metadataColl.DRGPriceName +
      "&_priceversion=" +
      metadataColl.DRGPriceVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getMetadataForLogicName = createAsyncThunk(
  "viewer/getMetadataForLogicName",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "api/GetMetadataforLogicName?logicname=" +
      metadataColl.DRGLogicName +
      "&logicversion=" +
      metadataColl.DRGLogicVersion +
      "&year=" +
      state.viewer.selectedVisualYear;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGPopUpJSON = createAsyncThunk(
  "viewer/getDRGPopUpJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "api/GetDRGPopupData/?_drg=" +
      state.viewer.subCode +
      "&_drgLogicId=" +
      state.viewer.drgLogicID +
      "&egenskabname=" +
      state.viewer.egenskaberName +
      "&egenskabversion=" +
      state.viewer.egenskaberVersion +
      "&mdcname=" +
      state.viewer.mdcname +
      "&mdcversion=" +
      state.viewer.mdcversion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getMDCGroupCodeJSON = createAsyncThunk(
  "viewer/getMDCGroupCodeJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = "GET";
    let url =
      "api/GetMDCCodesWithDescription?groupCode=" +
      state.viewer.groupCode +
      " &mdc=" +
      state.viewer.subCode +
      "&drgLogicId=" +
      state.viewer.drgLogicID +
      "&sksname=" +
      state.viewer.sksName +
      "&sksversion=" +
      state.viewer.sksVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getGroupCodeJSON = createAsyncThunk(
  "viewer/getGroupCodeJSON",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = "GET";
    const subList = state.viewer.subCode;
    let url =
      "api/GetCodesWithDescription?groupCode=" +
      encodeURIComponent(state.viewer.groupCode) +
      " &groupCodeSubList=" +
      encodeURIComponent(subList) +
      "&drgLogicId=" +
      state.viewer.drgLogicID +
      "&sksname=" +
      state.viewer.sksName +
      "&sksversion=" +
      state.viewer.sksVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getshowTariff = createAsyncThunk(
  "viewer/getshowTariff",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url = "api/GetShowTariffByVisualId/?visualId=" + obj.visualID;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGTypeSpecifications = createAsyncThunk(
  "viewer/getDRGTypeSpecifications",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetDRGTypeSpecifications?logicid=" +
      state.viewer.drgLogicID +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGTypeSpecificationsSearch = createAsyncThunk(
  "viewer/getDRGTypeSpecificationsSearch",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetDRGTypeSpecificationsSearch?logicid=" +
      state.viewer.drgLogicID +
      "&tabType=" +
      "specification" +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&search=" +
      obj.search;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getDRGDiagramData = createAsyncThunk(
  "viewer/getDRGDiagramData",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetDRGDiagramData?filtervalue=" +
      obj.filter +
      "&rulenumber=" +
      obj.regel +
      "&column=" +
      obj.column +
      "&_logicName=" +
      metadataColl.DRGLogicName +
      "&_logicVersion=" +
      metadataColl.DRGLogicVersion +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&strYear=" +
      state.viewer.selectedVisualYear;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getAllParentSKS = createAsyncThunk(
  "viewer/getAllParentSKS",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let parentId = obj.parentId ? obj.parentId : "";
    let url =
      "api/GetAllParentSKS?parentid=" +
      parentId +
      "&sksname=" +
      state.viewer.sksName +
      "&sksversion=" +
      state.viewer.sksVersion;

    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getSKSSearch = createAsyncThunk(
  "viewer/getSKSSearch",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "api/GetSKSSearch?sksName=" +
      state.viewer.sksName +
      "&sksVer=" +
      state.viewer.sksVersion +
      "&search=" +
      obj.search;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getSKSTable = createAsyncThunk(
  "viewer/getSKSTable",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;

    let url =
      "api/Getsksmap?skscode=" +
      obj.code +
      "&logicid=" +
      state.viewer.drgLogicID +
      "&logicname=" +
      metadataColl.DRGLogicName +
      "&logicversion=" +
      metadataColl.DRGLogicVersion +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&year=" +
      state.viewer.selectedVisualYear +
      "&interactivename=" +
      metadataColl.InteractiveName +
      "&interactiveversion=" +
      metadataColl.InteractiveVersion;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getSKSCodeDiagramData = createAsyncThunk(
  "viewer/getSKSCodeDiagramData",
  async (obj, { dispatch, getState }) => {
    const state = getState();
    let metadataColl = state.viewer.metadataCollection;
    let reqOptions = {};
    reqOptions.method = obj.method;
    let url =
      "/api/GetSKSCodeDiagramData?filtervalue=" +
      obj.drgGroup +
      "&rulenumber=" +
      obj.regel +
      "&_logicName=" +
      metadataColl.DRGLogicName +
      "&_logicVersion=" +
      metadataColl.DRGLogicVersion +
      "&pricename=" +
      metadataColl.DRGPriceName +
      "&priceversion=" +
      metadataColl.DRGPriceVersion +
      "&strYear=" +
      state.viewer.selectedVisualYear;
    return fetch(url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

const initialState = {
  visualCollection: [],
  yearsCollection: [],
  status: null,
  enableTabs: false,
  tabValue: "viewerMap",
  selectedVisualObj: {},
  selectedVisualID: "",
  selectedVisualName: "",
  selectedVisualYear: "",
  visualMenuCollection: [],
  selectedSpecification: null,
  blobToDownload: null,
  selectedFileType: "",
  fileNotExists: false,
  view: "SimpleView",
  metadataCollection: null,
  isLoading: false,
  dataForGoJs: null,
  isNodeData: false,
  groupCode: "",
  groupCodeValue: "",
  subCode: "",
  groupcodeArray: [],
  priceArray: [],
  drgLogicID: "",
  egenskaberName: "",
  egenskaberVersion: "",
  sksName: "",
  sksVersion: "",
  sCode: "",
  dataForModalDialog: [],
  modalActive: true,
  isShowTariff: false,
  menuItems: [],
  drgTypeTree: [],
  drgSpecificationsTree: [],
  drgSearchTree: [],
  drgSelectedName: "",
  drgLoading: true,
  drgDataLoading: false,
  drgGroupCodeCollection: [],
  drgPriceInfoCollection: [],
  searchValue: "",
  regelValue: "",
  showDRGDiagram: false,
  showRegelDiagram: false,
  isDrgData: false,
  drgDataForGoJs: null,
  sksLoading: true,
  sksParentCollection: null,
  hasChildren: false,
  childrenCount: -1,
  sksChildrenCollection: null,
  isDRGSearch: false,
  sksSelectedId: "",
  sksSelectedName: "",
  sksMapData: null,
  sksSearchTree: [],
  isSKSSearch: false,
  showSKSTable: false,
  isSessionValid: true,
  isShowDRG: false,
  isHourGlass: false,
  isExpandedValue: "",
  page: 0,
  rowsPerPage: 5,
  mdcname: "",
  mdcversion: ""
};

const viewerSlice = createSlice({
  name: "viewer",
  initialState,
  reducers: {
    endUserSession(state, action) {
      state.isSessionValid = false;
    },
    setSelectedVisual(state, action) {
      state.selectedVisualObj = action.payload;
      //state.enableTabs = false;
    },
    setTabValue(state, action) {
      state.tabValue = action.payload;
      state.searchValue = "";
      state.regelValue = "";
      state.selectedSpecification = null;
      state.view = "SimpleView";
      state.metadataCollection = null;
      state.isLoading = true;
      state.showSKSTable = false;
      state.isShowDRG = false;
      state.isDRGSearch = false;
    },
    setVisualDetails(state, action) {
      state.selectedVisualID = action.payload.id;
      state.selectedVisualName = action.payload.name;
      state.selectedVisualYear = action.payload.year;
      state.enableTabs = true;
    },
    setSelectedSpecification(state, action) {
      if (state.tabValue == "drg" || state.tabValue == "skscodes") {
        state.selectedSpecification = action.payload;
      } else {
        state.selectedSpecification = action.payload;
        if (!("language" in state.selectedSpecification)) {
          state.isLoading = true;
        }
      }
    },
    setSelectedFileToDownload(state, action) {
      state.selectedFileType = action.payload;
    },
    clearValues(state, action) {
      state.selectedFileType = null;
      state.blobToDownload = null;
    },
    errorMessageClose(state, action) {
      state.fileNotExists = false;
    },
    setView(state, action) {
      state.view = action.payload;
    },
    testCall(state, action) {
      state.status = "";
    },
    saveCodeValues(state, { payload }) {
      state.groupCode = payload.groupCode.trim();
      state.groupCodeValue = payload.groupCodeValue;
      state.subCode = payload.subCode.trim();
    },
    setModalActive(state, { payload }) {
      state.modalActive = payload;
    },
    setDRGSelected(state, { payload }) {
      state.subCode = payload.code;
      state.drgSelectedName = payload.name;
      state.selectedSpecification = null;
      state.showDRGDiagram = false;
      state.isShowDRG = true;
    },
    setDRGLoading(state, { payload }) {
      if (payload.name == "drgLoading") {
        state.drgLoading = payload.value;
      } else if (payload.name == "drgDataLoading") {
        state.drgDataLoading = payload.value;
      } else if (payload.name == "showDRGDiagram") {
        state.showDRGDiagram = payload.value;
      } else if (payload.name == "sksLoading") {
        state.sksLoading = payload.value;
      } else if (payload.name == "showRegelDiagram") {
        state.showRegelDiagram = payload.value;
        state.isExpandedValue = payload.expandedValue;
      } else if (payload.name == "drgSearch") {
        state.isDRGSearch = payload.value;
      } else if (payload.name == "sksSearch") {
        state.isSKSSearch = payload.value;
      } else if (payload.name == "showSKSTable") {
        state.showSKSTable = payload.value;
      }
    },
    setDRGDiagramValues(state, { payload }) {
      state.view = payload.view;
      state.searchValue = payload.search;
      state.regelValue = payload.regel;
      state.showDRGDiagram = true;
    },
    setChildrenNode(state, { payload }) {
      state.hasChildren = payload;
    },
    setSKSSelected(state, { payload }) {
      state.sksSelectedId = payload.id
      state.sksSelectedName = payload.name;
    },
    setResetValues(state, action) {
      state.searchValue = "";
      state.regelValue = "";
    },
    resetSKS(state, action) {
      state.isSKSSearch = false;
      state.showSKSTable = false;
      state.showRegelDiagram = false;
    },
    setPagination(state, action) {
      state.page = parseInt(action.payload ?.page);
      state.rowsPerPage = parseInt(action.payload ?.rowsPerPage);
    },
    resetViewer: () => initialState,
  },
  extraReducers: {
    [getAllVisual.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllVisual.fulfilled]: (state, { payload }) => {
      state.visualCollection = payload;
      state.yearsCollection = payload
        .map((item) => item.year)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((one, two) => (one > two ? -1 : 1));

      state.status = "success";
    },
    [getAllVisual.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getAllVisual", action.error.message);
    },
    [getVisualMenus.pending]: (state, action) => {
      state.status = "loading";
    },
    [getVisualMenus.fulfilled]: (state, { payload }) => {
      if (payload != null && payload.length > 0) {
        const language = localStorage.getItem("language");
        state.menuItems = payload;
        var tempArray = [
          {
            displayName: language == "en" ? "Full Logic" : "Komplet logik",
            groupName: language == "en" ? "Full Logic" : "Komplet logik",
            name: "Full Logic",
            value: language == "en" ? "Full Logic" : "Komplet logik",
          },
        ];
        for (let i = 0; i < payload.length; i++) {
          for (let j = 0; j < payload[i].value.length; j++) {
            tempArray.push({
              displayName: payload[i].value[j].name + " " + payload[i].value[j].value,
              groupName: language == "en" ? payload[i].name : i == 0 ? "MDC Specifikationer" : i == 1 ? "Øvrige Specifikationer" : "Type",
              name: payload[i].value[j].name,
              value: payload[i].value[j].value,
            });
          }
        }
        state.visualMenuCollection = tempArray;
      } else {
        state.visualMenuCollection = [];
      }
    },
    [getVisualMenus.rejected]: (state, action) => {
      state.visualMenuCollection = [];
      state.status = "failed";
      console.log("Error from getVisualMenus", action.error.message);
    },
    [getZipFile.pending]: (state, action) => {
      state.status = "loading";
    },
    [getZipFile.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getZipFile:", payload);
      if (payload.type == "application/json") {
        state.fileNotExists = true;
      } else {
        state.blobToDownload = payload;
      }
    },
    [getZipFile.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getZipFile", action.error.message);
    },
    [getMetadata.pending]: (state, action) => {
      state.status = "loading";
      state.isLoading = true;
    },
    [getMetadata.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getMetadata:", payload);
      state.metadataCollection = payload;
      state.isLoading = false;
    },
    [getMetadata.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getMetadata", action.error.message);
    },
    [getNodesJSON.pending]: (state, action) => {
      state.status = "loading";
      state.isNodeData = false;
    },
    [getNodesJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getNodesJSON:", payload);
      state.dataForGoJs = payload;
      state.isNodeData = true;
    },
    [getNodesJSON.rejected]: (state, action) => {
      state.status = "failed";
      state.isNodeData = false;
      console.log("Error from getNodesJSON", action.error.message);
    },
    [getDRGPriceJSON.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGPriceJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGPriceJSON:", payload);
      var grpCodes = JSON.parse(payload);
      var count = 0;
      var priceArray = [];
      grpCodes.forEach((grp) => {
        let g = { code: grp.Code, desc: grp.Text };
        priceArray.push(g);
      });
      //console.log("priceArray:", priceArray);
      state.priceArray = priceArray;
      if (state.tabValue == "drg") {
        state.drgPriceInfoCollection = priceArray;
      }
      //store.dispatch(getMetadataForLogicName());
    },
    [getDRGPriceJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getDRGPriceJSON", action.error.message);
    },
    [getMetadataForLogicName.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMetadataForLogicName.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getMetadataForLogicName:", payload);
      state.drgLogicID = payload.id;
      state.egenskaberName = payload.egenskaberMaster.name;
      state.egenskaberVersion = payload.egenskaberMaster.version;
      state.sksName = payload.sksMaster.name;
      state.sksVersion = payload.sksMaster.version;
      state.mdcname = payload.mdcspecificationMaster.name;
      state.mdcversion = payload.mdcspecificationMaster.version;
      if (state.subCode.startsWith("12")) {
        state.sCode = state.subCode.replace("12", "98");
      } else if (state.subCode.startsWith("13")) {
        state.sCode = state.subCode.replace("13", "98");
      }
    },
    [getMetadataForLogicName.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getMetadataForLogicName", action.error.message);
    },
    [getDRGPopUpJSON.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGPopUpJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGPopUpJSON:", payload);

      var grpCodes = JSON.parse(payload);
      var groupcodeArray = [];
      grpCodes.forEach((grp) => {
        var links = grp.Text.split(" / ");
        let g = { code: grp.Code, desc: grp.Text };
        groupcodeArray.push(g);
      });
      state.groupcodeArray = groupcodeArray;
      if (state.tabValue == "drg") {
        state.drgGroupCodeCollection = groupcodeArray;
      }
      var strData = "";
      if (state.groupCodeValue.trim().startsWith("-")) {
        strData = state.groupCodeValue.trim().substr(1);
      } else {
        strData = state.groupCodeValue.trim();
      }
      const dataForModalDialog = {
        groupCodeHeader: state.subCode,
        groupCodeValue: strData.replace(state.subCode, ""),
        groupCodeInfo: state.groupcodeArray,
        priceInfo: state.priceArray,
        visualid: state.selectedVisualID,
        year: state.selectedVisualYear,
      };
      state.dataForModalDialog = dataForModalDialog;

      //console.log("dataForModalDialog:", state.dataForModalDialog);
    },
    [getDRGPopUpJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getDRGPopUpJSON", action.error.message);
    },
    [getMDCGroupCodeJSON.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMDCGroupCodeJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getMDCGroupCodeJSON:", payload);
      var grpCodes = JSON.parse(payload);
      var groupcodeArray = [];
      grpCodes.forEach((grp) => {
        let g = { code: grp.Code, desc: grp.Text };
        groupcodeArray.push(g);
      });
      state.groupcodeArray = groupcodeArray;
      var strData = "";
      if (state.groupCodeValue.trim().startsWith("-")) {
        strData = state.groupCodeValue.trim().substr(1);
      } else {
        strData = state.groupCodeValue.trim();
      }
      const dataForModalDialog = {
        groupCodeHeader: state.subCode,
        groupCodeValue: strData.replace(state.subCode, ""),
        groupCodeInfo: state.groupcodeArray,
      };
      state.dataForModalDialog = dataForModalDialog;
      //console.log("dataForModalDialog:", state.dataForModalDialog);
    },
    [getMDCGroupCodeJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getMDCGroupCodeJSON", action.error.message);
    },
    [getGroupCodeJSON.pending]: (state, action) => {
      state.status = "loading";
      state.isHourGlass = true;
    },
    [getGroupCodeJSON.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getGroupCodeJSON:", payload);
      state.isHourGlass = false;
      var groupcodeArray = [];
      if (state.sCode != "") {
        if (payload != null && payload != [] && payload != "[]") {
          var grpCodes = JSON.parse(payload);
          grpCodes.forEach((grp) => {
            let g = { code: grp.Code, desc: grp.Text };
            groupcodeArray.push(g);
          });
        }
      } else {
        var grpCodes = JSON.parse(payload);
        grpCodes.forEach((grp) => {
          let g = { code: grp.Code, desc: grp.Text };
          groupcodeArray.push(g);
        });
      }
      state.groupcodeArray = groupcodeArray;
      var strData = "";
      if (state.groupCodeValue.trim().startsWith("-")) {
        strData = state.groupCodeValue.trim().substr(1);
      } else {
        strData = state.groupCodeValue.trim();
      }
      const dataForModalDialog = {
        groupCodeHeader: state.subCode,
        groupCodeValue: strData.replace(state.subCode, ""),
        groupCodeInfo: state.groupcodeArray,
      };
      state.dataForModalDialog = dataForModalDialog;
      //console.log("dataForModalDialog:", state.dataForModalDialog);
    },
    [getGroupCodeJSON.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getGroupCodeJSON", action.error.message);
    },
    [getshowTariff.pending]: (state, action) => {
      state.status = "loading";
    },
    [getshowTariff.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getshowTariff:", payload);
      if (payload) {
        state.isShowTariff = payload;
      }
    },
    [getshowTariff.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getshowTariff", action.error.message);
    },
    [getDRGTypeSpecifications.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGTypeSpecifications.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGTypeSpecifications:", payload);
      if (payload) {
        state.drgTypeTree = payload.typeTree;
        state.drgSpecificationsTree = payload.specificationTree;
      }
    },
    [getDRGTypeSpecifications.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getDRGTypeSpecifications", action.error.message);
    },
    [getDRGTypeSpecificationsSearch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDRGTypeSpecificationsSearch.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGTypeSpecificationsSearch:", payload);
      if (payload) {
        state.drgSearchTree = payload.specificationTree;
      }
    },
    [getDRGTypeSpecificationsSearch.rejected]: (state, action) => {
      state.status = "failed";
      console.log(
        "Error from getDRGTypeSpecificationsSearch",
        action.error.message
      );
    },
    [getDRGDiagramData.pending]: (state, action) => {
      state.status = "loading";
      state.isDrgData = false;
    },
    [getDRGDiagramData.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getDRGDiagramData:", payload);
      state.drgDataForGoJs = payload;
      state.isDrgData = true;
    },
    [getDRGDiagramData.rejected]: (state, action) => {
      state.status = "failed";
      state.isDrgData = false;
      console.log("Error from getDRGDiagramData", action.error.message);
    },
    [getAllParentSKS.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllParentSKS.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getAllParentSKS:", payload);
      if (!state.hasChildren) {
        state.sksParentCollection = payload;
      } else {
        state.sksChildrenCollection = payload;
      }
    },
    [getAllParentSKS.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getAllParentSKS", action.error.message);
    },
    [getSKSSearch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSKSSearch.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getSKSSearch:", payload);
      if (payload) {
        state.sksSearchTree = payload;
      }
    },
    [getSKSSearch.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getSKSSearch", action.error.message);
    },
    [getSKSTable.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSKSTable.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getSKSTable:", payload);
      if (payload) {
        state.sksMapData = payload;
      }
    },
    [getSKSTable.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getSKSTable", action.error.message);
    },
    [getSKSCodeDiagramData.pending]: (state, action) => {
      state.status = "loading";
      state.isDrgData = false;
    },
    [getSKSCodeDiagramData.fulfilled]: (state, { payload }) => {
      //console.log("Payload from getSKSCodeDiagramData:", payload);
      state.drgDataForGoJs = payload;
      // let tempArray = [];
      // tempArray = payload;
      // if (state.drgDataForGoJs != null) {
      //   state.drgDataForGoJs.push(tempArray);
      // } else {
      //   state.drgDataForGoJs = payload; 
      // }
      state.isDrgData = true;
    },
    [getSKSCodeDiagramData.rejected]: (state, action) => {
      state.status = "failed";
      state.isDrgData = false;
      console.log("Error from getSKSCodeDiagramData", action.error.message);
    },
  },
});

export const {
  setSelectedVisual,
  setTabValue,
  setVisualDetails,
  setSelectedSpecification,
  setSelectedFileToDownload,
  clearValues,
  errorMessageClose,
  setView,
  testCall,
  saveCodeValues,
  setModalActive,
  setDRGSelected,
  setDRGLoading,
  setDRGDiagramValues,
  setChildrenNode,
  setSKSSelected,
  endUserSession,
  setResetValues,
  resetSKS,
  resetViewer,
  setPagination,
} = viewerSlice.actions;
export default viewerSlice.reducer;
