import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getDrgLogics = createAsyncThunk(
  "publisher/getDrgLogics",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getAllGrouperData = createAsyncThunk(
  "publisher/getAllGrouperData",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/GetPublishGrouper", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getAllInteractiveData = createAsyncThunk(
  "publisher/getAllInteractiveData",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/GetPublishInteractive", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getAllVisualData = createAsyncThunk(
  "publisher/getAllVisualData",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("api/GetPublishVisual", reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const validatePublishAction = createAsyncThunk(
  "publisher/validatePublishAction",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch(obj.apiUrl, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const publishDRG = createAsyncThunk(
  "publisher/publishDRG",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.body = JSON.stringify(obj.body);
    reqOptions.method = obj.method;
    return await fetch("api/Editor/PublishUnpublish", reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null && payload == true) {
          dispatch(setPublishSuccess());
          dispatch(setActionFlag(false));
          dispatch(getDrgLogics({ url: obj.apiUrl, method: "GET" }));
        } else if (payload != null && payload == false) {
          dispatch(setPublishFailure());
        }
      });
  }
);

export const publishOtherCollection = createAsyncThunk(
  "publisher/publishOtherCollection",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    const token = localStorage.getItem("token");
    let headers = new Headers({
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
    });
    headers.set("Access-Control-Allow-Origin", "*");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    reqOptions.headers = headers;
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions)
      .then((r) => {
        if (r.status === 401) {
          dispatch(endUserSession());
        } else {
          return r.json();
        }
      })
      .then((payload) => {
        if (payload != null && payload == true) {
          dispatch(setPublishSuccess());
          dispatch(setActionFlag(false));
          switch (obj.logicType) {
            case "2":
              dispatch(getAllGrouperData({ method: "GET" }));
              break;
            case "3":
              dispatch(getAllInteractiveData({ method: "GET" }));
              break;
            case "4":
              dispatch(getAllVisualData({ method: "GET" }));
              break;
          }
        } else if (payload != null && payload == false) {
          dispatch(setPublishFailure());
        }
      });
  }
);

export const getDRGContent = createAsyncThunk(
  "publisher/getDRGContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getGrouperContent = createAsyncThunk(
  "publisher/getGrouperContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getInteractiveContent = createAsyncThunk(
  "publisher/getInteractiveContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

export const getVisualContent = createAsyncThunk(
  "publisher/getVisualContent",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch(obj.url, reqOptions).then((r) => {
      if (r.status === 401) {
        dispatch(endUserSession());
      } else {
        return r.json();
      }
    });
  }
);

const initialState = {
  masterDrgData: [],
  masterGrouperData: [],
  masterInteractiveData: [],
  masterVisualData: [],
  logicValue: "1",
  yearValue: "",
  prevSelectedYearValue: "",
  prevSelectedFileName: "",
  showTariff: false,
  uniqueYears: [],
  fileNameValue: "1",
  selectedFile: {},
  fileNames: [],
  publishValue: "",
  disablePublish: false,
  disableUnPublish: false,
  users: [],
  rows: [],
  drgStatus: null,
  actionFlag: false,
  publishSuccess: false,
  publishFailed: false,
  actionAlert: false,
  isSessionValid: true,
  isLoading: false,
  destEnvironment: "",
  noUploadContent: false,
  noDRGContent: false,
  noGrouperContent: false,
  noInteractiveContent: false,
  noViewerContent: false,
  alreadyPublished: {},
};

const publisherSlice = createSlice({
  name: "publisher",
  initialState,
  reducers: {
    endUserSession(state, action) {
      state.isSessionValid = false;
    },
    setLogicValue(state, action) {
      state.logicValue = action.payload;
    },
    setActionFlag(state, action) {
      state.actionFlag = action.payload;
    },
    setShowTariff(state, action) {
      state.showTariff = action.payload;
    },
    setAlreadyPublished(state, action) {
      state.alreadyPublished = action.payload;
    },
    handleYearValueChange(state, action) {
      const { logicValue, masterData, value } = action.payload;
      state.yearValue = value;
      if (logicValue == "1") {
        var filterData = masterData.filter((a) => a.year == value);
        state.fileNames = filterData;
        state.fileNameValue = filterData[0].id;
        state.selectedFile = filterData[0];
        if (filterData[0].isPublished == true) {
          state.publishValue = "1";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else if (filterData[0].isPreProd == true) {
          state.publishValue = "2";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else if (filterData[0].isTest == true) {
          state.publishValue = "3";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else {
          state.publishValue = "";
          state.disablePublish = false;
          state.disableUnPublish = true;
        }
      } else {
        var filterData = masterData.filter((a) => a.year == value);
        if (filterData.length > 0) {
          state.fileNameValue = filterData[0].id;
          state.selectedFile = filterData[0];
          state.showTariff = filterData[0].showTariff;
          if (filterData[0].status !== 0) {
            state.publishValue = filterData[0].status;
            state.disablePublish = true;
            state.disableUnPublish = false;
          } else {
            state.publishValue = "";
            state.disablePublish = false;
            state.disableUnPublish = true;
          }
          state.fileNames = [];
          var tempArray = [];
          if (logicValue == "2") {
            for (let i = 0; i < filterData.length; i++) {
              tempArray.push({
                id: filterData[i].id,
                name: filterData[i].grouperName,
                version: filterData[i].grouperVersion,
                status: filterData[i].status,
                showTariff: filterData[i].showTariff,
              });
            }
            state.fileNames = tempArray;
          } else if (logicValue == "3") {
            for (let i = 0; i < filterData.length; i++) {
              tempArray.push({
                id: filterData[i].id,
                name: filterData[i].interactiveName,
                version: filterData[i].interactiveVersion,
                status: filterData[i].status,
                showTariff: filterData[i].showTariff,
              });
            }
            state.fileNames = tempArray;
          } else if (logicValue == "4") {
            for (let i = 0; i < filterData.length; i++) {
              tempArray.push({
                id: filterData[i].id,
                name: filterData[i].visualName,
                version: filterData[i].visualVersion,
                status: filterData[i].status,
                showTariff: filterData[i].showTariff,
              });
            }
            state.fileNames = tempArray;
          }
        } else {
          state.fileNameValue = "";
          state.fileNames = [];
          state.publishValue = "";
        }
      }
    },
    handleFileNameUpdates(state, action) {
      const { logicValue, fileNames, value } = action.payload;
      state.fileNameValue = value;
      var filterData = fileNames.filter((a) => a.id == value);
      state.selectedFile = filterData[0];
      if (logicValue == "1") {
        if (filterData[0].isPublished == true) {
          state.publishValue = "1";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else if (filterData[0].isPreProd == true) {
          state.publishValue = "2";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else if (filterData[0].isTest == true) {
          state.publishValue = "3";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else {
          state.publishValue = "";
          state.disablePublish = false;
          state.disableUnPublish = true;
        }
      } else {
        state.showTariff = filterData[0].showTariff;
        if (filterData[0].status !== 0) {
          state.publishValue = filterData[0].status;
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else {
          state.publishValue = "";
          state.disablePublish = false;
          state.disableUnPublish = true;
        }
      }
    },
    handleActionChange(state, action) {
      state.publishValue = action.payload;
      switch (state.publishValue) {
        case "1":
          state.destEnvironment = "Production";
          break;
        case "2":
          state.destEnvironment = "Hearing";
          break;
        case "3":
          state.destEnvironment = "Test";
          break;
      }
      state.showTariff = false;
      if (state.logicValue != "1" && state.logicValue != "") {
        if (state.publishValue == state.selectedFile.status.toString()) {
          state.showTariff = state.selectedFile.showTariff;
          state.disableUnPublish = false;
          state.disablePublish = true;
          state.actionFlag = false;
        } else {
          state.showTariff = state.selectedFile.showTariff;
          state.disableUnPublish = true;
          state.disablePublish = false;
          state.actionFlag = true;
        }
      } else if (state.logicValue == "1" && state.logicValue != "") {
        if (state.publishValue == 1 && state.selectedFile.isPublished == true) {
          state.disablePublish = true;
          state.disableUnPublish = false;
          state.actionFlag = false;
        } else if (
          state.publishValue == 2 &&
          state.selectedFile.isPreProd == true
        ) {
          state.disablePublish = true;
          state.disableUnPublish = false;
          state.actionFlag = false;
        } else if (
          state.publishValue == 3 &&
          state.selectedFile.isTest == true
        ) {
          state.disablePublish = true;
          state.disableUnPublish = false;
          state.actionFlag = false;
        } else {
          state.disablePublish = false;
          state.disableUnPublish = true;
          state.actionFlag = true;
        }
      }
    },
    handleClose(state, action) {
      state.publishSuccess = false;
      state.publishFailed = false;
      state.actionAlert = false;
    },
    setActionAlert(state, { payload }) {
      state.actionAlert = payload;
    },
    setPublishSuccess(state, action) {
      state.prevSelectedFileName = state.fileNameValue;
      state.prevSelectedYearValue = state.yearValue;
      state.publishSuccess = true;
    },
    setPublishFailure(state, action) {
      state.publishFailed = true;
    },
    closeUploadContent(state, action) {
      state.noUploadContent = false;
      state.noDRGContent = false;
      state.noGrouperContent = false;
      state.noInteractiveContent = false;
      state.noViewerContent = false;
    },
    resetPublisher: () => initialState,
  },
  extraReducers: {
    [getDrgLogics.pending]: (state, action) => {
      state.drgStatus = "loading";
      state.isLoading = true;
    },
    [getDrgLogics.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (Array.isArray(payload) && payload.length > 0) {
        state.masterDrgData = payload;
        var distinctYear = [];
        var selectedYear = "";
        distinctYear = payload
          .map((item) => item.year)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .sort()
          .reverse();
        let currentYear = new Date().getFullYear();
        let upcomingYear = currentYear + 1;
        let previousYear = currentYear - 1;
        if (distinctYear.includes(JSON.stringify(upcomingYear))) {
          selectedYear = JSON.stringify(upcomingYear);
        } else if (distinctYear.includes(JSON.stringify(currentYear))) {
          selectedYear = JSON.stringify(currentYear);
        } else if (distinctYear.includes(JSON.stringify(previousYear))) {
          selectedYear = JSON.stringify(previousYear);
        } else {
          selectedYear = distinctYear[distinctYear.length - 1];
        }
        state.yearValue =
          state.prevSelectedYearValue != ""
            ? state.prevSelectedYearValue
            : selectedYear;
        state.uniqueYears = distinctYear;
        var filterData = payload.filter((a) => a.year == state.yearValue);
        state.fileNames = filterData;
        state.fileNameValue =
          state.prevSelectedFileName != ""
            ? state.prevSelectedFileName
            : filterData[0].id;
        var status = payload.filter((a) => a.id == state.fileNameValue);
        state.selectedFile = status[0];
        if (status[0].isPublished == true) {
          state.publishValue = "1";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else if (status[0].isPreProd == true) {
          state.publishValue = "2";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else if (status[0].isTest == true) {
          state.publishValue = "3";
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else {
          state.publishValue = "";
          state.disablePublish = false;
          state.disableUnPublish = true;
        }
        state.drgStatus = "success";
        state.prevSelectedFileName = "";
        state.prevSelectedYearValue = "";
      } else {
        state.uniqueYears = [];
        state.fileNames = [];
      }
    },
    [getDrgLogics.rejected]: (state, action) => {
      state.drgStatus = "failed";
      state.isLoading = false;
      console.log("Error from getDrgLogics", action.error.message);
    },
    [getAllGrouperData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllGrouperData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (Array.isArray(payload) && payload.length > 0) {
        state.masterGrouperData = payload;
        var distinctYear = [];
        var selectedYear = "";
        distinctYear = payload
          .map((item) => item.year)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .sort()
          .reverse();
        let currentYear = new Date().getFullYear();
        let upcomingYear = currentYear + 1;
        let previousYear = currentYear - 1;
        if (distinctYear.includes(JSON.stringify(upcomingYear))) {
          selectedYear = JSON.stringify(upcomingYear);
        } else if (distinctYear.includes(JSON.stringify(currentYear))) {
          selectedYear = JSON.stringify(currentYear);
        } else if (distinctYear.includes(JSON.stringify(previousYear))) {
          selectedYear = JSON.stringify(previousYear);
        } else {
          selectedYear = distinctYear[distinctYear.length - 1];
        }
        state.yearValue =
          state.prevSelectedYearValue != ""
            ? state.prevSelectedYearValue
            : selectedYear;
        state.uniqueYears = distinctYear;
        var filterData = payload.filter((a) => a.year == state.yearValue);
        state.fileNameValue =
          state.prevSelectedFileName != ""
            ? state.prevSelectedFileName
            : filterData[0].id;
        var status = payload.filter((a) => a.id == state.fileNameValue);
        state.selectedFile = status[0];
        state.showTariff = status[0].showTariff;
        if (status[0].status !== 0) {
          state.publishValue = status[0].status;
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else {
          state.publishValue = "";
          state.disablePublish = false;
          state.disableUnPublish = true;
        }
        state.fileNames = [];
        var tempArray = [];
        for (let i = 0; i < filterData.length; i++) {
          tempArray.push({
            id: filterData[i].id,
            name: filterData[i].grouperName,
            version: filterData[i].grouperVersion,
            status: filterData[i].status,
            showTariff: filterData[i].showTariff,
          });
        }
        state.fileNames = tempArray;
        state.prevSelectedFileName = "";
        state.prevSelectedYearValue = "";
      } else {
        state.uniqueYears = [];
        state.fileNames = [];
      }
    },
    [getAllGrouperData.rejected]: (state, action) => {
      state.isFailed = true;
      state.isLoading = false;
      console.log("Error from getAllGrouperData", action.error.message);
    },
    [getAllInteractiveData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllInteractiveData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (Array.isArray(payload) && payload.length > 0) {
        state.masterInteractiveData = payload;
        var distinctYear = [];
        var selectedYear = "";
        distinctYear = payload
          .map((item) => item.year)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .sort()
          .reverse();
        let currentYear = new Date().getFullYear();
        let upcomingYear = currentYear + 1;
        let previousYear = currentYear - 1;
        if (distinctYear.includes(JSON.stringify(upcomingYear))) {
          selectedYear = JSON.stringify(upcomingYear);
        } else if (distinctYear.includes(JSON.stringify(currentYear))) {
          selectedYear = JSON.stringify(currentYear);
        } else if (distinctYear.includes(JSON.stringify(previousYear))) {
          selectedYear = JSON.stringify(previousYear);
        } else {
          selectedYear = distinctYear[distinctYear.length - 1];
        }
        state.yearValue =
          state.prevSelectedYearValue != ""
            ? state.prevSelectedYearValue
            : selectedYear;
        state.uniqueYears = distinctYear;
        var filterData = payload.filter((a) => a.year == state.yearValue);
        state.fileNameValue =
          state.prevSelectedFileName != ""
            ? state.prevSelectedFileName
            : filterData[0].id;
        var status = payload.filter((a) => a.id == state.fileNameValue);
        state.selectedFile = status[0];
        state.showTariff = status[0].showTariff;
        if (status[0].status !== 0) {
          state.publishValue = status[0].status;
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else {
          state.publishValue = "";
          state.disablePublish = false;
          state.disableUnPublish = true;
        }
        state.fileNames = [];
        var tempArray = [];
        for (let i = 0; i < filterData.length; i++) {
          tempArray.push({
            id: filterData[i].id,
            name: filterData[i].interactiveName,
            version: filterData[i].interactiveVersion,
            status: filterData[i].status,
            showTariff: filterData[i].showTariff,
          });
        }
        state.fileNames = tempArray;
        state.prevSelectedFileName = "";
        state.prevSelectedYearValue = "";
      } else {
        state.uniqueYears = [];
        state.fileNames = [];
      }
    },
    [getAllInteractiveData.rejected]: (state, action) => {
      state.isFailed = true;
      state.isLoading = false;
      console.log("Error from getAllInteractiveData", action.error.message);
    },
    [getAllVisualData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllVisualData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (Array.isArray(payload) && payload.length > 0) {
        state.masterVisualData = payload;
        var distinctYear = [];
        var selectedYear = "";
        distinctYear = payload
          .map((item) => item.year)
          .filter(
            (value, index, self) =>
              self.indexOf(value) === index && value != null
          )
          .sort()
          .reverse();
        console.log("distint year", distinctYear);
        let currentYear = new Date().getFullYear();
        let upcomingYear = currentYear + 1;
        let previousYear = currentYear - 1;
        if (distinctYear.includes(JSON.stringify(upcomingYear))) {
          selectedYear = JSON.stringify(upcomingYear);
        } else if (distinctYear.includes(JSON.stringify(currentYear))) {
          selectedYear = JSON.stringify(currentYear);
        } else if (distinctYear.includes(JSON.stringify(previousYear))) {
          selectedYear = JSON.stringify(previousYear);
        } else {
          selectedYear = distinctYear[distinctYear.length - 1];
        }
        state.yearValue =
          state.prevSelectedYearValue != ""
            ? state.prevSelectedYearValue
            : selectedYear;
        state.uniqueYears = distinctYear;
        var filterData = payload.filter((a) => a.year == state.yearValue);
        state.fileNameValue =
          state.prevSelectedFileName != ""
            ? state.prevSelectedFileName
            : filterData[0].id;
        var status = payload.filter((a) => a.id == state.fileNameValue);
        state.selectedFile = status[0];
        state.showTariff = status[0].showTariff;
        if (status[0].status !== 0) {
          state.publishValue = status[0].status;
          state.disablePublish = true;
          state.disableUnPublish = false;
        } else {
          state.publishValue = "";
          state.disablePublish = false;
          state.disableUnPublish = true;
        }
        state.fileNames = [];
        var tempArray = [];
        for (let i = 0; i < filterData.length; i++) {
          tempArray.push({
            id: filterData[i].id,
            name: filterData[i].visualName,
            version: filterData[i].visualVersion,
            status: filterData[i].status,
            showTariff: filterData[i].showTariff,
          });
        }
        state.fileNames = tempArray;
        state.prevSelectedFileName = "";
        state.prevSelectedYearValue = "";
      } else {
        state.uniqueYears = [];
        state.fileNames = [];
      }
    },
    [getAllVisualData.rejected]: (state, action) => {
      state.isFailed = true;
      state.isLoading = false;
      console.log("Error from getAllVisualData", action.error.message);
    },
    [validatePublishAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [validatePublishAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.alreadyPublished = payload;
    },
    [validatePublishAction.rejected]: (state, action) => {
      state.isFailed = true;
      state.isLoading = false;
      console.log("Error from validatePublish", action.error.message);
    },
    [publishDRG.pending]: (state, action) => {},
    [publishDRG.fulfilled]: (state, { payload }) => {
      // if (payload) {
      //   console.log("Payload from publish:", payload.status);
      // }
      // state.publishSuccess = true;
    },
    [publishDRG.rejected]: (state, action) => {
      console.log("Error from publishDRG", action.error.message);
    },
    [publishOtherCollection.pending]: (state, action) => {},
    [publishOtherCollection.fulfilled]: (state, action) => {},
    [publishOtherCollection.rejected]: (state, action) => {
      console.log("Error from publishOtherCollection", action.error.message);
    },
    [getDRGContent.pending]: (state, action) => {},
    [getDRGContent.fulfilled]: (state, { payload }) => {
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(
          /(<([^>]+)>)(&nbsp;)*/gi,
          ""
        );
        if (content.length == 1) {
          state.noUploadContent = true;
          state.noDRGContent = true;
        }
      } else {
        state.noUploadContent = true;
        state.noDRGContent = true;
      }
    },
    [getDRGContent.rejected]: (state, action) => {
      console.log("Error from getDRGContent", action.error.message);
    },
    [getGrouperContent.pending]: (state, action) => {},
    [getGrouperContent.fulfilled]: (state, { payload }) => {
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(
          /(<([^>]+)>)(&nbsp;)*/gi,
          ""
        );
        if (content.length == 1) {
          state.noUploadContent = true;
          state.noDRGContent = true;
        }
      } else {
        state.noUploadContent = true;
        state.noGrouperContent = true;
      }
    },
    [getGrouperContent.rejected]: (state, action) => {
      console.log("Error from getGrouperContent", action.error.message);
    },
    [getInteractiveContent.pending]: (state, action) => {},
    [getInteractiveContent.fulfilled]: (state, { payload }) => {
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(
          /(<([^>]+)>)(&nbsp;)*/gi,
          ""
        );
        if (content.length == 1) {
          state.noUploadContent = true;
          state.noDRGContent = true;
        }
      } else {
        state.noUploadContent = true;
        state.noInteractiveContent = true;
      }
    },
    [getInteractiveContent.rejected]: (state, action) => {
      console.log("Error from getInteractiveContent", action.error.message);
    },
    [getVisualContent.pending]: (state, action) => {},
    [getVisualContent.fulfilled]: (state, { payload }) => {
      if (payload.length > 0) {
        var content = payload[0].languageValue.replace(
          /(<([^>]+)>)(&nbsp;)*/gi,
          ""
        );
        if (content.length == 1) {
          state.noUploadContent = true;
          state.noDRGContent = true;
        }
      } else {
        state.noUploadContent = true;
        state.noViewerContent = true;
      }
    },
    [getVisualContent.rejected]: (state, action) => {
      console.log("Error from getVisualContent", action.error.message);
    },
  },
});
export const {
  setLogicValue,
  setActionFlag,
  handleYearValueChange,
  handleFileNameUpdates,
  handleActionChange,
  handleClose,
  setActionAlert,
  setPublishSuccess,
  setPublishFailure,
  endUserSession,
  resetPublishSuccess,
  setShowTariff,
  closeUploadContent,
  setAlreadyPublished,
  resetPublisher,
} = publisherSlice.actions;
export default publisherSlice.reducer;
