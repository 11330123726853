import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import "./Editor.css";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SearchIcon from "@mui/icons-material/Search";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import EditorSelectionTree from "./EditorSelectionTree";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CellEdit from "./CellEdit";
import CellEditContains from "./CellEditContains";
import { useForm, Controller } from "react-hook-form";
import SharedComponent from "./SharedComponent";
import {
  GetDRGLogicList,
  GetDRGLogic,
  CheckDrgLogicLockStatus,
  GetEditorName,
  GetLockedRows,
  GetPublishAndPreProd,
  setEditorDetails,
  DrgLogicLock,
  LockDrgRules,
  DeleteLockedRows,
  setMasterSelectedEditorData,
  setSelectedEditorData,
  setSelectedEditorRule,
  GetGroupCodes,
  setSelectedGroupCode,
  GEtSKSForLogic,
  setOnCellDoubleClick,
  setOpenCellEdit,
  setOpenCellEditContains,
  SaveDrgRule,
  SaveAsDrgRule,
  CheckDrgLogicExists,
  clearStatus,
  setMetaData,
  setSelectionModel,
  setIsPaste,
  setIsDuplicate,
  setIsDelete,
  setDrgLogicComments,
  setReadOnly,
  setSatusMessages,
  GetLockedRow,
  resetEditor,
  setUnSavedData,
  setSearchFilter
} from "./editorSlice";
import ClassificationCodes from "./ClassificationCodes";
import { redirectToLogin } from ".././authentication/authenticationSlice";

function escapeRegExp(value) {
  return value.toString().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 125,
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E0E0",
    color: " #000000",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontStyle: "normal",
      fontSize: "14px",
      fontStretch: "normal",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "#0D3062",
      },
    },
  },
}));

export default function Editor() {
  const { t } = useTranslation("language");
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, isViewerRole } = useSelector((state) => state.authentication);
  const editor = useSelector((state) => state.editor);
  const {
    isLoading,
    editorCollection,
    yearsCollection,
    selectedEditorObj,
    selectedEditorID,
    selectedEditorName,
    selectedEditorYear,
    selectedEditorVersion,
    masterSelectedEditorData,
    selectedEditorData,
    selectedLockedRows,
    lockBtnText,
    selectedEditorRule,
    selectedGroupCode,
    openCellEdit,
    openCellEditContains,
    DrgLogicComments,
    isPublished,
    isPreProd,
    isTest,
    isDrgLogicExists,
    selectionModel,
    isDelete,
    isDuplicate,
    isPaste,
    isSelectedRow,
    isClassificationCodesEnabled,
    isEditor,
    isViewer,
    metaData,
    selectedEditorPerson,
    isEditorEditable,
    selectedCellDetails,
    isUnSavedData,
    isSearchFilter,
  } = editor;
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const apiRef = useGridApiRef();
  const [openView, setOpenView] = useState(false);
  const [openEmptyLogic, setOpenEmptyLogic] = useState(false);
  const [cellDetails, setCellDetails] = useState({});
  const [saveAsLogic, setSaveAsLogic] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();
  const [anchorEl, setAnchorEl] = useState(null);
  const openBulkActions = Boolean(anchorEl);
  const [openWarning, setOpenWarning] = useState(false);
  const [pRow, setPRow] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [unSavedWarning, setUnSavedWarning] = useState(false);
  const [lastLocation, setLastLocation] = useState();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
  const [openLogicCall, setOpenLogicCall] = useState(false);
  const [handleRefreshCall, setHandleRefreshCall] = useState(false);

  useEffect(() => {
    if (selectedEditorID == "") {
      let url =
        "api/GetDRGLogicList?userId=" +
        user[0]["email"] +
        "&isAdmin=" +
        user[0].isAdmin;
      dispatch(GetDRGLogicList({ method: "GET", url: url })).then(() => {
        dispatch(GetDRGLogic({ method: "GET" })).then(() => {
          dispatch(CheckDrgLogicLockStatus({ method: "GET" }));
          dispatch(GetEditorName({ method: "GET" }));
          dispatch(GetLockedRows({ method: "GET" }));
          dispatch(GetPublishAndPreProd({ method: "GET" })).then(() => {
            dispatch(setReadOnly(user));
            dispatch(GEtSKSForLogic({ method: "GET" }));
          });
        });
      });
    } else {
      let url =
        "api/GetDRGLogicList?userId=" +
        user[0]["email"] +
        "&isAdmin=" +
        user[0].isAdmin;
      dispatch(GetDRGLogicList({ method: "GET", url: url })).then(() => {
        dispatch(GetDRGLogic({ method: "GET" })).then(() => {
          dispatch(CheckDrgLogicLockStatus({ method: "GET" }));
          dispatch(GetEditorName({ method: "GET" }));
          dispatch(GetLockedRows({ method: "GET" }));
          dispatch(GetPublishAndPreProd({ method: "GET" })).then(() => {
            dispatch(setReadOnly(user));
            dispatch(GEtSKSForLogic({ method: "GET" }));
          });
        });
      });
    }
    return () => {
    }
  }, []);

  useEffect(() => {
    if (isDrgLogicExists == false) {
      setSaveAsLogic(false);
      dispatch(clearStatus());
      reset();

      var date = new Date();
      let drgLogicLog = [];

      drgLogicLog.push({
        DRGLogicID: selectedEditorID,
        Name: metaData ?.Name,
        Version: metaData ?.version,
        Year: metaData ?.Year,
        ModifiedBy: user[0]["email"],
        ModifiedOn: date.toString(),
        LogicComments: [],
      });

      var data = {
        pdata: masterSelectedEditorData,
        drgLogicName: selectedEditorID,
        drgMetaData: metaData,
        drgLogicLog: drgLogicLog[0],
      };
      let url = "api/Editor/SaveAsDrgRule";
      dispatch(SaveAsDrgRule({ method: "POST", url: url, body: data }));
    }
  }, [isDrgLogicExists]);

  useEffect(() => {
    if (isEditorEditable == true) {
      if (selectedCellDetails.isEditable == true) {
        if (selectedCellDetails ?.logicType == "contains") {
          dispatch(setOpenCellEditContains(true));
        } else {
          dispatch(setOpenCellEdit(true));
        }
      }
    }
  }, [isEditorEditable]);

  const handleClose = () => {
    setOpen(false);
    setOpenView(false);
    setOpenEmptyLogic(false);
    setSaveAsLogic(false);
    setOpenWarning(false);
    reset();
    reset1();
    dispatch(clearStatus());
    setUnSavedWarning(false);
    setLastLocation();
    setOpenLogicCall(false);
    setHandleRefreshCall(false);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    if (selectedEditorObj) {
      dispatch(
        setEditorDetails({
          id: selectedEditorObj.id,
          name: selectedEditorObj.name,
          year: selectedEditorObj.year,
          version: selectedEditorObj.version,
        })
      );
      dispatch(GetDRGLogic({ method: "GET" })).then(() => {
        dispatch(CheckDrgLogicLockStatus({ method: "GET" }));
        dispatch(GetEditorName({ method: "GET" }));
        dispatch(GetLockedRows({ method: "GET" }));
        dispatch(GetPublishAndPreProd({ method: "GET" })).then(() => {
          dispatch(setReadOnly(user));
          dispatch(GEtSKSForLogic({ method: "GET" }));
        });
      });
    }
    setSearchValue("");
    setPageSize(100);
    setPage(0);
    setOpen(false);
    apiRef.current.restoreState({
      pagination: {
        page: 0,
      },
    });
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    if (isUnSavedData) {
      setUnSavedWarning(true);
      setHandleRefreshCall(true);
    } else {
      refreshTrigger();
    }
  };

  function refreshTrigger() {
    dispatch(GetDRGLogic({ method: "GET" })).then(() => {
      dispatch(CheckDrgLogicLockStatus({ method: "GET" }));
      dispatch(GetEditorName({ method: "GET" }));
      dispatch(GetLockedRows({ method: "GET" }));
      dispatch(GetPublishAndPreProd({ method: "GET" })).then(() => {
        dispatch(setReadOnly(user));
        dispatch(GEtSKSForLogic({ method: "GET" }));
      });
    });
    apiRef.current.restoreState({
      pagination: {
        page: 0,
      },
    });
  }

  const requestSearch = async (event) => {
    dispatch(setSearchFilter(true));
    await setSearchValue(event.target.value);
    const filteredRows = masterSelectedEditorData.filter((row) => {
      return (
        row ?.drg ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
          row ?.regel
            ?.toLowerCase()
              ?.includes(event.target.value ?.toLowerCase()) ||
                row ?.rtc ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
                  row ?.icd ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
                    row ?.indm ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
                      row ?.dgcat
                        ?.toLowerCase()
                          ?.includes(event.target.value ?.toLowerCase()) ||
                            row ?.mdc ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
                              row ?.pdgprop
                                ?.toLowerCase()
                                  ?.includes(event.target.value ?.toLowerCase()) ||
                                    row ?.or ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
                                      row ?.procprO1
                                        ?.toLowerCase()
                                          ?.includes(event.target.value ?.toLowerCase()) ||
                                            row ?.secproC1
                                              ?.toLowerCase()
                                                ?.includes(event.target.value ?.toLowerCase()) ||
                                                  row ?.secproC2
                                                    ?.toLowerCase()
                                                      ?.includes(event.target.value ?.toLowerCase()) ||
                                                        row ?.dgproP1
                                                          ?.toLowerCase()
                                                            ?.includes(event.target.value ?.toLowerCase()) ||
                                                              row ?.dgproP2
                                                                ?.toLowerCase()
                                                                  ?.includes(event.target.value ?.toLowerCase()) ||
                                                                    row ?.dgproP3
                                                                      ?.toLowerCase()
                                                                        ?.includes(event.target.value ?.toLowerCase()) ||
                                                                          row ?.dgproP4
                                                                            ?.toLowerCase()
                                                                              ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                row ?.agelim
                                                                                  ?.toLowerCase()
                                                                                    ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                      row ?.compl
                                                                                        ?.toLowerCase()
                                                                                          ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                            row ?.dgalt
                                                                                              ?.toLowerCase()
                                                                                                ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                  row ?.discdisp
                                                                                                    ?.toLowerCase()
                                                                                                      ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                        row ?.sex ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                          row ?.till ?.toLowerCase() ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                            row ?.doedlim
                                                                                                              ?.toLowerCase()
                                                                                                                ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                  row ?.indlaeg
                                                                                                                    ?.toLowerCase()
                                                                                                                      ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                        row ?.pattype
                                                                                                                          ?.toLowerCase()
                                                                                                                            ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                              row ?.kontaars
                                                                                                                                ?.toLowerCase()
                                                                                                                                  ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                    row ?.visit
                                                                                                                                      ?.toLowerCase()
                                                                                                                                        ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                          row ?.secproC3
                                                                                                                                            ?.toLowerCase()
                                                                                                                                              ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                row ?.secproC4
                                                                                                                                                  ?.toLowerCase()
                                                                                                                                                    ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                      row ?.secproC5
                                                                                                                                                        ?.toLowerCase()
                                                                                                                                                          ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                            row ?.dgproP5
                                                                                                                                                              ?.toLowerCase()
                                                                                                                                                                ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                                  row ?.dgproP6
                                                                                                                                                                    ?.toLowerCase()
                                                                                                                                                                      ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                                        row ?.dgproP7
                                                                                                                                                                          ?.toLowerCase()
                                                                                                                                                                            ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                                              row ?.diagraM1
                                                                                                                                                                                ?.toLowerCase()
                                                                                                                                                                                  ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                                                    row ?.diagraM2
                                                                                                                                                                                      ?.toLowerCase()
                                                                                                                                                                                        ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                                                          row ?.diagraM3
                                                                                                                                                                                            ?.toLowerCase()
                                                                                                                                                                                              ?.includes(event.target.value ?.toLowerCase()) ||
                                                                                                                                                                                                row ?.diagraM4
                                                                                                                                                                                                  ?.toLowerCase()
                                                                                                                                                                                                    ?.includes(event.target.value ?.toLowerCase())
      );
    });
    await dispatch(setSelectedEditorData(filteredRows));
    dispatch(setSearchFilter(false));
  };

  const columns = [
    {
      field: "ruleNo",
      headerName: "Rule No",
      width: 80,
      editable: false,
      renderCell: ({ id }) => {
        const isInLockMode = selectedLockedRows.some(
          (item) => item.ruleNo == id
        );
        if (isInLockMode) {
          return (
            <div className="handle-ruleNo">
              <div className="handle-lock-icon">
                <LockIcon />
              </div>
              <div className="handle-ruleNo-id">{id}</div>
            </div>
          );
        }
        return (
          <div className="handle-ruleNo">
            <div className="handle-lock-icon"></div>
            <div className="handle-ruleNo-id">{id}</div>
          </div>
        );
      },
    },
    {
      field: "drg",
      headerName: "DRG",
      editable: true,
    },
    {
      field: "regel",
      headerName: "REGEL",
      editable: false,
    },
    {
      field: "rtc",
      headerName: "RTC",
      editable: true,
    },
    {
      field: "icd",
      headerName: "ICD",
      editable: true,
    },
    {
      field: "indm",
      headerName: "INDM",
      editable: true,
    },
    {
      field: "dgcat",
      headerName: "DGCAT",
      editable: true,
      logic: "contains",
    },
    {
      field: "mdc",
      headerName: "MDC",
      editable: true,
    },
    {
      field: "pdgprop",
      headerName: "PDGPROP",
      editable: true,
      logic: "contains",
    },
    {
      field: "or",
      headerName: "OR",
      editable: true,
    },
    {
      field: "procprO1",
      headerName: "PROCPRO1",
      editable: true,
      logic: "contains",
    },
    {
      field: "secproC1",
      headerName: "SECPROC1",
      editable: true,
      logic: "contains",
    },
    {
      field: "secproC2",
      headerName: "SECPROC2",
      editable: true,
      logic: "contains",
    },
    {
      field: "dgproP1",
      headerName: "DGPROP1",
      editable: true,
      logic: "contains",
    },
    {
      field: "dgproP2",
      headerName: "DGPROP2",
      editable: true,
      logic: "contains",
    },
    {
      field: "dgproP3",
      headerName: "DGPROP3",
      editable: true,
      logic: "contains",
    },
    {
      field: "dgproP4",
      headerName: "DGPROP4",
      editable: true,
      logic: "contains",
    },
    {
      field: "agelim",
      headerName: "AGELIM",
      editable: true,
    },
    {
      field: "compl",
      headerName: "COMPL",
      editable: true,
    },
    {
      field: "dgalt",
      headerName: "DGALT",
      editable: true,
      logic: "contains",
    },
    {
      field: "discdisp",
      headerName: "DISCDISP",
      editable: true,
    },
    {
      field: "sex",
      headerName: "SEX",
      editable: true,
    },
    {
      field: "till",
      headerName: "TILL",
      editable: true,
      logic: "contains",
    },
    {
      field: "doedlim",
      headerName: "DOEDLIM",
      editable: true,
    },
    {
      field: "indlaeg",
      headerName: "INDLAEG",
      editable: true,
    },
    {
      field: "pattype",
      headerName: "PATTYPE",
      editable: true,
    },
    {
      field: "kontaars",
      headerName: "KONTAARS",
      editable: true,
    },
    {
      field: "visit",
      headerName: "VISIT",
      editable: true,
    },

    {
      field: "secproC3",
      headerName: "SECPROC3",
      editable: true,
      logic: "contains",
    },
    {
      field: "secproC4",
      headerName: "SECPROC4",
      editable: true,
      logic: "contains",
    },
    {
      field: "secproC5",
      headerName: "SECPROC5",
      editable: true,
      logic: "contains",
    },
    {
      field: "dgproP5",
      headerName: "DGPROP5",
      editable: true,
      logic: "contains",
    },
    {
      field: "dgproP6",
      headerName: "DGPROP6",
      editable: true,
      logic: "contains",
    },
    {
      field: "dgproP7",
      headerName: "DGPROP7",
      editable: true,
      logic: "contains",
    },
    {
      field: "diagraM1",
      headerName: "DIAGRAM1",
      editable: true,
    },
    {
      field: "diagraM2",
      headerName: "DIAGRAM2",
      editable: true,
    },
    {
      field: "diagraM3",
      headerName: "DIAGRAM3",
      editable: true,
    },
    {
      field: "diagraM4",
      headerName: "DIAGRAM4",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      renderHeader: () => <SettingsIcon />,
      getActions: (params) => {
        const isInLockMode = selectedLockedRows.some(
          (item) => item.ruleNo == params.id
        );
        if (
          isInLockMode &&
          isPublished == false &&
          isPreProd == false &&
          isTest == false &&
          isViewer == false &&
          lockBtnText != "Logic Locked"
        ) {
          return [
            <GridActionsCellItem
              icon={<LockOpenIcon />}
              label={t("Editor.Unlock_Row")}
              onClick={UnlockRow(params.id)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<PreviewIcon />}
              label={t("Editor.View")}
              onClick={ViewRow(params)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<ContentCopyIcon />}
              label={t("Editor.Copy")}
              onClick={CopyRow(params.id)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={isPaste == true ? <ContentPasteIcon /> : <></>}
              label={t("Editor.Paste")}
              onClick={PasteRow(params.id)}
              showInMenu={isPaste}
            />,
            <GridActionsCellItem
              icon={<FileCopyIcon />}
              label={t("Editor.Duplicate")}
              onClick={DuplicateRow(params.id)}
              showInMenu
            />,
            // <GridActionsCellItem
            //   icon={<DeleteIcon />}
            //   label="Delete"
            //   onClick={DeleteRow(params.id)}
            //   showInMenu
            // />,
          ];
        }
        if (
          !isInLockMode &&
          isPublished == false &&
          isPreProd == false &&
          isTest == false &&
          isViewer == false &&
          lockBtnText != "Logic Locked"
        ) {
          return [
            <GridActionsCellItem
              icon={<LockIcon />}
              label={t("Editor.Lock_Row")}
              onClick={LockRow(params.id)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<PreviewIcon />}
              label={t("Editor.View")}
              onClick={ViewRow(params)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<ContentCopyIcon />}
              label={t("Editor.Copy")}
              onClick={CopyRow(params.id)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={isPaste == true ? <ContentPasteIcon /> : <></>}
              label={t("Editor.Paste")}
              onClick={PasteRow(params.id)}
              showInMenu={isPaste}
            />,
            <GridActionsCellItem
              icon={<FileCopyIcon />}
              label={t("Editor.Duplicate")}
              onClick={DuplicateRow(params.id)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label={t("Editor.Delete")}
              onClick={DeleteRow(params.id)}
              showInMenu
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<PreviewIcon />}
            label={t("Editor.View")}
            onClick={ViewRow(params)}
            showInMenu
          />,
        ];
      },
    },
  ];

  const handleLockUnlockLogic = () => {
    var date = new Date();
    var data = {
      DRGLogicID: selectedEditorID,
      EmailID: user[0]["email"],
      CreatedOn: date,
    };
    let url = "api/DrgLogicLock?strLockUnlock=" + lockBtnText;
    dispatch(DrgLogicLock({ method: "POST", url: url, body: data }));
  };

  const handleBulkLockRows = () => {
    setAnchorEl(null);
    if (selectionModel != null && selectionModel.length > 0) {
      const rules = selectionModel.map(selectRule);
      function selectRule(ruleNo) {
        const rule = selectedEditorData.filter(
          (item) => item.ruleNo == ruleNo
        )[0];
        return rule;
      }
      if (rules != null && rules.length > 0) {
        var date = new Date();
        var EditorRowLock = [];
        rules.forEach((element) => {
          EditorRowLock.push({
            DRGLogicID: selectedEditorID,
            ruleNo: element.ruleNo,
            CreatedByUser: user[0]["email"],
            CreatedDate: date.toString(),
          });
        });
        dispatch(LockDrgRules({ method: "POST", body: EditorRowLock }));
        dispatch(clearStatus());
      }
    } else {
      console.log("Please select atleast one row to perform bulk actions");
      dispatch(setSatusMessages({ method: "info_bulkactions" }));
    }
  };

  const handleBulkUnlockRows = () => {
    setAnchorEl(null);
    if (selectionModel != null && selectionModel.length > 0) {
      const rules = selectionModel.map(selectRule);
      function selectRule(ruleNo) {
        const rule = selectedEditorData.filter(
          (item) => item.ruleNo == ruleNo
        )[0];
        return rule;
      }
      if (rules != null && rules.length > 0) {
        var date = new Date();
        var EditorRowLock = [];
        rules.forEach((element) => {
          EditorRowLock.push({
            DRGLogicID: selectedEditorID,
            ruleNo: element.ruleNo,
          });
        });
        let url = "api/UnlockRows";
        dispatch(
          DeleteLockedRows({ method: "POST", url: url, body: EditorRowLock })
        );
        dispatch(clearStatus());
      }
    } else {
      console.log("Please select atleast one row to perform bulk actions");
      dispatch(setSatusMessages({ method: "info_bulkactions" }));
    }
    // let url =
    //   "api/DeleteLockedRows?DRGLogicID=" +
    //   selectedEditorID +
    //   "&CreatedBy=" +
    //   user[0]["email"] +
    //   "&RuleNo=0";
    // dispatch(DeleteLockedRows({ method: "GET", url: url }));
  };

  const LockRow = (id) => () => {
    var date = new Date();
    var EditorRowLock = [];
    var data = {
      DRGLogicID: selectedEditorID,
      ruleNo: id,
      CreatedByUser: user[0]["email"],
      CreatedDate: date.toString(),
    };
    EditorRowLock.push(data);
    dispatch(LockDrgRules({ method: "POST", body: EditorRowLock }));
  };

  const UnlockRow = (id) => () => {
    let url =
      "api/DeleteLockedRows?DRGLogicID=" +
      selectedEditorID +
      "&CreatedBy=" +
      user[0]["email"] +
      "&RuleNo=" +
      id;
    dispatch(DeleteLockedRows({ method: "GET", url: url }));
  };

  const ViewRow = (params) => () => {
    console.log(params);
    dispatch(setSelectedEditorRule(params));
    setOpenView(true);
  };

  const CopyRow = (id) => () => {
    const row = selectedEditorData.filter((item) => item.ruleNo == id)[0];
    dispatch(setIsPaste(row));
  };

  const PasteRow = (id) => () => {
    setOpenWarning(true);
    const pRow = selectedEditorData.filter((item) => item.ruleNo == id)[0];
    setPRow(pRow);
  };

  const DeleteRow = (id) => () => {
    setOpenWarning(true);
    const row = selectedEditorData.filter((item) => item.ruleNo == id)[0];
    dispatch(setIsDelete(row));
  };

  const DuplicateRow = (id) => () => {
    setOpenWarning(true);
    const row = selectedEditorData.filter((item) => item.ruleNo == id)[0];
    dispatch(setIsDuplicate(row));
  };

  const onWarning = (values) => {
    setSearchValue("");
    const comment = values.comment_msg;
    var i;
    var ruleValue = 0;
    let pdata = JSON.parse(JSON.stringify([...masterSelectedEditorData]));
    if (isSelectedRow != null) {
      var row = JSON.parse(JSON.stringify(isSelectedRow));
    }
    let tRow = row;
    if (isPaste) {
      pdata.splice(parseInt(pRow.ruleNo) - 1, 0, tRow);
      if (pRow.ruleNo == 1) {
        ruleValue = 1;
      } else {
        ruleValue = parseInt(pRow.ruleNo) - 1;
      }
      for (i = ruleValue; i <= pdata.length; i++) {
        if (i == 0) {
          pdata[0].ruleNo = i.toString();
          pdata[0].regel = i.toString();
        } else {
          pdata[i - 1].ruleNo = i.toString();
          pdata[i - 1].regel = i.toString();
        }
      }
      var commentData = {
        RuleNo: parseInt(pRow.ruleNo).toString(),
        FieldName: null,
        Comments: comment,
        OldValue: null,
        NewValue: null,
      };
      dispatch(setDrgLogicComments(commentData));
      dispatch(setSelectedEditorData(pdata));
      dispatch(setMasterSelectedEditorData(pdata));
      setPRow({});
      dispatch(setSatusMessages({ method: "Pasted", status: true }));
    }
    if (isDuplicate) {
      pdata.splice(parseInt(tRow.ruleNo) - 1, 0, tRow);
      if (tRow.ruleNo == 1) {
        ruleValue = 1;
      } else {
        ruleValue = parseInt(tRow.ruleNo) - 1;
      }
      for (i = ruleValue; i <= pdata.length; i++) {
        if (i == 0) {
          pdata[0].ruleNo = i.toString();
          pdata[0].regel = i.toString();
        } else {
          pdata[i - 1].ruleNo = i.toString();
          pdata[i - 1].regel = i.toString();
        }
      }
      var commentData = {
        RuleNo: (parseInt(tRow.ruleNo) + 1).toString(),
        FieldName: null,
        Comments: comment,
        OldValue: null,
        NewValue: null,
      };
      dispatch(setDrgLogicComments(commentData));
      dispatch(setSelectedEditorData(pdata));
      dispatch(setMasterSelectedEditorData(pdata));
      dispatch(setSatusMessages({ method: "Duplicated", status: true }));
    }
    if (isDelete) {
      pdata.splice(parseInt(tRow.ruleNo) - 1, 1);
      if (tRow.ruleNo == 1) {
        ruleValue = 1;
      } else {
        ruleValue = parseInt(tRow.ruleNo) - 1;
      }
      for (i = ruleValue; i <= pdata.length; i++) {
        if (i == 0) {
          pdata[0].ruleNo = i.toString();
          pdata[0].regel = i.toString();
        } else {
          pdata[i - 1].ruleNo = i.toString();
          pdata[i - 1].regel = i.toString();
        }
      }
      var commentData = {
        RuleNo: parseInt(tRow.ruleNo).toString(),
        FieldName: null,
        Comments: comment,
        OldValue: null,
        NewValue: null,
      };
      dispatch(setDrgLogicComments(commentData));
      dispatch(setSelectedEditorData(pdata));
      dispatch(setMasterSelectedEditorData(pdata));
      dispatch(setSatusMessages({ method: "Deleted", status: true }));
    }

    setOpenWarning(false);
    // dispatch(clearStatus());
    reset1();
  };

  const handleBulkActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkActionsClose = () => {
    setAnchorEl(null);
  };

  // const handleFilter = () => {
  //   console.log("Working Handle Filter");
  // }

  const handleOnCellDoubleClick = (params) => {
    if (selectionModel.length <= 1) {
      if (
        isPublished == false &&
        isPreProd == false &&
        isTest == false &&
        isViewer == false &&
        lockBtnText != "Logic Locked"
      ) {
        if (isEditor) {
          let url =
            "api/GetLockedRows?DrgLogicID=" +
              selectedEditorID +
              "&RuleNo=" +
              params ?.id;
          dispatch(GetLockedRow({ method: "GET", url: url }));
          dispatch(
            setOnCellDoubleClick({
              id: params.id,
              field: params.field,
              value: params.value,
              selectedRows: selectionModel,
              isEditable: params.isEditable,
              logicType: params ?.colDef ?.logic,
            })
          );
        } else {
          if (params.isEditable == true) {
            dispatch(
              setOnCellDoubleClick({
                id: params.id,
                field: params.field,
                value: params.value,
                selectedRows: selectionModel,
              })
            );
            if (params ?.colDef ?.logic == "contains") {
              dispatch(setOpenCellEditContains(true));
            } else {
              dispatch(setOpenCellEdit(true));
            }
          }
        }
      }
    }
    // if (isEditor && lockBtnText == "Logic Locked") {
    //   let url =
    //     "api/GetLockedRows?DrgLogicID=" +
    //     selectedEditorID +
    //     "&RuleNo=" +
    //     params?.id;
    //   dispatch(GetLockedRow({ method: "GET", url: url }));
    // }
  };

  // function EditToolbar(props) {
  //     <CellEdit props={props} />
  // }

  const handleSaveLogic = () => {
    setSearchValue("");
    var date = new Date();
    let drgLogicLog = [];

    var metaData = {
      Editor_name: selectedEditorPerson,
      ID: selectedEditorID,
      Name: selectedEditorName,
      version: selectedEditorVersion,
      Year: selectedEditorYear,
    };
    dispatch(setMetaData(metaData));

    drgLogicLog.push({
      DRGLogicID: selectedEditorID,
      Name: selectedEditorName,
      Version: selectedEditorVersion,
      Year: selectedEditorYear,
      ModifiedBy: user[0]["email"],
      ModifiedOn: date.toString(),
      LogicComments: DrgLogicComments,
      drgLogicName: selectedEditorID,
    });

    var data = {
      pdata: masterSelectedEditorData,
      drgLogicName: selectedEditorID,
      drgMetaData: metaData,
      drgLogicLog: drgLogicLog[0],
    };
    let url = "api/Editor/SaveDrgRule";
    dispatch(setUnSavedData(false));
    dispatch(SaveDrgRule({ method: "POST", url: url, body: data }));
  };

  const handleSaveAsLogic = () => {
    setSaveAsLogic(true);
    dispatch(clearStatus());
  };

  const onSubmit = (values) => {
    let url =
      "api/CheckDrgLogicExists?drgLogicName=" +
      values.name +
      "&drgLogicVersion=" +
      values.version +
      "&drgLogicYear=" +
      values.year;
    dispatch(CheckDrgLogicExists({ method: "GET", url: url }));

    var metaData = {
      Editor_name: selectedEditorPerson,
      ID: selectedEditorID,
      Name: values.name,
      version: values.version,
      Year: values.year,
    };
    dispatch(setMetaData(metaData));
  };

  if (!editor.isSessionValid) {
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  const handleBlockedNavigation = (location) => {
    if (!confirmedNavigation) {
      setLastLocation(location);
      setUnSavedWarning(true);
      return false;
    }
    return true;
  }

  const onDiscard = async (e) => {
    e.preventDefault();
    setUnSavedWarning(false);
    dispatch(setUnSavedData(false));
    if (lastLocation) {
      updateConfirmedNavigation(true);
    }
    if (openLogicCall) {
      setOpen(true);
      dispatch(clearStatus());
    }
    if (handleRefreshCall) {
      refreshTrigger();
    }
  }

  return (
    <div className="editor">
      <Prompt when={isUnSavedData} message={handleBlockedNavigation} />
      <div className="editor-section-header">
        <div className="section-header-left">
          <Typography id="headerLabel" variant="h6">
            {t("Editor.editor")}
          </Typography>
        </div>
        <div className="section-header-right">
          {/* {isClassificationCodesEnabled == true && ( */}
          <Button
            variant="contained"
            size="small"
            className="editor-button"
            onClick={() => history.push("/groupcodes")}
          >
            {t("Editor.Classification_Code")}
          </Button>
          {/* )} */}
          <Button
            variant="contained"
            size="small"
            className="editor-button"
            onClick={() => history.push("/logs")}
          >
            {t("Editor.Show_Log")}
          </Button>
          {isPublished == false &&
            isPreProd == false &&
            isTest == false &&
            isViewer == false &&
            lockBtnText != "Logic Locked" && (
              <Button
                variant="contained"
                size="small"
                className="editor-button"
                onClick={handleSaveLogic}
              >
                {t("Global.save")}
              </Button>
            )}
          {!isViewerRole && (
            <Button
              variant="contained"
              size="small"
              className="editor-button"
              onClick={handleSaveAsLogic}
            >
              {t("Global.Save_as")}
            </Button>
          )}
          {lockBtnText == "Lock Logic" && user[0].isAdmin == true && (
            <Button
              variant="contained"
              size="small"
              className="editor-button"
              onClick={handleLockUnlockLogic}
            >
              {t("Editor.Lock_Logic")}
            </Button>
          )}
          {lockBtnText == "Unlock Logic" && user[0].isAdmin == true && (
            <Button
              variant="contained"
              size="small"
              className="editor-button"
              onClick={handleLockUnlockLogic}
            >
              {t("Editor.Unlock_Logic")}
            </Button>
          )}
          {lockBtnText == "Logic Locked" && (
            <Button
              variant="contained"
              size="small"
              className="editor-button"
              disabled={true}
            >
              {t("Editor.Logic_Locked")}
            </Button>
          )}
        </div>
      </div>
      <Divider />
      <div className="editor-content-header">
        <div className="editor-content-header-first-section">
          <div className="first-subsection">
            <div className="first-subsection-align">
              {(lockBtnText == "Unlock Logic" ||
                lockBtnText == "Logic Locked") && <LockIcon />}
              <Typography id="headerLabel" sx={{ fontWeight: 600 }}>
                {selectedEditorName == ""
                  ? "Select the editor logic"
                  : selectedEditorName +
                  " - " +
                  selectedEditorVersion +
                  " - " +
                  selectedEditorYear +
                  (isPublished == true
                    ? " - " + t("Editor.Published_Version")
                    : " ") +
                  (isPreProd == true
                    ? " - " + t("Editor.Hearing_Version")
                    : " ") +
                  (isTest == true ? " - " + "Test" : " ")}
              </Typography>
            </div>
            <Typography
              component="div"
              sx={{ fontSize: "12px", paddingLeft: "1px" }}
              color="#00000099"
            >
              {selectedEditorData.length} {t("Global.Total")}
            </Typography>
          </div>
          <Link
            component="button"
            variant="body2"
            sx={{ marginLeft: "1%" }}
            onClick={() => {
              if (isUnSavedData) {
                setUnSavedWarning(true);
                setOpenLogicCall(true);
              } else {
                setOpen(true);
                dispatch(clearStatus());
              }
            }}
          >
            {t("Global.open_logic")}
          </Link>
        </div>

        <div className="editor-content-header-last-section">
          <TextField
            variant="outlined"
            placeholder={t("Global.search")}
            value={searchValue}
            onChange={requestSearch}
            sx={{ width: "40%", marginRight: "1%" }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
            }}
          />
          {isPublished == false &&
            isPreProd == false &&
            isTest == false &&
            isViewer == false &&
            lockBtnText != "Logic Locked" && (
              <div>
                <Button
                  color="inherit"
                  sx={{
                    border: "1px solid #0000001F",
                  }}
                  id="demo-customized-button-bulk-actions"
                  aria-controls="demo-customized-button-bulk-actions"
                  aria-haspopup="true"
                  aria-expanded={openBulkActions ? "true" : undefined}
                  disableElevation
                  onClick={handleBulkActionsClick}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {t("Editor.Grid_Actions")}
                </Button>
                <StyledMenu
                  id="demo-customized-button-bulk-actions"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button-bulk-actions",
                  }}
                  anchorEl={anchorEl}
                  open={openBulkActions}
                  onClose={handleBulkActionsClose}
                >
                  <MenuItem disableRipple onClick={handleBulkLockRows}>
                    {t("Editor.Lock_Rows")}
                  </MenuItem>
                  <MenuItem disableRipple onClick={handleBulkUnlockRows}>
                    {t("Editor.Unlock_Rows")}
                  </MenuItem>
                </StyledMenu>
              </div>
            )}
          {/* <Button
            variant="contained"
            size="small"
            onClick={handleFilter}
            className="editor-button"
          >
            <FilterAltIcon />
            {t("Global.filter")}
          </Button> */}
          <Button
            variant="contained"
            size="small"
            onClick={handleRefresh}
            className="editor-button"
          >
            {t("Editor.Refresh")}
          </Button>
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGridPro
          columns={columns}
          showColumnRightBorder={true}
          showCellRightBorder={true}
          disableColumnMenu={false}
          disableColumnResize={true}
          disableColumnReorder={true}
          rows={selectedEditorData}
          getRowId={(row) => row.ruleNo}
          checkboxSelection = {isPublished == false && isPreProd == false && isTest == false && isViewer == false ? true: false}
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => {
            dispatch(setSelectionModel(newSelectionModel));
          }}
          selectionModel={selectionModel}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[100, 500, 1000]}
          pagination
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "ruleNo"],
              right: ["actions"],
            },
            pagination: {
              page: 0,
            },
          }}
          apiRef={apiRef}
          onCellDoubleClick={handleOnCellDoubleClick}
          onCellEditStart={(params, event) =>
            (event.defaultMuiPrevented = true)
          }
          onCellEditStop={(params, event) => (event.defaultMuiPrevented = true)}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("Global.row_per_page")
            }
          }}
        // isCellEditable={(params) => console.log(params)}
        // components={{
        //     Toolbar: EditToolbar,
        // }}
        // componentsProps={{
        //     toolbar: { apiRef },
        // }}
        />
      </div>
      {openCellEdit == true && <CellEdit apiRef={apiRef} />}
      {openCellEditContains == true && <CellEditContains apiRef={apiRef} />}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2, width: "420px" }}>
          {t("Editor.drg_logic_file")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <EditorSelectionTree />
        </DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button onClick={handleClose}>{t("Global.cancel")}</Button>
          <Button variant="contained" onClick={handleOpen}>
            {t("Global.open")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openView} onClose={handleClose} maxWidth="xs">
        <DialogTitle>
          {t("Global.Viewing_Rule_No")} {selectedEditorRule.id}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="align-view-layout">
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DRG</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.drg}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">REGEL</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.regel}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">RTC</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.rtc}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">ICD</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.icd}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">INDM</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.indm}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGCAT</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgcat}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">MDC</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.mdc}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">PDGPROP</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.pdgprop}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">OR</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.or}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">PROCPRO1</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.procprO1}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">SECPROC1</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.secproC1}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">SECPROC2</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.secproC2}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGPROP1</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgproP1}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGPROP2</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgproP2}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGPROP3</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgproP3}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGPROP4</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgproP4}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">AGELIM</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.agelim}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">COMPL</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.compl}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGALT</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgalt}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DISCDISP</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.discdisp}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">SEX</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.sex}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">TILL</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.till}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DOEDLIM</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.doedlim}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">INDLAEG</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.indlaeg}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">PATTYPE</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.pattype}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">KONTAARS</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.kontaars}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">VISIT</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.visit}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">SECPROC3</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.secproC3}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">SECPROC4</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.secproC4}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">SECPROC5</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.secproC5}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGPROP5</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgproP5}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGPROP6</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgproP6}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DGPROP7</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.dgproP7}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DIAGRAM1</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.diagraM1}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DIAGRAM2</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.diagraM2}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DIAGRAM3</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.diagraM3}
              </Typography>
            </Typography>
            <Typography
              component="div"
              className="align-view-Typography"
              gutterBottom
            >
              <Typography className="view-cellName">DIAGRAM4</Typography>
              <Typography className="view-cellValue">
                {selectedEditorRule.row ?.diagraM4}
              </Typography>
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={saveAsLogic} onClose={handleClose}>
        <DialogTitle>{t("Global.Save_as")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              paddingTop: "5px !important",
              paddingBottom: "12px !important",
            }}
          >
            <div className="form-section">
              <TextField
                id="name"
                label={t("Manager_Common.Name")}
                fullWidth
                variant="outlined"
                className="form-section"
                {...register("name", {
                  required: t("Manager_Common.Logic_Name_Required"),
                  // pattern: {
                  //   value: /^[a-zA-Z0-9_.-]*$/,
                  //   message:
                  //     t("Manager_Common.Validataion"),
                  // },
                })}
                error={Boolean(errors.name)}
                helperText={errors ?.name ?.message}
                inputProps={{ maxLength: 20 }}
              />
            </div>
            <div className="form-section-double">
              <div className="form-sub-section">
                <TextField
                  label="Version"
                  variant="outlined"
                  className="form-sub-section"
                  {...register("version", {
                    required: t("Manager.Version_Required"),
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/,
                      message: t("Manager.Invalid_Version"),
                    },
                  })}
                  error={Boolean(errors.version)}
                  helperText={errors ?.version ?.message}
                  inputProps={{ maxLength: 5 }}
                />
              </div>
              <div className="form-sub-section">
                <TextField
                  label={t("Manager.yearLabel")}
                  variant="outlined"
                  className="form-sub-section"
                  {...register("year", {
                    required: t("Manager.Year_Required"),
                    pattern: {
                      value: /^[0-9]{4,4}$/,
                      message: t("Manager.entryDigits"),
                    },
                  })}
                  error={Boolean(errors.year)}
                  helperText={errors ?.year ?.message}
                  type="Number"
                />
              </div>
            </div>
            {isDrgLogicExists == true && (
              <Typography
                variant="h6"
                className="typographySubHeading"
                color="#f70404"
              >
                {t("Editor.isDrgLogicExists")}
              </Typography>
            )}
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Manager.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Manager.Save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={openWarning} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {isDelete == true && t("Manager.Warning")}
          {isPaste == true && t("Manager.Warning2")}
          {isDuplicate == true && t("Manager.Warning1")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit1(onWarning)}>
          <DialogContent>
            <TextField
              id="outlined-multiline-static"
              label={t("Editor.comments")}
              multiline
              rows={3}
              margin="normal"
              fullWidth
              {...register1("comment_msg", {
                required: t("Editor.comment_msg"),
              })}
              error={Boolean(errors1.comment_msg)}
              helperText={errors1 ?.comment_msg ?.message}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Global.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={unSavedWarning} maxWidth="xs">
        <DialogTitle color="red">{t("Global.warning")}</DialogTitle>
        <DialogContent>
          {t("Editor.unSavedWarning")}
        </DialogContent>
        <DialogActions sx={{ padding: "10px 24px !important" }}>
          <Button onClick={handleClose}>{t("Global.cancel")}</Button>
          <Button variant="contained" onClick={onDiscard} type="submit">{t("Global.yes")}</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <SharedComponent />
    </div>
  );
}
