import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getNodesJSON } from "./publicViewerSlice";
import { DiagramBase } from "./DiagramBase";
import LinearProgress from "@mui/material/LinearProgress";

export default function ViewerDiagram(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const viewer = useSelector((state) => state.publicViewer);
  const {
    metadataCollection,
    selectedVisualObj,
    selectedSpecification,
    view,
    isLoading,
    isNodeData,
    dataForGoJs,
    tabValue,
    searchValue,
    regelValue,
    showDRGDiagram,
  } = viewer;

  useEffect(() => {
    console.log("isLoading:", isLoading);
    console.log("metadataCollection:", metadataCollection);
    if (!isLoading) {
      let name = "";
      let specification = "";
      if (selectedSpecification != null) {
        if ((selectedSpecification.groupName === "MDC Specification")  || (selectedSpecification.groupName === "MDC Specifikationer")) {
          specification = "MDC";
        } else if ((selectedSpecification.groupName === "Other Specification") || (selectedSpecification.groupName === "Øvrige Specifikationer")) {
          specification = "OTHER";
        } else if (selectedSpecification.groupName === "Type") {
          specification = "TYPE";
        } else {
          specification = selectedSpecification.groupName;
        }
        name = selectedSpecification.name;
      }
      console.log("before dispatch - getNodesJSON ");
      dispatch(
        getNodesJSON({
          logicName: metadataCollection.DRGLogicName,
          logicVersion: metadataCollection.DRGLogicVersion,
          priceName: metadataCollection.DRGPriceName,
          priceVersion: metadataCollection.DRGPriceVersion,
          year: selectedVisualObj.year,
          specification: specification,
          mdc: name,
          viewType: view,
          method: "GET",
        })
      );
    }
  }, [isLoading, view, selectedSpecification?.name, showDRGDiagram]);

  useEffect(() => {
    console.log("Current Value of isNodeData:", isNodeData);
  }, [isNodeData]);

  return (
    <div>
      {!isLoading ?(
      <div> 
      {isNodeData ? (
        <DiagramBase
          dataForGoJs={dataForGoJs}
          searchValue={searchValue}
          regelValue={regelValue}
        />
      ) : (
        <LinearProgress />
      )}
      </div>
      ): null}
    </div>
  );
}
