import React from "react";
import "./Dashboard.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import CreateIcon from "@mui/icons-material/Create";
import AssessmentIcon from "@mui/icons-material/Assessment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { blue, pink, green, purple } from "@mui/material/colors";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Dashboard() {
  const { t, i18n } = useTranslation("language");
  const history = useHistory();
  const { user, isViewerRole } = useSelector((state) => state.authentication);
  return (
    <div className="dashboardContainer">
      <Grid container>
        <Card className="name-container">
          <Grid item xs={12}>
            <Typography
              variant="h5"
              id="loggedInUserName"
              sx={{
                color: "white",
                paddingRight: "0px",
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {`${t("dashboard.welcome")} ${user[0].firstName}`}
            </Typography>
            <Typography
              sx={{
                color: "white",
                paddingRight: "0px",
                paddingLeft: "20px",
                paddingTop: "0px",
                paddingBottom: "10px",
              }}
            >
              {t("dashboard.select_module")}
            </Typography>
          </Grid>
        </Card>
        <Grid container className="grid-container">
          {!isViewerRole && (
            <Grid item xs={3} className="first-grid">
              <Card interactive="true" className="card-design">
                <div className="avatar-design">
                  <Avatar
                    sx={{
                      backgroundColor: "#ebf2fc",
                      color: blue,
                      width: "50px",
                      height: "50px",
                    }}
                    size="large"
                  >
                    <FolderOpenIcon
                      sx={{ color: blue[500], fontSize: "xx-large" }}
                    ></FolderOpenIcon>
                  </Avatar>
                </div>
                <Typography
                  variant="h6"
                  className="title"
                  id="labelManager"
                  gutterBottom
                >
                  {t("dashboard.manager")}
                </Typography>
                <Typography
                  sx={{ fontSize: 14, textAlign: "center", padding: "5px" }}
                  gutterBottom
                >
                  {t("dashboard.manager_text")}
                </Typography>
                <Button
                  size="small"
                  className="button-margin"
                  id="moduleButton"
                  onClick={() => history.push("/manager")}
                >
                  {t("dashboard.go_to_module")}
                </Button>
              </Card>
            </Grid>
          )}
          <Grid item xs={3} className="middle-grids">
            <Card interactive="true" className="card-design">
              <div className="avatar-design">
                <Avatar
                  sx={{
                    backgroundColor: "#f8f2f9",
                    color: purple,
                    width: "50px",
                    height: "50px",
                  }}
                  size="large"
                >
                  <CreateIcon
                    sx={{ color: purple[500], fontSize: "xx-large" }}
                  ></CreateIcon>
                </Avatar>
              </div>
              <Typography variant="h6" className="title" gutterBottom>
                {t("dashboard.editor")}
              </Typography>
              <Typography
                sx={{ fontSize: 14, textAlign: "center", padding: "5px" }}
                gutterBottom
              >
                {t("dashboard.editor_text")}
              </Typography>
              <Button
                size="small"
                className="button-margin"
                onClick={() => history.push("/editor")}
              >
                {t("dashboard.go_to_module")}
              </Button>
            </Card>
          </Grid>
          <Grid item xs={3} className="middle-grids">
            <Card interactive="true" className="card-design">
              <div className="avatar-design">
                <Avatar
                  sx={{
                    backgroundColor: "#fdeef4",
                    color: pink[100],
                    width: "50px",
                    height: "50px",
                  }}
                  size="large"
                >
                  <AssessmentIcon
                    sx={{ color: pink[500], fontSize: "xx-large" }}
                  ></AssessmentIcon>{" "}
                </Avatar>
              </div>
              <Typography variant="h6" className="title" gutterBottom>
                {t("dashboard.interactive")}
              </Typography>
              <Typography
                sx={{ fontSize: 14, textAlign: "center", padding: "5px" }}
                gutterBottom
              >
                {t("dashboard.interactive_text")}
              </Typography>
              <Button
                size="small"
                className="button-margin"
                onClick={() => history.push("/interactive")}
              >
                {t("dashboard.go_to_module")}
              </Button>
            </Card>
          </Grid>
          <Grid item xs={3} className="last-grid">
            <Card interactive="true" className="card-design">
              <div className="avatar-design">
                <Avatar
                  sx={{
                    backgroundColor: "#ebfbee",
                    color: green,
                    width: "50px",
                    height: "50px",
                  }}
                  size="large"
                >
                  <VisibilityIcon
                    sx={{ color: green[500], fontSize: "xx-large" }}
                  ></VisibilityIcon>
                </Avatar>
              </div>
              <Typography variant="h6" className="title" gutterBottom>
                {t("dashboard.viewer")}
              </Typography>
              <Typography
                sx={{ fontSize: 14, textAlign: "center", padding: "5px" }}
                gutterBottom
              >
                {t("dashboard.viewer_text")}
              </Typography>
              <Button
                size="small"
                className="button-margin"
                onClick={() => history.push("/viewer")}
              >
                {t("dashboard.go_to_module")}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
