import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCountries = createAsyncThunk(
  "login/getCountries",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return fetch("home/GetCountry", reqOptions).then((r) => {
      return r.json();
    });
  }
);

export const onLogin = createAsyncThunk(
  "login/onLogin",
  async (obj, { dispatch, getState }) => {
    let reqOptions = {};
    reqOptions.method = obj.method;
    return await fetch("authentication/Login", reqOptions).then((r) => {
      //console.log("Res:", r.json());
      return r.json();
    });
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    countries: [],
    status: null,
    isLoggedIn: false,
    loginStatus: null,
    redirectUrl: "",
  },
  extraReducers: {
    [getCountries.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countries = payload;
      state.status = "success";
    },
    [getCountries.rejected]: (state, action) => {
      state.status = "failed";
      console.log("Error from getCountries", action.error.message);
    },
    [onLogin.pending]: (state, action) => {
      state.loginStatus = "loading";
    },
    [onLogin.fulfilled]: (state, { payload }) => {
      console.log("payload from onLogin", payload.url.replace("http", "https"));
      let url = payload.url.replace("https", "http");
      console.log("url:", url);
      state.redirectUrl = url.replace("http", "https");
      state.isLoggedIn = true;
      state.loginStatus = "success";
    },
    [onLogin.rejected]: (state, action) => {
      state.loginStatus = "failed";
      console.log("Error from onLogin", action.error.message);
    },
  },
});

export default loginSlice.reducer;
