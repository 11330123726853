export const editorEditValues = {
    ICD : ["+","-",""],
    INDM : ["1","2",""],
    OR : ["+","-",""],
    COMPL : ["0","1",""],
    DISCDISP : ["E","R",""],
    SEX : ["M","F","-",""],
    INDLAEG : [">1","<:1",""],
    PATTYPE : ["5","6","7","8","9",""],
    KONTAARS : ["1",""]
}