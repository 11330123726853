import React, { useState, useEffect } from "react";
import "./Viewer.css";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MuiGrid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import ViewerDiagram from "./ViewerDiagram";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMetadata,
  getMetadataForLogicName,
  getAllParentSKS,
  setChildrenNode,
  setDRGLoading,
  getSKSCodeDiagramData,
  getSKSSearch,
  resetSKS,
  setPagination,
  setResetValues,
  setView,
  setSelectedSpecification,
} from "./viewerSlice";
import SKSTreeView from "./SKSTreeView";
import DRGDiagram from "./DRGDiagram";

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [expandValue, setExpandValue] = React.useState("");
  const dispatch = useDispatch();
  const viewer = useSelector((state) => state.viewer);
  const { showRegelDiagram, isExpandedValue, drgDataForGoJs } = viewer;

  const rowExpand = (sks) => {
    // setExpandValue(sks.regel);
    if (sks.regel == isExpandedValue) {
      dispatch(
        setDRGLoading({
          name: "showRegelDiagram",
          value: false,
          expandedValue: "",
        })
      );
    } else {
      dispatch(
        setDRGLoading({
          name: "showRegelDiagram",
          value: true,
          expandedValue: sks.regel,
        })
      );
      dispatch(
        getSKSCodeDiagramData({
          method: "GET",
          drgGroup: sks.drGgroup,
          regel: sks.regel,
        })
      );
    }
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    // setOpen(!open);
  };
  function displayRegelDiagram() {
    if (showRegelDiagram) {
      return <DRGDiagram />;
    } else {
      return null;
    }
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <TableCell sx={{ width: "3%", padding: "6px !important" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => rowExpand(row)}
          >
            {row.regel == isExpandedValue ? (
              <ArrowDropDownIcon />
            ) : (
              <ArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.regel}</TableCell>
        <TableCell align="left">{row.characteristic}</TableCell>
        <TableCell align="left">{row.propertyType}</TableCell>
        <TableCell align="left">{row.propertyName}</TableCell>
        <TableCell align="left">{row.drGgroup}</TableCell>
        <TableCell align="left">{row.drgName}</TableCell>
      </TableRow>
      <TableRow sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse
            in={row.regel == isExpandedValue ? open : false}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              {showRegelDiagram ? displayRegelDiagram() : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function SKSCodes() {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const viewer = useSelector((state) => state.viewer);
  const {
    selectedVisualObj,
    sksLoading,
    sksSelectedName,
    sksMapData,
    isSKSSearch,
    showSKSTable,
    page,
    rowsPerPage,
    selectedSpecification,
    showDRGDiagram,
    visualMenuCollection,
    view,
  } = viewer;

  useEffect(() => {
    if (selectedVisualObj) {
      dispatch(setDRGLoading({ name: "sksLoading", value: true }));
      dispatch(
        getMetadata({
          method: "GET",
          name: selectedVisualObj.visualName,
          version: selectedVisualObj.visualVersion,
          year: selectedVisualObj.year,
        })
      ).then(() => {
        dispatch(
          getMetadataForLogicName({
            method: "GET",
            year: selectedVisualObj.year,
          })
        ).then(() => {
          dispatch(getAllParentSKS({ method: "GET" })).then(() => {
            dispatch(setDRGLoading({ name: "sksLoading", value: false }));
          });
        });
      });
    }
    return () => {
      setSearchValue("");
      dispatch(resetSKS());
    };
  }, []);
  const handleChangePage = (event, newPage) => {
    dispatch(setPagination({ page: newPage, rowsPerPage: rowsPerPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setPagination({ page: 0, rowsPerPage: event.target.value }));
  };
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value.length > 2) {
      setTimeout(() => {
        dispatch(
          getSKSSearch({
            method: "GET",
            search: event.target.value?.toLowerCase(),
          })
        ).then(() => {
          dispatch(setDRGLoading({ name: "sksSearch", value: true }));
        });
      }, 1000);
    } else if (event.target.value == "") {
      dispatch(setDRGLoading({ name: "sksSearch", value: false }));
      dispatch(setDRGLoading({ name: "showSKSTable", value: false }));
      dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
      dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    }
  };
  const closeSearch = () => {
    setSearchValue("");
    dispatch(setDRGLoading({ name: "sksSearch", value: false }));
    dispatch(setDRGLoading({ name: "showSKSTable", value: false }));
    dispatch(setDRGLoading({ name: "showRegelDiagram", value: false }));
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
  };

  const handleViewChange = (e) => {
    dispatch(setResetValues());
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setView(e.target.value));
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: true }));
  };

  const handleSpecification = (event, value) => {
    // if (value ?.groupName == "Full Logic" || value ?.groupName == "Komplet logik" || value ?.groupName == "Other Specification" || value ?.groupName == "Øvrige Specifikationer" || value ?.groupName == "Type" ) {
    //   dispatch(setView("SimpleView"));
    // }
    dispatch(setView("SimpleView"));
    dispatch(setResetValues());
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: false }));
    dispatch(setSelectedSpecification(value));
    dispatch(setDRGLoading({ name: "showDRGDiagram", value: true }));
  };

  function displayDRGDiagram() {
    if (selectedSpecification != null && showDRGDiagram) {
      return (
        <div className="drg-diagram-container">
          <div className="viewermap-header">
            <div className="viewermap-header-left">
              <Typography id="headerLabel" className="viewer-text" gutterBottom>
                {selectedSpecification?.value}
              </Typography>
            </div>
            <div className="viewermap-header-right">
              <TextField
                variant="outlined"
                select={
                  selectedSpecification?.groupName == "MDC Specification" ||
                  selectedSpecification?.groupName == "MDC Specifikationer"
                    ? true
                    : false
                }
                disabled={
                  selectedSpecification?.groupName == "MDC Specification" ||
                  selectedSpecification?.groupName == "MDC Specifikationer"
                    ? false
                    : true
                }
                value={
                  selectedSpecification?.groupName == "MDC Specification" ||
                  selectedSpecification?.groupName == "MDC Specifikationer"
                    ? view
                    : t("viewer.simpleView")
                }
                onChange={handleViewChange}
                style={{ width: "180px" }}
              >
                <MenuItem key="SimpleView" value="SimpleView">
                  {t("viewer.simpleView")}
                </MenuItem>
                {(selectedSpecification?.groupName == "MDC Specification" ||
                  selectedSpecification?.groupName ==
                    "MDC Specifikationer") && (
                  <MenuItem key="AdvancedView" value="AdvancedView">
                    {t("viewer.advancedView")}
                  </MenuItem>
                )}
              </TextField>
              <Autocomplete
                id="grouped-demo"
                options={visualMenuCollection}
                groupBy={(option) => option.groupName}
                getOptionLabel={(option) => option.displayName}
                sx={{ width: 300, paddingLeft: "20px" }}
                onChange={handleSpecification}
                value={selectedSpecification}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("viewer.selectSpecification")}
                  />
                )}
              />
            </div>
          </div>
          <Divider sx={{ paddingTop: "10px" }} />
          <ViewerDiagram />
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      {sksLoading ? (
        <div>
          <Backdrop
            sx={{
              color: "#ffffff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={sksLoading}
          >
            <CircularProgress />
          </Backdrop>
        </div>
      ) : (
        <div className="sks-layout">
          <Grid container>
            <TextField
              variant="outlined"
              placeholder={t("Global.search")}
              value={searchValue}
              onChange={handleSearch}
              sx={{ width: "100%", paddingBottom: "10px" }}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "#00000040" }} />,
                endAdornment: (
                  <CancelRoundedIcon
                    fontSize="small"
                    sx={{ color: "#00000040", cursor: "pointer" }}
                    onClick={closeSearch}
                  />
                ),
              }}
            />
            {isSKSSearch ? <SKSTreeView search={true} /> : <SKSTreeView />}
          </Grid>
          <Divider sx={{ paddingTop: "10px" }} />

          {showSKSTable ? (
            <div>
              <Typography
                id="headerLabel"
                className="viewer-text"
                sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                gutterBottom
              >
                {t("Global.SKS_Code_Details")}
              </Typography>
              <Paper elevation={1} variant="outlined" square>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" colSpan={3}>
                          <Typography variant="string">
                            {sksSelectedName}
                          </Typography>{" "}
                          <br />
                          {sksMapData != null ? sksMapData.length : ""}
                          &nbsp;&nbsp;{t("Global.Total")}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        <TableCell
                          sx={{ width: "3%", padding: "6px !important" }}
                        />
                        <TableCell>{t("viewer.rule")}</TableCell>
                        <TableCell align="left">
                          {t("Global.Characteristic")}
                        </TableCell>
                        <TableCell align="left">
                          {t("Global.Property_Type")}
                        </TableCell>
                        <TableCell align="left">
                          {t("Global.Property_Name")}
                        </TableCell>
                        <TableCell align="left">
                          {t("Global.drgGroup")}
                        </TableCell>
                        <TableCell align="left">
                          {t("Global.drgName")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sksMapData != null
                        ? sksMapData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => <Row key={row.regel} row={row} />)
                        : ""}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={sksMapData != null ? sksMapData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("Global.row_per_page")}
                  />
                </TableContainer>
              </Paper>
            </div>
          ) : null}
        </div>
      )}
      <Divider sx={{ paddingTop: "10px" }} />
      {displayDRGDiagram()}
    </div>
  );
}
