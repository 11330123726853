import React, { useState, useEffect } from "react";
import "./Editor.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCountingCodes, updateCountingCodes } from "./editorSlice";
import CodeMirror from "@uiw/react-codemirror";
import { oneDark } from "@codemirror/theme-one-dark";
import { useForm, Controller } from "react-hook-form";
import SharedComponent from "./SharedComponent";
import GroupCodes from "./GroupCodes";
import { useHistory } from "react-router-dom";

export default function ClassificationCodes() {
  const { t, i18n } = useTranslation("language");
  const dispatch = useDispatch();
  const { user, isViewerRole } = useSelector((state) => state.authentication);
  const editor = useSelector((state) => state.editor);
  const { language } = useSelector((state) => state.language);
  const history = useHistory();

  const {
    selectedEditorObj,
    selectedEditorID,
    selectedEditorName,
    selectedEditorYear,
    selectedEditorVersion,
    countingCodes,
    grpCodeLoading,
  } = editor;
  const [value, setValue] = useState("groupCodes");

  const [saveConfirm, setSaveConfirm] = useState(false);
  const [updatedCountingCode, setUpdatedCountingCode] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    dispatch(
      getCountingCodes({
        method: "GET",
        drgLogicID: selectedEditorID,
      })
    );
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  //COUNTING CODE
  const handleCountingCodeChange = (value, viewUpdate) => {
    setUpdatedCountingCode(value);
  };
  const handleSaveCountingCodes = (e) => {
    setSaveConfirm(true);
  };
  const onSaveConfirm = (values) => {
    const comment = values.comment_msg;
    let logicComments = [];
    var log = {
      DRGLogicID: selectedEditorID,
      Comments:
        language == "en"
          ? "Modified Counting code by " + user[0]["fullName"]
          : "Ændret tællekode af " + user[0]["fullName"],
      masterGroupCode: "Counting Code",
      GroupCode: "",
      OldValue: "",
      NewValue: "",
    };

    logicComments.push(log);
    var date = new Date();
    let countingCodeLogList = {
      DRGLogicID: selectedEditorID,
      Name: selectedEditorName,
      Version: selectedEditorVersion,
      ModifiedBy: user[0]["email"],
      ModifiedOn: date.toString(),
      Comment: comment,
      LogicComments: logicComments,
    };
    var data = {
      logicID: selectedEditorID,
      logicName: selectedEditorName,
      versionNo: selectedEditorVersion,
      cntCodes: updatedCountingCode,
      logs: countingCodeLogList,
    };
    dispatch(updateCountingCodes({ method: "POST", body: data })).then((r) => {
      dispatch(
        getCountingCodes({
          method: "GET",
          drgLogicID: selectedEditorID,
        })
      );
      setSaveConfirm(false);
      reset();
    });
  };

  const handleClose = () => {
    setSaveConfirm(false);
    reset();
  };

  return (
    <div className="viewer-container">
      <Paper className="viewer-section-container">
        <div className="header-layout">
          <Typography id="headerLabel" className="groupcode-title" gutterBottom>
          {t("ClassificationCode.Classification_Codes")}:{" "}
            {selectedEditorYear +
              " - " +
              selectedEditorName +
              " - " +
              selectedEditorVersion}
          </Typography>
          <Button
            variant="contained"
            size="small"
            className="editor-backButton"
            onClick={() => history.push("/editor")}
          >
            {t("Editor.BackToEditor")}
          </Button>
        </div>
        <Divider />
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: "0px 24px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="content tabs"
              >
                <Tab
                  id="tabInteractiveLabel"
                  value="groupCodes"
                  label={t("ClassificationCode.groupCodes")}
                />
                <Tab
                  value="countingCodes"
                  label={t("ClassificationCode.countingCodes")}
                />
              </Tabs>
            </Box>
            <TabPanel sx={{ padding: "5px" }} value="groupCodes">
              <GroupCodes />
            </TabPanel>
            <TabPanel sx={{ padding: "10px 25px" }} value="countingCodes">
              <CodeMirror
                value={countingCodes}
                height="400px"
                theme={oneDark}
                options={{
                  keyMap: "sublime",
                  lineNumbers: true,
                  lineWrapping: true,
                  mode: "clike",
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleCountingCodeChange}
              />
              <Divider sx={{ paddingTop: "10px" }} />
              {!isViewerRole && (
                <div className="countingCode-button-container">
                  <Button
                    variant="contained"
                    size="small"
                    className="editor-button"
                    onClick={handleSaveCountingCodes}
                  >
                    {t("Global.save")}
                  </Button>
                </div>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
      <Dialog open={saveConfirm} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
        {t("Global.Message")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSaveConfirm)}>
          <DialogContent>
            <TextField
              id="outlined-multiline-static"
              label={t("Editor.comments")}
              multiline
              rows={3}
              margin="normal"
              fullWidth
              {...register("comment_msg", {
                required: t("Editor.comment_msg"),
              })}
              error={Boolean(errors.comment_msg)}
              helperText={errors?.comment_msg?.message}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "12px 24px !important" }}>
            <Button onClick={handleClose}>{t("Global.cancel")}</Button>
            <Button variant="contained" type="submit">
              {t("Global.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <SharedComponent />
      <Backdrop
        sx={{
          color: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={grpCodeLoading}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
