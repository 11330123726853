import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./RegisterUser.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRoles, registration, clearStatus, EmailValidation } from "./registerUserSlice";
import {redirectToLogin} from ".././authentication/authenticationSlice";

export default function RegisterUser(props) {
  const { t } = useTranslation("language");
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur" // "onChange"
  });
  const registerUser = useSelector((state) => state.registerUser);
  const { status, roles, isRegSuccess, regStatus, isRegFailed } = registerUser;

  useEffect(() => {
    dispatch(getRoles({ method: "GET" }));
  }, []);

  const handleClose = () => {
    dispatch(clearStatus());
  };

  const handleEmailValidation = (email) => async (value) => {
      let url = "home/EmailValidation?email=" + value;
      let status = await dispatch(EmailValidation({ method: "GET", url: url })).then((data) => {
        if (data.payload) {
          return true;
        }
      });
      if (status) {
        return t("registerUser.failure");
      }
      else {
        return true;
      }
  }

  if (regStatus == "success" && isRegSuccess) {
    if (!props.IsAdmin) {
      setTimeout(() => {
        props.OnCancel();
      }, 3000);
    } else {
      setTimeout(() => {
        history.push("/manage");
      }, 5000);
    }
  }
  const onSubmit = (values) => {
    let user = {};
    user.Email = values.email;
    user.Organization = values.organization;
    user.FirstName = values.firstName;
    user.LastName = values.lastName;
    user.PhoneNumber = values.phone;
    user.Purpose = values.purpose;
    user.Active = props.IsAdmin ? "1" : "0";
    user.Role = props.IsAdmin ? [values.role] : null;
    setTimeout(() => {
      userRegistration(user);
    }, 400);
  };

  if(!registerUser.isSessionValid){
    dispatch(redirectToLogin({ isAuthorised: false }));
  }

  return (
    <div
      className={
        props.IsAdmin ? "registerContainer admin" : "registerContainer"
      }
    >
      <Card className="card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-section-header">
            <Typography variant="h5" className="form-title" gutterBottom>
              {t("registerUser.title")}
            </Typography>
          </div>
          <div className="form-section">
            <TextField
              id="outlined-basic"
              label={t("registerUser.email")}
              variant="outlined"
              className="form-section"
              {...register("email", {
                required:t("registerUser.emailRequired"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("registerUser.Email_Validation"),
                },
                validate: handleEmailValidation("email"),
              })}
              error={Boolean(errors.email)}
              helperText={errors ?.email ?.message}
              inputProps={{ maxLength: 60 }}
              required
            />
          </div>
          <div className="form-section">
            <TextField
              id="outlined-basic"
              label={t("registerUser.organization")}
              variant="outlined"
              className="form-section"
              name="organization"
              {...register("organization", {
                required: t("registerUser.Oraganization_Required"),
              })}
              error={Boolean(errors.organization)}
              helperText={errors ?.organization ?.message}
              inputProps={{ maxLength: 20 }}
              required
            />
          </div>
          <div className="form-section-double">
            <div className="form-sub-section">
              <TextField
                label={t("registerUser.firstName")}
                variant="outlined"
                className="form-sub-section"
                {...register("firstName", {
                  required: t("registerUser.First_Name_Required"),
                  pattern: {
                    value: /^[a-zA-Z '.-]*$/,
                    message: t("registerUser.entryLetters"),
                  },
                })}
                error={Boolean(errors.firstName)}
                helperText={errors ?.firstName ?.message}
                inputProps={{ maxLength: 15 }}
                required
              />
            </div>
            <div className="form-sub-section">
              <TextField
                label={t("registerUser.lastName")}
                variant="outlined"
                className="form-sub-section"
                {...register("lastName", {
                  required: "Last Name is required.",
                  pattern: {
                    value: /^[a-zA-Z '.-]*$/,
                    message: t("registerUser.entryLetters"),
                  },
                })}
                error={Boolean(errors.lastName)}
                helperText={errors ?.lastName ?.message}
                inputProps={{ maxLength: 15 }}
                required
              />
            </div>
          </div>
          {props.IsAdmin && (
            <div className="form-section">
              <TextField
                label={t("registerUser.role")}
                variant="outlined"
                select
                className="form-section"
                {...register("role", {
                  required: "Role is required.",
                })}
                error={Boolean(errors.role)}
                helperText={errors ?.role ?.message}
                required
              >
                {roles.map((role) => {
                  return (
                    <MenuItem key={role.id} value={role.id}>
                      {role.roleName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          )}

          <div className="form-section">
            <TextField
              label={t("registerUser.phone")}
              variant="outlined"
              type="Number"
              className="form-section"
              {...register("phone", {
                pattern: {
                  value: /^[0-9]{1,20}$/,
                  message: t("registerUser.entryMaxChar"),
                },
              })}
              inputProps={{ maxLength: 20 }}
              error={Boolean(errors.phone)}
              helperText={errors ?.phone ?.message}
            />
          </div>
          <div className="form-section">
            <TextField
              label={t("registerUser.purpose")}
              variant="outlined"
              className="form-section"
              multiline
              {...register("purpose")}
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div className="form-button-section">
            <div className="form-button-container">
              <Button
                size="small"
                className="form-button-secondary"
                onClick={
                  props.IsAdmin ? () => history.push("/manage") : props.OnCancel
                }
              >
                {t("registerUser.cancel")}
              </Button>
            </div>
            <div className="form-button-container">
              <Button
                variant="contained"
                size="small"
                className="form-button-primary"
                type="submit"
              >
                {props.IsAdmin
                  ? t("registerUser.grantAccess")
                  : t("registerUser.reqAccess")}
              </Button>
            </div>
          </div>
        </form>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isRegSuccess}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {props.IsAdmin
            ? t("registerUser.grantSuccess")
            : t("registerUser.reqSuccess")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isRegFailed}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          {t("registerUser.failure")}
        </Alert>
      </Snackbar>
    </div>
  );
  async function userRegistration(user) {
    let language = localStorage.getItem("language");
    let body = user;
    let url = "";
    if (props.IsAdmin) {
      url = "home/api/Register/AdminRegister?lang=" + language + "";
    } else {
      url = "home/home/Register/UserRegister?lang=" + language + "";
    }
    dispatch(registration({ method: "POST", url: url, body: user }));
  }
}
