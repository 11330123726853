import React, { useState } from "react";
import "./Interactive.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  expandCollapseUpdate,
  addEpisodeToSpell,
  editEpisode,
  deleteEpisode,
} from "./publicInteractiveSlice";

export default function Episodes() {
  const [expandValue, setExpandValue] = useState("Expand");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("language");
  const interactive = useSelector((state) => state.publicInteractive);
  const { interactiveList, spellsList } = interactive;
  const languageState = useSelector((state) => state.language);
  const { language } = languageState;
  const [currentIndex, setCurrentIndex] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (index) => (event) => {
    setCurrentIndex(index);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExpand = (index) => (e) => {
    if (e.target.value == "Expand") {
      setExpandValue("Collapse");
      dispatch(expandCollapseUpdate({ index: index, value: "Expand" }));
    } else {
      setExpandValue("Expand");
      dispatch(expandCollapseUpdate({ index: index, value: "Collapse" }));
    }
  };
  const handleAddEpisodeToSpell = (e) => {
    var item = interactiveList[currentIndex];
    dispatch(addEpisodeToSpell({ item: item, id: item[0].id }));
  };
  const handleEditEpisode = (e) => {
    var item = interactiveList[currentIndex];
    dispatch(editEpisode(item[0]));
  };
  const handleDeleteEpisode = (val) => () => {
    dispatch(deleteEpisode(currentIndex));
  };

  return (
    <div>
      {interactiveList.length > 0 ? (
        <div className="episodes-container">
          <ul>
            {interactiveList?.map((a, index) => {
              return (
                <li
                  className={
                    a[0].expandCollapseValue === "Expand"
                      ? "episode-expand"
                      : "episode-collapse"
                  }
                >
                  <Paper
                    elevation={0}
                    className={
                      a[0].expandCollapseValue === "Expand"
                        ? "episode-paper-expand"
                        : "episode-paper-collapse"
                    }
                  >
                    <div className="episode-header">
                      <Typography
                        gutterBottom
                        sx={{ padding: "10px", width: "75%" }}
                        className="spell-header-bold"
                      >
                        {t("interactive.Episode")}&nbsp;&nbsp; {index + 1}
                      </Typography>
                      <div className="episode-toolbar">
                        <Link
                          component="button"
                          variant="body2"
                          className="episode-expand-link"
                          onClick={handleExpand(index)}
                          value={a[0].expandCollapseValue == "Expand" ? "Collapse" : "Expand"}
                        >
                          {a[0].expandCollapseValue == "Expand" ? t("interactive.collapse") : t("interactive.expand")}
                        </Link>
                        <Tooltip title="">
                          <IconButton
                            onClick={handleClick(index)}
                            size="small"
                            sx={{ ml: 2 }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            className="episode-menuItem"
                            onClick={handleAddEpisodeToSpell}
                          >
                            <ListItemIcon className="episode-listItemIcon">
                              <AddCircleIcon fontSize="small" />
                            </ListItemIcon>
                            {t("interactive.addEpisode")}
                          </MenuItem>
                          <MenuItem
                            className="episode-menuItem"
                            onClick={handleEditEpisode}
                          >
                            <ListItemIcon className="episode-listItemIcon">
                              <EditIcon fontSize="small" />
                            </ListItemIcon>
                            {t("interactive.editEpisode")}
                          </MenuItem>
                          <MenuItem
                            className="episode-menuItem"
                            onClick={handleDeleteEpisode(a[0])}
                          >
                            <ListItemIcon className="episode-listItemIcon">
                              <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            {t("interactive.deleteEpisode")}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    {a[0].expandCollapseValue === "Collapse" ? (
                      <div>
                        <div className="spell-primary-diagnosis">
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="spell-content-bold"
                          >
                            {t("interactive.primaryDiagnosis")}:&nbsp;
                          </Typography>
                          <Typography gutterBottom className="spell-content">
                            {a[0].diagnosis[0]?.name}
                          </Typography>
                        </div>

                        <div className="spell-patientInfo">
                          <Typography
                            gutterBottom
                            sx={{ padding: "10px" }}
                            className="spell-content-bold"
                          >
                            {t("interactive.Patient")}:&nbsp;
                            <Typography gutterBottom className="spell-content">
                              {a[0].genderText[language]},&nbsp;
                              {a[0].age}&nbsp;{a[0].ageTypeText[language]}
                            </Typography>
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <div className="episode-full-information">
                        <Grid container sx={{ padding: "" }}>
                          <Grid item xs>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="grid-header-text"
                            >
                              {t("interactive.patientInformation")}
                            </Typography>
                            <Divider
                              sx={{
                                width: "360px",
                                ml: "10px",
                                borderTopWidth: "thin",
                              }}
                            />
                            <div className="grid-patientInfo">
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.sex")}:&nbsp;
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a[0].genderText[language]}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  sx={{ padding: "10px" }}
                                  className="episode-content-bold"
                                >
                                  {t("interactive.age")}:&nbsp;
                                </Typography>
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a[0].age}&nbsp;{a[0].ageTypeText[language]}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.gestationAge")}:&nbsp;
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a[0].gestationText[language]}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.dischargeType")}:&nbsp;
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a[0].dischargeText[language]}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.contactType")}:&nbsp;
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a[0].contactTypeText[language]}
                                </Typography>
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ padding: "10px" }}
                                className="episode-content-bold"
                              >
                                {t("interactive.priority")}:&nbsp;
                                <Typography
                                  gutterBottom
                                  className="episode-content"
                                >
                                  {a[0].hospWayText[language]}
                                </Typography>
                              </Typography>
                            </div>
                          </Grid>

                          <Grid item xs>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="grid-header-text"
                            >
                              {t("interactive.selectedDiagnosis")}
                            </Typography>
                            <Divider
                              sx={{
                                width: "360px",
                                ml: "10px",
                                borderTopWidth: "thin",
                              }}
                            />
                            <div>
                              {a[0].diagnosis?.map((item, index) => {
                                return (
                                  <div className="episode-selected-diagnosis-item">
                                    <Paper
                                      elevation={0}
                                      className="episode-selected-primary"
                                    >
                                      <Typography
                                        gutterBottom
                                        className="episode-diagnosis-content"
                                      >
                                        <span className="episode-diagnosis-bold">
                                          {item.type}
                                        </span>
                                        &nbsp;&nbsp;
                                        {item.name}
                                      </Typography>
                                    </Paper>
                                    {item.additionalCode.map((b) => {
                                      return (
                                        <Paper
                                          elevation={0}
                                          className="episode-selected-subcode"
                                        >
                                          <Typography
                                            gutterBottom
                                            className="episode-diagnosis-content"
                                          >
                                            <span className="episode-diagnosis-bold">
                                              {b.type}
                                            </span>
                                            &nbsp;&nbsp;
                                            {b.name}
                                          </Typography>
                                        </Paper>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </Grid>
                          <Grid item xs>
                            <Typography
                              gutterBottom
                              sx={{ padding: "10px" }}
                              className="grid-header-text"
                            >
                              {t("interactive.selectedProcedure")}
                            </Typography>
                            <Divider
                              sx={{
                                width: "360px",
                                ml: "10px",
                                borderTopWidth: "thin",
                              }}
                            />
                            <div>
                              {a[0].procedure?.map((item, index) => {
                                return (
                                  <div className="episode-selected-diagnosis-item">
                                    <Paper
                                      elevation={0}
                                      className="episode-selected-primary"
                                    >
                                      <Typography
                                        gutterBottom
                                        className="episode-diagnosis-content"
                                      >
                                        <span className="episode-diagnosis-bold">
                                          {item.type}
                                        </span>
                                        &nbsp;&nbsp;
                                        {item.name}
                                      </Typography>
                                    </Paper>
                                    {item.additionalCode.map((b) => {
                                      return (
                                        <Paper
                                          elevation={0}
                                          className="episode-selected-subcode"
                                        >
                                          <Typography
                                            gutterBottom
                                            className="episode-diagnosis-content"
                                          >
                                            <span className="episode-diagnosis-bold">
                                              {b.type}
                                            </span>
                                            &nbsp;&nbsp;
                                            {b.name}
                                          </Typography>
                                        </Paper>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Paper>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
